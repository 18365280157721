// Store
import { Selector, State } from '@ngxs/store';
// State
import { ApnSearchState } from './apn-search.state';
import { MlsSearchState } from './mls-search.state';
import { AddressSearchState } from './address-search.state';
// Models
import { SearchStateModel } from './../../../models/search/search.model';
import { Injectable } from '@angular/core';

@State<SearchStateModel>({
	name: 'search',
	children: [
		AddressSearchState,
		MlsSearchState,
		ApnSearchState
	]
})
@Injectable()
export class SearchState {
	constructor() {
		//
	}

	/**
	 * Get Feature SearchStateModel State Model.
	 * @param state: SearchStateModel
	 */
	@Selector()
	static getSearch(state: SearchStateModel): SearchStateModel {
		return state;
	}
}