<!--Listing History-->
<div *ngIf="dataSource" class="noPrint">
  <button class="btn btn-primary btn-search" *ngIf="!hasListingHistory" (click)="getListingHistory()" [disabled]="isSearching">
    <span class="fal fa-search icon" *ngIf="!isSearching"></span>
    <span *ngIf="!isSearching">Get Listing History</span>
    <span class="far fa-spinner fa-pulse spinner icon" *ngIf="isSearching"></span>
    <span *ngIf="isSearching">Retrieving Listing History</span>
  </button>

  <div *ngIf="!isSubject">
    <label>Get Listing History Automatically</label>
    <input type="checkbox" class="checkbox" [checked]="userSettings?.alwaysGetListingHistory"
      (change)="onGetListingHistoryAutomaticallyChange($event)" />
  </div>
</div>
<div *ngIf="message" class="noPrint error-msg">
  {{message}}
</div>
<div class="listing-history-results" *ngIf="hasListingHistory">
  <h3 class="table-header">{{dataSource.displayName}} Listing History</h3>
  <table matSort class="table table-hover" (matSortChange)="sortListingHistory($event)">
    <thead>
      <tr>
        <th mat-sort-header="mlsNum" scope="col">MLS #</th>
        <th mat-sort-header="soldDate" scope="col">Sold Date</th>
        <th mat-sort-header="soldPrice" scope="col">Sold Price</th>
        <th mat-sort-header="status" scope="col">Status</th>
        <th mat-sort-header="origListDate" scope="col">Original list Date</th>
        <th mat-sort-header="finalPriceChangeDate" scope="col">Final Price Change Date</th>
        <th mat-sort-header="origListPrice" scope="col">Original List Price</th>
        <th mat-sort-header="finalListPrice" scope="col">Final List Price</th>
        <th mat-sort-header="dom" scope="col">DOM</th>
        <th mat-sort-header="cdom" scope="col">CDOM</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let listingHistory of listingHistoryParcels">
        <td>{{listingHistory.mlsNumber}}</td>
        <td>{{listingHistory.settlementDate | date : 'MM/dd/yyyy'}}</td>
        <td>{{listingHistory.closingPrice | currency:'USD':'symbol':'1.0' }}</td>
        <td>{{listingHistory.listingStatusTypes[0]?.originalValue}}</td>
        <td>{{listingHistory.originalListDate | date : 'MM/dd/yyyy'}}</td>
        <td>{{listingHistory.finalPriceChangeDate | date : 'MM/dd/yyyy'}}</td>
        <td>{{listingHistory.originalListPrice | currency:'USD':'symbol':'1.0' }}</td>
        <td>{{listingHistory.listPrice | currency:'USD':'symbol':'1.0'}}</td>
        <td>{{listingHistory.daysOnMarket}}</td>
        <td>{{listingHistory.cumulativeDaysOnMarket}}</td>
      </tr>
    </tbody>
  </table>
</div>
