export const environment = {
  production: false,
  environment: 'development',
  userNotificationMock: true,
  defaultTheme: 'classic', // options: classic, default
  dashboardLayout: 'web', // options: web, desktop

  classDataApiUrl: 'https://datamaster-api.test.classvaluation.com/',
  authUrl: 'https://dm-identity-web-w-us-apps-integration.azurewebsites.net/',
  dmWebUrl: 'https://datamaster-web.develop.sandbox.app.datamasterusa.com', // default is running locally
  newsFeedUrl: 'https://dm-rssapic-w-us-apps.azurewebsites.net/rssfeed',
  dataMasterApi: 'https://dm-api-web-us-w-apps-integration.azurewebsites.net/api/',
  userNotificationUrl: 'https://localhost:44349/api/Notification/',
};
