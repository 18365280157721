import { PropertyUsageType } from '../../workfile/enum/property-usage-type.enum';
import { SearchCriteriaUserData } from '../property-search/search-criteria.model';
import { PropertyTranslationRequest } from './property-translation-request.model';

export class TranslationRequest {
  propertyUsageType: PropertyUsageType;
  propertyTranslationRequests: PropertyTranslationRequest[];

  ///this userData isn't used in web, but it added here for serialization matching
  userData: any;
  formCommonName: string;
}

export class TranslationRequestWeb {
  translationRequest: TranslationRequest;
  userData: SearchCriteriaUserData;
}
