<app-under-construction *ngIf="!doesUserHaveWebAccess"></app-under-construction>
<div class="sub-menu {{theme}}" [ngStyle]="theme ==='default' ? {'max-width': 'calc(100vw - '+sidebarWidthValue+'px)'} : {}">
  <div class="navbar-nav {{theme === 'classic' ? 'comp-classic' : ''}}" [ngStyle]="theme !== 'classic' ? {'margin-right': sidebarWidth} : {}">
    <div *ngIf="doesUserHaveWebAccess; else disabledButtons">
      <a class="nav-item navbar-link"  (click)="openPropertyImportModal()" style="cursor: pointer;">
        <span class="far fa-search"></span>
        Get Comp(s)
      </a>
      <a class="nav-item navbar-link " routerLink="./deeds" routerLinkActive="active">
        <span class="far fa-file-alt"></span>
        Deeds
      </a>
      <a class="nav-item navbar-link {{isActiveLink}}" routerLink=".">
        <span class="fa fa-th"></span>
        Comp Grid
      </a>
      <a class="nav-item navbar-link " routerLink="./map" routerLinkActive="active">
        <span class="far fa-map"></span>
        Map
      </a>
      <a class="nav-item navbar-link " routerLink="./addenda" routerLinkActive="active">
        <span class="far fa-edit"></span>
        Addenda
      </a>
    </div>
  </div>

  <div class="right-content">
    <app-effective-date-info></app-effective-date-info>
  </div>

</div>
<div class="clear {{theme}}"></div>

<div class="grid-header row" (window:resize)="onResize($event)" #compHeader>
  <div class="col">

    <div class="form">
      <label>Form</label>
      <span class="text">{{formType}}</span>
    </div>

    <div class="comp-summary">
      <span class="comp-count">
        <label>Comp Count</label>
        <span class="count">{{compCount}}</span>
      </span>
      <a href="#" class="btn btn-primary btn-add-comps d-none" data-toggle="modal" data-target="#addComps">Add Comps</a>
    </div>
  </div>
  <div class="col">

    <div class="data-source-list" *ngIf="dataSources && dataSources.length > 0">
      <h6 class="background-fade-out">
        Data sources loaded
      </h6>
      <div class="source-list background-fade-out">
        <div class="row" *ngFor="let source of dataSources">
          <div class="table-layout">
            <div class="source-name">{{source.displayName}}</div>
            <label>{{source.type}}</label>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<router-outlet (activate)="onActivate($event)"></router-outlet>

<ng-template #disabledButtons>
  <a class="nav-item navbar-link ">
        <span class="fas fa-search"></span>
        Get Comp(s)
      </a>
      <a class="nav-item navbar-link ">
        <span class="far fa-file-alt"></span>
        Deeds
      </a>
      <a class="nav-item navbar-link {{isActiveLink}}">
        <span class="fa fa-th"></span>
        Comp Grid
      </a>
      <a class="nav-item navbar-link ">
        <span class="far fa-map"></span>
        Map
      </a>
      <a class="nav-item navbar-link ">
        <span class="far fa-edit"></span>
        Addenda
      </a>
</ng-template>

