import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import {
  ListingHistoryPropertyRequest,
  ListingHistorySearchRequest,
  ListingHistorySearchWebRequest,
  TranslationResult,
  PropertyUsageType,
  StandardizedParcel,
  DataMasterApiService,
  DataMasterFileService,
  UserStateService,
  DataSource,
  UserSettings,
  ListingHistorySearchResult
 } from '@datamaster/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { rejects } from 'assert';

@Component({
  selector: 'app-listing-history',
  templateUrl: './listing-history.component.html',
  styleUrls: ['./listing-history.component.scss'],
})
export class ListingHistoryComponent implements OnInit, OnDestroy {
  getListingHistoryAutomatically: boolean;
  hasListingHistory = false;
  isSearching = false;
  isSubject = false;
  message: string = null;

  dataSource: DataSource = null;
  listingHistoryParcels: StandardizedParcel[];
  _subscription : Subscription = new Subscription();

  @Input() propertyUsageType: PropertyUsageType;
  @Input() userSettings: UserSettings;
  @Input() mlsDataSources: DataSource[];
  @Input() propertyGuid: string;
  @Output() saveUserSettings: EventEmitter<any> = new EventEmitter();

  constructor(
    private dataMasterService: DataMasterApiService,
    private dataMasterFileService: DataMasterFileService,
    private userStateService: UserStateService
  ) {}
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.isSubject = this.propertyUsageType == PropertyUsageType.Subject;
    this.listingHistoryParcels = this.getSortedListingHistory();

    const translatedParcels =
      this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.filter(
        (x) => x.propertyGuid == this.propertyGuid
      );

    if (
      !this.listingHistoryParcels ||
      (this.listingHistoryParcels?.length ?? 0) == 0
    ) {
      this.dataSource = this.getDataSourceForListingHistoryRequest(
        translatedParcels?.map((x) => x.dataSourceId)
      );
    } else {
      this.dataSource = this.mlsDataSources?.find(
        (a) => a.id == this.listingHistoryParcels[0]?.dataSourceId
      );
    }

    if (
      (this.getListingHistoryAutomatically ||
        this.propertyUsageType == PropertyUsageType.Subject) &&
      (this.listingHistoryParcels?.length ?? 0) < 1
    ) {
      this.getListingHistory();
    }

    this.hasListingHistory =
      this.listingHistoryParcels && this.listingHistoryParcels.length > 0;
  }

  getSortedListingHistory(): StandardizedParcel[] {
    return this.dataMasterFileService.dataMasterFile.appraisal.listingHistoryParcels
      ?.filter((a) => a && a.propertyGuid == this.propertyGuid)
      ?.sort((x, y) => (x?.mlsNumber > y?.mlsNumber ? 1 : -1));
  }

  async getListingHistory() {
    if (!this.hasListingHistory && this.dataSource == null) {
      this.message = 'MLS Data Source required to retrieve listing history';
      return;
    }

    this.isSearching = true;
    //create request
    const listingHistoryPropertyRequests: ListingHistoryPropertyRequest[] = [];

    const prioritizedParcel =
      this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(
        (a) => a.propertyGuid == this.propertyGuid
      );
    if (!prioritizedParcel) {
      return;
    }

    const listingHistoryPropertyRequest: ListingHistoryPropertyRequest = {
      propertyGuid: prioritizedParcel.propertyGuid,
      apn: prioritizedParcel.apn,
      mls: prioritizedParcel.mlsNumber,
      houseNumber: prioritizedParcel.houseNumber,
      streetPreDirection: prioritizedParcel.streetPreDirection,
      streetName: prioritizedParcel.streetName,
      streetSuffix: prioritizedParcel.streetSuffix,
      streetPostDirection: prioritizedParcel.streetPostDirection,
      unitNumber: prioritizedParcel.unitNumber,
      city: prioritizedParcel.city,
      county: prioritizedParcel.county,
      state: prioritizedParcel.state,
      zip: prioritizedParcel.zip,
      addressLine1: prioritizedParcel.addressLine1,
      addressLine2: prioritizedParcel.addressLine2,
      fullAddress: prioritizedParcel.fullAddress,
      latitude: prioritizedParcel.latitude,
      longitude: prioritizedParcel.longitude,
    };

    listingHistoryPropertyRequests.push(listingHistoryPropertyRequest);

    let listingHistorySearchResult : ListingHistorySearchResult;

    await this.getListingHistorySearch(listingHistoryPropertyRequests).then(data=>{
        listingHistorySearchResult = data;
      }).catch((err)=>{
        console.log(err);
        throw new Error();
      });
      
    let translationResult;
    await this.getTranslateListingHistory(listingHistorySearchResult).then((data)=>{
      translationResult = data;
    }).catch((err)=>{
      console.log(err);
      throw new Error();
    });
    this.finalizeListingHistoryTranslation(translationResult);
  }
  getTranslateListingHistory(listingHistorySearchResult: ListingHistorySearchResult) : Promise<TranslationResult>{
    // eslint-disable-next-line no-async-promise-executor
    return new Promise( async (resolve, reject)=>{
      
      const translationResult =  await lastValueFrom(this.dataMasterService.translateListingHistory(
        listingHistorySearchResult
      ));
      if (translationResult) {
        resolve(translationResult);
      }else {
        reject();
        throw new Error('');
      }
    });
  }
  getListingHistorySearch(listingHistoryPropertyRequests: ListingHistoryPropertyRequest[]) : Promise<ListingHistorySearchResult>{
    // eslint-disable-next-line no-async-promise-executor
    return new Promise( async (resolve, reject)=>{
      const listingHistorySearchRequest: ListingHistorySearchRequest = {
        dataSourceId: this.dataSource.id,
        listingHistoryPropertyRequests: listingHistoryPropertyRequests,
      };
      const listingHistorySearchWebRequest: ListingHistorySearchWebRequest = {
        listingHistorySearchRequest: listingHistorySearchRequest,
        userData: this.userStateService.userDataForSearch,
      };
      const listingHistorySearchResult =  await lastValueFrom(this.dataMasterService
        .getListingHistory(listingHistorySearchWebRequest));
      if (listingHistorySearchResult) {
        resolve(listingHistorySearchResult);
      }else {
        reject();
        throw new Error('');
      }
    });
  }

  finalizeListingHistoryTranslation(translationResult: TranslationResult) {
    translationResult.translatedPropertyResults.forEach(
      (translatedPropertyResult) => {
        const listingHistoryParcelAlreadyExists =
          this.dataMasterFileService.dataMasterFile.appraisal.listingHistoryParcels.some(
            (x) =>
              x?.propertyGuid ==
                translatedPropertyResult?.translatedParcel?.propertyGuid &&
              x?.mlsNumber ==
                translatedPropertyResult?.translatedParcel?.mlsNumber
          );

        if (listingHistoryParcelAlreadyExists) {
          return;
        }

        this.dataMasterFileService.dataMasterFile.appraisal.listingHistoryParcels.push(
          translatedPropertyResult.translatedParcel
        );
      }
    );

    this.getSortedListingHistory()?.forEach((x) =>
      this.listingHistoryParcels.push(x)
    );
    this.isSearching = false;
    this.hasListingHistory = this.listingHistoryParcels.length > 0;
  }

  getDataSourceForListingHistoryRequest(dataSourceIds: number[]): DataSource {
    if (!dataSourceIds || dataSourceIds.length == 0 || !this.mlsDataSources) {
      return null;
    }

    for (const dataSourceId of dataSourceIds) {
      const dataSource = this.mlsDataSources.find((a) => a.id == dataSourceId);

      if (!dataSource) {
        continue;
      }

      if (this.isDataSourceTypeListingHistoryCompatible(dataSource.type)) {
        return dataSource;
      }
    }

    return null;
  }

  private isDataSourceTypeListingHistoryCompatible(type: string): boolean {
    return type == 'Rets' || type == 'Reso' || type == 'DataMasterMLSGrid';
  }

  sortListingHistory(sort: Sort | any) {
    this.listingHistoryParcels = this.listingHistoryParcels.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'mlsNum':
          return this.compare(a.mlsNumber, b.mlsNumber, isAsc);
        case 'soldDate':
          return this.compare(a.settlementDate, b.settlementDate, isAsc);
        case 'soldPrice':
          return this.compare(a.closingPrice, b.closingPrice, isAsc);
        case 'status':
          return this.compare(
            a.listingStatusTypes[0].originalValue,
            b.listingStatusTypes[0].originalValue,
            isAsc
          );
        case 'origListDate':
          return this.compare(a.originalListDate, b.originalListDate, isAsc);
        case 'finalPriceChangeDate':
          return this.compare(
            a.finalPriceChangeDate,
            b.finalPriceChangeDate,
            isAsc
          );
        case 'origListPrice':
          return this.compare(a.originalListPrice, b.originalListPrice, isAsc);
        case 'finalListPrice':
          return this.compare(a.listPrice, b.listPrice, isAsc);
        case 'dom':
          return this.compare(a.daysOnMarket, b.daysOnMarket, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onGetListingHistoryAutomaticallyChange(e) {
    //save in settings
    this.userSettings.alwaysGetListingHistory = e.target.checked;

    this.saveUserSettings.emit();
  }
}
