import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA, MatDialogRef
  } from '@angular/material/dialog';
  
@Component({
    selector: 'app-core-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: {
        message: string,
        title: string
    }, private mdDialogRef: MatDialogRef<ErrorDialogComponent>) { }

    public close() {
        this.mdDialogRef.close();
      }
      
}
