import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Modules
import { MaterialSharedModule } from '@datamaster/core';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
// Components
import { DeedsDisplayComponent } from './deeds/deeds-display/deeds-display.component';
import { DeedsHeaderComponent } from './deeds/deeds-header/deeds-header.component';
import { DeedsComponent } from './deeds/deeds.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { DualListSelectComponent } from './dual-list-select/dual-list-select.component';
import { EffectiveDateInfoComponent } from './effective-date-info/effective-date-info.component';
import { ErrorComponent } from './error/error.component';
import { ListingHistoryComponent } from './listing-history/listing-history.component';
import { MultipleResultsTableComponent } from './multiple-results-table/multiple-results-table.component';
import { NewsComponent } from './news/news.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PrimaryDatasourceDropdownComponent } from './primary-datasource-dropdown/primary-datasource-dropdown.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { TellMeMoreComponent } from './tell-me-more/tell-me-more.component';

const EXPORTS = [
    DeedsComponent,
    DeedsDisplayComponent,
    DisclaimerComponent,
    DualListSelectComponent,
    EffectiveDateInfoComponent,
    ErrorComponent,
    ListingHistoryComponent,
    MultipleResultsTableComponent,
    NewsComponent,
    NotFoundComponent,
    PrimaryDatasourceDropdownComponent,
    UnderConstructionComponent,
    TellMeMoreComponent,
];

const DECLARATIONS = [...EXPORTS, DeedsHeaderComponent];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialSharedModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
    ],
    declarations: [...DECLARATIONS, ],
    exports: [...EXPORTS],
})
export class CommonComponentsModule {}
