import { PropertyUsageType } from '../../workfile/enum/property-usage-type.enum'
import { MarketCondition } from '../../workfile/market-condition.model';
import { StandardizedParcel } from '../../workfile/standardized-parcel.model';
import { SearchCriteriaUserData } from '../property-search/search-criteria.model';

export class MarketConditionsCalculationRequest {
  marketConditions: MarketCondition[];
  prioritizedParcels: StandardizedParcel[];
  propertyUsageType: PropertyUsageType;
  effectiveDate: Date;
  userData: SearchCriteriaUserData;
  formCommonName: string;
}
