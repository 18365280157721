import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DataMasterFileService } from '@datamaster/core';

@Component({
  selector: 'app-effective-date-info',
  templateUrl: './effective-date-info.component.html',
  styleUrls: [
    './effective-date-info.component.scss'
  ],
})
export class EffectiveDateInfoComponent implements OnInit {
  model: NgbDateStruct;
  @Output() effectiveDateChange: EventEmitter<Date> = new EventEmitter();
  regexpDate = new RegExp('');

  constructor(
    private calendar: NgbCalendar,
    private dataMasterFileService: DataMasterFileService
  ) {}

  ngOnInit(): void {
    this.model = this.calendar.getToday();
  }

  selectToday() {
    this.model = this.calendar.getToday();
    this.effectiveDateChange.emit(this.getDate(this.model.year, this.model.month, this.model.day));
  }

  ngModelChangeEvent(event) {
    let newEffectiveDate;
    if (typeof event === 'string') newEffectiveDate = new Date(event);
    else newEffectiveDate = this.getDate(event.year, event.month, event.day);
    this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate = newEffectiveDate;
    this.effectiveDateChange.emit(newEffectiveDate);
  }

  private getDate(year : number, month : number, day : number){
    //Javascript: Integer value representing the month, beginning with 0 for January to 11 for December.
    return new Date(this.model.year, this.model.month - 1, this.model.day);
  }
}
