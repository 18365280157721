
import { FormattedField } from '../models/workfile/formatted-field.model'
import { FormatFieldMap } from '../models/format-field-map.model';

export class FormattingHelper {
  public static getFormattedFieldResultFormattedValue(field: string, sectionType: string, formattedFieldResults: { [index: number]: FormattedField }, formatFieldMaps: FormatFieldMap[]): string {

    const formSoftwareFormSheetSectionFieldId = FormattingHelper.getFormSoftwareFormSheetSectionFieldId(field, sectionType, formatFieldMaps);
    if (!formSoftwareFormSheetSectionFieldId) {
      return null;
    }

    if (!formattedFieldResults || !(formSoftwareFormSheetSectionFieldId in formattedFieldResults)) {
      return null;
    }

    const formattedFieldResult = formattedFieldResults[formSoftwareFormSheetSectionFieldId];

    if (!formattedFieldResult) {
      return null;
    }
    return formattedFieldResult.formattedValue

  }

  public static setFormattedFieldResultFormattedValue(field: string, sectionType: string, formattedFieldResults: { [index: number]: FormattedField }, formatFieldMaps: FormatFieldMap[], updatedFieldResult: string) {
    const formSoftwareFormSheetSectionFieldId = FormattingHelper.getFormSoftwareFormSheetSectionFieldId(field, sectionType, formatFieldMaps);
    if (!formSoftwareFormSheetSectionFieldId) {
      return null;
    }

    if (!formattedFieldResults || !(formSoftwareFormSheetSectionFieldId in formattedFieldResults)) {
      return null;
    }
    const formattedField = new FormattedField();
    formattedField.formattedValue = updatedFieldResult;

    formattedFieldResults[formSoftwareFormSheetSectionFieldId] = formattedField;
  }

  public static getFormSoftwareFormSheetSectionFieldId(field: string, sectionType: string, formatFieldMaps: FormatFieldMap[]): number {
    const formSoftawreFormSheetSectionField = formatFieldMaps?.find(a => a.fieldCommonName == field && a.sectionPropertyUseType == sectionType);
    if (!formSoftawreFormSheetSectionField) {
      return null;
    }

    return formSoftawreFormSheetSectionField.formSoftwareFormSheetSectionFieldId;
  }
}
