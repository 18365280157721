<app-search-error #searchError></app-search-error>

<form id="addressSearchForm" [formGroup]="addressSearchForm" #addressSearch="ngForm">
    <div class="form-row">
        <div class="form-group col-12">
            <label for="address">
                Address
                <span class="required"
                    *ngIf="(addressSearchForm.controls['address'].invalid && addressSearchForm.controls['address'].touched)">required</span>
            </label>
            <input type="text" formControlName="address" class="full-width" data-cy="searchResultsAddressInput"/>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-7">
            <label for="city">
                City
            </label>
            <input type="text" formControlName="city" class="full-width" data-cy="searchResultsAddressCityInput"/>
        </div>
        <div class="form-group col-5">
            <label for="state">
                State
                <span class="required"
                    *ngIf="(addressSearchForm.controls['state'].invalid && addressSearchForm.controls['state'].touched)">required</span>
            </label>
            <select formControlName="state" class="full-width" data-cy="searchResultsAddressStateSelect">
                <option *ngFor="let state of stateJson " [ngValue]="state.Abbreviation">{{state.State}}</option>
            </select>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-4">
            <label for="zip">
                Zip
            </label>
            <input type="text" formControlName="zip" class="full-width" data-cy="searchResultsAddressZipInput"/>
        </div>

        <div class="form-group col-8">
            <label for="county">
                County
                <span class="required"
                    *ngIf="(addressSearchForm.controls['county'].invalid && addressSearchForm.controls['county'].touched)">required</span>
            </label>
            <select formControlName="county" class="full-width" data-cy="searchResultsAddressCountySelect">
                <!--<option *ngFor="let county of countiesJson" [ngValue]="county.CountyName">{{county.CountyName}}</option>-->
                <option *ngFor="let county of countiesJsonAddressSearch" [ngValue]="county.CountyName">
                    {{county.CountyName}}</option>
            </select>
        </div>
    </div>
    <div class="submit search">

        <button type="button" class="btn reset-button" (click)="resetForm()" data-cy="searchResultsAddressResetButton">
            <span class="far fa-redo icon"></span>
            Reset
        </button>

        <button type="submit" class="btn btn-primary btn-search" (click)="searchValidate(addressSearchForm)" [disabled]="isFormSearching(addressSearchForm)" data-cy="searchResultsAddressSearchButton">
            <span class="fal fa-search icon" *ngIf="!isFormSearching(addressSearchForm)"></span>
            <span *ngIf="!isFormSearching(addressSearchForm)">Search</span>
            <span class="far fa-spinner fa-pulse spinner icon" *ngIf="isFormSearching(addressSearchForm)"></span>
            <span *ngIf="isFormSearching(addressSearchForm)">Searching...</span>
        </button>

    </div>
</form>