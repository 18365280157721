import { UserStateModel } from '../../../../models/user/user-state.model';

export class SetUserData {
	static readonly type = '[Auth] Set UserData';
    constructor(public userData: UserStateModel) {}
}

export class RefreshUserData {
	static readonly type = '[Auth] Refresh UserData';
    constructor(public username?: string) {}
}

export class SetUserBillingPortalUrl {
	static readonly type = '[Auth] User Set Token';
    constructor(public url: string) {}
}

export class SetUserPropertySearchAccess {
	static readonly type = '[Auth] User Set PropertySearch Access';
}

export class SetUserIsImpersonating {
	static readonly type = '[Auth] User Set isImpersonating';

    constructor(public isImpersonating: boolean) {}
}

export class Logout {
	static readonly type = '[Auth] User Log out';
}
