import { Component, OnInit } from '@angular/core';
import { DataSourceService } from '@datamaster/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {

  currentYear: number = new Date().getFullYear();
  mlsNames : string;

  constructor(private dataSourceService : DataSourceService) { }

  ngOnInit(): void {
    
    this.mlsNames = this.dataSourceService.getMLSDataSources()?.map(a=> a.displayName).toString();
  }

}
