import { Component, OnInit } from '@angular/core';
import { MlsSearchComponent } from '../mls-search/mls-search.component';

@Component({
  selector: 'app-dialog-mls-search',
  templateUrl: './dialog-mls-search.component.html',
  styleUrls: ['./dialog-mls-search.component.scss']
})
export class DialogMlsSearchComponent extends MlsSearchComponent {

}
