import { Component, OnDestroy} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerService } from '../../../services/spinner.service';
import { RefreshUserData, SetUserIsImpersonating } from '../../../services/user/state';
import { UserStateService } from '../../../services/user/user-state.service';

@Component({
  selector: 'app-log-in-as',
  templateUrl: './log-in-as.component.html',
  styleUrls: ['./log-in-as.component.scss'],
})
export class LogInAsComponent implements  OnDestroy {
  private _subscription: Subscription = new Subscription();

  email: string;
  error: string;

  constructor(
    private store: Store,
    private userStateService: UserStateService,
    private mdDialogRef: MatDialogRef<LogInAsComponent>,
    private spinnerService: SpinnerService
  ) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  impersonate() {
    if (!this.email) {
      this.error = `Email is required.`;
      return;
    }

    const spinner = this.spinnerService.open();
    this.store.dispatch(new RefreshUserData(this.email));
    this.store.dispatch(new SetUserIsImpersonating(true));

    this.onClose();
    spinner.close();

    // Error for later
    // (error) => {
    //     console.log(error);
    //     spinner.close();
    //     this.error = `Unable to log in as ${this.email}.`;
    //   }

    // const impersonating$ = this.userStateService
    //   .refresh(this.email)
    //   .pipe(
    //     tap((_) => {
    //       this.userStateService.userState.isImpersonating = true;
    //       this.onClose();
    //       spinner.close();
    //     })
    //   )
    //   .subscribe(
    //     // eslint-disable-next-line @typescript-eslint/no-empty-function
    //     (_) => {},
    //     (error) => {
    //       console.log(error);
    //       spinner.close();
    //       this.error = `Unable to log in as ${this.email}.`;
    //     }
    //   );

    // this._subscription.add(impersonating$);
  }

  onClose() {
    this.mdDialogRef.close({ event: 'Cancel' });
  }
}
