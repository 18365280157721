
<h4 *ngIf="isDashboardSearch === true">Start a new file by retrieving subject property info</h4>

<app-search-error #searchError></app-search-error>

<form id="apnSearchForm" [formGroup]="apnSearchForm">

  <div class="form-group">
    <label for="apnNumber">
      APN Number
      <span class="required" *ngIf="(apnSearchForm.controls['apnNumber'].invalid && apnSearchForm.controls['apnNumber'].touched)" data-cy="homeApnRequiredLabel">required</span>
    </label>
    <input type="text" placeholder="APN Number" formControlName="apnNumber" data-cy="homeApnNumberInput"/>
  </div>
  <div class="form-row">
    <div class="form-group col-5">
      <label for="state">
        State
        <span class="required" *ngIf="(apnSearchForm.controls['state'].invalid && apnSearchForm.controls['state'].touched)">required</span>
      </label>
      <select formControlName="state" data-cy="homeApnStateSelect">
        <option *ngFor="let state of stateJson" [ngValue]="state.Abbreviation">{{state.State}}</option>
      </select>
    </div>
    <div class="form-group col">
      <label for="county">
        County
        <span class="required" *ngIf="(apnSearchForm.controls['county'].invalid && apnSearchForm.controls['county'].touched)">required</span>
      </label>
      <select formControlName="county" data-cy="homeApnCountySelect">
        <!--<option *ngFor="let county of countiesJson" [ngValue]="county.CountyName">{{county.CountyName}}</option>-->
        <option *ngFor="let county of countiesJsonApnSearch" [ngValue]="county.CountyName">{{county.CountyName}}</option>
      </select>
    </div>
  </div>

  <div class="submit search">
    <!-- <button type="button" class="btn btn-link btn-sm show-results" (click)="showResults()" *ngIf="hasSearchResultsApn">
      show search results
    </button> -->

    <button type="submit" class="btn btn-primary btn-search" (click)="searchValidate(apnSearchForm)" [disabled]="isFormSearching(apnSearchForm)" data-cy="homeApnSearchButton">
      <span class="fal fa-search" *ngIf="!isFormSearching(apnSearchForm)"></span>
      <span *ngIf="!isFormSearching(apnSearchForm)">Search</span>
      <span class="far fa-spinner fa-pulse spinner" *ngIf="isFormSearching(apnSearchForm)"></span>
      <span *ngIf="isFormSearching(apnSearchForm)">Searching...</span>
    </button>
  </div>

</form>

