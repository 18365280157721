import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MarketAnalysisReportService,
  MarketConditionsForm,
  DataMasterFileService,
  PropertyUsageType,
  MarketConditionsGraph,
  UserSettings,
  GraphsService,
  UserSettingsService,
  UserStateService,
  Graph} from '@datamaster/core';
import { McGraphComponent } from '../../graphs/mc-graph/mc-graph.component';
import { filter, lastValueFrom, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-neighborhood',
  templateUrl: './neighborhood.component.html',
  styleUrls: ['./neighborhood.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NeighborhoodComponent implements OnInit {
  hasCompetingMarket : boolean;
  @Input() CompetingMarketForm : MarketConditionsForm;
  canvasQuery: HTMLCanvasElement[];
  marketConditionsGraph: MarketConditionsGraph = new MarketConditionsGraph();
  neighborhoodGraph: MarketConditionsGraph = new MarketConditionsGraph();
  userSettings: UserSettings;
  neighborhoodGraphs?: MarketConditionsGraph[];
  marketConditionsGraphs?: MarketConditionsGraph[];
  effectiveDate: Date;
  get selectedGraph(): string {
    return this.neighborhoodGraph?.commonName ?? 'TotalSales';
  }
  private _subscription = new Subscription();
  get NeighborhoodGraph(): MarketConditionsGraph {
    return this.neighborhoodGraph;
  }
  @Input() NeighborhoodAnalysisForm : MarketConditionsForm;
  
  constructor(private _marketAnalysisReportService: MarketAnalysisReportService,
    private _userSettingsService: UserSettingsService,
    private _userStateService: UserStateService,
    public dataMasterFileService: DataMasterFileService,
    private _graphsService: GraphsService,
    private router: Router,
  ) {
  }

ngOnInit(): void {
    this.initialize();
    const getGraphs$ = this._graphsService.getGraphs(this._userStateService.user.user.userName);
      this._subscription.add(getGraphs$.subscribe(results =>{
        results.userGraphs.map(graph=> this.getGraphList(graph, this.selectedGraph));
      }));
      this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((params : any) => {
        if (params.url.includes('true')) {
          this.initialize(); 
        }         
      });
}

  initialize() {
    this.userSettings = this._userSettingsService.userSettings;
    this.hasCompetingMarket = this.dataMasterFileService.dataMasterFile.appraisal.HasNeighborhoodAnalysisMcProperty 
      && this.dataMasterFileService.dataMasterFile.appraisal.HasMcProperties;
    this.effectiveDate = this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate;
    this.neighborhoodGraphs = this._marketAnalysisReportService.marketConditionsGraphs?.filter(
      (x) => x.graphDetails.reportUsageType == PropertyUsageType.NeighborhoodAnalysis);
    this.marketConditionsGraphs = this._marketAnalysisReportService.marketConditionsGraphs?.filter(
      (x) => x.graphDetails.reportUsageType == PropertyUsageType.MarketConditions);
    if (this.hasCompetingMarket) {
      const getGraphs$ = this._graphsService.getGraphs(this._userStateService.user.user.userName);
      this._subscription.add(getGraphs$.subscribe(results =>{
         this.reInitGraphs();
         results.userGraphs.map(graph=> this.getGraphList(graph, this.selectedGraph));
      }));
      
    }
  }
  
  reInitGraphs() {
    this.marketConditionsGraphs = this._marketAnalysisReportService.marketConditionsGraphs?.filter((a) => a.graphDetails.reportUsageType == PropertyUsageType.MarketConditions);
    this.neighborhoodGraphs = this._marketAnalysisReportService.marketConditionsGraphs?.filter((a) => a.graphDetails.reportUsageType == PropertyUsageType.NeighborhoodAnalysis);
  }

  getGraphList(graph: Graph, commonName: string) {
    if (graph.commonName == commonName) {
      this.marketConditionsGraph = this.marketConditionsGraphs?.find((a) => a.commonName == graph.commonName);
      this.neighborhoodGraph = this.neighborhoodGraphs?.find((a) => a.commonName == graph.commonName);
    }
  }

  changeGraph(neighborhoodGraph: MarketConditionsGraph) {
    const getGraphs$ = this._graphsService.getGraphs(this._userStateService.user.user.userName);
      this._subscription.add(getGraphs$.subscribe(results =>{
        results.userGraphs.map(graph=> this.getGraphList(graph, neighborhoodGraph.commonName));
      }));
  }

}
