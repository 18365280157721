import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-file-alt',
  templateUrl: './new-file-alt.component.html',
  styleUrls: ['./new-file-alt.component.scss']
})
export class NewFileAltComponent implements OnInit {
  selectedVendorImgSrc: string;
  selectedVendorImgHeight: number;
  selectedVendorImgHeightResults: number;
  selectedVendorImgAlt: string;

    // TODO:  Pull these from a source
  appraisalFormTypes = [{ text: '1004 URAR - UAD', value: '1004' }, { text: '1073', value: '1073' }];

  @Input() newFileForm: FormGroup;

  constructor(private router: Router, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.selectedVendorImgSrc = '/assets/images/vendors/alamode.png';
    this.selectedVendorImgHeight = 25;
    this.selectedVendorImgAlt = 'a la mode';
    this.selectedVendorImgHeightResults = 24;

    //$("#exampleModal").modal("show");

    this.newFileForm = new FormGroup({
      fileName: new FormControl('', Validators.required),
      formType: new FormControl('', Validators.required),
      mlsFileUpload: new FormControl(''),
      //file: new FormControl(null, [Validators.required, requiredFileType('png')])
      file: new FormControl('')  // TODO:  Add file type validators (.txt, .csv, .dm, .wfr)
    });

    this.newFileForm.controls['formType'].setValue('1004');
  }

  // Validates the "New File" form
  validate() {
    if (this.newFileForm.valid === false) {
      //this.newFileForm.controls[''].markAsTouched();
      for (const item in this.newFileForm.controls) {
        //console.log(this.newFileForm.controls[item]);
        this.newFileForm.controls[item].markAsTouched();
      }
    } else {
      // TODO: create the new work file

      this.router.navigate(['/Comparables']);
    }
  }

  updateVendor(e) {
    if (e.srcElement.localName === 'img') {
      //console.log(e.srcElement.src);
      this.selectedVendorImgSrc = e.srcElement.src;
      this.selectedVendorImgHeight = e.srcElement.height;
      this.selectedVendorImgAlt = e.srcElement.alt;
    } else {
      //console.log(e.srcElement.children[0].src);
      this.selectedVendorImgSrc = e.srcElement.children[0].src;
      this.selectedVendorImgHeight = e.srcElement.children[0].height;
      this.selectedVendorImgAlt = e.srcElement.children[0].alt;
    }
  }

}
