
<h3>General Settings</h3>

<div class="setting-group">
    <h6 class="setting-group-heading">MLS</h6>
    <!-- <mat-checkbox [(ngModel)]="">Use MLS to get Public Records</mat-checkbox>
    <mat-checkbox [disabled]="true">Use Public Records to get MLS</mat-checkbox> -->
    <mat-checkbox [checked]="importPhotosFromRets">Get Photos from MLS</mat-checkbox>
    <mat-checkbox *ngIf="getMainPhotoOnlyIsEnabled" [checked]="getMainPhotoOnly" class="subcheckbox">Get Main Photo Only</mat-checkbox>
    <mat-checkbox  [checked]="includePhotosFromReport">Import photos into appraisal file</mat-checkbox>

    <section class="importPhotoSizeGroup">
        <mat-radio-group [value]="importPhotosSize" aria-label="Select an option">
            <mat-radio-button value="1">Small</mat-radio-button>
            <mat-radio-button value="2">Medium</mat-radio-button>
            <mat-radio-button value="3">Large</mat-radio-button>
        </mat-radio-group>
    </section>
</div>

<div class="setting-group">
    <h6 class="setting-group-heading">File</h6>
    <!-- <mat-checkbox [(ngModel)]="">Use File to get MLS</mat-checkbox>
    <mat-checkbox [disabled]="true">Use File to get Public Records</mat-checkbox> -->
    <mat-checkbox [checked]="importPhotosFromWeb">Get Photos from link in File/Web</mat-checkbox>
</div>

<div class="setting-group">
    <h6 class="setting-group-heading">Deeds</h6>
    <mat-checkbox [checked]="skipDeedSelection">Skip Deed Selection</mat-checkbox>
    <mat-checkbox [checked]="alwaysGetListingHistory">Always get Listing History</mat-checkbox>
    <!-- <mat-checkbox >Always get Listing History</mat-checkbox> -->
</div>


<div class="setting-group">
    <h6 class="setting-group-heading">Exceptions Report</h6>
    <mat-checkbox [checked]="alwaysShowExceptionsReport">Always Show Exceptions Report</mat-checkbox>
    <mat-checkbox [checked]="includeRealEstateAgentRemarksInExceptionsReport">Include Real Estate Agent Remarks in Exceptions Report</mat-checkbox>
</div>

<div class="setting-group">
    <h6 class="setting-group-heading">Navigation</h6>
    <label class="setting-drop-down-label">Default Navigation Location</label>
    <select name="defaultNaviagtionLocation" [value]="selectedDefaultNavigationLocation">
        <option *ngFor="let defaultNaviagtionLocationOption of defaultNavigationLocationOptions" [value]="defaultNaviagtionLocationOption">
            {{defaultNaviagtionLocationOption}}
        </option>
    </select>
    <!-- <mat-checkbox [(ngModel)]="alwaysShowExceptionsReport">Default Navigation Location</mat-checkbox> -->
</div>

<div class="setting-group">
    <h6 class="setting-group-heading">Settings for a la mode</h6>
    <mat-checkbox [checked]="sendDeedAndListingHistory">Send Deed and Listing History to the Total Workfile</mat-checkbox>
    <mat-checkbox [checked]="sendMlsSheets">Send MLS Sheets to the Total Workfile</mat-checkbox>
</div>

<div class="setting-group">
    <mat-checkbox [checked]="overwriteFieldsWithBlanks">Overwrite fields in your report with a blank if the imported data is blank</mat-checkbox>
</div>
