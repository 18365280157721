import { OperationResult } from '../operation-result.model';
import { PropertyUsageType } from '../../workfile/enum/property-usage-type.enum';
import { SearchProviderResult } from './search-provider-result.model';
import { SearchCriteriaUserData } from './search-criteria.model';

export class SearchResult extends OperationResult {
  propertyUsageType: PropertyUsageType;
  propertySearchRequest: any; //PropertySearchRequest;
  searchProviderResults: SearchProviderResult[];
}

export class SearchResultRequest {
  searchResult: SearchResult;
  userData: SearchCriteriaUserData;

  constructor(searchResult: SearchResult, userData: SearchCriteriaUserData) {
    this.searchResult = searchResult;
    this.userData = userData;
  }
}
