import { SearchResult } from './search-result.model';
import { PropertyUsageType } from '../../workfile/enum/property-usage-type.enum';
import { Address } from '../../workfile/address.model';

export enum SearchType {
  APN = 'APN',
  MLS = 'MLS',
  Address = 'Address',
  CompTracker = 'CompTracker',
  PlatMap = 'PlatMap'
}

export class SearchCriteria {
  searchType: SearchType;
  propertyUsageType: PropertyUsageType;
  mlsNumbers: string;
  apnNumbers: string;
  address: Address;
  selectedDataSourceId: number;
  dataSourceIds: number[];
  primarySearchResult: SearchResult;
  userData: SearchCriteriaUserData;

  fileNumber: string;
  fileId : string; //GUID //add back in for platmaps if we implement

}

export class SearchCriteriaUserData {
  authUserId: number; //this would ideally be verified server side with the access token
  email: string;
  fusebillCustomerId: number; //this would ideally be verified server side with the access token
  isInternal: boolean; //this should be verified or retrieved in the server side web api
  ip: string;
}
