import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
// Service
import { ConfigService } from '../dependency-issues';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  currentTheme: Subject<string> = new Subject();
  startupTheme: string = this.configService.options.defaultTheme;

  currentDashboardLayout: Subject<string> = new Subject();
  startupDashboardLayout: string = this.configService.options.dashboardLayout;

  constructor(private configService: ConfigService) {
    //
  }

  getCurrentTheme(): Observable<string> {
    // if (this.currentTheme === undefined) {
    //   this.currentTheme.next(this.configService.options.defaultTheme);
    // }
    return this.currentTheme.asObservable();
  }

  setCurrentTheme(value: string): void {
    //this.startupTheme = value;
    this.currentTheme.next(value);
    this.startupTheme = value;
  }

  getCurrentDashboardLayout(): Observable<string> {
    return this.currentDashboardLayout.asObservable();
  }

  setCurrentDashboardLayout(value: string): void {
    this.currentDashboardLayout.next(value);
    this.startupDashboardLayout = value;
  }

}
