<div class="container-fluid">
    <h1 class="font-weight-light">Plat Maps</h1>

    <div class="mb-3">
        <button class="btn btn-plat" [disabled]="isRetrievingPlat" (click)="getPlatFromPublicRecords()" data-cy="getPlatMapButton">
            <span *ngIf="!isRetrievingPlat" class="far fa-plus-circle icon"></span>
            <span *ngIf="!isRetrievingPlat">Get Plat From Public Records</span>
            <span *ngIf="isRetrievingPlat" class="far fa-spinner fa-pulse spinner icon" ></span>
            <span *ngIf="isRetrievingPlat">Getting Plat From Public Records...</span>
        </button>
        <span *ngIf="dataSourceErrorMessage" class="alert alert-danger" style="margin-left: 1em;">{{dataSourceErrorMessage}}</span>
    </div>
    <div class="mb-3">
        <label class="btn btn-plat" data-cy="uploadPlatMapButton">
            <i class="fal fa-file-upload icon"></i> Upload Plat From Computer <input #file type="file" accept='image/*' style="display: none;" (change)="preview(file.files)" />
        </label>
        <span *ngIf="uploadDataErrorMessage" class="alert alert-danger" style="margin-left: 1em;">{{uploadDataErrorMessage}}</span>
    </div>
    <div class="mb-3">
        <button *ngIf="platMapURL" class="btn btn-plat" (click)="clearPlat()" data-cy="clearPlatMapButton">
            <span class="fas fa-times-circle icon"></span>
            Clear Plat
        </button>
    </div>
    <div class="description mb-3">
        Plat maps are a paid service provided by DataMaster to streamline your appraisal workflow. By clicking the “Get
        Plat From Public Record” button, additional charges may apply. You can also upload your own plats for free using
        the “Upload Plat From Computer” button.
    </div>
    <pinch-zoom *ngIf="platMapURL" [limit-zoom]='10' class="mb-3">
        <img [src]="platMapURL | safe: 'url'" />
    </pinch-zoom>
    <div class="font-italic font-weight-light mb-3">
        Plat maps are not available in all counties. Please see the <a href="http://www.datamasterusa.com/plat-maps"
            target="_blank">coverage map</a>.
    </div>
</div>