import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MarketAnalysisReportService,
  MarketConditionsForm,
  DataMasterFileService,
  PropertyUsageType,
  AppraisalFormService,
  MCComments,
  CommentSectionType, 
  UserSettingsService} from '@datamaster/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NeighborhoodComponent } from './neighborhood/neighborhood.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, OnDestroy {
  @ViewChild('neighborhoodComponent', { static: false }) neighborhoodComponent!: NeighborhoodComponent; 
  commentSectionType = CommentSectionType;
  marketConditionsForm!: MarketConditionsForm;
  projectMarketConditionsForm!: MarketConditionsForm;
  neighborhoodAnalysisForm: MarketConditionsForm;
  mcComments!: MCComments;
  showProjectMarketConditions!: boolean;
  isCompetingMarketActive = true;  
  isNeighborhoodAnalysis!: boolean;
  private _mcPropertyUsageType: PropertyUsageType = PropertyUsageType.MarketConditions;
  public get mcPropertyUsageType(): PropertyUsageType {
    return this._mcPropertyUsageType;
  }
  public set mcPropertyUsageType(value: PropertyUsageType) {
    this._mcPropertyUsageType = value;
    this.isNeighborhoodAnalysis = this._mcPropertyUsageType == PropertyUsageType.NeighborhoodAnalysis;
  }
  showSummarySection = false;
  hasCompetingMarket!: boolean;
  mcCount!: number;
  effectiveDate!: Date;
  lastDate!: Date;

  projectName!: string;
  projectType!: string;
  private _requestCommentSubscription: any;

  constructor(
    private maReportService: MarketAnalysisReportService,
    private router: Router,
    private dataMasterFileService: DataMasterFileService,
    private appraisalFormService: AppraisalFormService,
    private _userSettingsService: UserSettingsService
  ) {}

  ngOnInit() {
    this.initialize();
    //not sure if this is best approach
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((params : any) => {
        this.mcPropertyUsageType = params.url.includes('true') ? PropertyUsageType.NeighborhoodAnalysis : PropertyUsageType.MarketConditions;
        this.initialize();
      });
  }

  initialize() {   
    this.getDateRangeForMarketAnalysisGraphsData();
    this.mcCount = this.getMarketConditionsCount();
    this.hasCompetingMarket = this.dataMasterFileService.dataMasterFile.appraisal.marketConditions?.filter(
      (a) => a.propertyUseType === PropertyUsageType.MarketConditions).length > 0 && this.dataMasterFileService.dataMasterFile.appraisal.marketConditions?.filter(
      (a) => a.propertyUseType === PropertyUsageType.NeighborhoodAnalysis).length > 0;
    this.showProjectMarketConditions =
      this.isCompetingMarketActive &&
      this.appraisalFormService.isCondoForm(
        this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form.commonName
      );
    if (this.showSummarySection || this.mcCount > 0) {
      this.showSummarySection = this.mcPropertyUsageType == PropertyUsageType.MarketConditions ? this.dataMasterFileService.dataMasterFile.appraisal.applyNeighborhoodSectionToMarketConditions :
          this.dataMasterFileService.dataMasterFile.appraisal.applyNeighborhoodSectionToNeighborhoodGreaterMarket;
    }
    this._requestCommentSubscription = this.maReportService
      .requestMCComments()
      .subscribe((mcComments) => {
        this.mcComments = mcComments;
      });
    
    this.marketConditionsForm = this.maReportService.marketConditionsForm;
    this.projectMarketConditionsForm = this.maReportService.projectMarketConditionsForm;
    this.neighborhoodAnalysisForm = this.maReportService.neighborhoodAnalysisForm;
  }
  // Gets the value by property name (string)
  // TODO:  I don't like the name of "parentProperty", rename to better descriptor
  getPropertyValueByName(parentProperty:any, propertyName:any) {
    for (const item in parentProperty) {
      if (item === propertyName) {
        return parentProperty[item];
      }
    }
    return null;
  }
  refresh() {
    this.initialize();
  }

  getMarketConditionsPropertyCount() {
    if (this.isCompetingMarketActive) {
      return (
        this.dataMasterFileService.dataMasterFile.appraisal.marketConditions?.filter(
          (a) => a.propertyUseType === PropertyUsageType.MarketConditions
        ) ?? 0
      );
    }

    return (
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions?.filter(
        (a) => a.propertyUseType === PropertyUsageType.NeighborhoodAnalysis
      ) ?? 0
    );
  }

  getProjecMarketConditionsPropertyCount() {
    return (
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions?.filter(
        (a) => a.propertyUseType === PropertyUsageType.ProjectMarketConditions
      )?.length ?? 0
    );
  }

  getMarketConditionsCount(): number {
    if (this.isCompetingMarketActive && (this.mcPropertyUsageType != PropertyUsageType.NeighborhoodAnalysis)) {
      return (
        this.dataMasterFileService.dataMasterFile.appraisal.marketConditions?.filter(
          (a) => a.propertyUseType === PropertyUsageType.MarketConditions).length);
    }
    return (
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions?.filter(
        (a) => a.propertyUseType === PropertyUsageType.NeighborhoodAnalysis).length);
  }

  updateInputModelChange(event:any) {
    console.log(event);
  }

  setTrendUserInput(e:any, field: string) {
    const sectionType = e.target.value;

    const formSoftwareFormSheetSectionFieldIdsToBlank = this.dataMasterFileService.formatFieldMaps
      .filter((a) => a.fieldCommonName.includes(field))
      ?.map((a) => a.formSoftwareFormSheetSectionFieldId);

    const formSoftwareFormSheetSectionFieldId = this.dataMasterFileService.formatFieldMaps.find(
      (a) => a.fieldCommonName == field + sectionType
    )?.formSoftwareFormSheetSectionFieldId;
    if (!formSoftwareFormSheetSectionFieldId) {
      return;
    }

    formSoftwareFormSheetSectionFieldIdsToBlank.forEach((formSoftwareFormSheetSectionFieldId) => {
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditionsFormattedFieldResults.set(
        formSoftwareFormSheetSectionFieldId,
        ''
      );
    });

    this.dataMasterFileService.dataMasterFile.appraisal.marketConditionsFormattedFieldResults.set(
      formSoftwareFormSheetSectionFieldId,
      'X'
    );
  }

  getTrendUserInput(sectionType: string, field: string) {
    const formSoftwareFormSheetSectionFieldId = this.dataMasterFileService.formatFieldMaps?.find(
      (a) => a.fieldCommonName == field + sectionType
    )?.formSoftwareFormSheetSectionFieldId;
    if (!formSoftwareFormSheetSectionFieldId) {
      return false;
    }

    if (
      !this.dataMasterFileService.dataMasterFile.appraisal.marketConditionsFormattedFieldResults.has(
        formSoftwareFormSheetSectionFieldId
      )
    ) {
      return false;
    }
    const value =
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditionsFormattedFieldResults.get(
        formSoftwareFormSheetSectionFieldId
      );
    return value && value === 'X';
  }

  applyToNeighborhoodSectionChecked(e): void {
    this.showSummarySection = e.target.checked;
    if (this.showSummarySection && this.mcPropertyUsageType != PropertyUsageType.NeighborhoodAnalysis) {
      this.dataMasterFileService.dataMasterFile.appraisal.applyNeighborhoodSectionToMarketConditions = e.target.checked;
      this.dataMasterFileService.dataMasterFile.appraisal.applyNeighborhoodSectionToNeighborhoodGreaterMarket = !e.target.checked;
    } else if (this.showSummarySection) {
      this.dataMasterFileService.dataMasterFile.appraisal.applyNeighborhoodSectionToNeighborhoodGreaterMarket = e.target.checked;
      this.dataMasterFileService.dataMasterFile.appraisal.applyNeighborhoodSectionToMarketConditions = !e.target.checked;
    }
    
  }

  getDateRangeForMarketAnalysisGraphsData() {
    const numberOfMonthsFromEffectiveDate = this._userSettingsService.userSettings.userMarketConditionsMarketAnalysisSettings.monthsFromEffectiveDate ?? 24;
    this.effectiveDate = new Date(this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate);
    
    this.lastDate = new Date(this.effectiveDate);
    this.lastDate.setMonth(this.lastDate.getMonth() - numberOfMonthsFromEffectiveDate);
  }

  ngOnDestroy(): void {
    this._requestCommentSubscription.unsubscribe();
  }
}
