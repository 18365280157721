import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  OnChanges,
} from '@angular/core';
import {
  MarketConditionsGraph,
  UserGraph,
  UserSettings,
  UserStateService,
  GraphsService,
  UserStateModel,
  PropertyUsageType
} from '@datamaster/core';
import 'chartjs-adapter-date-fns';
import { McGraphGenerator } from './mc-graph-generator';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-mc-graph',
  templateUrl: './mc-graph.component.html',
  styleUrls: ['./mc-graph.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class McGraphComponent implements AfterViewInit, OnChanges {

  public includeInReport = true;

  @Input() marketConditionsGraph: MarketConditionsGraph;
  @Input() userSettings: UserSettings;
  @Input() effectiveDate : Date;
  @Output() removeGraphEvent = new EventEmitter<MarketConditionsGraph>();
  @Output() changeGraphEvent = new EventEmitter<MarketConditionsGraph>();
  largerMarket: string;
  private chart : Chart;
  get userState(): UserStateModel {
    return this._userStateService.user;
  }

  @ViewChild('canvasForChart') canvas: ElementRef<HTMLCanvasElement>;
  constructor(private _graphsService: GraphsService, private _userStateService: UserStateService, ) {
  }

  ngAfterViewInit() {
    const mcGraphGenerator = new McGraphGenerator(this.marketConditionsGraph.graphDetails, this.userSettings,this.effectiveDate,false);
    this.chart = mcGraphGenerator.generateChart(this.canvas.nativeElement.getContext('2d'));
  }

  removeGraph() {
    this.removeGraphEvent.emit(this.marketConditionsGraph);
  }

  saveIncludeInExportPreference(event) {
    const userGraph = new UserGraph();

    userGraph.graphId = this.marketConditionsGraph.graphDetails.graph.id;
    userGraph.includeInExport = event.target.checked;
    userGraph.userId = this.userState.userId;
    userGraph.graphGroupId = this.marketConditionsGraph.graphDetails.graphGroup.id;
    userGraph.reportUsageType = this.marketConditionsGraph.graphDetails.reportUsageType.toString();

    return this._graphsService.updateUserGraph(userGraph, this._userStateService.userDataForSearch).subscribe();
  }

  copyToClipboard(){
    const canvas = this.generateCanvasForClipboard();
    canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({'image/png': blob})]));
  }

  generateCanvasForClipboard(){
    const newCanvas = document.createElement('canvas');
    const context = newCanvas.getContext('2d');

    newCanvas.height = this.canvas.nativeElement.height;
    newCanvas.width = this.canvas.nativeElement.width;

    //ChartJs graph without fill is the behavior we want for display
    //We want to set the background to white because it copies with a black background even if set to transparent
    context.fillStyle = 'white';
    context.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);

    context.drawImage(this.canvas.nativeElement, 0, 0);
    return newCanvas;
  }

  ngOnChanges() {
    if (this.canvas) {
      const mcGraphGenerator = new McGraphGenerator(this.marketConditionsGraph.graphDetails, this.userSettings,this.effectiveDate,false);
      this.chart.destroy();
      this.chart = mcGraphGenerator.generateChart(this.canvas.nativeElement.getContext('2d'));
    }
    
  }
}
