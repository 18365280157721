export class UserShareSetting {
    //userId: number;
    sharesEnabled: boolean;
    UserShares: [];

    constructor() {
        this.sharesEnabled = null;
        this.UserShares = [];
    }
}
