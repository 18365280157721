import { EntityFrameworkBase } from '../data-source/entity-framework-base.model';

export enum CommentSectionType {
  McSellerConcessions = 'McSellerConcessions',
  McReoSales = 'McReoSales',
  McCiteDataSources = 'McCiteDataSources',
  McSummary = 'McSummary',
  ProReoSales = 'ProReoSales',
  ProSummary = 'ProSummary',
  DaAppraiserComments = 'DaAppraiserComments',
  NeighborhoodAnalysisSummary = 'NeighborhoodAnalysisSummary',
}
export class MCComments {
  daAppraiserComments: CommentBlock;
  mcSellerConcessions: CommentBlock;
  mcReoSales: CommentBlock;
  mcCiteDataSources: CommentBlock;
  mcSummary: CommentBlock;
  proReoSales: CommentBlock;
  proSummary: CommentBlock;
  neighborhoodAnalysisSummary: CommentBlock;
  neighborhoodAnalysisBoundaries: CommentBlock;
  neighborhoodAnalysisConditions: CommentBlock;
  noDataDaAppraiserComments: CommentBlock;
  noDataMcSellerConcessions: CommentBlock;
  noDataMcReoSales: CommentBlock;
  noDataProReoSales: CommentBlock;
  noDataProSummary: CommentBlock;

  constructor() {
    this.daAppraiserComments = new CommentBlock();
    this.mcSellerConcessions = new CommentBlock();
    this.mcReoSales = new CommentBlock();
    this.mcCiteDataSources = new CommentBlock();
    this.mcSummary = new CommentBlock();
    this.proReoSales = new CommentBlock();
    this.proSummary = new CommentBlock();
    this.neighborhoodAnalysisSummary = new CommentBlock();
    this.neighborhoodAnalysisBoundaries = new CommentBlock();
    this.neighborhoodAnalysisConditions = new CommentBlock();
    this.noDataDaAppraiserComments = new CommentBlock();
    this.noDataMcSellerConcessions = new CommentBlock();
    this.noDataMcReoSales = new CommentBlock();
    this.noDataProReoSales = new CommentBlock();
    this.noDataProSummary = new CommentBlock();
  }
}
export class CommentBlock extends EntityFrameworkBase {
  
  commonName: string;

  displayName: string;

  text: string;

  isNoDataComment: boolean;

  dataMasterCommentBlocks: DataMasterCommentBlock[];
  defaultUserCommentBlocks: DefaultUserCommentBlock[];
  userSectionBlocks: UserSectionBlock[];

  displayComment: string;
  calculatedComment: string;
  isUserCreated: boolean;

  constructor() {
    super();
  }
  static duplicate(commentBlock : CommentBlock): CommentBlock {
    const duplicate = new CommentBlock();
    duplicate.commonName = commentBlock.commonName ?? '';
    duplicate.displayName = commentBlock.displayName ?? '';
    duplicate.text = commentBlock.text ?? '';
    duplicate.isNoDataComment = commentBlock.isNoDataComment;
    duplicate.displayComment = commentBlock.displayComment ?? '';
    duplicate.calculatedComment = commentBlock.calculatedComment ?? '';
    duplicate.isUserCreated = commentBlock.isUserCreated;
    
    return duplicate;
  }
}

export class DataMasterCommentBlock extends EntityFrameworkBase{
    CommentBlockId : number;

    CommentSectionId : number;

    CommentBlock : CommentBlock;

    CommentSection : any;// CommentSection;
}
export class UserSectionBlock extends EntityFrameworkBase{
     CommentSectionId : number;

     CommentBlockId : number;

     UserId : number;

     CommentBlock : CommentBlock;

     CommentSection : CommentSection

     User : any;//User
}
export class DefaultUserCommentBlock extends EntityFrameworkBase{
     UserId : number;

    CommentBlockId : number;

     CommentBlock : CommentBlock;

     User : any;// User

}
export class CommentSection extends EntityFrameworkBase{
    CommonName : string;
    DisplayName : string;

    CommentElements : any;//CommentElement[];

    DataMasterCommentBlocks : DataMasterCommentBlock[];

    DefaultCommentBlocks : any;//DefaultCommentBlock[];

    UserCommentBlocks : any;//UserCommentBlock[];

    UserSectionBlocks : UserSectionBlock[];
}
