
<div *ngIf="!showForm">
  <h3>Appraiser Information</h3>
  <button class="btn btn-primary" (click)="showForm = true"><i class="fas fa-plus-circle"></i> Add Appraiser Information</button>
</div>


<div *ngIf="showForm">
        
        <h3>Appraiser Information</h3>
        
      <div class="container">
          <div class="row justify-content-center">
            <div class="col-4">
                <label>Full Name</label>
                <input type="text" id="appraiserName" placeholder="" formControlName="AppraiserName" />
                <Label>Company Name</Label>
                <input type="text" placeholder="" formControlName="AppraiserCompanyName" />
                <Label>Company Address</Label>
                <input type="text" placeholder="" formControlName="AppraiserCompanyAddress" />
                <Label>Company City</Label>
                <input type="text" placeholder="" formControlName="AppraiserCity" />
                <Label>Company State</Label>
                <select name="company-state">
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AZ">AZ</option>
                  <option value="AR">AR</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="IA">IA</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="ME">ME</option>
                  <option value="MD">MD</option>
                  <option value="MA">MA</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MS">MS</option>
                  <option value="MO">MO</option>
                  <option value="MT">MT</option>
                  <option value="NE">NE</option>
                  <option value="NV">NV</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NY">NY</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="PR">PR</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WV">WV</option>
                  <option value="WI">WI</option>
                  <option value="WY">WY</option>
                </select>
                <Label>Company Zip</Label>
                <input type="text" placeholder="" formControlName="AppraiserZip" />
                <Label>Phone Number</Label>
                <input type="text" placeholder="" formControlName="AppraiserPhone" />
                <Label>Email</Label>
                <input type="text" placeholder="" formControlName="AppraiserEmail" />
                
              </div>
            <div class="col-4">
              <button type="button" class="close" (click)="showForm = false" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              
              <Label>State Certification Number</Label>
              <input type="text" placeholder="" formControlName="AppraiserStateCertificationNumber" />
              <Label>License Number</Label>
              <input type="text" placeholder="" formControlName="AppraiserLicenseNumber" />
              <Label>Other Description</Label>
              <input type="text" placeholder="" formControlName="AppraiserOtherDescription" />
              <Label>Other State Number</Label>
              <input type="text" placeholder="" formControlName="AppraiserOtherStateNumber" />
              <Label>License State</Label>
              <select name="license-state">
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NE">NE</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="PR">PR</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </select>
              <Label>Certification Or License Expiration Date</Label>
              <input type="text" placeholder="" formControlName="AppraiserCertificationOrLicenseExpiration" />
          
          </div>
        </div>       
        </div>
      <div>
          <button type="button" class="btn btn-secondary" (click)="showForm = false">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
      </div>

  </div>