import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
// Service
import { AuthService } from './auth.service';
import { ConfigService } from '../../../dependency-issues';
// Model
import { SKIP_AUTH_HEADER } from '../model/auth.model';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        private configService: ConfigService,
        private _authService: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.reqNeedsAuthorizationHeader(req)) {
            return next.handle(req);
        }

        const authReq = req.clone({
            setHeaders: {
                Authorization: `${this._authService.getAuthorizationHeaderValue()}`,
            },
        });
        return next.handle(authReq);
    }

    private reqNeedsAuthorizationHeader(request: HttpRequest<any>): boolean {
        if (!request.headers.has(SKIP_AUTH_HEADER)) {
            const apis = this.configService.api;
            if (
                request.url.includes(apis.authUrl) ||
                request.url.includes(apis.dataMasterApi) ||
                request.url.includes(apis.userNotificationUrl)
            ) {
                return true;
            }
            return false;
        } else {
            return false;
        }

    }
}
