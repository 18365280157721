// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StsConfigHttpLoader } from 'angular-auth-oidc-client';
// Other
import { Store } from '@ngxs/store';
// Model
import { ConfigFileModel, ConfigServiceModel, LoadConfigFile, SKIP_AUTH_HEADER } from '@datamaster/core';
// Other
import { lastValueFrom, Observable, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
/**
 * AuthLoaderFactory for OIDC
 *
 * @param configService ConfigDMService
 * @returns
 */
export const httpLoaderFactory: any = (store: Store, configService: ConfigDMService) => {
	const config$: any = configService.loadConfigFile()
		.pipe(
			map((configData: ConfigFileModel) => {
                configService.config = configData;
                configService.isLoadingFile = false;
                configService.initialized = true;
				// Dispatch the Config File
				store.dispatch(new LoadConfigFile(configData.oidc_configuration.clientId));
				// Return Configuration
				return configData.oidc_configuration;
			})
		).toPromise();

	return new StsConfigHttpLoader(config$);
};

@Injectable()
export class ConfigDMService extends ConfigServiceModel {
	public override config = new ConfigFileModel();
    public override isLoadingFile = false;
    public override initialized = false;

    override configLoader$: Observable<ConfigFileModel> = new Observable();

	constructor(private http: HttpClient) {
        super();
    }

	/**
	 * Load configfile
	 *
	 * @returns Observable<ConfigFileModel>
	 */
	loadConfigFile(): Observable<ConfigFileModel> {
        if (this.initialized) {
            console.log('ConfigFile: Already loaded.');
            return of(this.config);
        } else if (this.isLoadingFile) {
            console.log('ConfigFile: Waiting for the file to finish to load.');
            return this.configLoader$;
        } else {
            console.log('ConfigFile: Not loaded, starting the load.');
            this.isLoadingFile = true;

            return this.http.get<any>(
                    'assets/json/config.json',
                    {
                        headers: {
                            [SKIP_AUTH_HEADER]: SKIP_AUTH_HEADER
                        }
                    }
                ).pipe(
                    tap((config: ConfigFileModel) => {
                        this.configLoader$ = of(config);
                        this.isLoadingFile = false;
                        this.initialized = true;
                    })
                );
        }
	}

	/**
	 * Get ids config
	 *
	 * @returns Observable<ConfigFileModel>
	 */
	getIdsConfig(): Observable<ConfigFileModel> {
		return of(this.ids);
	}
}
