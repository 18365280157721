import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { of, Subscription, Observable, Subject, lastValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
// Models
import usaCounties from '../../assets/data/usa-counties.json';
import { PropertySearchResult } from '../../models/operation/property-search/property-search-result.model';
import { SearchResult } from '../../models/operation/property-search/search-result.model';
import { TranslationResult } from '../../models/operation/translation/translation-result.model';
import { SearchType } from '../../models/operation/property-search/search-criteria.model';
import { DataSource } from '../../models/data-source/data-source';
import { Address } from '../../models/workfile/address.model';
import { DataSourceGroupType } from '../../models/operation/enum/data-source-group-type.enum';
import { AddressSearchStateModel } from './../../models/search/address-search.model';
// Services
import { AddressSearchService } from '../../services/property-search/address-search.service';
import { DataSourceService } from '../../services/data-source.service';
import { UserStateService } from '../../services/user/user-state.service';
import { SearchService } from '../../services/property-search/search.service';
// Actions
import { UpdateAddress } from './../../services/property-search/state/model/address-search.actions';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: [
    './address-search.component.scss'
  ],
  encapsulation: ViewEncapsulation.None, // TODO: shared CSS not working without this - not sure why
})
export class AddressSearchComponent implements OnInit, OnDestroy {
  searchResults: PropertySearchResult[];
  searching = false;
  hasSearchResultsAddress = false; // flag used to show search results if the modal gets closed

  @Input() addressSearchForm: FormGroup = new FormGroup({
    address: new FormControl('', Validators.required),
    city: new FormControl(''),
    state: new FormControl('', Validators.required),
    county: new FormControl('', Validators.required),
    zip: new FormControl(''),
  });

  @Input() public propertyUseType: string;

  @Output() resultsEvent = new EventEmitter<{
    searchResult: SearchResult;
    translationResult: TranslationResult;
    searchType: SearchType;
  }>();
  @Output() showResultsEvent = new EventEmitter<any>();
  @Output() clearAllSearchesEvent = new EventEmitter<any>();
  @Output() noResultsEvent = new EventEmitter();

  @ViewChild('searchError', { static: true }) searchErrorChild;
  @Input() public isDashboardSearch = true;
  stateJson: any = usaCounties;
  countiesJsonAddressSearch: any;

  @Input() public dataSources: DataSource[];
  private _dataSources: DataSource[];

  private _subscription = new Subscription();

  addressSearchState$: Observable<AddressSearchStateModel> = this.store.select(state => state.search.addressSearch);

  private atComponentDestroy = new Subject();

  constructor(
    private addressSearchService: AddressSearchService,
    private dataSourceService: DataSourceService,
    private userStateService: UserStateService,
    private searchService: SearchService,
    private store: Store
  ) {
    this._dataSources = this.dataSources;

    // address-search state change listener
    this.addressSearchState$.pipe(takeUntil(this.atComponentDestroy)).subscribe(address => {

      // add valueChanges observable to subscription, to listen state change event.
      this.stateChangeEventForCountyList();

      if (address) {
        // if address data is not exist in the address-search state then, set addressSearchForm data to initial values
        if (address.addressLine1 === '') {
          this.addressSearchForm.reset();
        } else {
          // set values from address-search state data
          this.addressSearchForm.controls['address'].setValue(address.addressLine1);
          this.addressSearchForm.controls['city'].setValue(address.city);
          this.addressSearchForm.controls['state'].setValue(address.state);
          this.addressSearchForm.controls['county'].setValue(address.county);
          this.addressSearchForm.controls['zip'].setValue(address.zip);
        }
      }
    });
  }

  ngOnInit() {
    this.searching = false;
    // this.addressSearchForm = new FormGroup({
    //   address: new FormControl('', Validators.required),
    //   city: new FormControl(''),
    //   state: new FormControl('', Validators.required),
    //   county: new FormControl('', Validators.required),
    //   zip: new FormControl(''),
    // });

    // Not sure if this is the right way to do this.  The json file is used to build the dropdowns
    this.stateJson = this.stateJson.filter((state) => state.UsaTerritory === undefined);
    // this.stateChangeEventForCountyList();

    this.dataSourceService.getUserDataSources().then(((userDataSources) => {
      const prDataSources = this.dataSourceService.filterPrDataSources(userDataSources);

      this._dataSources = prDataSources;
    })
  );

    this._subscription.add(
      this.dataSourceService.userDataSourcesChanged
        .pipe(
          map((userDataSources) => {
            const prDataSources = this.dataSourceService.filterPrDataSources(userDataSources);

            return prDataSources;
          })
        )
        .subscribe((prDataSources) => {
          this._dataSources = prDataSources;
        })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();

    this.atComponentDestroy.next({});
    this.atComponentDestroy.unsubscribe();
  }

  resetForm() {
    this.addressSearchForm.reset();
    this.stateChangeEventForCountyList();
  }

  stateChangeEventForCountyList() {
    this._subscription.add(
      this.addressSearchForm.controls['state'].valueChanges.subscribe((selectedState) => {
        this.countiesJsonAddressSearch = this.stateJson.filter(
          (state) => state.Abbreviation === selectedState
        )[0]?.Counties;
      })
    );
  }

  searchValidate(formGroup) {
    this.clearAllSearchesEvent.emit();

    if (formGroup.valid === false) {
      for (const item in formGroup.controls) {
        formGroup.controls[item].markAsTouched();
      }
    } else {
      this.searching = true;
      this.addressSearch();

    }
  }

  // This is a bit of a hack, prob need a more robust solution
  isFormSearching(formGroup): boolean {
    if (formGroup.valid && this.searching == true) {
      return true;
    } else {
      return false;
    }
  }

  getDataSource(): DataSource {
    if (!this._dataSources) {
      return null;
    }
    if (this._dataSources.length > 1) {
      return this._dataSources[0]; //TODO: Add drop down for apn search so user can select data source instead of just grabbing first
    }

    return this._dataSources.find((a) => {
      return a.dataSourceGroup.commonName == DataSourceGroupType[DataSourceGroupType.PublicRecords.toString()]
    });
  }

  async addressSearch(): Promise<void> {
    const address: Address = {
      addressLine1: this.addressSearchForm.controls['address'].value,
      city: this.addressSearchForm.controls['city'].value,
      state: this.addressSearchForm.controls['state'].value,
      county: this.addressSearchForm.controls['county'].value,
      zip: this.addressSearchForm.controls['zip'].value,
    };

    const searchType = SearchType.Address;

    let dataSource = this.getDataSource();
    let prDataSource: string;

    // This has three different places it checks for valid Public Records and will finally try CoreLogic
    // if all else fails.
    if (dataSource === null) {
      this._dataSources = this.dataSources;
      dataSource = this.getDataSource();

      if (dataSource === null) {
        prDataSource = 'CoreLogic';
      } else {
        prDataSource = dataSource.commonName;
      }
    } else {
      prDataSource = dataSource.commonName;
    }

    let searchResult : SearchResult;
     await this.getAddressSearchResult(
      address,
      prDataSource).then(data=>{
        searchResult = data;
      }).catch(err => {
        const errorString = `${err.statusText} (${err.status}):  ${err.message}`;
        this.searching = false;
        console.log(errorString); // Log to the console - just for fun
            this.searchErrorChild.searchHttpError = err; // Now display to the user
        throw new Error(errorString);
      });
    
      this.hasSearchResultsAddress =
        this.searchService.doesSearchResultHaveResults(searchResult);
      if (!this.hasSearchResultsAddress) {
        this.searchErrorChild.searchHttpError = {
          message: 'Search returned no results.',
        };
      }
      
      if (this.hasSearchResultsAddress) {
        // update address-search state.
        this.store.dispatch(new UpdateAddress(address));
        
        const translationResult=  await lastValueFrom( this.searchService
          .translateResults(
            searchResult,
            this.getDataSource()?.id,
            this.userStateService.userDataForSearch
          ));
          this.searching = false;
        this.resultsEvent.emit({
          searchResult,
          translationResult,
          searchType,
        });
      } else {
        this.noResultsEvent.emit();
      }
  }
  getAddressSearchResult(address: Address, prDataSource: string): Promise<SearchResult> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const searchResult = await lastValueFrom(this.addressSearchService
      .search(
        address,
        this.propertyUseType,
        prDataSource,
        this.userStateService.userDataForSearch
      ));
      if (searchResult) {
        resolve(searchResult);
    }else {
        reject()
        throw new Error('');
    }
    });
  }

  showResults(): void {
    this.showResultsEvent.emit();
  }

  clearSearchResults(): void {
    this.searchResults = [];
    this.searching = false;
    this.hasSearchResultsAddress = false;
    this.searchErrorChild.searchHttpError = null;
  }
}
