import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Modules
import { Store } from '@ngxs/store';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { SafePipeModule } from 'safe-pipe';
import { AuthModule, StsConfigLoader } from 'angular-auth-oidc-client';
import { DMCoreModule, DMSharedModule } from '@datamaster/core';
import { AuthConfigModule } from './auth/auth-config.module';
import { AppRoutingModule } from './app-routing.module';
// Component
import { AppComponent } from './app.component';
// Service
import { ConfigService, loadAppConfigService } from '@datamaster/core';
import { ConfigDMService, httpLoaderFactory } from './config/config-dm.service';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        // 3rd party
        GoogleMapsModule,
        NgbModule,
        NgxsStoragePluginModule.forRoot(),
        SafePipeModule,
        // own
        AppRoutingModule,
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: httpLoaderFactory,
                deps: [Store, ConfigDMService]
            }
        }),
        // AuthConfigModule,
        DMSharedModule,
        DMCoreModule
    ],
    providers: [
        ConfigDMService,
        {
            provide: ConfigService,
            useFactory: loadAppConfigService,
            deps: [ConfigDMService]
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
