import { Injectable } from '@angular/core';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {

  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<ErrorDialogComponent>;

  public open(options) {
    this.dialogRef = this.dialog.open(ErrorDialogComponent, {
      maxWidth: '30vw',
      minWidth: '30vw',
      data: {
        title: options.title,
        message: options.message,
      },
      disableClose: false,

    });

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    });
  }
}
