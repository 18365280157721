
import { Component, OnInit } from '@angular/core';
import { 
  UserLegacyBulletinUpdateStatusRequest,
  UserNotification,
  UserNotificationUpdateStatusRequest,
  NotificationStatus } from '@datamaster/core';
import { UserNotificationService } from '../../../services/user-notification.service';

@Component({
  selector: 'app-critical-notifications',
  templateUrl: './critical-notifications.component.html',
  styleUrls: ['./critical-notifications.component.scss']
})
export class CriticalNotificationsComponent implements OnInit {
  criticalNotifications: UserNotification[] = [];
  
  constructor(private userNotificationService: UserNotificationService) { }

  ngOnInit(): void {

    // Temp values until we can get the values from the user object
    this.userNotificationService.criticalNotifications
      .subscribe((notifications: UserNotification[]) => {
        //this.criticalNotifications = notifications.filter(n => n.priorityName.toLocaleLowerCase() === 'critical' || n.priorityName.toLocaleLowerCase() === 'outage');
        this.criticalNotifications = notifications;
      });

  }

  /// Deletes the notification from the page and changes notification status to DELETED
  deleteUserNotification(notification: any): void {
    notification.notificationStatus = NotificationStatus.Deleted;

    if (notification.notificationId !== null) {
      const notificationRequest: UserNotificationUpdateStatusRequest[] = new Array<UserNotificationUpdateStatusRequest>();
      notificationRequest.push({ 
        userId: notification.userId, 
        notificationId: notification.notificationId, 
        statusId: NotificationStatus.Deleted        
      });
      this.userNotificationService.updateUserNotificationStatus(notificationRequest);
    }

    if (notification.legacyProductBulletinId !== null) {
      const legacyRequest: UserLegacyBulletinUpdateStatusRequest[] = new Array<UserLegacyBulletinUpdateStatusRequest>();
      legacyRequest.push({
        userId: notification.userId,
        legacyBulletinId: notification.legacyProductBulletinId,
        statusId: NotificationStatus.Deleted
      }); 
      this.userNotificationService.updateUserNotificationStatusLEGACY(legacyRequest);
    }
  }

}
