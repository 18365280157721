import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MarketAnalysisReportService,
  DataMasterFileService,
  AppraisalFormService,
  Appraisal, 
  Subject,
  FormattingHelper,
  UserSettingsService,
  MarketConditionsForm} from '@datamaster/core';
import { SubjectDetailComponent } from '../../subject/subject-detail/subject-detail.component';

@Component({
  selector: 'app-mc-subject',
  templateUrl: './mc-subject.component.html',
  styleUrls: ['./mc-subject.component.scss'],
})
export class McSubjectComponent implements OnInit {
  @Input() mcCount: number;
  @Input() isNeighborhoodLargerMarket: boolean;
  @Input() marketConditionsForm: MarketConditionsForm;
  appraisal: Appraisal;
  applyToNeighborhoodSection = false;
  showSummarySection = false;
  subj!: Subject;
  sectionType = 'Subject';
  lastDate!: Date;
  firstDate!: Date;

  neighborhoodLocation_Urban!: string;
  neighborhoodLocation_Suburban!: string;
  neighborhoodLocation_Rural!: string;
  neighborhoodBuiltUp_PercentOver75!: string;
  neighborhoodBuiltUp_Percent25To75!: string;
  neighborhoodBuiltUp_PercentUnder25!: string;
  neighborhoodGrowth_Rapid!: string;
  neighborhoodGrowth_Stable!: string;
  neighborhoodGrowth_Slow!: string;
  neighborhoodLocation: string[];
  propertyValues_Increasing!: string;
  propertyValues_Stable!: string;
  propertyValues_Declining!: string;
  demandSupply_Shortage!: string;
  demandSupply_InBalance!: string;
  demandSupply_OverSupply!: string;
  marketingTime_MonthUnder3!: string;
  marketingTime_Month3To6!: string;
  marketingTime_MonthOver6!: string;
  singleFamilyLowPrice!: string;
  singleFamilyHighPrice!: string;
  singleFamilyPredPrice!: string;
  singleFamilyLowAge!: string;
  singleFamilyHighAge!: string;
  singleFamilyPredAge!: string;
  oneUnitLowPrice!: string;
  oneUnitHighPrice!: string;
  oneUnitPredPrice!: string;
  oneUnitLowAge!: string;
  oneUnitHighAge!: string;
  oneUnitPredAge!: string;
  oneUnitPercentage!: string;
  twoToFourUnitPercentage!: string;
  multiFamilyPercentage!: string;
  commercialPercentage!: string;
  otherVacantPercentage!: string;
  neighborhoodBoundaries!: string;
  neighborhoodDescription!: string;
  marketConditions!: string;

  constructor(
    private maReportService: MarketAnalysisReportService,
    private dataMasterFileService: DataMasterFileService,
    private appraisalFormService: AppraisalFormService,
    private _userSettingsService: UserSettingsService
  ) {
    this.appraisal = dataMasterFileService.dataMasterFile.appraisal;
  }

  ngOnInit() {
    this.subj = this.appraisal.subject;
    if (!this.isNeighborhoodLargerMarket)
      this.showSummarySection = this.appraisal.applyNeighborhoodSectionToMarketConditions;
    else 
      this.showSummarySection = this.dataMasterFileService.dataMasterFile.appraisal.applyNeighborhoodSectionToNeighborhoodGreaterMarket;
    this.getDateRangeForMarketAnalysisGraphsData();
    if (this.subj) {
      this.neighborhoodLocation_Urban = this.getFormattedFieldResultFormattedValue('NeighborhoodLocation_Urban');
      this.neighborhoodLocation_Suburban = this.getFormattedFieldResultFormattedValue('NeighborhoodLocation_Suburban');
      this.neighborhoodLocation_Rural = this.getFormattedFieldResultFormattedValue('NeighborhoodLocation_Rural');
      this.neighborhoodBuiltUp_PercentOver75 = this.getFormattedFieldResultFormattedValue('NeighborhoodBuiltUp_PercentOver75');
      this.neighborhoodBuiltUp_Percent25To75 = this.getFormattedFieldResultFormattedValue('NeighborhoodBuiltUp_Percent25To75');
      this.neighborhoodBuiltUp_PercentUnder25 = this.getFormattedFieldResultFormattedValue('NeighborhoodBuiltUp_PercentUnder25');
      this.neighborhoodGrowth_Rapid = this.getFormattedFieldResultFormattedValue('NeighborhoodGrowth_Rapid');
      this.neighborhoodGrowth_Stable = this.getFormattedFieldResultFormattedValue('NeighborhoodGrowth_Stable');
      this.neighborhoodGrowth_Slow = this.getFormattedFieldResultFormattedValue('NeighborhoodGrowth_Slow');
      this.propertyValues_Increasing = this.getFormattedFieldResultFormattedValue('PropertyValues_Increasing');
      this.propertyValues_Stable = this.getFormattedFieldResultFormattedValue('PropertyValues_Stable');
      this.propertyValues_Declining = this.getFormattedFieldResultFormattedValue('PropertyValues_Declining');
      this.demandSupply_Shortage = this.getFormattedFieldResultFormattedValue('DemandSupply_Shortage');
      this.demandSupply_InBalance = this.getFormattedFieldResultFormattedValue('DemandSupply_InBalance');
      this.demandSupply_OverSupply = this.getFormattedFieldResultFormattedValue('DemandSupply_OverSupply');
      this.marketingTime_MonthUnder3 = this.getFormattedFieldResultFormattedValue('MarketingTime_MonthUnder3');
      this.marketingTime_Month3To6 = this.getFormattedFieldResultFormattedValue('MarketingTime_Month3To6');
      this.marketingTime_MonthOver6 = this.getFormattedFieldResultFormattedValue('MarketingTime_MonthOver6');
      this.singleFamilyLowPrice = this.getFormattedFieldResultFormattedValue('SingleFamilyLowPrice');
      this.singleFamilyHighPrice = this.getFormattedFieldResultFormattedValue('SingleFamilyHighPrice');
      this.singleFamilyPredPrice = this.getFormattedFieldResultFormattedValue('SingleFamilyPredPrice');
      this.singleFamilyLowAge = this.getFormattedFieldResultFormattedValue('SingleFamilyLowAge');
      this.singleFamilyHighAge = this.getFormattedFieldResultFormattedValue('SingleFamilyHighAge');
      this.singleFamilyPredAge = this.getFormattedFieldResultFormattedValue('SingleFamilyPredAge');
      this.setNeighborhoodOneUnitHousing();
      this.oneUnitPercentage = this.getFormattedFieldResultFormattedValue('OneUnitPercentage');
      this.twoToFourUnitPercentage = this.getFormattedFieldResultFormattedValue('TwoToFourUnitPercentage');
      this.multiFamilyPercentage = this.getFormattedFieldResultFormattedValue('MultiFamilyPercentage');
      this.commercialPercentage = this.getFormattedFieldResultFormattedValue('CommercialPercentage');
      this.otherVacantPercentage = this.getFormattedFieldResultFormattedValue('OtherVacantPercentage');
      this.neighborhoodBoundaries = this.getFormattedFieldResultFormattedValue('NeighborhoodBoundaries');
      this.neighborhoodDescription = this.getFormattedFieldResultFormattedValue('NeighborhoodDescription');
      this.marketConditions = this.getFormattedFieldResultFormattedValue('MarketConditions');
    }
  }
  
  getFormattedFieldResultFormattedValue(field: string): string {
    return FormattingHelper.getFormattedFieldResultFormattedValue(field, this.sectionType, this.subj.formattedFieldResults, this.dataMasterFileService.formatFieldMaps);
  }

  applyToNeighborhoodSectionChecked(e): void {
    this.showSummarySection = e.target.checked;
    if (this.showSummarySection && !this.isNeighborhoodLargerMarket)
      this.appraisal.applyNeighborhoodSectionToMarketConditions = e.target.checked;
    else if (this.showSummarySection)
      this.appraisal.applyNeighborhoodSectionToNeighborhoodGreaterMarket = e.target.checked;
  }

  getDateRangeForMarketAnalysisGraphsData() {
    const numberOfMonthsFromEffectiveDate = this._userSettingsService.userSettings.userMarketConditionsMarketAnalysisSettings.monthsFromEffectiveDate ?? 24;
    this.firstDate = new Date(this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate);
    
    this.lastDate = new Date(this.firstDate);
    this.lastDate.setMonth(this.lastDate.getMonth() - numberOfMonthsFromEffectiveDate);
  }

  setNeighborhoodOneUnitHousing() {
    let summaryPage = this.marketConditionsForm?.summaryPage;
    if (!summaryPage) {
      summaryPage = this.isNeighborhoodLargerMarket ? this.maReportService.neighborhoodAnalysisForm?.summaryPage : this.maReportService.marketConditionsForm?.summaryPage;
    }
    const userValueFormattedFields = this.subj.formattedDataSources?.filter((a) => a?.dataSource == 'UserValues')[0]?.formattedFields;

    if (summaryPage) {
      if (summaryPage.salePriceSummary) {
        const unitLowPrice = Math.trunc(summaryPage.salePriceSummary.min / 1000).toString();
        const unitHighPrice = Math.trunc(summaryPage.salePriceSummary.max / 1000).toString();
        const unitPredPrice = Math.trunc(summaryPage.salePriceSummary.mode / 1000).toString();
        this.oneUnitLowPrice = unitLowPrice;
        this.oneUnitHighPrice = unitHighPrice;
        this.oneUnitPredPrice = unitPredPrice;
        FormattingHelper.setFormattedFieldResultFormattedValue('OneUnitLowPrice', this.sectionType, this.subj.formattedFieldResults, this.dataMasterFileService.formatFieldMaps, unitLowPrice);
        FormattingHelper.setFormattedFieldResultFormattedValue('OneUnitHighPrice', this.sectionType, this.subj.formattedFieldResults, this.dataMasterFileService.formatFieldMaps, unitHighPrice);
        FormattingHelper.setFormattedFieldResultFormattedValue('OneUnitPredPrice', this.sectionType, this.subj.formattedFieldResults, this.dataMasterFileService.formatFieldMaps, unitPredPrice);
        }
      if (summaryPage.ageSummary) {
        const unitLowAge = summaryPage.ageSummary.min.toString();
        const unitHighAge = summaryPage.ageSummary.max.toString();
        const unitPredAge = summaryPage.ageSummary.mode.toString();
        this.oneUnitLowAge = unitLowAge;
        this.oneUnitHighAge = unitHighAge;
        this.oneUnitPredAge = unitPredAge;
        FormattingHelper.setFormattedFieldResultFormattedValue('OneUnitLowAge', this.sectionType, this.subj.formattedFieldResults, this.dataMasterFileService.formatFieldMaps, unitLowAge);
        FormattingHelper.setFormattedFieldResultFormattedValue('OneUnitHighAge', this.sectionType, this.subj.formattedFieldResults, this.dataMasterFileService.formatFieldMaps, unitHighAge);
        FormattingHelper.setFormattedFieldResultFormattedValue('OneUnitPredAge', this.sectionType, this.subj.formattedFieldResults, this.dataMasterFileService.formatFieldMaps, unitPredAge);
      }
    }
  }
  
}
