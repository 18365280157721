import { Component, OnInit } from '@angular/core';
import { DataMasterFileService,
  Appraisal,
  PropertyUsageType,
  AppraisalFormService } from '@datamaster/core';
import {
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { CheckboxRendererComponent } from './checkbox-renderer/checkbox-renderer.component';
import { DateValidationEditor } from './date-validation-editor/date-validation-editor.component';

@Component({
  selector: 'app-mc-detail',
  templateUrl: './mc-detail.component.html',
  styleUrls: ['./mc-detail.component.scss'],
})
export class McDetailComponent implements OnInit {
  effectiveDate!: Date;
  twelveMonthsAgoDate!: Date;
  sevenMonthsAgoDate!: Date;
  sixMonthsAgoDate!: Date;
  fourMonthsAgoDate!: Date;
  threeMonthsAgoDate!: Date;
  zeroToTwelveRange: string;
  sevenToTwelveRange: string;
  fourToSixRange: string;
  zeroToThreeRange: string;
  appraisal: Appraisal;

  public gridApi!: GridApi;
  public gridColumnApi!: ColumnApi;
  public rowSelection = 'single';

  public columnDefs!: ColDef[];
  public defaultColDef:any;
  public rowData: any[] = [];
  public isCondoForm!: boolean;

  dateRange = '0-12';
  mcCount = 0;

  constructor(
    private dataMasterFileService: DataMasterFileService,
    private appraisalFormService: AppraisalFormService
  ) {
    this.appraisal = dataMasterFileService.dataMasterFile.appraisal;
  }

  ngOnInit() {
    this.isCondoForm = this.appraisalFormService.isCondoForm(
      this.appraisal.appraisalDetail.form.commonName
    );
    this.effectiveDate = this.appraisal.appraisalDetail.effectiveDate;
    this.mcCount = this.appraisal.marketConditions.length;
    this.setDates();

    this.columnDefs = [
      { field: 'mlsNumber', headerName: 'Mls #', resizable: true, editable: true },
      { field: 'addressLine1', headerName: 'Address', resizable: true, editable: true },
      { field: 'city', headerName: 'City', resizable: true, editable: true },
      { field: 'county', headerName: 'County', resizable: true, editable: true },
      { field: 'state', headerName: 'State', resizable: true, editable: true },
      { field: 'zeroToThreeStatus', headerName: '0-3 Status', resizable: true, editable: true },
      { field: 'fourToSixStatus', headerName: '4-6 Status', resizable: true, editable: true },
      { field: 'sevenToTwelveStatus', headerName: '7-12 Status', resizable: true, editable: true },
      {
        headerName: 'Status',
        field: 'originalValue',
        valueGetter: function (params: ValueGetterParams) {
          return params.data.listingStatusTypes?.find((e) => true).originalValue;
        },
        resizable: true,
        editable: true,
      },
      {
        field: 'originalListDate',
        headerName: 'Original List Date',
        resizable: true,
        editable: true,
        valueFormatter: this.dateFormatter,
        cellEditor: DateValidationEditor,
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.originalListDate = params.newValue;
          return true;
        },
      },
      {
        field: 'marketConditionsNotListedDate',
        headerName: 'Off Market Date',
        resizable: true,
        editable: true,
        valueFormatter: this.dateFormatter,
        cellEditor: DateValidationEditor,
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.marketConditionsNotListedDate = params.newValue;
          return true;
        },
      },
      {
        field: 'contractDate',
        headerName: 'Contract Date',
        resizable: true,
        editable: true,
        valueFormatter: this.dateFormatter,
        cellEditor: DateValidationEditor,
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.contractDate = params.newValue;
          return true;
        },
      },
      {
        field: 'settlementDate',
        headerName: 'Close Date',
        resizable: true,
        editable: true,
        valueFormatter: this.dateFormatter,
        cellEditor: DateValidationEditor,
        valueSetter: (params) => {
          if (!params.newValue) return false;
          params.data.settlementDate = params.newValue;
          return true;
        },
      },
      {
        field: 'listPrice',
        headerName: 'List Price',
        resizable: true,
        editable: true,
        valueFormatter: this.currencyFormatter,
      },
      {
        field: 'salePrice',
        headerName: 'Sale Price',
        resizable: true,
        editable: true,
        valueFormatter: this.currencyFormatter,
      },
      { field: 'salePriceRatio', headerName: 'Sale Price Ratio', resizable: true, editable: true },
      { field: 'daysOnMarket', headerName: 'DOM', resizable: true, editable: true },
      { field: 'cumulativeDaysOnMarket', headerName: 'CDOM', resizable: true, editable: true },
      {
        field: 'concessionAmount',
        headerName: 'Concessions',
        resizable: true,
        editable: true,
        valueFormatter: this.concessionsFormatter,
      },
      {
        field: 'hasConcessions',
        headerName: 'Has Concessions',
        resizable: true,
        editable: true,
        cellRenderer: CheckboxRendererComponent,
      },
      {
        field: 'isReo',
        headerName: 'REO',
        resizable: true,
        editable: true,
        cellRenderer: CheckboxRendererComponent,
      },
      {
        field: 'salePricePerGrossLivingArea',
        headerName: 'Sold Price Per SqFt',
        resizable: true,
        editable: true,
        valueFormatter: this.currencyFormatter,
      },
      {
        field: 'listPricePerSqft',
        headerName: 'List Price Per SqFt',
        resizable: true,
        editable: true,
        valueFormatter: this.currencyFormatter,
      },
      { field: 'yearBuilt', headerName: 'Year Built', resizable: true, editable: true },
      { field: 'actualAge', headerName: 'Age', resizable: true, editable: true },
      { 
        headerName: 'Basement', 
        field: 'basement', 
        valueGetter: function (params: ValueGetterParams) {
          return params.data.basementExitTypes?.find((e) => true).standardValue;
        },
        resizable: true, 
        editable: true },
      {
        field: 'style',
        headerName: 'Style',
        valueGetter: (params: ValueGetterParams) => {
          return params.data.architecturalStyleTypes?.find((e) => true).originalValue;
        },
        resizable: true,
        editable: true,
      },
      { field: 'aboveGradeGla', headerName: 'GLA', resizable: true, editable: true },
      { field: 'belowGradeGla', headerName: 'GBA', resizable: true, editable: true },
      { field: 'siteArea', headerName: 'Lot Size', resizable: true, editable: true },
      {
        field: 'totalAboveGradeBedrooms',
        headerName: 'Total Beds',
        resizable: true,
        editable: true,
      },
      {
        field: 'totalAboveGradeBathrooms',
        headerName: 'Total Baths',
        resizable: true,
        editable: true,
      },
    ];

    this.setRowData();

    this.zeroToTwelveRange =
      this.twelveMonthsAgoDate.toLocaleDateString() +
      ' To ' +
      this.effectiveDate.toLocaleDateString();

    this.sevenToTwelveRange =
      this.twelveMonthsAgoDate.toLocaleDateString() +
      ' To ' +
      this.sevenMonthsAgoDate.toLocaleDateString();

    this.fourToSixRange =
      this.sixMonthsAgoDate.toLocaleDateString() +
      ' To ' +
      this.fourMonthsAgoDate.toLocaleDateString();

    this.zeroToThreeRange =
      this.threeMonthsAgoDate.toLocaleDateString() +
      ' To ' +
      this.effectiveDate.toLocaleDateString();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridColumnApi.autoSizeAllColumns();
  }

  onCellValueChanged(params: CellValueChangedEvent) {
    if (
      params.column.getColId() === 'marketConditionsNotListedDate' ||
      params.column.getColId() === 'zeroToThreeStatus' ||
      params.column.getColId() === 'fourToSixStatus' ||
      params.column.getColId() === 'sevenToTwelveStatus'
    ) {
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.forEach(
        (marketCondition, index) => {
          if (marketCondition.propertyGuid !== params.data.propertyGuid) return;

          this.dataMasterFileService.dataMasterFile.appraisal.marketConditions[index] = params.data;
        }
      );
    } else {
      this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.forEach(
        (prioritizedParcel, index) => {
          if (params.data.propertyGuid !== prioritizedParcel.propertyGuid) return;

          this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels[index] =
            params.data;
        }
      );
    }
  }

  onCellKeyDown(e) {
    const keyPress = e.event.keyCode;
    if (keyPress === 46) {
      const selectedRow = this.gridApi.getSelectedRows();
      const marketConditionIndex =
        this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.indexOf(
          selectedRow.find((e) => true).propertyGuid
        );
      if (marketConditionIndex > -1) {
        this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.splice(
          marketConditionIndex,
          1
        );
      }
      this.gridApi.applyTransaction({ remove: selectedRow });
    }
  }

  undo() {
    this.gridApi.undoCellEditing();
  }

  currencyFormatter(params: ValueFormatterParams) {
    if (!params.value) return '';
    return '$' + params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  concessionsFormatter(params: ValueFormatterParams) {
    if (params.value === null) return '0';

    return params.value;
  }

  dateFormatter(params) {
    if (isNaN(Date.parse(params.value))) return null;

    const newDate = new Date(params.value);
    return newDate.toLocaleDateString('en-US');
  }

  zeroToTwelveTabSelected(usageType: string) {
    usageType === 'MC' ? (this.dateRange = '0-12') : (this.dateRange = '0-12Condo');
    this.gridColumnApi.setColumnsVisible(this.gridColumnApi.getAllColumns(), true);
    this.setRowData();
  }

  sevenToTwelveTabSelected(usageType: string) {
    usageType === 'MC' ? (this.dateRange = '7-12') : (this.dateRange = '7-12Condo');
    this.gridColumnApi.setColumnsVisible(this.gridColumnApi.getAllColumns(), true);
    this.gridColumnApi.setColumnsVisible(['zeroToThreeStatus', 'fourToSixStatus'], false);
    this.setRowData();
  }

  fourToSixTabSelected(usageType: string) {
    usageType === 'MC' ? (this.dateRange = '4-6') : (this.dateRange = '4-6Condo');
    this.gridColumnApi.setColumnsVisible(this.gridColumnApi.getAllColumns(), true);
    this.gridColumnApi.setColumnsVisible(['zeroToThreeStatus', 'sevenToTwelveStatus'], false);
    this.setRowData();
  }

  zeroToThreeTabSelected(usageType: string) {
    usageType === 'MC' ? (this.dateRange = '0-3') : (this.dateRange = '0-3Condo');
    this.gridColumnApi.setColumnsVisible(this.gridColumnApi.getAllColumns(), true);
    this.gridColumnApi.setColumnsVisible(['fourToSixStatus', 'sevenToTwelveStatus'], false);
    this.setRowData();
  }

  setRowData() {
    this.rowData = [];

    this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.forEach((mcProperty) => {
      this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.forEach(
        (prioritizedParcel) => {
          if (prioritizedParcel.propertyGuid !== mcProperty.propertyGuid) return;

          //If the date range is set to 0-12 months then we want to display all market Conditions that are not Condo MC's
          if (
            this.dateRange === '0-12' &&
            mcProperty.propertyUseType === PropertyUsageType.MarketConditions
          ) {
            this.rowData.push({ ...prioritizedParcel, ...mcProperty });
            return;
          }

          //If the date range is set to 0-12 months then we want to display all Condo MC's
          if (
            this.dateRange === '0-12Condo' &&
            mcProperty.propertyUseType === PropertyUsageType.ProjectMarketConditions
          ) {
            this.rowData.push({ ...prioritizedParcel, ...mcProperty });
            return;
          }

          //If date range is set to 7-12 months display all non project mc's with a 7-12 status and/or not listed date within the range
          if (
            this.dateRange === '7-12' &&
            mcProperty.propertyUseType === PropertyUsageType.MarketConditions &&
            (mcProperty.sevenToTwelveStatus ||
              (new Date(mcProperty.marketConditionsNotListedDate) <= this.sevenMonthsAgoDate &&
                new Date(mcProperty.marketConditionsNotListedDate) >= this.twelveMonthsAgoDate))
          ) {
            this.rowData.push({ ...prioritizedParcel, ...mcProperty });
            return;
          }

          //If date range is set to 7-12 months and is a project MC display all Project mc's that  have a 7-12 status and/or not listed date within the range
          if (
            this.dateRange === '7-12Condo' &&
            mcProperty.propertyUseType === PropertyUsageType.ProjectMarketConditions &&
            (mcProperty.sevenToTwelveStatus ||
              (new Date(mcProperty.marketConditionsNotListedDate) <= this.sevenMonthsAgoDate &&
                new Date(mcProperty.marketConditionsNotListedDate) >= this.twelveMonthsAgoDate))
          ) {
            this.rowData.push({ ...prioritizedParcel, ...mcProperty });
            return;
          }

          //If date range is set to 4-6 months display all non project mc's with a 4-6 status and/or not listed date within the range
          if (
            this.dateRange === '4-6' &&
            mcProperty.propertyUseType === PropertyUsageType.MarketConditions &&
            (mcProperty.fourToSixStatus ||
              (new Date(mcProperty.marketConditionsNotListedDate) <= this.fourMonthsAgoDate &&
                new Date(mcProperty.marketConditionsNotListedDate) >= this.sixMonthsAgoDate))
          ) {
            this.rowData.push({ ...prioritizedParcel, ...mcProperty });
            return;
          }

          //If date range is set to 4-6 months and is a project MC display all Project mc's that  have a 4-6 status and/or not listed date within the range
          if (
            this.dateRange === '4-6Condo' &&
            mcProperty.propertyUseType === PropertyUsageType.ProjectMarketConditions &&
            (mcProperty.fourToSixStatus ||
              (new Date(mcProperty.marketConditionsNotListedDate) <= this.fourMonthsAgoDate &&
                new Date(mcProperty.marketConditionsNotListedDate) >= this.sixMonthsAgoDate))
          ) {
            this.rowData.push({ ...prioritizedParcel, ...mcProperty });
            return;
          }

          //If date range is set to 0-3 months display all non project mc's with a 0-3 status and/or not listed date within the range
          if (
            this.dateRange === '0-3' &&
            mcProperty.propertyUseType === PropertyUsageType.MarketConditions &&
            (mcProperty.zeroToThreeStatus ||
              (new Date(mcProperty.marketConditionsNotListedDate) <= this.effectiveDate &&
                new Date(mcProperty.marketConditionsNotListedDate) >= this.threeMonthsAgoDate))
          ) {
            this.rowData.push({ ...prioritizedParcel, ...mcProperty });
            return;
          }

          //If date range is set to 0-3 months and is a project MC display all Project mc's that  have a 0-3 status and/or not listed date within the range
          if (
            this.dateRange === '0-3Condo' &&
            mcProperty.propertyUseType === PropertyUsageType.ProjectMarketConditions &&
            (mcProperty.zeroToThreeStatus ||
              (new Date(mcProperty.marketConditionsNotListedDate) <= this.effectiveDate &&
                new Date(mcProperty.marketConditionsNotListedDate) >= this.threeMonthsAgoDate))
          ) {
            this.rowData.push({ ...prioritizedParcel, ...mcProperty });
            return;
          }
        }
      );
    });
  }

  setDates() {
    this.twelveMonthsAgoDate = new Date(this.effectiveDate);
    this.twelveMonthsAgoDate.setFullYear(this.twelveMonthsAgoDate.getFullYear() - 1);
    this.twelveMonthsAgoDate.setHours(24, 0, 0, 0);

    this.sevenMonthsAgoDate = new Date(this.effectiveDate);
    this.sevenMonthsAgoDate.setMonth(this.sevenMonthsAgoDate.getMonth() - 6);

    this.sixMonthsAgoDate = new Date(this.effectiveDate);
    this.sixMonthsAgoDate.setMonth(this.sixMonthsAgoDate.getMonth() - 6);
    this.sixMonthsAgoDate.setHours(24, 0, 0, 0);

    this.fourMonthsAgoDate = new Date(this.effectiveDate);
    this.fourMonthsAgoDate.setMonth(this.fourMonthsAgoDate.getMonth() - 3);

    this.threeMonthsAgoDate = new Date(this.effectiveDate);
    this.threeMonthsAgoDate.setMonth(this.threeMonthsAgoDate.getMonth() - 3);
    this.threeMonthsAgoDate.setHours(24, 0, 0, 0);
  }

  exportToCsv() {
    this.gridApi.exportDataAsCsv();
  }
}
