
<div class="about-txt">
    <img src="/assets/images/web-logo.png" />
    DataMaster <br />
    1-801-657-5769 <br />
    <a href="https://www.datamasterusa.com" target="_blank">https://www.datamasterusa.com</a> <br />
    <a href="https://www.datamasterusa.com/about-us/" target="_blank">About Us - Company Overview</a> <br />
    <a href="https://www.datamasterusa.com/patents" target="_blank">Patent - www.datamasterusa.com/patents</a> <br />
    
</div>
<div class="dialog-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">OK</button>
        Covered by U.S. Patent No. 9,224,177 <br />
        Covered by U.S. Patent No. 10,134,063 B2 <br />
        Patent Pending <br />
</div>