// Angular
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
// component
import { SettingsComponent } from '../../components/settings/settings.component';
import { LogInAsComponent } from './log-in-as/log-in-as.component';
import { AboutComponent } from '../../components/about/about.component';
// services
import { HeaderNavService } from '../../services/header-nav.service';
import { AuthService } from '../../components/auth/services/auth.service';
import { ThemeService } from '../../services/theme.service';
import { UserStateService } from '../../services/user/user-state.service';
import { SidebarLogoService } from '../../services/sidebar-logo.service';
import { SpinnerService } from '../../services/spinner.service';
import { DataMasterFileService } from '../../services/datamaster-file.service';
import { ConfigService } from '../../dependency-issues';
// State
import { Store } from '@ngxs/store';
import { Logout, RefreshUserData } from '../../services/user/state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('logo', { static: true }) logo!: ElementRef;
  @ViewChild('navbarElement') navBarElement!: ElementRef;
  logoSrcExpanded = '/assets/images/web-logo-white.png';
  logoSrcCollapsed = '/assets/images/dm-icon-white.png';
  logoSrc = this.logoSrcCollapsed;
  logoSize = '30';
  _navEnabled = false;
  _useFullHeaderEnabled = true;
  theme: string = this.themeService.startupTheme;
  expandedSidebar = false;
  isPropertySearchApp!: boolean;
  _subscription: Subscription = new Subscription();
  // homeUrl!: string;

  get homeUrl(): string{
    const PROPERTYSEARCHPATH = '/propertysearch'
    const isInternal = this.userStateService.user.isInternal;

    return !isInternal ? PROPERTYSEARCHPATH : (window.location.href.indexOf(PROPERTYSEARCHPATH) > -1) ? PROPERTYSEARCHPATH : '/';
  }

  get showSettings(): boolean {
    return this.userStateService.user.isInternal || this.userStateService.user.userHasPropertySearchAppAccess;
  }

  constructor(
    private store: Store,
    private sidebarLogoService: SidebarLogoService,
    private headerNavService: HeaderNavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private dialog: MatDialog,
    private userStateService: UserStateService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    public dataMasterFileService: DataMasterFileService,
    private configService: ConfigService
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

//   getHomeUrl(){
//     const PROPERTYSEARCHPATH = '/propertysearch'
//       return this.userStateService
//       .getUserState()
//       .pipe(
//         tap((userState) => {
//             if(!userState) return;
//             this.homeUrl = !userState.isInternal ? PROPERTYSEARCHPATH : (window.location.href.indexOf(PROPERTYSEARCHPATH) > -1) ? PROPERTYSEARCHPATH : '/';
//           })
//         );
//   }

  ngOnInit() {
    // const PROPERTYSEARCHPATH = '/propertysearch'
    // this._subscription.add(
    //   this.userStateService
    //     .getUserState()
    //     .pipe(
    //       tap((_) => {
    //         this.isPropertySearchApp = this.userStateService.userHasPropertySearchAppAccess;
    //         this.homeUrl = !_.isInternal ? PROPERTYSEARCHPATH : (window.location.href.indexOf(PROPERTYSEARCHPATH) > -1) ? PROPERTYSEARCHPATH : '/';
    //       })
    //     )
    //     .subscribe()
    // );

    this._subscription.add(
      this.themeService.currentTheme.subscribe((val) => {
        this.theme = val;
      })
    );

    this._subscription.add(
      this.headerNavService.isNavVisible.subscribe((val) => {
        this._navEnabled = val;
      })
    );

    this._subscription.add(
      this.headerNavService.useFullHeader.subscribe((val) => {
        this._useFullHeaderEnabled = val;
      })
    );

    this._subscription.add(
      this.sidebarLogoService.isSbExpanded.subscribe((val) => {
        this.logoSrc = val == true ? this.logoSrcExpanded : this.logoSrcCollapsed;
        this.expandedSidebar = val;
      })
    );

    // This is used to get the parent route to determine whether or not the top nav header menu should be shown
    this._subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => {
          const url = (event as NavigationEnd).url.split('/');
          const subUrl = url[1]?.split(';');
          this.headerNavService.setShowNav(subUrl[0]);
        })
    );
  }

  public get navEnabled(): boolean {
    return this._navEnabled;
  }

  public get useFullHeader(): boolean {
    return this._useFullHeaderEnabled;
  }

  ngAfterViewInit() {
    if (this.theme !== 'classic') {
      //const navBgColor2 = this.navBarElement.nativeElement.backgroundColor;
      const navBgColor = getComputedStyle(document.querySelector('nav.navbar')).backgroundColor;
      if (navBgColor === 'rgb(16, 42, 67)') {
        // if theme is dark (rgb(16, 42, 67) is the dark bg color on the navbar)
        this.logoSrc = '/assets/images/dm-icon-white.png';
      }
    }

    this._subscription.add(
      this.sidebarLogoService.sidebarWidth.subscribe((val) => {
        //console.log(val);
        //(document.querySelector('.logo') as HTMLElement).style.width = val + 'px';
        //this.logo.nativeElement

        this.logo.nativeElement.style.width = val + 'px';
      })
    );
  }

  logout() {
    if (this.userStateService.user.isImpersonating) {
      const spinner = this.spinnerService.open();
      this.store.dispatch(new RefreshUserData(this.userStateService.user.supportUsername));
      spinner.close()

      return;
    }

    this.store.dispatch(new Logout());
    this.authService.logout();
  }

  getIsInternal() {
    // console.log(this.authService.getClaims());
    return this.authService.getClaims()?.IsInternal;
  }

  getEmail() {
    return of(this.authService.getClaims()?.name);
  }

  openMyAccount() {
    let billingPortalUrl = this.userStateService.user.billingPortalUrl;
        this.openExternalSite(billingPortalUrl);
  }

  private openExternalSite(url: string) {
    window.open(url, '_blank');
  }

  openSettingsModal() {
    const dialogRef = this.dialog.open(SettingsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '90%',
    });

    // dialogRef.afterClosed().subscribe(_=>{
    //   console.log(this.router.url);
    //   this.router.navigate([this.router.url]);
    // })
  }

  openAboutModal() {
    const dialogRef = this.dialog.open(AboutComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '35vw',
    });
  }

  openLogInAsModal() {
    const dialogRef = this.dialog.open(LogInAsComponent, {
      width: '25vw',
    });
  }
}
