import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PropertySearchStatusType } from '../../models/operation/enum/property-search-status-type.enum';
import { PropertySearchResult } from '../../models/operation/property-search/property-search-result.model';
import { SearchCriteriaUserData } from '../../models/operation/property-search/search-criteria.model';
import { SearchProviderResult } from '../../models/operation/property-search/search-provider-result.model';
import { SearchResult } from '../../models/operation/property-search/search-result.model';
import { DataSourceTranslationRequest } from '../../models/operation/translation/data-source-translation-request.model';
import { PropertyTranslationRequest } from '../../models/operation/translation/property-translation-request.model';
import {
  TranslationRequest,
  TranslationRequestWeb,
} from '../../models/operation/translation/translation-request.model';
import { TranslationResult } from '../../models/operation/translation/translation-result.model';
import { DataMasterApiService } from './datamaster-api.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private dataMasterApiService: DataMasterApiService) { }

  public doesSearchResultHaveResults(searchResult: SearchResult) {
    const n = PropertySearchStatusType.NoResults;
    if (
      !searchResult.searchProviderResults ||
      !searchResult.searchProviderResults.some((a) =>
        a.propertySearchResults.some((b) => b.status != n)
      )
    ) {
      return false;
    }
    return true;
  }
  public createTranslationRequestWeb(
    propertySearchResult: PropertySearchResult,
    dataSourceId: number,
    userData: SearchCriteriaUserData
  ) {
    const propertyTranslationRequest: PropertyTranslationRequest =
      new PropertyTranslationRequest();
    propertyTranslationRequest.dataSourceTranslationRequests = [];
    propertyTranslationRequest.propertyGuid = propertySearchResult.propertyGuid;

    propertySearchResult.propertyResults.forEach((propertyResult) => {
      const newDataSourceTranslation: DataSourceTranslationRequest = {
        dataSourceId: dataSourceId,
        rawGuid: propertyResult.rawGuid,
        propertyFields: propertyResult.propertyFields,
        propertySubTables: propertyResult.propertySubTables,
        propertyResultsFoundUsingLimitedDataSource:
          propertySearchResult.propertyResultsFoundUsingLimitedDataSource,
      };
      propertyTranslationRequest.dataSourceTranslationRequests.push(
        newDataSourceTranslation
      );
    });

    const translationRequest: TranslationRequest = new TranslationRequest();
    translationRequest.propertyTranslationRequests = [
      propertyTranslationRequest,
    ];

    const translationRequestWeb: TranslationRequestWeb =
      new TranslationRequestWeb();
    translationRequestWeb.userData = userData;
    translationRequestWeb.translationRequest = translationRequest;

    return translationRequestWeb;
  }

  public getPropertySearchResult(
    searchResult: SearchResult
  ): PropertySearchResult {
    if (!searchResult || !searchResult.searchProviderResults) {
      return null;
    }

    const hasDuplicateResults = searchResult.searchProviderResults.some(function (
      searchProviderResult
    ) {
      return searchProviderResult.propertySearchResults.some(function (
        propertySearchResult
      ) {
        return (
          propertySearchResult.status ==
          PropertySearchStatusType.DuplicateResults
        );
      });
    });

    let searchProviderResult: SearchProviderResult;
    if (hasDuplicateResults) {
      searchProviderResult = searchResult.searchProviderResults.find((x) =>
        x.propertySearchResults.some(function (propertySearchResult) {
          return (
            propertySearchResult.status ==
            PropertySearchStatusType.DuplicateResults
          );
        })
      );
    } else {
      searchProviderResult = searchResult.searchProviderResults.find((x) =>
        x.propertySearchResults.some(function (propertySearchResult) {
          return (
            propertySearchResult.status != PropertySearchStatusType.NoResults
          );
        })
      );
    }

    //clear the rest of the crap
    if (searchResult.searchProviderResults.length > 1) {
      searchResult.searchProviderResults = [];
      searchResult.searchProviderResults.push(searchProviderResult);
    }

    const propertySearchResult = searchProviderResult.propertySearchResults.find(
      (x) => x.status != PropertySearchStatusType.NoResults
    );

    return propertySearchResult;

    //this.multipleResultsTableComponent.dataSourceId =
    //searchProviderResult.dataSourceId;
  }

  public translateResults(
    searchResult: SearchResult,
    dataSourceid: number,
    userData: SearchCriteriaUserData
  ): Observable<TranslationResult> {
    const propertySearchResult = this.getPropertySearchResult(searchResult);
    const translationRequestWeb = this.createTranslationRequestWeb(
      propertySearchResult,
      dataSourceid,
      userData
    );

    return this.dataMasterApiService.translateByRequest(translationRequestWeb);
  }
}
