import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Parcel } from '@datamaster/core';
import { PhotosComponent } from '../components/comparables/photos/photos.component';

@Injectable({
  providedIn: 'root'
})
export class PhotosDialogService {

  constructor(private dialog: MatDialog) { }

  openPhotos(parcel: Parcel) {
    this.dialog.open(PhotosComponent, {
      maxWidth: '95vw',
      minWidth: '73vw',
      data: {
        parcel: parcel
      },
    });
  }
}
