import { Component, OnInit } from '@angular/core';

import {
  DataMasterFileService,
  DataMasterApiService,
 } from '@datamaster/core';
 import {FileInfoService} from '../../../services/file-info.service';
import { Router } from '@angular/router';
import {FileInfo} from '../../../models/file-info';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
  // Tab example taken from https://www.codementor.io/temidayoajisebutu/an-easy-way-to-create-tabs-using-ngswitch-in-angular-2-io3cowd0g
  viewMode = 'tab1';  // default tab
  fileInfos!: FileInfo[];
  selectedFileInfo!: FileInfo;
  pinnedFileInfos!: FileInfo[];

  constructor(private fileInfoService: FileInfoService,
    private dataMasterFileService: DataMasterFileService,
    private dataMasterApiService: DataMasterApiService,
    private router: Router) { }

  ngOnInit() {
    this.getFileInfos();
    this.getPinnedFileInfos();
  }

  onSelect(fileInfo: FileInfo): void {
    this.selectedFileInfo = fileInfo;
  }

  getDynamicExportLabel(exportCount: number): string {
    return exportCount === 1 ? 'export' : 'exports';
  }

  getFileInfos(): void {
    const cutoffDate: Date = new Date(new Date(Date.now()).setHours(new Date(Date.now()).getHours() - 261));

    this.fileInfoService.getFileInfos()
      .subscribe(fileInfos => {
        //this.fileInfos = fileInfos;
        this.fileInfos = fileInfos.filter(info => info.lastModified > cutoffDate);
      });
  }

  getPinnedFileInfos(): void {

    this.fileInfoService.getFileInfos()
      .subscribe(fileInfos => {

        this.pinnedFileInfos = fileInfos.filter(info => info.id === 1 || info.id === 4 || info.id === 5);
      });
  }

  loadAllFiles(): void {
    const cutoffDate: Date = new Date(new Date(Date.now()).setHours(new Date(Date.now()).getHours() - 261));

    // this is a hack to show potential functionality
    this.fileInfoService.getFileInfos()
      .subscribe(fileInfos => {
        fileInfos.filter(info => info.lastModified < cutoffDate)
          .forEach(info => {
            this.fileInfos.push(info);
          });
      });
  }

  getDynamicDateLabel(modifiedDate: Date): string {
    const magicNumber = (1000 * 60 * 60 * 24); // Days
    const now: Date = new Date(Date.now());
    let dayDiff = Math.floor((now.valueOf() - modifiedDate.valueOf()) / magicNumber);
    let timeSpanLabel = 'days';
    if (dayDiff > 0 && dayDiff < 30) {
      if (dayDiff === 1) timeSpanLabel = 'day';
    } else if (dayDiff === 0) {
      dayDiff = Math.floor((now.valueOf() - modifiedDate.valueOf()) / (1000 * 60 * 60));  // for hours
      timeSpanLabel = (dayDiff === 1) ? 'hour' : 'hours';
    } else if (dayDiff > 30 && dayDiff < 365) {
      dayDiff = Math.floor(dayDiff / 30);  // for months
      timeSpanLabel = (dayDiff === 1) ? 'month' : 'months';
    } else if (dayDiff > 365) {
      return `last modified on ${modifiedDate.getMonth() + 1}-${modifiedDate.getDate()}-${modifiedDate.getFullYear()}`;
    } else {
      return `last modified on ${modifiedDate.getMonth() + 1}-${modifiedDate.getDate()}-${modifiedDate.getFullYear()}`;
    }

    return `${dayDiff} ${timeSpanLabel} ago`;
  }

  handleFileInput(files: FileList) {
    this.handleZipFile(files[0]);
  }

  handleZipFile(file: File){
    const formData = new FormData();

    console.log(`Received ${file.name}`);
    formData.append('file', file, file.name);

    console.log('Retrieving DataMasterFile.');
    this.dataMasterApiService.getDataMasterFile(formData).subscribe(dataMasterFile => {
      //add spinner?
      this.dataMasterFileService.dataMasterFile = dataMasterFile;
      console.log(`Received DataMasterFile:`);
      console.log(dataMasterFile);
      this.router.navigate(['/comparables']); // best way of doing this? sharing data between routes (non-children)
    },
    err => console.log(err));
  }

  openFileDialog(e:any) {
    e.preventDefault();

    const element: HTMLElement = document.getElementById('myFile') as HTMLElement;
    element.click();
  }
}
