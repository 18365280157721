<footer>
  <span class="copy">
    &copy; {{currentYear}} <span class="data">data</span><span class="master">master</span>.io
  </span>
  <div class="bottom-nav left-nav">
    <a href="https://www.datamaster.io/privacy-policy" target="_blank">Privacy Policy</a>
    <a href="https://www.datamaster.io/patent-information" target="_blank">Patent Information</a>
  </div>
  <div class="bottom-nav float-right right-nav">
    <a href="https://www.datamaster.io/contact" target="_blank">Contact Us</a>
    <a href="https://www.datamaster.io/about-us" target="_blank">About</a>
    <a href="#" class="social-media first"><span class="fab fa-facebook-f fa-lg"></span></a>
    <a href="#" class="social-media"><span class="fab fa-twitter fa-lg"></span></a>
    <a href="#" class="social-media"><span class="fab fa-youtube fa-lg"></span></a>
    <a href="#" class="social-media"><span class="fab fa-linkedin-in fa-lg"></span></a>
  </div>
</footer>
