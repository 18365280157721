import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// Models
import { DataSource } from '../models/data-source/data-source';
// Services
import { DataSourceService } from '../services/data-source.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataSourcesResolver implements Resolve<DataSource[]> {
  constructor(private dataSourceService: DataSourceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<DataSource[]> {
    return this.dataSourceService.getUserDataSources();
  }
}
