<!-- <div class="dialog-header">
    <img class="dialog-header-icon" src="../../../assets/images/dm-icon.png" />
    <h2 mat-dialog-title>Create Work File</h2>
</div> -->
<app-dialog-header title="Create Work File"></app-dialog-header>
<mat-dialog-content>

<form [formGroup]="createNewWorkFileForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-9 create-new-file-column">
      <div class="row form-group">
        <div class="col-4 ">
          <label for="fileNumber">
            File Number
          </label>
        </div>
        <div class="col-8">
          <span class="required"
            *ngIf="(createNewWorkFileForm.controls['fileNumber'].invalid && createNewWorkFileForm.controls['fileNumber'].touched)">required</span>
          <input type="text" placeholder="" formControlName="fileNumber" required style="width: 100%;" data-cy="fileNumberModalInput"/>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-4">
          <label for="formSoftware">
            Form Software
          </label>
        </div>
        <div class="col-8">
          <select class="custom-select" formControlName="formSoftware" data-cy="formSoftwareModalSelect">
            <option [ngValue]="null" disabled>Select a Form Software</option>
            <option *ngFor="let formSoftware of formSoftwares" [ngValue]="formSoftware">
              {{ formSoftware.displayName }}
            </option>
          </select>
          
          <span class="required"
            *ngIf="(createNewWorkFileForm.controls['formSoftware'].invalid && createNewWorkFileForm.controls['formSoftware'].touched)">required</span>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="form">
            Form
          </label>
        </div>
        <div class="col-8">
          <select class="custom-select" formControlName="form" data-cy="formTypeModalSelect">
            <option [ngValue]="null" disabled>Select a Form</option>
            <option *ngFor="let form of forms" [ngValue]="form">
              {{ form.displayName }}
            </option>
          </select>
          <span class="required"
            *ngIf="(createNewWorkFileForm.controls['form'].invalid && createNewWorkFileForm.controls['form'].touched)">required</span>

        </div>
      </div>

    </div>
    <div class="col-3 create-new-file-column">

      <div class="row" >
        <div class="col-md-12 align-self-center">
          <div>

            <button id="create-work-file-button" type="submit" data-cy="createWorkFileModalButton">
              <div>
                <div>
                  <span class="fa fa-sign-in fa-2x"></span>
                </div>
                <div class="big-button-header">
                  <b>Create</b>
                </div>
                <div class="big-button-description">
                  work file
                </div>
              </div>
            </button>
          </div>
          <div>
            <button id="cancel-work-file-button" (click)="onClose()" cancelWorkFileModalButton>
              Cancel
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
</mat-dialog-content>