<div *ngIf="parcel;else noDeedsDisplay" class="deed-display">
  <!--Header-->
  <div class="property-photo-section">
   <app-deeds-header [propertyGuid]="parcel?.propertyGuid"
   [primaryPhotoUrl]="parcel?.primaryPhotoUrl">

   </app-deeds-header>
  </div>

  <div>
    <!--Listing History-->
    <div class="listing-history-section">
      <app-listing-history
      [propertyUsageType]="parcel?.propertyUseType"
      [userSettings]="userSettings"
      [mlsDataSources]="mlsDataSources"
      [propertyGuid]="parcel?.propertyGuid"
      ></app-listing-history>
    </div>

    <!--Deeds-->
    <div class="deeds-section">
      <app-deeds-display
      [propertyGuid]="parcel?.propertyGuid"
      [prDataSources]="prDataSources"
      [readonly]="false">
      </app-deeds-display>
    </div>

  </div>

</div>

<ng-template #noDeedsDisplay>
  <h6>No deeds were found for this property.</h6>
</ng-template>
