
<h3>Data Source Selection</h3>


<!--Data Source has a its own meaning in angular material tables, not to be confused with DataMaster data sources-->
<table mat-table [dataSource]="userDataSources">
  <!-- Data Source Name Column -->
  <ng-container matColumnDef="dataSourceDisplayName">
    <th mat-header-cell *matHeaderCellDef> Data Source </th>
    <td mat-cell *matCellDef="let dataSource"> {{dataSource.displayName}} </td>
  </ng-container>

    <!-- Data Source Group Name Column -->
    <ng-container matColumnDef="dataSourceGroupDisplayName">
        <th mat-header-cell *matHeaderCellDef> Data Source Group </th>
        <td mat-cell *matCellDef="let dataSource"> {{dataSource.dataSourceGroup.displayName}} </td>
      </ng-container>

    <!-- Data Source Group Name Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Remove </th>
        <td mat-cell *matCellDef="let dataSource">
            <button type="button" class="btn" (click)="remove(dataSource?.id)"><span class="fal fa-times"></span></button>
         </td>
      </ng-container>

     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<div id="addDataSourceDiv">
    <mat-form-field appearance="fill">
        <mat-label>Data Source</mat-label>
        <mat-select [(value)]="selectedDataSourceId">
            <mat-option *ngFor="let dataSource of dataSources" [value]="dataSource.id">
            {{dataSource.displayName}} ({{dataSource.dataSourceGroup.displayName}})
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button type="button" class="btn btn-primary" (click)="addDataSource()">Add <span class="fal fa-plus"></span></button>
</div>