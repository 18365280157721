<app-under-construction *ngIf="!doesUserHaveWebAccess"></app-under-construction>

<div class="review-container">
  <h5>Review and fine tune your report before exporting</h5>

  <div class="review-grid">
    <div class="flex-column">
      <div *ngIf="hasSubject" class="widget widget-tall widget-review">
        <div>
          <div class="widget-heading">
            Subject
          </div>
          <div class="widget-heading">
            <span>{{subjectAddress}}</span>
          </div>
          <div *ngIf="unresolvedDiscrepancies > 0">
            {{unresolvedDiscrepancies}} unresolved discrepancies found
          </div>
        </div>
        <div>
          <label>
            <input type="checkbox" name="includeSubject" class="checkbox" [(ngModel)]="includeSubject">
            Include Subject
          </label>
          <label>
            <input type="checkbox" name="includeSubjectPhoto" class="checkbox" [checked]="includeSubjectPhoto">
            Include Subject Photo
          </label>
          <label *ngIf="hasPlatService">
            <input type="checkbox" name="includePlatMap" class="checkbox" [checked]="includePlatMap">
            Include Plat Map
          </label>
        </div>
      </div>
      <ng-container *ngIf="!hasSubject">
        <ng-container *ngTemplateOutlet="widgetNavTemplate; context: {propertyUsageType: 'Subject'}">
        </ng-container>
      </ng-container>

      <ng-container>
        <ng-container
          *ngTemplateOutlet="widgetNavTemplate; context: {propertyUsageType: 'DataMasterStandardAddendum',displayName: 'DataMaster Standard Addendum', bodyDisplayName: 'Subject or Comparables'}">
        </ng-container>
      </ng-container>
      <div class="btn-container">
        <button class="blue-review-btn"
          [cdkCopyToClipboard]="summaryComment">
          Copy Comments & Exceptions
        </button>
      </div>
    </div>
    <div class="flex-column">
      <div *ngIf="hasComparables" class="widget widget-review">
        <div>
          <div class="widget-heading">
            Comparables
          </div>
          <div class="widget-nav-body">
            <a data-toggle="modal" data-target="#customExportModal"><span class="fa fa-edit edit-button"> Edit</span></a>
            <span>{{compCount}} properties included</span>
          </div>
        </div>
        <label>
          <input type="checkbox" name="includeComparables" [(ngModel)]="includeComparables"
            (change)="includeComparablesChecked()">
          Include Comparables
        </label>
      </div>
      <ng-container *ngIf="!hasComparables">
        <ng-container *ngTemplateOutlet="widgetNavTemplate; context: {propertyUsageType: 'Comparables'}">
        </ng-container>
      </ng-container>
      <div *ngIf="hasAddenda" class="widget widget-review">
        <div>
          <div class="widget-heading">
            Addenda
          </div>
          <div class="widget-nav-body">
            <a data-toggle="modal" data-target="#customExportAddendaModal"><span class="fa fa-edit edit-button"> Edit</span></a>
            <span [(ngModel)]="comparablesAddendumText"></span>
          </div>
        </div>
        <!--<label>
          <input type="checkbox" name="includeAddenda" [(ngModel)]="includeAddenda"
            (change)="includeAddendaChecked()">
          Include Addenda
        </label>-->
      </div>
      <ng-container *ngIf="!hasAddenda">
        <ng-container
          *ngTemplateOutlet="widgetNavTemplate; context: {propertyUsageType: 'Addenda',displayName: 'Addenda' }">
        </ng-container>
      </ng-container>
      <div class="btn-container">
        <button class="blue-review-btn">
          Print
        </button>

      </div>
    </div>
    <div class="flex-column">
      <div class="widget widget-review" *ngIf="hasMC || hasProjMC">
        <div>
          <div class="widget-heading">
            Competing Market (1004 MC)
          </div>        
          <div class="widget-nav-body">
              <a (click)="navigate('MC')"><span class="fa fa-edit edit-button"> Edit</span></a>
              <span>{{mcGraphCount}} Graph(s) Included</span>
              <span>{{mCCount}} properties included</span>
          </div>
        </div>
          <label>            
            <input type="checkbox" name="include1004MC" class="checkbox" [(ngModel)]="include1004MC"
            (change)="includeMCChecked()">
            Include 1004MC
          </label>
          <ng-container *ngIf="isCondoForm">
            <div>
              <span>{{projMCCount}} properties included</span>
            </div>
            <label>
              <input type="checkbox" name="includeProjMC" class="checkbox" [(ngModel)]="includeProjMC">
              Include Condo Market Analysis
            </label>
          </ng-container>

      </div>
      <ng-container *ngIf="!hasMC">
        <ng-container
          *ngTemplateOutlet="widgetNavTemplate; context: {propertyUsageType: 'MC', displayName: 'Competing Market (1004 MC)'}">
        </ng-container>
      </ng-container>
      <div *ngIf="hasNeighborhood" class="widget widget-review">
        <!--<div>-->
          <div class="widget-heading">
            Neighborhood (Larger Market)
          </div>        
          <div class="widget-nav-body">
              <a (click)="navigate('Neighborhood (Larger Market)')"><span class="fa fa-edit edit-button"> Edit</span></a>
              <span>{{neighborhoodAnalysisGraphCount}} Graph(s) Included</span>              
          </div>
          <div>
            <span>{{neighborhoodAnalysisCount}} Neighborhood properties </span>
          </div>
        </div>
      <ng-container *ngIf="!hasNeighborhood">
        <ng-container
          *ngTemplateOutlet="widgetNavTemplate; context: {propertyUsageType: 'Neighborhood (Larger Market)', displayName: 'Neighborhood (Larger Market)'}">
        </ng-container>
      </ng-container>
      <div class="btn-container">
        <button
          class="green-review-btn"
          (click)="export()">
          Send To Report<span class="fa fa-share"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row">
    <textarea readonly class="form-control" id="comment-summary" [value]="summaryComment"></textarea>
  </div>
</div>

<app-summary-custom-export [comparables]="comparables"></app-summary-custom-export>
<app-summary-custom-export [hasAddenda]="true" [comparables]="addendaComparables"></app-summary-custom-export>

<ng-template #widgetNavTemplate let-propertyUsageType="propertyUsageType" let-displayName="displayName"
  let-bodyDisplayName="bodyDisplayName">
  <div class="widget">
    <button class="widget-nav" (click)="navigate(propertyUsageType)">
      <div class="widget-heading">
        {{displayName !== null ? displayName : propertyUsageType}}
      </div>
      <div class="widget-nav-body">
        <p>
          No {{ bodyDisplayName !== null ? bodyDisplayName : displayName !== null ? displayName : propertyUsageType }}
          Information has been included
        </p>
      </div>
      <span class="plus-icon fa fa-plus-circle fa-lg"></span>
    </button>
  </div>
</ng-template>
