<h4 *ngIf="isDashboardSearch === true">Start a new file by retrieving subject property info</h4>

<app-search-error #searchError></app-search-error>

<form [formGroup]="mlsSearchForm">

  <ng-container *ngIf="dataSources && dataSources.length > 1">
    <div class="form-group">
        <label for="mlsNumber">
          MLS
      </label>
      <select name="datasources" class="select-select full-width" (change)="handleChange($event.target)">
          <option *ngFor="let dataSource of dataSources" [value]="dataSource.commonName">{{dataSource.displayName}}</option>
      </select>
    </div>
  </ng-container>

  <div class="form-group">
    <label for="mlsNumber">
      MLS Number
      <span class="required"
        *ngIf="(mlsSearchForm.controls['mlsNumber'].invalid && mlsSearchForm.controls['mlsNumber'].touched)" data-cy="homeMlsRequiredLabel">required</span>
    </label>
    <input type="text" placeholder="MLS Number" formControlName="mlsNumber" data-cy="homeMlsInput"/>
  </div>
  <div class="submit search">
    <!-- <button type="button" class="btn btn-link btn-sm show-results" (click)="showResults()" *ngIf="hasSearchResultsMls">
      show search results
    </button> -->

    <button type="submit" class="btn btn-primary btn-search" (click)="searchValidate(mlsSearchForm)"
      [disabled]="isFormSearching(mlsSearchForm)" data-cy="homeMlsSearchButton">
      <span class="fal fa-search" *ngIf="!isFormSearching(mlsSearchForm)"></span>
      <span *ngIf="!isFormSearching(mlsSearchForm)">Search</span>
      <span class="far fa-spinner fa-pulse spinner" *ngIf="isFormSearching(mlsSearchForm)"></span>
      <span *ngIf="isFormSearching(mlsSearchForm)">Searching...</span>
    </button>
  </div>

</form>
