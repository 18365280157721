<app-dialog-header title="Log In As..."></app-dialog-header>

<div mat-dialog-content>
        <div class="form-group">
            
            <label for="email">
                Email
            </label>
            <input class="form-control" type="text" placeholder="" [value]="email"/>
            <small style="color: red;">{{error}}</small >
            
        </div>
</div>

<div mat-dialog-actions class="dialog-footer" align="end">
    <button class="btn btn-primary " (click)="impersonate()">
        Impersonate
      </button>
      <button class="btn btn-primary btn-cancel" (click)="onClose()">
        Cancel
      </button>
</div>