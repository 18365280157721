import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of,  throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// Models
import {
  ConfigService,
  UserNotification,
  UserNotificationUpdateStatusRequest,
  UserLegacyBulletinUpdateStatusRequest
} from '@datamaster/core';
import { USERNOTIFICATIONS } from '../models/mocks/mock-user-notifiation';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  notifications: BehaviorSubject<UserNotification[]> = new BehaviorSubject<UserNotification[]>([]);
  criticalNotifications: BehaviorSubject<UserNotification[]> = new BehaviorSubject<UserNotification[]>([]);

  constructor(private http: HttpClient, private configService: ConfigService) { }

  getUserNotifications(userId: string, productNames: string): Observable<UserNotification[]> {
    if (this.configService.options.userNotificationMock) return of(USERNOTIFICATIONS);

    //https://localhost:44349/api/Notification/GetNotifications?UserId=1234&ProductNames=WFR,RYANMLS
    const url = this.configService.api.userNotificationUrl + 'GetNotifications?UserId=' + userId + '&ProductNames=' + productNames;
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const results = this.http.get<UserNotification[]>(url, requestOptions)
      .pipe(
        map(notifications => {
          notifications.forEach(notificationItem => {
            notificationItem.messageExpiration = new Date(notificationItem.messageExpiration);
            notificationItem.createdDate = new Date(notificationItem.createdDate);
          })
          this.notifications.next(notifications);
          this.criticalNotifications.next(notifications.filter(n => n.priorityName.toLocaleLowerCase() === 'critical' || n.priorityName.toLocaleLowerCase() === 'outage'));
          return notifications;
        })
      );

    return results;
    //return this.notifications.asObservable();
  }

  updateUserNotificationStatus(notificationUpdateStatusRequest: UserNotificationUpdateStatusRequest[]): void {
    if (this.configService.options.userNotificationMock) return;

    const url = this.configService.api.userNotificationUrl + 'UpdateNotificationStatus';
    const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Access-Control-Allow-Origin': '*' }) };
    const result = this.http.post<any>(url, JSON.stringify(notificationUpdateStatusRequest), requestOptions)
      .pipe(
        tap(_ => {

          notificationUpdateStatusRequest.forEach(item => {
            const index = this.notifications.value.findIndex(n => n.notificationId === item.notificationId);
            this.notifications.value.splice(index, 1);
          });
          this.criticalNotifications.next([]);
          this.criticalNotifications.next(this.notifications.value.filter(n => n.priorityName.toLocaleLowerCase() === 'critical' || n.priorityName.toLocaleLowerCase() === 'outage'));

        }),
        catchError(this.handleError)
      )
      .subscribe();

  }

  updateUserNotificationStatusLEGACY(legacyNotificationUpdateStatusRequest: UserLegacyBulletinUpdateStatusRequest[]) {
    if (this.configService.options.userNotificationMock) return;

    const url = this.configService.api.userNotificationUrl + 'UpdateLegacyBulletinStatus';
    const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Access-Control-Allow-Origin': '*' }) };
    const result = this.http.post<any>(url, legacyNotificationUpdateStatusRequest, requestOptions)
      .pipe(
        tap(_ => {

          legacyNotificationUpdateStatusRequest.forEach(item => {
            const index = this.notifications.value.findIndex(n => n.legacyProductBulletinId === item.legacyBulletinId);
            this.notifications.value.splice(index, 1);
          });
          this.criticalNotifications.next([]);
          this.criticalNotifications.next(this.notifications.value.filter(n => n.priorityName.toLocaleLowerCase() === 'critical' || n.priorityName.toLocaleLowerCase() === 'outage'));

        }),
        catchError(this.handleError)
      )
      .subscribe();
  }

  handleError(error: HttpErrorResponse) {
    console.log(error.message);
    return throwError(error.message);
  }

}
