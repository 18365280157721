import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {  Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
// Model
import { Comparable, ExportRequest, MarketConditionsGraph, PropertyUsageType, UserSettingsService } from '@datamaster/core';
// Service
import {
  AppraisalFormService,
  DataMasterApiService,
  DataMasterFileService,
  MarketAnalysisReportService,
  UserStateService
} from '@datamaster/core';
import { McGraphGenerator } from '../market-conditions/graphs/mc-graph/mc-graph-generator';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  hasComparables = false;
  hasSubject = false;
  hasAddenda = false;
  hasMC = false;
  //hasDataMasterAddendum: boolean = false;
  hasProjMC = false;
  hasNeighborhood = false;
  compCount = 0;
  mCCount = 0;
  projMCCount = 0;
  mcGraphCount = 0;
  neighborhoodAnalysisCount = 0;
  neighborhoodAnalysisGraphCount = 0;
  addendaCount = 0;

  hasPlatService = false;
  includeSubject = false;
  includeSubjectPhoto = false;
  includePlatMap = false;
  include1004MC = false;
  includeProjMC = false;
  includeComparables = false;
  includeAddenda = false;

  isCondoForm = false;
  subjectAddress!: string;
  unresolvedDiscrepancies = 0;

  comparables!: Comparable[];
  addendaComparables!: Comparable[];
  get doesUserHaveWebAccess(): boolean {
    return this.userStateService.user.isInternal;
  }
  summaryComment!: string;
  comparablesAddendumText: string;

  private _subscription: Subscription = new Subscription();

  constructor(
    private dataMasterFileService: DataMasterFileService,
    private marketAnalysisReportService: MarketAnalysisReportService,
    private dataMasterApiService: DataMasterApiService,
    private router: Router,
    private appraisalFormService: AppraisalFormService,
    private userStateService: UserStateService,
    private userSettingsService: UserSettingsService
  ) { }

  ngOnInit(): void {
    // this._subscription.add(
    //   this.userStateService.getUserState().pipe(tap(userState => {
    //     this.doesUserHaveWebAccess = userState.isInternal;
    //   })).subscribe()
    // );

    if (!this.dataMasterFileService.dataMasterFile?.appraisal) return;

    if (this.dataMasterFileService.dataMasterFile.appraisal.subject.subjectLoaded) {
      this.hasSubject = true;
      this.includeSubject = true;

      if (this.dataMasterFileService.dataMasterFile.appraisal.subject.primaryPhotoUrl) {
        this.includeSubjectPhoto = true;
      }

      if (this.dataMasterFileService.dataMasterFile.appraisal.subject.platMapImageURL) {
        this.includePlatMap = true;
      }

      const subjectPropertyGuid =
        this.dataMasterFileService.dataMasterFile.appraisal.subject.propertyGuid;
      this.subjectAddress =
        this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(
          (x) => x.propertyGuid == subjectPropertyGuid
        ).addressLine1;
    }

    //TODO:
    //includeSubjectPhoto
    //hasPlatService // get from user Data Sources "PlatService"and check if plat exists
    if (
      this.dataMasterFileService.dataMasterFile.appraisal.comparables &&
      this.dataMasterFileService.dataMasterFile.appraisal.comparables.length > 0
    ) {
      this.hasComparables = true;
      this.includeComparables = true;
      this.comparables = this.dataMasterFileService.dataMasterFile.appraisal.comparables.filter(
        (x) => x.propertyUseType == PropertyUsageType.Comparable
      );
      this.compCount = this.comparables.length;

      this.addendaComparables =
        this.dataMasterFileService.dataMasterFile.appraisal.comparables.filter(
          (x) => x.propertyUseType != PropertyUsageType.Comparable
        );
    }

    if (this.dataMasterFileService.dataMasterFile.appraisal.marketConditions) {
      this.mCCount = this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.filter(
        (x) => x.propertyUseType == PropertyUsageType.MarketConditions
      )?.length;
      this.hasMC = this.mCCount > 0;
      this.include1004MC = this.hasMC;
      this.mcGraphCount = this.marketAnalysisReportService.marketConditionsGraphs.filter(a => a.includeInExport && a.graphDetails.reportUsageType == PropertyUsageType.MarketConditions).length;
      this.projMCCount =
        this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.filter(
          (x) => x.propertyUseType == PropertyUsageType.ProjectMarketConditions
        )?.length;
      this.hasProjMC = this.projMCCount > 0;
      this.includeProjMC = this.hasProjMC;
      this.neighborhoodAnalysisCount =
        this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.filter(
          (x) => x.propertyUseType == PropertyUsageType.NeighborhoodAnalysis
        )?.length;
      this.hasNeighborhood = this.neighborhoodAnalysisCount > 0;
      this.neighborhoodAnalysisGraphCount = this.marketAnalysisReportService.marketConditionsGraphs.filter(a => a.includeInExport && a.graphDetails.reportUsageType == PropertyUsageType.NeighborhoodAnalysis).length;
    }

    this.isCondoForm = this.appraisalFormService.isCondoForm(
      this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form.commonName
    );

    //if there is no subject comment will return undefined due to empty formattedFieldResults array
    this.dataMasterApiService.getSummaryCommentText(this.dataMasterFileService.dataMasterFile?.appraisal)
      .subscribe((summaryCommentText) => {
      this.summaryComment = summaryCommentText?.text ?? '';
    });

  }

  /**
   * Doc must exist
   */
  async export() {
    const exportRequest: ExportRequest = new ExportRequest();
    exportRequest.formId =
      this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form.id;
    exportRequest.formSoftwareId =
      this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.formSoftware.id;
    exportRequest.fileNumber =
      this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.fileNumber;
    exportRequest.fileId = this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.fileId;
    exportRequest.userData = this.userStateService.userDataForSearch;

    if (this.includeComparables) {
      const compsToExport = this.dataMasterFileService.dataMasterFile.appraisal.comparables.filter(
        (x) => x.exportToReport
      );
      exportRequest.comparables = this.setExportPositionNumber(compsToExport);
    }

    if (this.includeSubject) {
      exportRequest.subjectExportRequest.subjectAddress =
      this.dataMasterFileService.getTranslatedStandardizedParcel(this.dataMasterFileService.dataMasterFile.appraisal.subject.propertyGuid).addressLine1;
      exportRequest.subjectExportRequest.subject =
        this.dataMasterFileService.dataMasterFile.appraisal.subject;
    }

    if (this.includeSubjectPhoto) {
      exportRequest.subjectExportRequest.exportSubjectPhoto = true;
      exportRequest.subjectExportRequest.photoUrl =
        this.dataMasterFileService.dataMasterFile.appraisal.subject.primaryPhotoUrl;
    }

    if (this.includePlatMap) {
      exportRequest.subjectExportRequest.encodedPlatMap =
        this.dataMasterFileService.dataMasterFile.appraisal.subject.platMapImageURL?.toString();
    }

    if (this.include1004MC) {
      exportRequest.marketConditionsForm =
        this.marketAnalysisReportService.marketConditionsForm;

      const marketConditionsGraphs = this.marketAnalysisReportService.marketConditionsGraphs.filter(a => a.includeInExport);

      if(marketConditionsGraphs) {
        for(const marketConditionsGraphKey in marketConditionsGraphs){
          const marketConditionsGraph = marketConditionsGraphs[marketConditionsGraphKey];

          const encodedGraph = await new McGraphGenerator(marketConditionsGraph.graphDetails, this.userSettingsService.userSettings, this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate, false).getDataUrlAsync();
          exportRequest.graphExportRequests.push({ encodedGraph: encodedGraph, graphDisplayName: marketConditionsGraph.commonName, propertyUsageType: PropertyUsageType.MarketConditions });
        }
      }
    }

    if (this.includeProjMC) {
      exportRequest.projectMarketConditionsForm =
        this.marketAnalysisReportService.projectMarketConditionsForm;
    }
    if (this.includeProjMC || this.include1004MC) {
      exportRequest.marketConditionsFormattedFieldResults = {};
      //Typescript maps cannot be used directly inside a http post body.
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditionsFormattedFieldResults?.forEach((val: string, key: number) => {
        exportRequest.marketConditionsFormattedFieldResults[key] = val;
      });
    }

    this.dataMasterApiService.getTotalXmlExport(exportRequest).subscribe((blob) => {
      console.log(blob);
      const fileName = `${this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.fileNumber}.tdcx`;
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob.body);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  /**
   * Doc must exist
   */
  navigate(propertyUsageType: string): void {
    let url: string;

    switch (propertyUsageType) {
      case 'Subject':
        url = '/subject';
        break;
      case 'Comparables':
      case 'Addenda':
        url = '/comparables';
        break;
      //case 'Competing Market (1004 MC)':
      case 'MC':
      case 'Neighborhood (Larger Market)':
      // eslint-disable-next-line no-fallthrough
      case 'ProjMC':
        url = '/market-analysis';
        break;
    }

    if (propertyUsageType == 'Neighborhood (Larger Market)') {
      this.router.navigate([url, {neighborhoodAnalysis:true}]);
    } else {
      this.router.navigate([url]);
    }
  }

  /**
   * Doc must exist
   */
  includeComparablesChecked(): void {
    this.comparables.forEach((x) => {
      x.exportToReport = this.includeComparables;
    });
    this.compCount = this.comparables.filter((x) => x.exportToReport)?.length ?? 0;
  }

  /**
   * Doc must exist
   */
  includeMCChecked(): void {
    this.mCCount = !this.include1004MC
      ? 0
      : this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.filter(
        (x) => x.propertyUseType == PropertyUsageType.MarketConditions
      )?.length;
  }

  /**
   * Doc must exist
   */
   setExportPositionNumber(comparables: Comparable[]): Comparable[] {
    const returnComps: Comparable[] = [];
    comparables.forEach((a) => {
      if (a.propertyPlacementNumber != a.exportPropertyPlacementNumber) {
        const b = a;
        b.propertyPlacementNumber = a.exportPropertyPlacementNumber;
        returnComps.push(b);
      } else {
        returnComps.push(a);
      }
    });
    return returnComps.sort((a, b) => (a.propertyPlacementNumber > b.propertyPlacementNumber) ? 1 : -1);
  }
}
