import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//
import { TokenResponse } from '../../models/token-response.model';
import { ConfigService } from '../../dependency-issues';

@Injectable({
  providedIn: 'root',
})
export class ClassIdentityServerService {
  private _tokenResponse: TokenResponse = null;
  private readonly dmApiUrl: string = this.configService.api.dataMasterApi;
  private _requestOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*', //TODO: make thsi specific instead of wildcard
    }),
  };
  private _expirationTime: number;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  public async getBearerToken(): Promise<string> {
    if (this.isTokenExpired()) {
      await this.getToken()
        .toPromise()
        .then((tokenResponse) => {
          this._tokenResponse = tokenResponse;
          this._expirationTime = Date.now() + tokenResponse.expires_in;
        });
    }

    return this._tokenResponse.access_token;
  }

  private getToken(): Observable<TokenResponse> {
    return this.httpClient.get<TokenResponse>(
      `${this.dmApiUrl}classvaluationidentityserver/connect/token`,
      this._requestOptions
    );
  }

  public isTokenExpired(): boolean {
    if (this._tokenResponse == null || this._tokenResponse.access_token == null) return true;
    return Date.now() > this._expirationTime;
  }
}
