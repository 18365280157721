<ng-container *ngTemplateOutlet="(!dataSourceLinkInfos || dataSourceLinkInfos?.length === 0) ? placeholder : (dataSourceLinkInfos?.length === 1 ? singleDataSourceLinkInfo : multipleDataSourceLinkInfo)"></ng-container>

<ng-template #placeholder>
    <div class="data-source-info-container">
        <div class="data-source-logo-container mr-2">
            <ng-container *ngTemplateOutlet="defaultDataSourceLogo"></ng-container>
        </div>
        <div>
            <ng-container *ngTemplateOutlet="messaging"></ng-container>
            <span>Visit your MLS's website in your default browser.</span>
        </div>
    </div>
</ng-template>

<ng-template #singleDataSourceLinkInfo>
    <div class="data-source-info-container">
        <div class="data-source-logo-container mr-2">
            <ng-container *ngTemplateOutlet="dataSourceLogo; context:{encodedLogo:dataSourceLinkInfos[0]?.encodedLogo}"></ng-container>
        </div>
        <div>
            <ng-container *ngTemplateOutlet="messaging"></ng-container>
            <a target="_blank" href="{{dataSourceLinkInfos[0].url}}">Open {{dataSourceLinkInfos[0].mlsDisplayName}} website in your default browser.</a>
        </div>
    </div>
</ng-template>

<ng-template #multipleDataSourceLinkInfo>
    <div>
        <ng-container *ngTemplateOutlet="messaging"></ng-container>
    </div>
    <div class="data-source-info-container">
        <div  *ngFor="let dataSourceLinkInfo of dataSourceLinkInfos" class="data-source-info">
            <div class="data-source-logo-container centered">
                <ng-container *ngTemplateOutlet="dataSourceLogo; context:{encodedLogo:dataSourceLinkInfo.encodedLogo}"></ng-container>
            </div>
            <a target="_blank" href="{{dataSourceLinkInfo.url}}">Go to {{dataSourceLinkInfo.mlsDisplayName}} website.</a>
        </div>
    </div>
</ng-template>

<ng-template #dataSourceLogo let-encodedLogo="encodedLogo">
    <img class="data-source-logo centered" *ngIf="encodedLogo; else defaultDataSourceLogo" id='base64image' [src]="encodedLogo | safe: 'url'" />

</ng-template>

<ng-template #defaultDataSourceLogo>
    <div class="data-source-logo-placeholder centered" >
        <span class="fas fa-external-link-alt fa-2x default-logo"></span>
    </div>
</ng-template>

<ng-template #messaging>
    <div class="section-title">
        Get data directly from your MLS data provider.
    </div>
    <div>
        Search for data directly from your MLS data provider by clicking on the link.
    </div>
</ng-template>
