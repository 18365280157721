<div class="container">
    <app-comp-details 
    [parcel]="parcel"
    [mlsDataSources]="mlsDataSources" >
    </app-comp-details>
    <h4>Select Photo To Use</h4> 
    <div class="d-flex flex-row">
        <div class="d-flex flex-column">
            <img [src]="DisplayPhoto?.url" />
            <div>{{DisplayPhoto?.shortDescription}}</div>
            <div class="d-flex flex-row">
                <button><i class="bi bi-plus-circle-fill"></i>Add New Photo</button>
                <button (click)="setMainPhoto()"><i class="bi bi-check2-square"></i>Set As Main Photo</button>
                <a *ngIf="VirtualTourIsVisible" [href]="VirtualTourLink">Virtual Tour</a>
            </div>
        </div>
        <div>
            <!--Gallery-->
        </div>
    </div>
    <hr/>
    
  <div>
    <div class="d-flex flex-row">
      <h5>Mls Public Remarks:</h5>
      <div>{{StandardizedParcel?.remarks}}</div>
    </div>
    <div class ="container">
        <div class ="row">
            <div class="col"></div>
            <div class="col">1</div>
            <div class="col">2</div>
            <div class="col">3</div>
            <div class="col">4</div>
            <div class="col">5</div>
            <div class="col">6</div>

        </div>
        <div class="row">
            <h5 class="col">Quality of Construction:</h5>
            <div *ngFor="let choice of QualityOfConstructionValues">
                <input class="col" type="radio"
                    [(ngModel)]="StandardizedParcel.qualityOfConstruction"
                    [checked]="StandardizedParcel.qualityOfConstruction===choice"
                    [value]="choice" />
            </div>
        </div>
        <div class="row">
            <h5 class="col">Condition:</h5>
            <div *ngFor="let choice of ConditionValues">
                <input class="col" type="radio"
                    [(ngModel)]="StandardizedParcel.condition"
                    [checked]="StandardizedParcel.condition===choice"
                    [value]="choice" />
            </div>
        </div>
    </div>
</div>