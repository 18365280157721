import { Component, HostListener, Inject } from '@angular/core';
import { NgModel , NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'datamaster-core-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
  styleUrls: ['./text-input-dialog.component.scss'],
})
export class TextInputDialogComponent  {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    cancelText: string,
    confirmText: string,
    message: string,
    title: string,
    value: any
}, private mdDialogRef: MatDialogRef<TextInputDialogComponent>) { }

  public close(isConfirmed : boolean) {
    this.mdDialogRef.close({isConfirmed: isConfirmed, value: this.data.value});
  }
  confirm() : void{
    this.close(true);

  }
  cancel() : void{
    this.close(false);

  }
  @HostListener('keydown.esc') 
  public onEsc() {
    this.close(false);
  }
  public inputValidator(event: any) { //If we want a value that isn't string input, change validator and input type of input control
    const pattern = /^[a-zA-Z0-9]*$/;   
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
      // invalid character, prevent input
    }
  }
  
}
