import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
// Service
import { ConfigFileModel, ConfigService } from '../dependency-issues';
// Model
import { AppStateModel, APP_PATH } from './model/app-state.model';
import { LoadConfigFile, SetAppError, SetAppLoading, SetAppName } from './model/app.actions';

@State<AppStateModel>({
    name: APP_PATH,
    defaults: new AppStateModel()
})
@Injectable()
export class AppState {
    constructor(
        private store: Store,
        private configService: ConfigService
    ) { }

    /**
     * Returns the App state.
     *
     * @param state
     */
    @Selector()
    static getAppState(state: AppStateModel): AppStateModel {
        return state;
    }

    /**
     * Set the isLoading sate of the app
     * @param ctx
     * @param isLoading
     */
    @Action(SetAppName)
    setAppName(ctx: StateContext<AppStateModel>, { appName }: SetAppName): any {
        ctx.patchState({
            appName: appName
        });
    }

    /**
     * Loads the Config File and save to the storage whatever is required
     * @param ctx
     * @param appname
     */
    @Action(LoadConfigFile)
    loadConfigFile(ctx: StateContext<AppStateModel>, { appname }: LoadConfigFile): any {
        const state = ctx.getState();
        return this.configService.getConfig().pipe(
            tap((configFile: ConfigFileModel) => {
                let appInfo = {};
                if (state[appname]) {
                    appInfo = Object.assign({}, state[appname]);
                }

                appInfo = {
                    ...appInfo,
                    api: configFile.api, // configFile.api
                    options: configFile.options
                };

                ctx.patchState({
                    ...state,
                    [appname]: appInfo
                });

                this.store.dispatch(new SetAppName(appname));
            })
        );
    }

    /**
     * Set the isLoading sate of the app
     * @param ctx
     * @param isLoading
     */
    @Action(SetAppLoading)
    setAppLoading(ctx: StateContext<AppStateModel>, { isLoading }: SetAppLoading): any {
        ctx.patchState({
            isLoading: isLoading
        });
    }

    /**
     * Set the isLoading sate of the app
     * @param ctx
     * @param error
     */
    @Action(SetAppError)
    setAppError(ctx: StateContext<AppStateModel>, { error }: SetAppError): any {
        if (error.stopLoading) {
            this.store.dispatch(new SetAppLoading(false));
        }

        ctx.patchState({
            appError: {
                message: error.message
            }
        });
    }
}
