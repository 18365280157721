import { ApnSearchStateModel } from './../../models/search/apn-search.model';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
// Models
import { PropertySearchResult } from '../../models/operation/property-search/property-search-result.model';
import { SearchResult } from '../../models/operation/property-search/search-result.model';
import { SearchCriteriaUserData } from '../../models/operation/property-search/search-criteria.model';
// Service
import { ConfigService } from '../../dependency-issues';

@Injectable({
  providedIn: 'root',
})
export class ApnSearchService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) { }

  searchShallow(
    apn: string,
    state: string,
    county: string,
    userData: SearchCriteriaUserData
  ): Observable<PropertySearchResult[]> {
    //return of(PROPERTYSEARCHRESULTS);    // Mock
    //.pipe(delay(1000));               // Pipe delay makes it mimic search/connection time

    const url =
      this.configService.api.dataMasterApi +
      'datasources/CoreLogic/apnSearchShallow?apn=' +
      apn +
      '&county=' +
      county +
      '&state=' +
      state;

    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    const results = this.http.post<PropertySearchResult[]>(
      url,
      userData,
      requestOptions
    );
    return results;

    //TODO:  Add error handling
  }

  search(
    apn: ApnSearchStateModel,
    propertyUseType: string,
    dataSourceCommonName: string,
    userData: SearchCriteriaUserData
  ): Observable<SearchResult> {
    const url =
      this.configService.api.dataMasterApi +
      `datasources/${dataSourceCommonName}/apnSearch/${propertyUseType}?apn=${apn.apnNumber}&county=${apn.county}&state=${apn.state}`;
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const results = this.http.post<SearchResult>(url, userData, requestOptions);
    return results;

    //TODO:  Add error handling
  }
}
