export class FileDetail {
  fileId: string; //GUID
  filePath: string;
  fileNumber: string;
  address: string;
  status: string;
  createdDate: Date;
  editedDate: Date;
  username: string;
  formVendor: string;
}
