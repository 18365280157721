import { Component, Input,  ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { DataSource } from '../../../models/data-source/data-source';

@Component({
  selector: 'app-settings-data-source-selection',
  templateUrl: './settings-data-source-selection.component.html',
  styleUrls: ['./settings-data-source-selection.component.scss'],
})
export class SettingsDataSourceSelectionComponent {
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @Input() userDataSources: DataSource[];
  @Input() dataSources: DataSource[];

  displayedColumns: string[] = [
    'dataSourceDisplayName',
    'dataSourceGroupDisplayName',
    'action',
  ];
  selectedDataSourceId: number;

  constructor() {
    //
  }

  remove(dataSourceId: number) {
    this.userDataSources = this.userDataSources.filter((value, key) => {
      return value.id != dataSourceId;
    });
  }

  addDataSource() {
    if (this.userDataSources.some((a) => a.id == this.selectedDataSourceId)) {
      return;
    }
    this.userDataSources.push(
      this.dataSources.find((a) => a.id == this.selectedDataSourceId)
    );
    this.table.renderRows();
  }
}
