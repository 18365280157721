import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mc-summary',
  templateUrl: './mc-summary.component.html',
  styleUrls: ['./mc-summary.component.scss'],
})
export class McSummaryComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
