import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// Models
import { DataSourceCredentials } from '../../../models/settings/data-source-credentials.model';
import { UserSettings } from '../../../models/settings/user-settings.model';
// Service
import { SpinnerService } from '../../../services/spinner.service';
import { UserSettingsService } from '../../../services/user/user-settings.service';

@Component({
  selector: 'app-mls-credentials',
  templateUrl: './mls-credentials.component.html',
  styleUrls: ['./mls-credentials.component.scss'],
})
export class MlsCredentialsComponent implements OnInit {
  username = '';
  password = '';
  userSettings: UserSettings;
  credentials: DataSourceCredentials[];

  @Output() isCredentialUpdated = new EventEmitter<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private mdDialogRef: MatDialogRef<MlsCredentialsComponent>,
    private spinnerService: SpinnerService,
    private userSettingsService : UserSettingsService
  ) {}

  ngOnInit(): void {
    this.credentials = this.data.userSettings?.dataSourceCredentials;
    if (this.credentials) {
      this.credentials
        .find((e) => true)
        .credentialFieldValues.forEach((cred) => {
          if (!cred.isPassword) this.username = cred.value;
          else if (cred.isPassword) this.password = cred.value;
        });
    }
  }

  updateFormValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  saveCredentials(event: Event) {
    const spinnerRef = this.spinnerService.open('Saving settings...');

    try {
      if (!this.credentials) {
        this.credentials = [
          {
            dataSourceCommonName: this.data.dataSource.dataSourceCommonName,
            dataSourceDisplayName: this.data.dataSource.dataSourceDisplayName,
            requiresCredentials: true,
            credentialFieldValues: this.data.dataSource.credentialFieldValues,
            isInternalOverride: this.data.isInternal,
            isUserPasswordRequired: this.data.isPasswordRequired,
          },
        ];
      }
      this.credentials
        .find((e) => true)
        .credentialFieldValues.forEach((cred) => {
          if (!cred.isPassword) cred.value = this.username;
          else if (cred.isPassword && this.password !== '') {
            cred.value = this.password;
          }
        });

      this.data.userSettings.dataSourceCredentials = this.credentials;

      this.userSettingsService
        .saveUserSettings(
          this.data.userSettings,
          this.data.username
        )
        .subscribe(
          (result) => {
            this.addIsCredentialUpdated(true);
            spinnerRef.close();
            this.mdDialogRef.close();
          },
          (error) => {
            spinnerRef.close();
          }
        );


    } catch (error) {
      console.log(error);
      spinnerRef.close();
    }
  }

  addIsCredentialUpdated(value: boolean) {
    this.isCredentialUpdated.emit(value);
  }
}
