import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserStateService, ThemeService, DataSource } from '@datamaster/core';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NewsComponent } from '../common/news/news.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('news', { static: true }) newsChild!: NewsComponent;
  newsToggleStateClass!: string;
  layout: string = this.themeService.startupDashboardLayout;

  get doesUserHaveWebAccess(): boolean {
    return this.userStateService.user.isInternal;
  }
  dataSources!: DataSource[];

  private _subscriptions: Subscription = new Subscription();

  constructor(
    private themeService: ThemeService,
    private userStateService: UserStateService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit() {
    // this._subscriptions.add(this.userStateService.getUserState().pipe(tap(userState =>{
    //   this.doesUserHaveWebAccess = userState.isInternal;
    // })).subscribe());

    this._subscriptions.add(
      this.activatedRoute.data.subscribe((response:any) => {
        this.dataSources = response.userDataSources;
      })
    );

    this._subscriptions.add(
      this.themeService.currentDashboardLayout.subscribe((val) => {
        this.layout = val;
      })
    );
  }

  onToggleNews(event: string) {
    this.newsToggleStateClass = this.newsToggleStateClass === 'news-toggled' ? '' : 'news-toggled';
  }
}
