export class Address {
  fullAddress?: string;
  addressLine1: string;
  addressLine2?: string;
  houseNumber?: string;
  streetPreDirection?: string;
  streetName?: string;
  streetPostDirection?: string;
  streetSuffix?: string;
  unitNumber?: string;
  city: string;
  state: string;
  zip: string;
  county?: string;
  latitude?: string;
  longitude?: string;

  constructor() {
      this.addressLine1 = '',
      this.city = '',
      this.state = '',
      this.zip = '',
      this.county = ''
  }
}

