import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PropertySearchResult } from '../../models/operation/property-search/property-search-result.model';
import { AddressSearchComponent } from '../address-search/address-search.component';
import { ApnSearchComponent } from '../apn-search/apn-search.component';
import { MlsSearchComponent } from '../mls-search/mls-search.component';
import { PropertySearchHelperService } from '../../services/property-search/property-search-helper.service';
import { DataMasterApiService } from '../../services/property-search/datamaster-api.service';

import { SearchResult } from '../../models/operation/property-search/search-result.model';
import {
  TranslationResult,
  TranslationResultPrioritizationRequest,
} from '../../models/operation/translation/translation-result.model';
import { TranslatedPropertyResult } from '../../models/operation/translation/translated-property-result.model';
import { StandardValueSet } from '../../models/workfile/standard-value-set.model';
import { SearchType } from '../../models/operation/property-search/search-criteria.model';
import { DataMasterFileService } from '../../services/datamaster-file.service';
import { AppraisalForm } from '../../models/workfile/appraisal-form.model';
import { AppraisalFormSoftware } from '../../models/workfile/appraisal-form-software.model';
import { SearchResultTranslationRequest } from '../../models/operation/translation/search-result-translation-request.model';
import { PropertyUsageType } from '../../models/workfile/enum/property-usage-type.enum';
import { PropertyImportComponent } from '../property-import/property-import.component';
import { DataSource } from '../../models/data-source/data-source';
import { PropertySearchType } from '../../models/enums/property-search-type';
import { ThemeService } from '../../services/theme.service';
import { DataSourceService } from '../../services/data-source.service';
import { UserStateService } from '../../services/user/user-state.service';
import { SpinnerService } from '../../services/spinner.service';
import { ProcessImportFileRequest } from '../../models/import/process-import-file-request.model';
import { FormattingRequestWithPrioritizationResult } from '../../models/operation/prioritization/prioritization-result.model';
// State
import { Store } from '@ngxs/store';
import { NewFileModel } from './state/model/new-file.model';

//  Additionally this form is a "REACTIVE" form, but should probably move to template form (model-driven)
//  once we have the models all figured out
//
@Component({
  selector: 'app-new-file',
  templateUrl: './new-file.component.html',
  styleUrls: [
    './new-file.component.scss',
    // '../home.component.scss'
  ],
})
export class NewFileComponent implements OnInit, OnDestroy {
  // MLS Sample: 1505252
  @Input() isPropertySearchApp!: boolean;
  mlsDataSources!: DataSource[];
  prDataSources!: DataSource[];
  viewMode = 'newFileTab'; // default tab // Pretty sure this is obsolete and can be deleted. Need to check in normal layout, not PDR product layout
  selectedVendorImgSrc!: string;
  selectedVendorImgHeight!: number;
  selectedVendorImgHeightResults!: number;
  selectedVendorImgAlt!: string;
  searching = false;
  TEMPsearchSource!: string;
  searchType!: PropertySearchType;
  searchResult!: SearchResult | null;
  translatedPropertyResults!: TranslatedPropertyResult[];

  showNewFile: boolean[] = [];

  layout: string = this.themeService.startupDashboardLayout;

  // TODO:  Pull these from a source
  appraisalFormTypes!: AppraisalForm[];
  formSoftwares!: AppraisalFormSoftware[];

  @ViewChild('addressSearch', { static: true })
  addressSearchChild!: AddressSearchComponent; // It works when defined like this, where 'addressSearch' is a var on the html element
  @ViewChild('apnSearch', { static: true }) apnSearchChild!: ApnSearchComponent;
  @ViewChild('mlsSearch', { static: false }) mlsSearchChild!: MlsSearchComponent; //static is set to false because the ngIf check for datasources prevent this from loading after that conditional has changed
  @ViewChild('propertyDetail', { static: true }) propertyDetailChild!:any;

  @Input() newFileForm!: FormGroup;
  @Input() createNewWorkFileModalForm!: FormGroup;
  @Input() dataSources!: DataSource[];

  private _subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private dataMasterApiService: DataMasterApiService,
    private dataMasterFileService: DataMasterFileService,
    private propertySearchHelperService: PropertySearchHelperService,
    private dataSourceService: DataSourceService,
    private userStateService: UserStateService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private store: Store
  ) {}
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit() {
    this.themeService.currentDashboardLayout.subscribe((val) => {
      this.layout = val;
    });

    this.mlsDataSources = this.dataSourceService.filterMlsDataSources(this.dataSources);
    this.prDataSources = this.dataSourceService.filterPrDataSources(this.dataSources);

    this._subscription.add(
      this.dataSourceService.userDataSourcesChanged
        .pipe(
          tap((userDataSources) => {
            this.mlsDataSources = this.dataSourceService.filterMlsDataSources(userDataSources);
            this.prDataSources = this.dataSourceService.filterPrDataSources(userDataSources);
          })
        )
        .subscribe((_) => {
          //
        })
    );

    if(this.isPropertySearchApp) return;

    const spinner = this.spinnerService.open();
    this.selectedVendorImgSrc = '/assets/images/vendors/alamode.png';
    this.selectedVendorImgHeight = 25;
    this.selectedVendorImgAlt = 'AlaMode';
    this.selectedVendorImgHeightResults = 24;
    this.searching = false;

    this.newFileForm = new FormGroup({
      fileName: new FormControl('', Validators.required),
      formType: new FormControl('', Validators.required),
      mlsFileUpload: new FormControl(''),
      //file: new FormControl(null, [Validators.required, requiredFileType('png')])
      file: new FormControl(''), // TODO:  Add file type validators (.txt, .csv, .dm, .wfr)
    });

    this.createNewWorkFileModalForm = new FormGroup({
      fileNumber: new FormControl('', Validators.required),
      formSoftware: new FormControl('', Validators.required),
      form: new FormControl('', Validators.required),
      appraiser: new FormControl(''),
    });

    this.newFileForm.controls['formType'].setValue('1004');
    this.createNewWorkFileModalForm.controls['form'].setValue('1004');
    this.createNewWorkFileModalForm.controls['appraiser'].setValue('DataMan');

    const forms$ = this.dataMasterApiService.getAppraisalForms();

    const formSoftwares$ = this.dataMasterApiService.getAppraisalFormSoftwares();

    forkJoin([forms$, formSoftwares$]).subscribe(
      (results) => {
        this.appraisalFormTypes = results[0];
        this.formSoftwares = results[1];
        spinner.close();
      },
      (error) => {
        console.log(error);
        spinner.close();
      }
    );

  }

  // Validates the "New File" form
  validate() {
    this.searching = false;
    if (this.newFileForm.valid === false) {
      //this.newFileForm.controls[''].markAsTouched();
      for (const item in this.newFileForm.controls) {
        //console.log(this.newFileForm.controls[item]);
        this.newFileForm.controls[item].markAsTouched();
      }
    } else {
      const propertyLimitBeforePromptingUser = 15;

      const selectedFormSoftware:any = this.formSoftwares.find(
        (x) => x.commonName == this.selectedVendorImgAlt
      );
      this.dataMasterApiService
        .getFormatFieldMaps(this.newFileForm.value.formType.id, selectedFormSoftware.id)
        .subscribe((formatFieldMaps) => {
          this.dataMasterFileService.initialize(formatFieldMaps);

          this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.formVendor =
            this.newFileForm.value.formType.commonName;
          this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.fileNumber =
            this.newFileForm.value.fileName;
          this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form =
            this.newFileForm.value.formType;
          this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.formSoftware =
            selectedFormSoftware;
          this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate =
            new Date(new Date().setHours(0, 0, 0, 0));

        const newFileParams = new NewFileModel();
        newFileParams.fileName
        // this.store.dispatch(new SetNewFileParams());

          if (!this.newFileForm.value.file) {
            this.router.navigate(['/comparables']); // best way of doing this? sharing data between routes (non-children)
            return;
          }
          // TODO: create the new work file
          const formData = new FormData();
          formData.append('file', this.newFileForm.value.file, this.newFileForm.value.file.name);
          //TODO:remove hard coded wfr
          formData.append('dataSourceCommonName', 'ure');
          formData.append('propertyUsageType', 'Comparable');

          this.dataMasterApiService.getFileReaderResults(formData).subscribe(
            (fileReaderResult) => {
              console.log(fileReaderResult);

              if (
                fileReaderResult &&
                fileReaderResult.propertySearchResults.length > propertyLimitBeforePromptingUser
              ) {
                //TODO: show pop up..
              }
              //stop here if the user prompts not to continue

              const processImportFileRequest: ProcessImportFileRequest = new ProcessImportFileRequest(
                fileReaderResult,
                this.userStateService.userDataForSearch
              );
              this.dataMasterApiService
                .processimportfile(processImportFileRequest)
                .subscribe((searchResult) => {
                  console.log(searchResult);
                  this.propertySearchHelperService.setRawProperties(searchResult);
                  const searchResultTranslationRequest: SearchResultTranslationRequest =
                    {
                      searchResult : searchResult,
                      userData : this.userStateService.userDataForSearch,
                      selectedFormCommonName : this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form.commonName
                    };
                  this.dataMasterApiService
                    .translate(searchResultTranslationRequest)
                    .subscribe((translationResult) => {
                      console.log(translationResult);

                      this.propertySearchHelperService.setTranslatedParcels(translationResult);

                      this.dataMasterApiService
                        .translateDeeds(searchResult)
                        .subscribe((deedTranslationResult) => {
                          this.propertySearchHelperService.setDeeds(deedTranslationResult);
                          const form =
                            this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form
                              .commonName;
                          const translationResultPrioritizationRequest: TranslationResultPrioritizationRequest =
                            new TranslationResultPrioritizationRequest(
                              translationResult,
                              this.userStateService.userDataForSearch,
                              form
                            );
                          this.dataMasterApiService
                            .prioritize(translationResultPrioritizationRequest)
                            .subscribe((prioritizationResult) => {
                              console.log(prioritizationResult);

                              this.propertySearchHelperService.setPrioritizedParcels(
                                prioritizationResult
                              );
                              const formSoftware =
                                this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail
                                  .formSoftware.commonName;
                              const prioritizationResultRequest: FormattingRequestWithPrioritizationResult =
                                new FormattingRequestWithPrioritizationResult(
                                  prioritizationResult,
                                  this.userStateService.userDataForSearch,
                                  form,
                                  formSoftware
                                );
                              this.dataMasterApiService
                                .formatByPrioritizationResult(prioritizationResultRequest)
                                .subscribe((formattingResult) => {
                                  console.log(formattingResult);

                                  this.propertySearchHelperService.setFormattedResults(
                                    formattingResult
                                  );
                                  this.router.navigate(['/comparables']); // best way of doing this? sharing data between routes (non-children)
                                });
                            });
                        });
                    });
                });

              //this.router.navigate(['/Comparables']);
            },
            (err: HttpErrorResponse) => {
              console.log(`${err.statusText} (${err.status}):  ${err.message}`);
            }
          );
        });
    }
  }

  // This is a bit of a hack, prob need a more robust solution
  isFormSearching(formGroup: { valid: any; }): boolean {
    if (formGroup.valid && this.searching == true) {
      return true;
    } else {
      return false;
    }
  }

  openPropertyImport(event: {
    searchResult: SearchResult;
    translationResult: TranslationResult;
    searchType: SearchType;
  }) {
    this.dialog.open(PropertyImportComponent, {
      maxWidth: '95vw',
      minWidth: '73vw',
      //height: "90%",
      data: {
        searchResult: event.searchResult,
        translatedPropertyResults: event.translationResult.translatedPropertyResults,
        propertyUseType: PropertyUsageType.Subject,
        isDashboardSearch: true,
        searchType: event.searchType,
        usePropertySearchAppFlow: this.isPropertySearchApp
      },
    });
  }

  clearSearchResults(): void {
    this.searchResult = null;
    this.searching = false;
    for (const index in this.showNewFile) {
      this.showNewFile[index] = false;
    }

    //Call each child "clearSearchResults"
    this.apnSearchChild.clearSearchResults();
    this.addressSearchChild.clearSearchResults();
    this.mlsSearchChild?.clearSearchResults();
    this.TEMPsearchSource = '';
  }

  showResults() {
    //$("#searchResultsModal").modal("show");
    //$('#propertyImportModal').modal('show');
  }

  //Region Alternative UI
  initDetail(result: PropertySearchResult | TranslatedPropertyResult): void {
    this.propertyDetailChild.initPropertyDetail(result);
  }

  useAsSubject(e:any, i:any, element:any): void {
    //var s = e.srcElement;
    //var t = element;
    e.preventDefault();
    e.stopPropagation();

    // first hide all the new file items, then show the selected new file item
    this.showNewFile[i] = !this.showNewFile[i];

    for (const index in this.showNewFile) {
      if (index != i) {
        this.showNewFile[index] = false;
      }
    }

    // Hack until we actually wire this up
    if (element.parentElement.querySelector('#fileName') !== null) {
      //var value = element.parentElement.querySelector('.new-file-subject');
     /// $('#searchResultsModal').modal('hide');
      this.router.navigate([
        '/Subject/detail/' + element.parentElement.querySelector('#fileName').value,
      ]);

      //this.router.navigate(['/Subject/detail/1']);
    }
    //const item = parent.nativeElement.querySelector("new-file-subject");
  }

  updateVendor(e:any) {
    console.log(e);
    if (e.srcElement.localName === 'img') {
      //console.log(e.srcElement.src);
      this.selectedVendorImgSrc = e.srcElement.src;
      this.selectedVendorImgHeight = e.srcElement.height;
      this.selectedVendorImgAlt = e.srcElement.alt;
    } else {
      //console.log(e.srcElement.children[0].src);
      this.selectedVendorImgSrc = e.srcElement.children[0].src;
      this.selectedVendorImgHeight = e.srcElement.children[0].height;
      this.selectedVendorImgAlt = e.srcElement.children[0].alt;
    }
  }

  convertToAcres(sizeInSqFt: number): number {
    if (sizeInSqFt !== undefined) {
      return Math.round(sizeInSqFt * 0.0000229568 * 100) / 100;
    } else {
      return 0;
    }
  }

  getOriginalValues(standardValueSets: StandardValueSet[]): string {
    if (!standardValueSets || standardValueSets.length == 0) return '';

    return standardValueSets.map((x) => x.originalValue).join(',');
  }
}
