import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

// Actions
import { UpdateAddress, ResetAddress } from './model/address-search.actions';
import { ResetMLS, UpdateMLS } from './model/mls-search.actions';
import { ResetAPN } from './model/apn-search.actions';

// Model
import { AddressSearchStateModel } from './../../../models/search/address-search.model';

@State<AddressSearchStateModel>({
    name: 'addressSearch',
    defaults: new AddressSearchStateModel()
})
@Injectable()
export class AddressSearchState {

    constructor(private store: Store) { }

    /**
     * Get address search data.
     * @param state: @see AddressSearchStateModel
     */
    @Selector()
    static getAddressSearchData(state: AddressSearchStateModel): AddressSearchStateModel {
        return state;
    }

    /**
     * Update Address State
     * @param ctx 
     * @param address 
     */
    @Action(UpdateAddress)
    updateAddress(ctx: StateContext<AddressSearchStateModel>, { address }: UpdateAddress): void {
        // Update Address State
        ctx.patchState({
            ...address
        });

        // reset MLS and APN state
        this.store.dispatch(new ResetMLS());
        this.store.dispatch(new ResetAPN());
    }

    /**
  * reset address state
  * @param ctx 
  * @returns 
  */
    @Action(ResetAddress)
    resetAddress(ctx: StateContext<AddressSearchStateModel>): void {
        const resetState = new AddressSearchStateModel();
        ctx.patchState({
            ...resetState
        });
    }
}