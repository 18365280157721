<h3>Analytics - past 30 days</h3>
<div class="analytics">


  <div class="analysis-item">
    <span class="number">28</span>
    <label>appraisal file exports</label>
    <span class="last-month">
      last month:
      <span class="count">26</span>
    </span>
  </div>

  <div class="analysis-item">
    <span class="number">233</span>
    <label>properties exported</label>
    <span class="last-month">
      last month:
      <span class="count">216</span>
    </span>
  </div>

  <!--<div class="analysis-item">
    <span class="number big-num">10,206</span>
    <label>data points exported</label>
  </div>-->

  <div class="analysis-item">
    <span class="number">~42</span>
    <label>hours saved</label>
    <span class="last-month">
      last month:
      <span class="count">~36</span>
    </span>
  </div>

</div>

