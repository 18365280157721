import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

// Actions
import { UpdateMLS, ResetMLS } from './model/mls-search.actions';
import { ResetAddress } from './model/address-search.actions';
import { ResetAPN } from './model/apn-search.actions';

// Model
import { MlsSearchStateModel } from './../../../models/search/mls-search.model';

@State<MlsSearchStateModel>({
    name: 'mlsSearch',
    defaults: new MlsSearchStateModel()
})
@Injectable()
export class MlsSearchState {

    constructor(private store: Store) { }

    /**
     * Get MLS search data.
     * @param state: @see MlsSearchStateModel
     */
    @Selector()
    static getMlsSearchData(state: MlsSearchStateModel): MlsSearchStateModel {
        return state;
    }

    /**
     * Update MLS State
     * @param ctx 
     * @param mlsNumber
     * @returns 
     */
    @Action(UpdateMLS)
    updateMLS(ctx: StateContext<MlsSearchStateModel>, { mlsNumber }: UpdateMLS): void {

        // Update MLS State
        ctx.patchState({
            mlsNumber
        });

        // Reset Address and APN State
        this.store.dispatch(new ResetAddress());
        this.store.dispatch(new ResetAPN());
    }

    /**
     * Reset MLS State
     * @param ctx 
     * @returns 
     */
    @Action(ResetMLS)
    clearMLS(ctx: StateContext<MlsSearchStateModel>): void {
        const resetState = new MlsSearchStateModel();
        ctx.patchState({
            ...resetState
        });
    }
}