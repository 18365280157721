<div class="larger-market" *ngIf="hasCompetingMarket">
  <div class="flex-row" >
    <ng-container *ngTemplateOutlet="mcGraphs"> </ng-container>
  </div>
  <div class="flex-row">
    <div class="graphs-table">
      <ng-container *ngTemplateOutlet="neighborhoodAnalysisGraphs"> </ng-container>
    </div>
  </div>  
</div>

<ng-template #neighborhoodAnalysisGraphs>
  <div class="graph-list">
    <div *ngFor="let graph of neighborhoodGraphs">
      <button type="button"  class="graph-list-button">
        <app-neighborhood-graphs (changeGraphEvent)="changeGraph($event)" [neighborhoodGraph]="graph" [userSettings]="userSettings" [effectiveDate]="dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate">
        </app-neighborhood-graphs>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #mcGraphs>
  <div class="top-graphs">
    <app-mc-graph class="mc-graph" [marketConditionsGraph]="marketConditionsGraph" [userSettings]="userSettings" [effectiveDate]="effectiveDate">
    </app-mc-graph>
    <app-mc-graph class="mc-graph" [marketConditionsGraph]="NeighborhoodGraph" [userSettings]="userSettings" [effectiveDate]="effectiveDate">
    </app-mc-graph>
  </div>
</ng-template>