
<div class="settings-content-header">

<h3>Data Source Credentials</h3>
<a class="tell-me-more" href="https://www.datamasterusa.com/tell-me-more/#data-source-credentials" target="_blank"><span class="fad fa-question-circle fa-lg icon"></span>
</a>
</div>

<ng-container *ngIf="dataSourceCredentials">
    <div class="data-source-credential-container" *ngFor="let dataSourceCredential of dataSourceCredentials">
        <div class="data-source-credential-header">
            <div>
                <label class="data-source-credential-label">{{dataSourceCredential.dataSourceDisplayName}}</label>
            </div>
            <div>
                <mat-checkbox *ngIf="showInternalOverride" [checked]="dataSourceCredential.isInternalOverride">Use Customer Credentials</mat-checkbox>
            </div>
        </div>
        <div *ngIf="!showInternalOverride || (showInternalOverride && dataSourceCredential.isInternalOverride)">
            <ng-container *ngFor="let credentialFieldValue of dataSourceCredential.credentialFieldValues">
                    <label *ngIf="!credentialFieldValue.isPassword || dataSourceCredential.isUserPasswordRequired" class="credential-field-value-label">{{credentialFieldValue.displayName}}</label>
                    <input *ngIf="!credentialFieldValue.isPassword" type="text" placeholder="" class="form-control" [(ngModel)]="credentialFieldValue.value" />
                    <input *ngIf="dataSourceCredential.isUserPasswordRequired && credentialFieldValue.isPassword" type="text" placeholder="" class="form-control" [(ngModel)]="credentialFieldValue.value" />
            </ng-container>
        </div>
    </div>
</ng-container>


