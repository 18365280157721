export enum ListingStatusType {
  Active,
  Expired,
  Contract,
  SettledSale,
  Withdrawn,
}

export const ListingStatusTypeDescription = new Map<number, string>([
  [ListingStatusType.Active, 'Active'],
  [ListingStatusType.Expired, 'Expired'],
  [ListingStatusType.Contract, 'Contract'],
  [ListingStatusType.SettledSale, 'Settled Sale'],
  [ListingStatusType.Withdrawn, 'Withdrawn'],
]);
