export enum DataSourceGroupType {
  None = 'None',
  Mls = 'Mls',
  PublicRecords = 'PublicRecords',
  CompTracker = 'CompTracker',
  Coop = 'Coop',
  Apprisal = 'Apprisal',
  File = 'File',
  User = 'User',
}
