import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// Models
import { PropertySearchResult } from '../../models/operation/property-search/property-search-result.model';
import { SearchResult } from '../../models/operation/property-search/search-result.model';
import { SearchCriteriaUserData } from '../../models/operation/property-search/search-criteria.model';
import { ConfigService } from '../../dependency-issues';

@Injectable({
  providedIn: 'root',
})
export class MlsSearchService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) { }

  searchShallow(
    mlsNumber: string,
    primaryDataSourceCommonName: string,
    userData: SearchCriteriaUserData
  ): Observable<PropertySearchResult[]> {
    //return of(PROPERTYSEARCHRESULTS);    // Mock
    ////  .pipe(delay(1000));               // Pipe delay makes it mimic search/connection time

    ////1505252
    //const url = `${this.configService.api.dataMasterApi}datasources/Wfr/mlsSearchShallow?mlsNumbers=' + mlsNumber`;
    const url = `${this.configService.api.dataMasterApi}datasources/${primaryDataSourceCommonName}/mlsSearchShallow?mlsNumbers=${mlsNumber}`;
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const results = this.http.post<PropertySearchResult[]>(url, userData, requestOptions);
    return results;

    //TODO:  - Add error handling
    //       - Handle no results
  }

  search(
    mlsNumbers: string[] | number[],
    primaryDataSourceCommonName: string,
    propertyUsageType: string,
    userData: SearchCriteriaUserData
  ): Observable<SearchResult> {
    let url = `${this.configService.api.dataMasterApi}datasources/${primaryDataSourceCommonName}/mlsSearch/${propertyUsageType}?`;

    mlsNumbers.forEach((mlsNumber) => {
      url += `mlsNumbers=${mlsNumber}&`;
    });

    url = url.slice(0, url.length - 1);
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const results = this.http.post<SearchResult>(url, userData, requestOptions);
    return results;

    //TODO:Add error handling; Handle no results
  }
}
