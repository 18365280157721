import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserSettings } from '../../models/settings/user-settings.model';
import { SettingsService } from '../../components/settings/services/settings.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  userSettings: UserSettings;
  
  private _userSettingsSubject = new Subject<UserSettings>();
  
  constructor(private settingApiService: SettingsService) { }

  public getUserSettings(username: string): Observable<UserSettings> {
    if (this.userSettings) {
      return of(this.userSettings);
    }

    return this.settingApiService.getSettings(username).pipe(tap(userSettings => {
      this.userSettings = userSettings;
    }));
  }

  public saveUserSettings(
    userSettings: UserSettings,
    username: string
  ): Observable<any> {
    this.userSettings = userSettings;

    this._userSettingsSubject.next(userSettings);
    return this.settingApiService.saveSettings(userSettings, username);
  }

  public resetSettingsToDefault(username: string): Observable<UserSettings> {
    return this.settingApiService
      .resetSettingsToDefault(username)
      .pipe(map((userSettings) => (this.userSettings = userSettings)));
  }
}
