import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Modules
import {
  DMSharedModule,
  DialogHeaderComponent,
  MaterialSharedModule,
} from '@datamaster/core';
import { AgGridModule } from 'ag-grid-angular';
import { CommonComponentsModule } from '../common/common-components.module';
// Components
import { ImportComponent } from './import/import.component';
import { GraphsComponent } from './graphs/graphs.component';
import { MarketConditionsComponent } from './market-conditions.component';
import { McCommentComponent } from './mc-comment/mc-comment.component';
import { McCommentEditComponent } from './mc-comment-edit/mc-comment-edit.component';
import { McDetailComponent } from './mc-detail/mc-detail.component';
import { CheckboxRendererComponent } from './mc-detail/checkbox-renderer/checkbox-renderer.component';
import { DateValidationEditor } from './mc-detail/date-validation-editor/date-validation-editor.component';
import { ReportComponent } from './report/report.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { McGraphComponent } from './graphs/mc-graph/mc-graph.component';
import { McSummaryComponent } from './mc-summary/mc-summary.component';
import { SearchCriteriaSummaryComponent } from './search-criteria-summary/search-criteria-summary.component';
import { AnnualComparisonSummaryComponent } from './annual-comparison-summary/annual-comparison-summary.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { NeighborhoodComponent } from './report/neighborhood/neighborhood.component';
import { NeighborhoodGraphComponent } from './report/neighborhood/neighborhood-graphs/neighborhood-graphs.component';
import { McSubjectComponent } from './mc-subject/mc-subject.component';
const EXPORTS = [
  GraphsComponent,
  ImportComponent,
  MarketConditionsComponent,
  McCommentComponent,
  McDetailComponent,
  ReportComponent,
  McCommentEditComponent,
  McGraphComponent,
  NeighborhoodComponent,
  McSubjectComponent,
  NeighborhoodGraphComponent
];

const DECLARATIONS = [
  ...EXPORTS,
  CheckboxRendererComponent,
  DateValidationEditor,
];

@NgModule({
  imports: [
    AgGridModule,
    CommonModule,
    DMSharedModule,
    CommonComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialSharedModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
  ],
  declarations: [
    ...DECLARATIONS,
    SearchCriteriaSummaryComponent,
    AnnualComparisonSummaryComponent,
    McSummaryComponent
  ],
  exports: [...EXPORTS],
  providers:[
    DatePipe
  ]
})
export class MarketConditionsModule {}
