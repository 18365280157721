<app-search-error #searchError></app-search-error>


<form [formGroup]="mlsSearchForm">

  <ng-container *ngIf="dataSources && dataSources.length > 1">
    <div class="form-row">
      <div class="form-group col">
          <label for="mlsNumber">
            MLS
        </label>
        <select name="datasources" class="select-select full-width" (change)="handleChange($event.target)">
            <option *ngFor="let dataSource of dataSources" [value]="dataSource.commonName">{{dataSource.displayName}}</option>
        </select>
      </div>
    </div>
</ng-container>

  <div class="form-row">
    <div class="form-group col">
      <label for="mlsNumber">
        MLS Number
        <span class="required"
          *ngIf="(mlsSearchForm.controls['mlsNumber'].invalid && mlsSearchForm.controls['mlsNumber'].touched)">required</span>
      </label>
      <input type="text" placeholder="MLS Number" formControlName="mlsNumber" class="full-width" data-cy="searchResultsMlsInput"/>
    </div>
  </div>
  <div class="submit search">

    <button type="button" class="btn reset-button" (click)="resetForm()" data-cy="searchResultsMlsResetButton">
      <span class="far fa-redo icon"></span>
      Reset
    </button>

    <button type="submit" class="btn btn-primary btn-search" (click)="searchValidate(mlsSearchForm)"
      [disabled]="isFormSearching(mlsSearchForm)" data-cy="searchResultsMlsSearchButton">
      <span class="fal fa-search icon" *ngIf="!isFormSearching(mlsSearchForm)"></span>
      <span *ngIf="!isFormSearching(mlsSearchForm)">Search</span>
      <span class="far fa-spinner fa-pulse spinner icon" *ngIf="isFormSearching(mlsSearchForm)"></span>
      <span *ngIf="isFormSearching(mlsSearchForm)">Searching...</span>
    </button>
  </div>

</form>
