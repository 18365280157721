export class BpoPayload {
  fileId: string;
  data: BpoData;
}

export class BpoData {
  //TODO:Add Fields we are sending over here
}

export class SendBpoDataResponse {
  success: boolean;
  transactionId: string;
  response: string;
}

export class TransactionStatusResponse {
  orderApiResponse: any; //don't know datatype
  status: string;
  message: string;
  orderId: string;
  orderApiResponseDate: any; //don't know datatype
}
