import { Injectable } from '@angular/core';
import { DataMasterFile } from '../models/workfile/datamaster-file.model';
import { FormatFieldMap } from '../models/format-field-map.model';
import { StandardizedParcel } from '../models/workfile/standardized-parcel.model';

@Injectable({
  providedIn: 'root',
})
export class DataMasterFileService {
  dataMasterFile: DataMasterFile;
  formatFieldMaps: FormatFieldMap[];

  initialize(formatFieldMaps: FormatFieldMap[]) {
    this.dataMasterFile = new DataMasterFile();
    this.formatFieldMaps = formatFieldMaps;
  }

  public compressAndSave() {
    //
  }

  public getTranslatedStandardizedParcel(propertyGuid : string): StandardizedParcel {
      return this.dataMasterFile.appraisal.translatedParcels?.find(x => x.propertyGuid == propertyGuid);
  }

}
