
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
// State
import { Select, Store } from '@ngxs/store';
// Models
import { RefreshUserData, SetUserData } from './state/model/user.actions';
import { UserStateModel } from '../../models/user/user-state.model';
// Services
import { AuthService } from '../../components/auth/services/auth.service';
import { UserState } from './state';

@Injectable({
    providedIn: 'root',
})
export class UserStateService implements OnDestroy {
    userState$: Observable<UserStateModel> = this.store.select(state => state.user);
    user = new UserStateModel();

    get userDataForSearch(): any {
        return {
            authUserId: this.user?.userId ?? 0,
            email: this.user?.user.userName ?? null,
            customerId: this.user?.user.maxioId ?? 0,
            isInternal: this.user?.isInternal ?? false,
            ip: null, //"todo,add if necessary, or remove"
        };
    }

    // /**
    //  * @deprecated
    //  */
    // userState: UserStateModel;
    // /**
    //  * @deprecated
    //  */
    // getUserStateObservable: Observable<UserStateModel>;
    // /**
    //  * @deprecated
    //  */
    // getUserStateObservableSubscription: any;

    private atComponentDestroy = new Subject();

    constructor(
        private authService: AuthService,
        private store: Store
    ) {
        this.userState$.pipe(takeUntil(this.atComponentDestroy)).subscribe((userState: UserStateModel) => this.user = userState);

        this.store.dispatch(new RefreshUserData(null));
    }

    // /**
    //  * @deprecated
    //  */
    // userHasPropertySearchAppAccess: boolean;

    // /**
    //  * @deprecated
    //  */
    // public getUserState(): Observable<UserStateModel> {
    //     if (this.userState) {
    //         return of(this.userState);
    //     }

    //     return this.refresh();
    // }

    // /**
    //  * @deprecated
    //  */
    // public refresh(username: string = null): Observable<UserStateModel> {
    //     if (this.getUserStateObservable != null) {
    //         return this.getUserStateObservable;
    //     }

    //     if (!username) {
    //         const nameClaim = this.authService.getClaims()?.name;
    //         if (!nameClaim) {
    //             return of(null);
    //         }
    //         username = nameClaim;
    //     }

    //     this.getUserStateObservable = this.authService.getUserStateFromService(username).pipe(shareReplay(1));

    //     this.getUserStateObservableSubscription = this.getUserStateObservable.subscribe((userState: UserStateModel) => {
    //         this.store.dispatch(new SetUserData(userState));
    //         this.userState = userState;

    //         if (userState.isInternal) {
    //             return;
    //         }

    //         this.userHasPropertySearchAppAccess = this.doesUserHavePropertySearchAppAccess();
    //     });

    //     return this.getUserStateObservable;
    // }

    // /**
    //  * @deprecated
    //  */
    // // give PSA access to those who explicitly have the psa product code and those who have an unlimited version of Pro
    // doesUserHavePropertySearchAppAccess(): boolean {
    //     return this.userState?.subscriptions?.some((a) =>
    //         a.productCodes.some((productCode) => {
    //             const code = productCode.toLowerCase();
    //             return code === 'psa web' || code.startsWith('unl');
    //         })
    //     );
    // }

    // /**
    //  * @deprecated
    //  */
    // getUserDataForSearch(): any {
    //     this.getUserState();
    //     return {
    //         authUserId: this.userState?.userId ?? 0,
    //         email: this.userState?.user.userName ?? null,
    //         fusebillCustomerId: this.userState?.user.fusebillId ?? 0,
    //         isInternal: this.userState?.isInternal ?? false,
    //         ip: null, //"todo,add if necessary, or remove"
    //     };
    // }

    ngOnDestroy(): void {
        this.atComponentDestroy.next({});
        this.atComponentDestroy.unsubscribe();
        // this.getUserStateObservableSubscription.unsubscribe();
    }
}
