import { Injectable } from '@angular/core';
// Store
import { Selector, State } from '@ngxs/store';
// State
// Models
import { DATAMASTER_MAIN_PATH, DatamasterMainStateModel } from './model/datamaster-main-state.model';

@State<DatamasterMainStateModel>({
    name: DATAMASTER_MAIN_PATH,
    defaults: new DatamasterMainStateModel(),
    children: [
        // NewFileState
    ]
})
@Injectable()
export class DatamasterMainState {
    constructor() {
        //
    }

    /**
     * Get Feature DatamasterMain State Model.
     * @param state: @see DatamasterMainStateModel
     */
    @Selector()
    static getDatamasterMain(state: DatamasterMainStateModel): DatamasterMainStateModel {
        return state;
    }
}
