import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
// Models
import { BpoPayload, TransactionStatusResponse } from '../../dependency-issues/models/datamaster-bpo/bpo-payload';
// Services
import { ClassIdentityServerService } from './class-identity-server.service';
import { ConfigService } from '../../dependency-issues';

@Injectable({
  providedIn: 'root',
})
export class ClassDataApiService {
  private readonly classDataApiUrl: string = this.configService.api.classDataApiUrl;
  private httpClient: HttpClient;

  constructor(
    private classIdentityServer: ClassIdentityServerService,
    private configService: ConfigService
  ) {
    this.httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
  }

  public async sendBpoData(bpoPayload: BpoPayload): Promise<Observable<any>> {
    const bearerToken: string = await this.classIdentityServer.getBearerToken();
    const options = this.getOptions(bearerToken);

    return this.httpClient.post<any>(
      `${this.classDataApiUrl}events/process-bpo-acuity`,
      JSON.stringify(bpoPayload),
      options
    );
  }

  public async getTransationStatus(
    transactionId: string
  ): Promise<Observable<TransactionStatusResponse>> {
    const bearerToken: string = await this.classIdentityServer.getBearerToken();
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + bearerToken,
        'Access-Control-Allow-Origin': '*', //TODO: make thsi specific instead of wildcard
      }),
    };

    return this.httpClient.get<TransactionStatusResponse>(
      `${this.classDataApiUrl}transactions/${transactionId}`,
      options
    );
  }

  getOptions(bearerToken: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
        'Access-Control-Allow-Origin': '*', //TODO: make thsi specific instead of wildcard
        //Accept: "*/*",
      }),
    };

    return httpOptions;
  }
}
