import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
// Core
import { DataSource, UserSettings, Parcel } from '@datamaster/core';
@Component({
  selector: 'app-deeds',
  templateUrl: './deeds.component.html',
  styleUrls: ['./deeds.component.scss'],
})
export class DeedsComponent  {
  @Input() parcel: Parcel;
  @Input() mlsDataSources: DataSource[];
  @Input() prDataSources: DataSource[];
  @Input() userSettings: UserSettings;

  @Output() saveUserSettings: EventEmitter<any> = new EventEmitter();
  @Output() loading: EventEmitter<Observable<any>> = new EventEmitter();

  isSearching = false;

  constructor() {
    //
  }
}
