import { Component, Input } from '@angular/core';
import { DataSourceLinkInfo } from '../../models/data-source/data-source-link-info';

@Component({
  selector: 'app-mls-data-source-media',
  templateUrl: './mls-data-source-media.component.html',
  styleUrls: ['./mls-data-source-media.component.scss'],
})
export class MlsDataSourceMediaComponent {
  @Input() dataSourceLinkInfos: DataSourceLinkInfo[];
}
