<app-dialog-header title="Market Conditions Comment Select"></app-dialog-header>
<div mat-dialog-content>
  <app-comp-details
    [parcel]="parcel"
    [mlsDataSources]="mlsDataSources" >
  </app-comp-details>
  <app-deeds 
      [parcel]="parcel" 
      [mlsDataSources]="mlsDataSources" 
      [prDataSources]="prDataSources"
      [userSettings]="userSettings"
      (saveUserSettings)="saveUserSettings($event)"
      (loading)="loading($event)">
  </app-deeds>
</div>
