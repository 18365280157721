
<header class="header fixed-top flex-md-nowrap p-0 {{theme === 'classic' ? 'classic-theme' : ''}}">

  <ng-container *ngTemplateOutlet="theme==='classic' ? navBarClassic : navBar"></ng-container>

</header>

<ng-template #navBar>
  <nav class="navbar" #navbarElement>

    <a class="navbar-brand logo {{expandedSidebar ? 'expanded' : ''}}" routerLink="{{homeUrl}}" #logo>
      <img src="{{logoSrc}}" height="{{logoSize}}" />
      <!-- <img src="~/images/logo-web.png" height="50" /> -->
    </a>

    <div class="navbar-nav container" *ngIf="navEnabled">
      <a class="nav-item navbar-link " routerLink="/subject" routerLinkActive="active" #subjectactive="routerLinkActive">
        Subject
      </a>
      <a class="nav-item navbar-link " routerLink="/comparables"  routerLinkActive="active">
        Comparables
      </a>
      <a class="nav-item navbar-link " [routerLink]="['/market-analysis', {neighborhoodAnalysis:false}]" routerLinkActive="active" #mcactive="routerLinkActive">
        Market Analysis
      </a>
      <a class="nav-item navbar-link " routerLink="/summary" routerLinkActive="active" #summaryactive="routerLinkActive">
        Report Summary
      </a>
    </div>


  </nav>

  <div class="nav-right">
    <!--uncomment to restore when we are ready-->
    <!-- <app-notifications viewType="new"></app-notifications> -->

    <a class="action-item" href="#"  title="Help">
      <span class="fal fa-question-circle fa-lg"></span>
    </a>

    <a class="action-item sign-out" href="#" title="Log out">
      <span class="fal fa-sign-out fa-lg"></span>
    </a>
  </div>
</ng-template>



<ng-template #navBarClassic>

  <nav class="navbar navbar-expand-lg navbar-basic" #navbarElement *ngIf="!useFullHeader">
      <a class="navbar-brand" routerLink="{{homeUrl}}">
        <img class="navbar-logo" src="../../../assets/images/web-logo-white.png" height="30" />
      </a>

  </nav>

  <ng-container *ngIf="useFullHeader">
    <div class="navbar-skinny-header">

      <a class="navbar-brand logo" routerLink="{{homeUrl}}">
        <img src="../../../assets/images/dm-icon.png" height="20" />
        <span class="logo-name">DataMaster</span>
      </a>
      <span *ngIf="getEmail() | async as email" class="user-name">
        <span class="spacer-dash">-</span>
        <a href="#" >{{email}}</a>
      </span>


      <div class="nav-right">

        <!--uncomment to restore when we are ready-->
        <!-- <app-notifications viewType="classic"></app-notifications> -->
        <!-- <a class="action-item" href="#" onclick="return false" title="Help">
          <span class="fal fa-question-circle"></span>
        </a> -->
      </div>

    </div>

    <div class="navbar-classic" #navbarElement>
      <div class="nav-item non-flex-width menu">
        <span class="fa fa-bars fa-2x menu-selector dropdown-toggle" data-toggle="dropdown" data-cy="hamburgerMenu"></span>
        <ng-container *ngTemplateOutlet="classicMenu"></ng-container>
      </div>
      <a class="nav-item navbar-link non-flex-width" routerLink="{{homeUrl}}" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" #homeactive="routerLinkActive" data-cy="homeButton">
        <span class="fa fa-home fa-lg"></span>
      </a>
      <ng-container *ngIf="navEnabled">

        <a class="nav-item navbar-link {{navEnabled ? '' : 'disabled'}}" [routerLink]="navEnabled ? '/subject' : null " [routerLinkActive]="navEnabled ? 'active' : ''" #subjectactive="routerLinkActive" data-cy="subjectTab">
          Subject
        </a>
        <a class="nav-item navbar-link {{navEnabled ? '' : 'disabled'}}" [routerLink]="navEnabled ? '/comparables' : null" [routerLinkActive]="navEnabled ? 'active' : ''" #comparablesactive="routerLinkActive" data-cy="comparablesTab">
          Comparables
        </a>
        <a class="nav-item navbar-link {{navEnabled ? '' : 'disabled'}}" [routerLink]="navEnabled ? ['/market-analysis', {neighborhoodAnalysis:false}] : null " [routerLinkActive]="navEnabled ? 'active' : ''" data-cy="marketAnalysisTab">
          Market Analysis
        </a>
        <a class="nav-item navbar-link non-flex-width send {{navEnabled ? '' : 'disabled'}}" [routerLink]="navEnabled ? '/summary' : null " [routerLinkActive]="navEnabled ? 'active' : ''" #exportactive="routerLinkActive" data-cy="reviewAndSendTab">
          <span class="text">
            Review & Send
            <span class="fas fa-arrow-circle-right"></span>
          </span>
          <span class="form-info" *ngIf="navEnabled">Form: {{this.dataMasterFileService.dataMasterFile?.appraisal.appraisalDetail.form.displayName}}</span>
        </a>
      </ng-container>

    </div>

  </ng-container>
</ng-template>



<ng-template #classicMenu>
  <div class="dropdown-menu">
    <a class="dropdown-item" (click)="openMyAccount()" data-cy="myAccount">
      <span class="fad fa-user fa-lg icon"></span>
      <span class="text">My Account</span>
    </a>

    <a *ngIf="getIsInternal()" class="dropdown-item" (click)="openLogInAsModal()">
      <span class="fad fa-share-square fa-lg icon"></span>
      <span class="text">Log in as</span>
    </a>
    <a class="dropdown-item" (click)="logout()" data-cy="signOut">
      <span class="fad fa-sign-out-alt fa-lg icon"></span>
      <span class="text">Sign Out</span>
    </a>
    <ng-container *ngIf="getIsInternal()">
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="#">
        <span class="fad fa-trash-alt fa-lg icon"></span>
        <span class="text">Clear Properties</span>
      </a>
      <a class="dropdown-item" href="#">
        <span class="fad fa-save fa-lg icon"></span>
        <span class="text">Save File</span>
      </a>
      <a class="dropdown-item" href="#">
        <span class="fad fa-times fa-lg icon"></span>
        <span class="text">Close File</span>
      </a>


      <div class="dropdown-divider"></div>
      <a class="dropdown-item disabled" href="#">
        <span class="fad fa-print fa-lg icon"></span>
        <span class="text">Print</span>
      </a>
    </ng-container>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item"  *ngIf="showSettings" (click)="openSettingsModal()" data-cy="settings">
        <span class="fad fa-cog fa-lg icon"></span>
        <span class="text">Settings</span>
      </a>

    <a class="dropdown-item" href="http://www.datamasterusa.com/contact-us" target="_blank" data-cy="help">
      <span class="fad fa-question fa-lg icon"></span>
      <span class="text">Help</span>
    </a>
    <a class="dropdown-item" (click)="openAboutModal()" data-cy="about">
      <span class="fad fa-info fa-lg icon"></span>
      <span class="text">About</span>
    </a>
  </div>
</ng-template>


