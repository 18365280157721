//import { Address } from './address';
import { PropertyResult } from './property-result.model';
import { PropertySearchStatusType } from '../enum/property-search-status-type.enum';

export class PropertySearchResult {
  // address: Address;
  // apn?: string;
  // mlsNumber?: string;
  // lotSize?: number;
  // squareFeet: string;
  // yearBuilt: number;
  // bedroomCount: number;
  // bathroomCount: number;
  // style: string;
  // type: string;

  // propertyFields?: Map<string, string>;

  status: PropertySearchStatusType;
  propertyGuid: string;
  propertyResults: PropertyResult[];
  propertyResultsFoundUsingLimitedDataSource: boolean;
}
