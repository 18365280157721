import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'date-validation-editor',
  template: `
    <input
      (input)="validateInput($event)"
      name="editedValueInput"
      value="{{ this.editedValue }}"
      [ngClass]="isValidDate ? 'valid-cell' : 'invalid-cell'"
    />
  `,
  styles: [
    `
      .valid-cell {
        padding: none;
        box-shadow: none;
      }

      input:focus {
        outline: none !important;
      }

      .invalid-cell {
        padding: none;
        box-shadow: none;
        color: red;
      }
    `,
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DateValidationEditor implements ICellEditorAngularComp {
  editedValue = '';
  isValidDate = false;

  private params: any;

  getValue() {
    return this.isValidDate ? this.editedValue : null;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    //
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.editedValue = this.params.value;
  }

  validateInput(event) {
    this.editedValue = event.target.value;

    if (
      Date.parse(this.editedValue).toString() !== 'Invalid Date' &&
      !isNaN(Date.parse(this.editedValue))
    )
      this.isValidDate = true;
    else this.isValidDate = false;
  }
}
