export class ApnSearchStateModel {
    apnNumber: string;
    state: string;
    county: string;
    
    constructor() {
        this.apnNumber = '';
        this.state = '';
        this.county = '';
    }
}