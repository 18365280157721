import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
// Model
import { Comparable, PropertyUsageType } from '@datamaster/core';
// Service
import { DataMasterFileService } from '@datamaster/core';

@Component({
  selector: 'app-summary-comparable-export',
  templateUrl: './summary-comparable-export.component.html',
  styleUrls: ['./summary-comparable-export.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SummaryComparableExportComponent implements OnInit {

  @Input() parcel! : Comparable;
  //standardizedParcel : StandardizedParcel;
  mlsNumber!: string;
  addressLine1! : string;
  positionError: string;

  @Input() index! : number;
  exportPositionExists = false;
  @Output() positionChanged= new EventEmitter();

  constructor(private dataMasterFileService: DataMasterFileService) { }

  ngOnInit(): void {
    const standardizedParcel = this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(x=> x.propertyGuid == this.parcel.propertyGuid);
    this.mlsNumber = standardizedParcel.mlsNumber;
    this.addressLine1 = standardizedParcel.addressLine1;
    this.parcel.exportPropertyPlacementNumber = this.index;
    this.exportPositionExists = false;
  }

  exportPosition(newPlacementNumber: any) {
    if (!newPlacementNumber) return;
    this.parcel.exportPropertyPlacementNumber = +newPlacementNumber.value;
    this.positionChanged.emit(this.parcel);
  }

  get ExportPosition() : number{
    if (this.parcel.exportPropertyPlacementNumber) return this.parcel.propertyPlacementNumber;
    return this.parcel.exportPropertyPlacementNumber;
  }
  set ExportPosition(value : number) {
    if(this.ExportPosition == +value) return;
    this.parcel.exportPropertyPlacementNumber = +value;
    this.ExportPosition = +value;
  }

}
