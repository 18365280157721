import { DataMasterFileService } from '../../services/datamaster-file.service';
import { Parcel } from './parcel.model';

export class Subject extends Parcel {
  override propertyGuid!: string; //GUID
  subjectChanged!: boolean;
  attemptedToGetPlat!: boolean;
  subjectLoaded!: boolean;
  platMapImageURL!: string | ArrayBuffer | null;

  constructor() {
    super();
    this.formattedDataSources = [];
  }
}
