import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  DataSource,
  Deed,
  Parcel,
  DataMasterFileService,
  PropertySearchHelperService,
  DataMasterApiService,
  FormattingPropertyRequest,
  FormattingRequest
 } from '@datamaster/core';

@Component({
  selector: 'app-deeds-display',
  templateUrl: './deeds-display.component.html',
  styleUrls: ['./deeds-display.component.scss'],
})
export class DeedsDisplayComponent implements OnInit {
  private priorSalesDeeds: Deed[];

  deeds: Deed[];
  parcel: Parcel;

  hasDeeds = false;
  showCurrSale : boolean;
  deedDataSourceDisplayName: string;

  @Input() readonly: boolean;
  @Input() propertyGuid: string;
  @Input() prDataSources: DataSource[];
  @Output() loading: EventEmitter<Observable<any>> = new EventEmitter();

  constructor(
    private propertySearchHelperService: PropertySearchHelperService,
    private dataMasterService: DataMasterApiService,
    private dataMasterFileService: DataMasterFileService
  ) {}

  ngOnInit(): void {
    const prioritizedStandardizedParcel =
      this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(
        (x) => x.propertyGuid
      );

    if (!prioritizedStandardizedParcel) {
      return;
    }
    this.showCurrSale = !this.readonly && this.dataMasterFileService.dataMasterFile.appraisal.subject.propertyGuid != this.propertyGuid;
    this.deeds = prioritizedStandardizedParcel?.deeds?.sort((x, y) =>
      x.recordingDate > y.recordingDate ? -1 : 1
    );
    this.priorSalesDeeds = prioritizedStandardizedParcel?.priorSalesDeeds ?? [];

    this.hasDeeds = this.deeds && this.deeds.length > 0;
    this.deedDataSourceDisplayName = this.hasDeeds
      ? this.deeds[0].dataSourceCommonName
      : 'Public Records';
  }

  sortDeeds(sort: Sort | any) {
    this.deeds = this.deeds.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'docNum':
          return this.compare(a.documentNumber, b.documentNumber, isAsc);
        case 'soldDate':
          return this.compare(a.saleDate, b.saleDate, isAsc);
        case 'amount':
          return this.compare(a.salePrice, b.salePrice, isAsc);
        case 'type':
          return this.compare(a.documentType, b.documentType, isAsc);
        case 'mortgageType':
          return this.compare(a.mortgageType, b.mortgageType, isAsc);
        case 'recorded':
          return this.compare(a.recordingDate, b.recordingDate, isAsc);
        case 'grantorSeller':
          return this.compare(a.sellerName, b.sellerName, isAsc);
        case 'grantorBuyer':
          return this.compare(a.buyerName, b.buyerName, isAsc);
        case 'alternateNum':
          return this.compare(
            a.transferDocumentNumber,
            b.transferDocumentNumber,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onDeedPriorSaleChange(e, deed: Deed) {
    const isPriorSale = e.target.checked;

    //let prioritizedParcel = this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(a => a.propertyGuid == this._propertyGuid);
    //let saleVerificationDeed = prioritizedParcel?.deeds.find(x =>x && x.isCurrentSale);
    let saleVerificationDeed = this.deeds.find((x) => x && x.isCurrentSale);

    deed.isPriorSale = isPriorSale;

    if (isPriorSale) {
      if (deed.isCurrentSale) {
        saleVerificationDeed = null;
      }
      deed.isCurrentSale = false;
      this.priorSalesDeeds.push(deed);
    } else {
      this.priorSalesDeeds.splice(
        this.priorSalesDeeds.findIndex((a) => a == deed),
        1
      );
    }

    this.propertySearchHelperService.setDeedsInParcel(
      this.propertyGuid,
      this.priorSalesDeeds,
      saleVerificationDeed,
      deed,
      this.prDataSources
    );
    this.reformat();
  }
  onDeedCurrSaleChange(e, selectedSaleVerificationDeed: Deed){
    const isCurrentSale = e.target.checked;
    let saleVerificationDeed = selectedSaleVerificationDeed;
    if(isCurrentSale){
      selectedSaleVerificationDeed.isCurrentSale = true;
      if(selectedSaleVerificationDeed.isPriorSale){
        selectedSaleVerificationDeed.isPriorSale = false;
        this.priorSalesDeeds.splice(
          this.priorSalesDeeds.findIndex((a) => a == selectedSaleVerificationDeed),
          1
        );
      }
      const saleVerificationDeeds = this.deeds.filter((x) => x && x.isCurrentSale);
      saleVerificationDeeds.forEach((deed) =>{
        if(deed !== selectedSaleVerificationDeed)
          deed.isCurrentSale = false;
      });
    }else{
      saleVerificationDeed = null;
    }
    this.propertySearchHelperService.setDeedsInParcel(
      this.propertyGuid,
      this.priorSalesDeeds,
      saleVerificationDeed,
      selectedSaleVerificationDeed,
      this.prDataSources
    );
    this.reformat();
  }

  reformat() {
    const prioritizedParcels =
      this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.filter(
        (a) => a.propertyGuid == this.propertyGuid
      );

    const formattingRequest = new FormattingRequest();
    formattingRequest.formattingPropertyRequests = [];
    prioritizedParcels.forEach((prioritizedParcel) => {
      const formattingPropertyRequest = new FormattingPropertyRequest();
      formattingPropertyRequest.standardizedParcel = prioritizedParcel;
      formattingPropertyRequest.dataSourceFormattedFields =
        this.parcel.formattedDataSources;//is parcel initiated?
      formattingPropertyRequest.tagsToIgnore = null;
      formattingPropertyRequest.rawProperties =
        this.dataMasterFileService.dataMasterFile.appraisal.rawProperties.filter(
          (a) => a.propertyGuid == this.propertyGuid
        );
      formattingPropertyRequest.propertyUsageType = this.parcel.propertyUseType;//is parcel initiated?
      formattingRequest.formattingPropertyRequests.push(
        formattingPropertyRequest
      );
    });

    const obsv = this.dataMasterService.format(formattingRequest).pipe(
      tap((result) => {
        this.propertySearchHelperService.setFormattedResults(result);
      })
    );
    this.loading.emit(obsv);
  }
}
