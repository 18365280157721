import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Models
import { NewsFeed } from '../models/news-feed';
import { ConfigService } from '@datamaster/core';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  getNewsFeed(): Observable<NewsFeed[]> {
    //return of(NEWSFEED);  // Mock

    const url = this.configService.api.newsFeedUrl + '/all';
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const results = this.http.get<NewsFeed[]>(url, requestOptions);  // I don't think this will work - need to scrub the data
    return results;  // TODO:  Need to normalize the results
  }

}
