import { OperationResult } from '../operation-result.model';
import { PropertyUsageType } from '../../workfile/enum/property-usage-type.enum';
import { TranslatedPropertyResult } from './translated-property-result.model';
import { SearchCriteriaUserData } from '../property-search/search-criteria.model';

export class TranslationResult extends OperationResult {
  propertyUsageType: PropertyUsageType;
  translatedPropertyResults: TranslatedPropertyResult[];
}

export class TranslationResultPrioritizationRequest {
  translationResult: TranslationResult;

  userData: SearchCriteriaUserData;
  formCommonName: string;

  constructor(
    translationResult: TranslationResult,
    userData: SearchCriteriaUserData,
    formCommonName: string
  ) {
    this.translationResult = translationResult;
    this.userData = userData;
    this.formCommonName = formCommonName;
  }
}
