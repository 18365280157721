<div class="critical-notification-container">
    <ng-container *ngFor="let notification of criticalNotifications">
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <div innerHTML="{{notification.notificationMessage}}" class="message">
                {{notification.notificationMessage}}
            </div>

            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="deleteUserNotification(notification)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </ng-container>
</div>