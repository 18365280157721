import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TextInputDialogComponent } from '../components/dialog-text-input/text-input-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TextInputDialogService {

  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<TextInputDialogComponent>;

  public open(options) {
    this.dialogRef = this.dialog.open(TextInputDialogComponent, {
      panelClass: 'app-full-bleed-dialog', 
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        value: options.value
      },
      disableClose: true,

    });

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    });
  }
  public confirmed(): Observable<any> {

    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }
    ));
  }
}
