import { Observable, of } from 'rxjs';
import { ConfigFileModel } from './config-file.model';

export abstract class ConfigServiceModel {
    public get ids(): any {
        return this.config.oidc_configuration;
    }
    api;
    isLoadingFile = false;
    initialized = false;

    config: ConfigFileModel = null
    configLoader$: Observable<ConfigFileModel> = new Observable();

    /**
	 * Load configfile
	 *
	 * @returns Observable<ConfigFileModel>
	 */
    abstract loadConfigFile(): Observable<ConfigFileModel>;

    /**
	 * Load Versions file
	 *
	 * @returns Observable<ConfigFileModel>
	 */
    loadVersionsFile(): Observable<any> {
		return of([]);
	}

    /**
	 * Get ids config
	 *
	 * @returns Observable<ConfigFileModel>
	 */
    abstract getIdsConfig(): Observable<ConfigFileModel>;

    /**
	 * Get dependencies
	 *
	 * @returns Observable<any>
	 */
    getDependenciesVersions(enviroment: string): Observable<any> {
        return of([]);
    };

    /**
	 * Get release notes
	 *
	 * @returns Observable<any>
	 */
    getReleaseNotes(): Observable<any> {
        return of([]);
    }
}
