import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    Subject,
    DataMasterFileService,
    SearchCriteria, SearchType, Address,
    UserStateService, DataSourceService,
    PlatMapSearchService
 } from '@datamaster/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-platmap',
    templateUrl: './platmap.component.html',
    styleUrls: ['./platmap.component.scss']
})
export class PlatMapComponent implements OnInit, OnDestroy {

    subj!: Subject;
    isRetrievingPlat : boolean;
    error !: boolean;
    platMapURL!: any;
    dataSourceErrorMessage !: string | null;
    uploadDataErrorMessage !: string | null;
    localImagePath! : string | null;
    searchSubscription : Subscription;
    constructor(
        private dataMasterFileService: DataMasterFileService,
        private dataSourceService : DataSourceService,
        private userStateService : UserStateService,
        private platMapSearchService: PlatMapSearchService
    ) {
        this.isRetrievingPlat = false;
        this.dataSourceErrorMessage = null;
        this.uploadDataErrorMessage = null;
        this.searchSubscription = this.platMapSearchService.getSearchResultEmitter().subscribe(result => this.processSearchResult(result));

    }

    ngOnInit() {
        this.subj = this.dataMasterFileService.dataMasterFile.appraisal.subject;

        //Initialize platMapURL if we have it
        this.platMapURL = null;
        if(this.subj.platMapImageURL)
            this.platMapURL = this.subj.platMapImageURL;
    }

    ngOnDestroy(){
        this.searchSubscription.unsubscribe();
    }

    preview(files:any) {
        this.uploadDataErrorMessage = null;
        if (files.length === 0)
          return;

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          this.uploadDataErrorMessage = 'Only images are supported.';
          return;
        }

        const reader = new FileReader();
        this.localImagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          this.platMapURL = reader.result;
          this.setPlatOnSubject()
        }
      }

    getPlatFromPublicRecords(): void {
        this.dataSourceErrorMessage = null;
        this.isRetrievingPlat = true;
        const newSearchCriteria: SearchCriteria = new SearchCriteria;
        newSearchCriteria.userData = this.userStateService.userDataForSearch;
        newSearchCriteria.fileNumber = this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.fileNumber;
        newSearchCriteria.fileId = this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.fileId ?? '';

        newSearchCriteria.dataSourceIds = this.dataSourceService.getUserPlatMapDatSourceIds();

        if(newSearchCriteria.dataSourceIds.length < 1){
            this.isRetrievingPlat = false;
            this.dataSourceErrorMessage = 'No Plat Map Data Sources found in your Data Sources'
            return;
        }
        const translatedStandardizedParcel = this.dataMasterFileService.getTranslatedStandardizedParcel(this.subj.propertyGuid);
        newSearchCriteria.searchType = SearchType.PlatMap;
        newSearchCriteria.apnNumbers = translatedStandardizedParcel.apn;
        newSearchCriteria.address = new Address;

        newSearchCriteria.address.state = translatedStandardizedParcel.state;
        newSearchCriteria.address.county = translatedStandardizedParcel.county;
        try {
            this.platMapSearchService.search(newSearchCriteria);
        } catch (error) {
            this.dataSourceErrorMessage = 'Error retrieving plat map from server. If problem persists please contact Data Master.';
            this.isRetrievingPlat = false;
        }
    }

    processSearchResult(platMapSearchResult : string | ArrayBuffer) {
        this.isRetrievingPlat = false;
        if(!platMapSearchResult) return;

        this.platMapURL = platMapSearchResult;
        this.setPlatOnSubject();
    }
    clearPlat(): void {
        this.isRetrievingPlat = false;
        this.platMapURL = null;
        this.localImagePath = null;
        this.setPlatOnSubject()
    }

    setPlatOnSubject(): void {
        this.subj.platMapImageURL = this.platMapURL;
    }
}
