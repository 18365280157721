import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { 
  MarketConditionsGraph,
  UserSettings,
  GraphsService,
  UserStateService} from '@datamaster/core';
import { McGraphGenerator } from '../../../graphs/mc-graph/mc-graph-generator';
@Component({
  selector: 'app-neighborhood-graphs',
  templateUrl: './neighborhood-graphs.component.html',
  styleUrls: ['./neighborhood-graphs.component.scss'],
})
export class NeighborhoodGraphComponent implements AfterViewInit {
  @Input() neighborhoodGraph: MarketConditionsGraph;
  @Input() userSettings: UserSettings;
  @Input() effectiveDate : Date;
  @Output() changeGraphEvent: EventEmitter<MarketConditionsGraph> = new EventEmitter();
  @ViewChild('canvasForChart') canvas: ElementRef<HTMLCanvasElement>;
  constructor(private _graphsService: GraphsService, private _userStateService: UserStateService, 
  ) {
  }

  ngAfterViewInit() {
    const mcGraphGenerator = new McGraphGenerator(this.neighborhoodGraph.graphDetails, this.userSettings,this.effectiveDate,true);
    mcGraphGenerator.generateChart(this.canvas.nativeElement.getContext('2d'));
  }

  changeGraph() {
    this.changeGraphEvent.emit(this.neighborhoodGraph);
  }
}
