import { GraphDataSeries, GraphDetails, PropertyUsageType, UserSettings } from '@datamaster/core';
import { ChartConfiguration } from 'chart.js';
import 'chartjs-adapter-date-fns';
import QuickChart from 'quickchart-js';

import Chart from 'chart.js/auto';

export class McGraphGenerator {
    private _calculatedMinValue = -1;
    private _yValuesForMinimumCalculation: number[] = [];
    private _xLabels: any[];
    private _chartConfiguration: ChartConfiguration;
    private _quickChart : QuickChart;

    constructor(private _graphDetails: GraphDetails, private _userSettings: UserSettings, private _effectiveDate : Date, private isThumbnail : boolean) {
        let isFirstBarSeries = true;
        let isFirstLineSeries = true;

        const userMarketConditionsGraphSetting = this._userSettings.userMarketConditionsMarketAnalysisSettings.userMarketConditionsGraphSettings.find(a => a.marketConditionGraphGroupType === this._graphDetails.marketConditionGraphGroupType);
        const startAtZeroSetting = userMarketConditionsGraphSetting.startAtZero;
        const dataSets = [];

        this._graphDetails.graphDataSeriesList.forEach((graphDataSeries) => {
            const isTrendLine = graphDataSeries.dataSeriesName.endsWith('Trend');
            let color;
            if (isTrendLine) {

                color = isFirstLineSeries ? userMarketConditionsGraphSetting.firstTrendLineColor : userMarketConditionsGraphSetting.secondTrendLineColor;
                isFirstLineSeries = false
            } else {
                color = isFirstBarSeries ? userMarketConditionsGraphSetting.firstDataSeriesColor : userMarketConditionsGraphSetting.secondDataSeriesColor;
                isFirstBarSeries = false;
            }

            dataSets.push(this.createDataSet(graphDataSeries, isTrendLine, color));
        });

        if (isThumbnail) {
            this._chartConfiguration = {
                type: 'bar',
                data: {
                    labels: this._xLabels,
                    datasets: dataSets,
                },
                options: {
                    responsive: true,
                    scales: {
                        x: {
                            type: 'time',
                            display: false,
                            time: {
                                unit: 'month',
                            },
                            bounds: 'ticks', //includes the first and last month in labels
                        },
                        y: {
                            min: this.getYAxisMinimumValue(startAtZeroSetting),
                            beginAtZero: startAtZeroSetting,
                            ticks: {
                                display: false,
                            }
                        },
                    },
                    plugins: {
                        title: {
                            text: this._graphDetails.graph.displayName,
                            align: 'center',
                            display: true,
                            position: 'top',
                            font:{
                                size: 11,
                            }
                        },
                        legend: {
                            display: false,
                        },
                    }
                }
            };
        } else {
            this._chartConfiguration = {
                type: 'bar',
                data: {
                    labels: this._xLabels,
                    datasets: dataSets,
                },
                options: {
                    responsive: true,
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'month',
                            },
                            bounds: 'ticks', //includes the first and last month in labels
                        },
                        y: {
                            min: this.getYAxisMinimumValue(startAtZeroSetting),
                            beginAtZero: startAtZeroSetting,
                        },
                    },
                    plugins: {
                        title: {
                            text: this.getTitle(this._graphDetails),
                            align: 'center',
                            display: true,
                            position: 'top',
                            font:{
                                size: 18,
                            }
                        },
                        legend: {
                            display: true,
                        },
                    }
                }
            };
        }
       
    }

    public generateChart(canvasContext: any) {
        return new Chart(canvasContext, this._chartConfiguration);
    }

    public async getDataUrlAsync() {
        const myChart = this.getQuickChart();
        return await myChart.toDataUrl();
    }

    public async getUrlAsync() {
        const myChart = this.getQuickChart();
        return await myChart.getUrl();
    }

    private getQuickChart(){
        if(this._quickChart) return this._quickChart;
        this._quickChart = new QuickChart();
        this._quickChart.setConfig(this._chartConfiguration).setBackgroundColor('white').setWidth(550).setHeight(300).setVersion('3');
        return this._quickChart;
    }

    private createDataSet(graphDataSeries: GraphDataSeries, isTrendLine: boolean, color: string) {

        const xValues = [];
        const yValues = [];

        if (graphDataSeries.dataPointList.some((a) => a.yValue > 0)) {
            graphDataSeries.dataPointList.forEach((graphDataPoint) => {
                if (graphDataPoint.yValue < 1 && !graphDataSeries.allowZeros) return;

                if (this._calculatedMinValue === -1) {
                    if (graphDataPoint.yValue > 0) {
                        this._calculatedMinValue = graphDataPoint.yValue;
                    }
                } else if (graphDataPoint.yValue < this._calculatedMinValue) {
                    if (graphDataPoint.yValue > 0) {
                        this._calculatedMinValue = graphDataPoint.yValue;
                    }
                }

                xValues.push(graphDataPoint.date);
                yValues.push(graphDataPoint.yValue);
                this._yValuesForMinimumCalculation.push(Math.floor(graphDataPoint.yValue));
            });
        } else {
            //add default graph
            const dates = graphDataSeries.dataPointList.map((a) => a.date);

            if (dates.length > 0) {

                const maxDate = dates?.reduce(function (a, b) {
                    return a > b ? a : b;
                });
                xValues.push(maxDate);
            }else{
                xValues.push(this._effectiveDate);
            }
            yValues.push(0);
            this._yValuesForMinimumCalculation.push(0);
        }

        this._xLabels = xValues;

        return {
            data: yValues,
            label: graphDataSeries.dataSeriesName,
            type: isTrendLine ? 'line' : 'bar',
            backgroundColor: color,
            borderColor: color,
            hoverBackgroundColor: color, //cancels out the hover to match Pro
            fill: false,
            //pointBackgroundColor : color, //only for the trend line data point
            //pointBorderColor: color,//only for the trend line data point
            pointRadius: 0,//only for the trend line data point; 0 radiues does not draw the data point
            order: isTrendLine ? 1 : 2 // z-index
        };
    }

    private getYAxisMinimumValue(startAtZero: boolean) {
        if (startAtZero) {
            return 0;
        }

        let average = 0
        this._yValuesForMinimumCalculation.forEach(a => average += a);
        average = average / this._yValuesForMinimumCalculation.length;

        let minValue: number;
        let roundingNumber: number;

        if (average < 1000) {
            roundingNumber = 5;
        } else if (average < 50000) {
            roundingNumber = 100;
        } else {
            roundingNumber = 1000;
        }

        minValue = roundingNumber * Math.floor(this._calculatedMinValue / roundingNumber)

        if (minValue >= this._calculatedMinValue) {
            minValue = roundingNumber * Math.floor((this._calculatedMinValue - Math.ceil(this._calculatedMinValue * 0.05)) / roundingNumber);
        }

        return minValue >= 0 ? minValue : 0;
    }

    private getTitle(graphDetails: GraphDetails) {
        return `${graphDetails.reportUsageType == PropertyUsageType.MarketConditions
            ? 'Competing Market'
            : 'Neighborhood'
            } - ${graphDetails.graph.displayName}`;
    }
}