<div class="subject-container">
  <form class="subject-form">

    <!--<div class="well well-sm map-canvas-container">
    <div id="google-mapCanvas" class="map-canvas"></div>
    <div id="google-streetMapCanvas" class="street-map-canvas"></div>
  </div>-->

    <div class="well subject-detail">

      <span class="vert-label">
        <span class="rotated-text">Subject</span>
      </span>
      <!-- <div class="form-group">
        <label>Subject Address</label>
        <input type="text" placeholder="Address" class="text-wide" />
      </div>
      <div class="form-group">
        <input type="text" placeholder="City" class="text-medium" />
        <input type="text" placeholder="Zip Code" class="text-small" maxlength="10" pattern="^\d{5}(?:[-\s]\d{4})?$" />
      </div> -->

      <div class="row">
        <div class="col-md-5">
          <label>Property Address</label>
          <input type="text" placeholder="" class="form-control" [value]="propertyAddress" />
        </div>
        <div class="col-md-3">
          <label>City</label>
          <input type="text" placeholder="" class="form-control" [value]="city" />
        </div>
        <div class="col-md-1">
          <label>State</label>
          <input type="text" placeholder="" class="form-control" [value]="state" />
        </div>
        <div class="col-md-3">
          <label>Zip</label>
          <input type="text" placeholder="" class="form-control" maxlength="10" pattern="^\d{5}(?:[-\s]\d{4})?$"
            [value]="zip" />
        </div>
      </div>

      <div class="row row-top-spacer">
        <div class="col-md-4">
          <label>Borrower</label>
          <input type="text" placeholder="" class="form-control" [value]="borrower" />
        </div>
        <div class="col-md-4">
          <label>Owner of Public Record</label>
          <input type="text" placeholder="" class="form-control" [value]="ownerOfPublicRecord" />
        </div>
        <div class="col-md-4">
          <label>County</label>

          <input type="text" placeholder="" class="form-control" [value]="county" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label>Legal Description</label>
          <input type="text" placeholder="" class="form-control" [value]="legalDescription" />
        </div>
      </div>


      <div class="row">
        <div class="col-md-5">
          <!--@Helpers.ParcelHelpers.DataSourcePopoverLabel("Assessor's Pracel #", "Apn", subject, "subject.Apn")-->
          <label>Assessors Parcel</label>
          <input type="text" placeholder="" class="form-control" [value]="apn" />
        </div>
        <div class="col-md-3">
          <label>Tax Year</label>
          <input type="text" placeholder="" class="form-control" [value]="taxYear" />
        </div>
        <div class="col-md-4">
          <label>R.E. Taxes</label>
          <div class="input-group">
            <span class="input-group-addon">$</span>
            <input type="text" placeholder="" class="form-control" [value]="reTaxes" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <!--@Helpers.ParcelHelpers.DataSourcePopoverLabel("Assessor's Pracel #", "Apn", subject, "subject.Apn")-->
          <label>Neighborhood Name</label>
          <input type="text" placeholder="" class="form-control" [value]="neighborhoodName" />
        </div>
        <div class="col-md-3">
          <label>Map Reference</label>
          <input type="text" placeholder="" class="form-control" [value]="mapReference" />
        </div>
        <div class="col-md-4">
          <label>Census Tract</label>
          <div class="input-group">
            <input type="text" placeholder="" class="form-control" [value]="censusTract" />
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-2">
          <label>Occupant</label>
          <!-- <select class="form-control">
            <option value="owner">Owner</option>
            <option value="tenant">Tenant</option>
            <option value="vacant">Vacant</option>
          </select> -->
          <div class="radio">
            <label>
              <input type="radio" class="radio" name="occupant" value="Owner" [checked]="occupant_Owner">
              Owner
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" class="radio" name="occupant" value="Tenant" [checked]="occupant_Tenant">
              Tenant
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" class="radio" name="occupant" value="Vacant" [checked]="occupant_Vacant">
              Vacant
            </label>
          </div>
        </div>
        <div class="col-md-4">
          <label>Special Assessments</label>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-9 no-right-padding">
                <div class="input-group">
                  <span class="input-group-addon">$</span>
                  <input type="text" placeholder="" class="form-control" [value]="specialAssessments" />
                </div>
              </div>
              <div class="col-md-3 no-right-padding">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="pud_Y">
                    PUD
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label>HOA</label>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-7 no-right-padding">
                <div class="input-group">
                  <span class="input-group-addon">$</span>
                  <input type="text" placeholder="" class="form-control" [value]="hoaAmount" />
                </div>
              </div>
              <div class="col-md-5 no-right-padding">
                <div class="radio">
                  <label>
                    <input type="radio" class="radio" name="hoa-payment-schedule" value="year"
                      [checked]="hoaPeriod_PerYear">
                    per year
                  </label>
                </div>
                <div class="radio">
                  <label>
                    <input type="radio" class="radio" name="hoa-payment-schedule" value="month"
                      [checked]="hoaPeriod_PerMonth">
                    per month
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label>Property Rights Appraised</label>
          <div class="container-fluid">
            <div class="row min-text-height">
              <div class="col-md-4">
                <div class="radio">
                  <label class="no-left-padding">
                    <input type="radio" name="PropertyRights" value="feesimple" class="radio" iradio
                      [checked]="propertyRightsAppraised_FeeSimple">
                    Fee Simple
                  </label>
                </div>
                <div class="radio">
                  <label>
                    <input type="radio" name="PropertyRights" value="leasehold" class="radio" iradio
                      [checked]="propertyRightsAppraised_Leasehold">
                    Leasehold
                  </label>
                </div>
                <div class="radio">
                  <label>
                    <input type="radio" name="PropertyRights" value="other" class="radio" iradio
                      [checked]="propertyRightsAppraised_Other">
                    Other (describe)
                  </label>
                </div>
              </div>
              <div class="col-md-8 no-right-padding">
                <input type="text" class="form-control" [value]="propertyRightsOtherDescription" />
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label>Assignment Type</label>
          <div class="container-fluid">
            <div class="row min-text-height">
              <div class="col-md-4">
                <div class="radio">
                  <label class="no-left-padding">
                    <input type="radio" name="AssignmentType" value="purchase" class="radio" iradio
                      [checked]="assignment_Purchase">
                    Purchase Transaction
                  </label>
                </div>
                <div class="radio">
                  <label>
                    <input type="radio" name="AssignmentType" value="refinance" class="radio" iradio
                      [checked]="assignment_Refinance">
                    Refinance Transaction
                  </label>
                </div>
                <div class="radio">
                  <label>
                    <input type="radio" name="AssignmentType" value="other" class="radio" iradio
                      [checked]="assignment_Other">
                    Other (describe)
                  </label>
                </div>
              </div>
              <div class="col-md-8 no-right-padding">
                <input type="text" class="form-control" [value]="assignmentOtherDescription" />
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <label>Lender/Client</label>
          <input type="text" class="form-control" [value]="lenderClient" />
        </div>
        <div class="col-md-8">
          <label>Address</label>
          <input type="text" class="form-control" [value]="lenderClientAddress" />
        </div>
      </div>

      <div class="row row-inline-label">
        <div class="col-md-12">
          <label class="inline-label vert-spacing">
            Is the subject property currently offered for sale or has it been offered for sale in the twelve months
            prior to the effective date of this appraisal?
          </label>
          <div class="radio">
            <label>
              <input type="radio" name="SubjectForSale" value="yes" class="radio" [checked]="subjectOfferForSale_Y">
              Yes
            </label>
          </div>
          <div class="radio">
            <label class="no-left-padding">
              <input type="radio" name="SubjectForSale" value="no" class="radio" [checked]="subjectOfferForSale_N">
              No
            </label>
          </div>
        </div>
      </div>

      <div class="row row-bottom-spacer">
        <div class="col-md-12">
          <label>
            Report data source(s) used, offering price(s), and date(s)
          </label>
          <textarea rows="2" class="form-control" [value]="reportedDataSources"
            name="reportedDataSources"></textarea>
        </div>
      </div>

    </div>

    <div class="well subject-detail">

      <span class="vert-label">
        <span class="rotated-text">Contract</span>
      </span>

      <div class="row">
        <div class="col-md-12">
          <label class="inline-label">
            I
          </label>
          <div class="radio inline-radio">
            <label class="no-left-padding">
              <input type="radio" name="AnalyzePurchaseTransaction" value="did" class="radio"
                [checked]="analyzedContract_Y">
              did
            </label>
          </div>
          <div class="radio inline-radio">
            <label class="no-left-padding">
              <input type="radio" name="AnalyzePurchaseTransaction" value="didnot" class="radio"
                [checked]="analyzedContract_N">
              did not
            </label>
          </div>
          <label class="full-inline">
            analyze the contract for sale for the subject purchase transaction. Explain the results
            of the analysis of the contract for sale or why the analysis was not performed.
          </label>
          <textarea rows="2" class="form-control" name="analyzedContractExplanation"
            [value]="analyzedContractExplanation"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
          <label>Contract Price</label>
          <div class="input-group">
            <span class="input-group-addon">$</span>
            <input type="text" placeholder="" class="form-control" [value]="contractPrice" />
          </div>
        </div>
        <div class="col-md-2">
          <label>Contract Date</label>
          <div class="input-group">
            <input id="ContractDate" type="text" placeholder="" class="date-picker form-control"
              date-format="MM/dd/yyyy" [value]="contractDate" />
            <span class="input-group-addon"><span class="fa fa-calendar"></span></span>
          </div>
        </div>
        <div class="col-md-5 middle-align-label">
          <label class="inline-label">
            Is the property seller the owner of the public record?
          </label>
          <div class="radio">
            <label>
              <input type="radio" name="SellerOwnerOfPublicRecord" value="yes" class="radio"
                [checked]="sellerOwnerOfPublicRecord_Y">
              Yes
            </label>
          </div>
          <div class="radio">
            <label class="no-left-padding">
              <input type="radio" name="SellerOwnerOfPublicRecord " value="no" class="radio"
                [checked]="sellerOwnerOfPublicRecord_N">
              No
            </label>
          </div>
        </div>
        <div class="col-md-3">
          <label>Data Source(s)</label>
          <input type="text" placeholder="" class="form-control" [value]="sellerOwnerOfPublicRecordDataSource" />
        </div>
      </div>

      <div class="row row-inline-label">
        <div class="col-md-12">
          <label class="inline-label vert-spacing">
            Is there any financial assistance (loan charges, sales concessions, gift or downpayment assistance, etc.) to
            be paid by any party on
            behalf of the borrower?
          </label>
          <div class="radio">
            <input type="radio" name="FinancialAssistanceToBorrower" value="yes" class="radio grow-container-fix"
              [checked]="financialAssistance_Y" />
            <label>
              Yes
            </label>
          </div>
          <div class="radio">
            <input type="radio" name="FinancialAssistanceToBorrower" value="no" class="radio grow-container-fix"
              [checked]="financialAssistance_N" />
            <label class="no-left-padding">
              No
            </label>
          </div>
          <div class="">
            <label>If Yes, report the total dollar amount and describe the terms to paid.</label>
            <textarea rows="2" class="form-control" [value]="financialAssistanceDescription"
              name="financialAssistanceDescription"></textarea>
          </div>

        </div>
      </div>

    </div>

    <div class="well well-sm subject-detail well-header-label">
      <span class="vert-label">
        <span class="rotated-text">Neighborhood</span>
      </span>

      <div class="row row-header-label">
        <div class="col-md-12">
          <label>Note: Race and the racial composition of the neighborhood are not appraisal factors.</label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h6>Neighborhood Characteristics</h6>
          <div class="container-fluid inline-radio-grid">
            <div class="row">

              <label>Location</label>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="neighborhoodLocation" value="Urban"
                    [checked]="neighborhoodLocation_Urban">
                  Urban
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="neighborhoodLocation" value="Suburban"
                    [checked]="neighborhoodLocation_Suburban">
                  Suburban
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="neighborhoodLocation" value="Rural"
                    [checked]="neighborhoodLocation_Rural">
                  Rural
                </label>
              </div>
            </div>

            <div class="row">

              <label>Built-Up</label>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="neighborhoodBuiltUp" value="Over75Percent"
                    [checked]="neighborhoodBuiltUp_PercentOver75">
                  Over 75%
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="neighborhoodBuiltUp" value="25to75Percent"
                    [checked]="neighborhoodBuiltUp_Percent25To75">
                  25-75%
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="neighborhoodBuiltUp" value="Under25Percent"
                    [checked]="neighborhoodBuiltUp_PercentUnder25">
                  Under 25%
                </label>
              </div>
            </div>

            <div class="row">

              <label>Growth</label>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="neighborhoodGrowth" value="Rapid"
                    [checked]="neighborhoodGrowth_Rapid">
                  Rapid
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="neighborhoodGrowth" value="Stable"
                    [checked]="neighborhoodGrowth_Stable">
                  Stable
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="neighborhoodGrowth" value="Slow"
                    [checked]="neighborhoodGrowth_Slow">
                  Slow
                </label>
              </div>
            </div>

          </div>
        </div>

        <div class="col-md-5">
          <h6>One-Unit Housing Trends</h6>
          <div class="container-fluid inline-radio-grid">
            <div class="row">

              <label>Property Values</label>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="propertyValues" value="Increasing"
                    [checked]="propertyValues_Increasing">
                  Increasing
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="propertyValues" value="Suburban"
                    [checked]="propertyValues_Stable">
                  Stable
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="propertyValues" value="Declining"
                    [checked]="propertyValues_Declining">
                  Declining
                </label>
              </div>
            </div>

            <div class="row">

              <label>Demand/Supply</label>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="demandSupply" value="Shortage"
                    [checked]="demandSupply_Shortage">
                  Shortage
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="demandSupply" value="InBalance"
                    [checked]="demandSupply_InBalance">
                  In Balance
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="demandSupply" value="OverSupply"
                    [checked]="demandSupply_OverSupply">
                  Over Supply
                </label>
              </div>
            </div>

            <div class="row">
              <label>Marketing Time</label>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="marketingTime" value="MonthUnder3"
                    [checked]="marketingTime_MonthUnder3">
                  Under 3 mths
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="marketingTime" value="Month3To6"
                    [checked]="marketingTime_Month3To6">
                  3-6 mths
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" class="radio" name="marketingTime" value="MonthOver6"
                    [checked]="marketingTime_MonthOver6">
                  Over 6 mths
                </label>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-3">
          <h6 class="with-sub-title">One-Unit Housing</h6>
          <div class="container-fluid one-unit">
            <div class="row sub-title">
              <div class="col-md-5">
                <label>Price<span class="measure">($000)</span></label>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-5">
                <label>Age<span class="measure">(yrs)</span></label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <input type="text" class="form-control" name="oneUnitLowPrice" [value]="oneUnitLowPrice" />
              </div>
              <div class="col-md-2">
                <label>Low</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" name="oneUnitLowAge" [value]="oneUnitLowAge" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <input type="text" class="form-control" name="oneUnitHighPrice" [value]="oneUnitHighPrice" />
              </div>
              <div class="col-md-2">
                <label>High</label>
              </div>
                <div class="col-md-5">
                  <input type="text" class="form-control" name="oneUnitHighAge" [value]="oneUnitHighAge" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <input type="text" class="form-control" name="oneUnitPredPrice" [value]="oneUnitPredPrice" />
              </div>
              <div class="col-md-2">
                <label>Pred.</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" name="oneUnitPredAge" [value]="oneUnitPredAge" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-9">
          <div class="row boundary-row">
            <div class="col-md-12">
              <label>Neighborhood Boundaries</label>
              <textarea cols="2" class="form-control" name="neighborhoodBoundaries"
                [value]="neighborhoodBoundaries"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Neighborhood Description</label>
              <textarea cols="2" class="form-control" name="neighborhoodDescription"
                [value]="neighborhoodDescription"></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <h6 class="present-land">Present Land Use %</h6>
          <div class="container-fluid present-land">
            <div class="row">
              <div class="col-md-6">
                <label>One Unit</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" name="oneUnitPercentage" [value]="oneUnitPercentage" />
              </div>
              <div class="col-md-1">
                <label>%</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>2-4 Unit</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" name="twoToFourUnitPercentage"
                  [value]="twoToFourUnitPercentage" />
              </div>
              <div class="col-md-1">
                <label>%</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Multi-Family</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" name="multiFamilyPercentage" [value]="multiFamilyPercentage" />
              </div>
              <div class="col-md-1">
                <label>%</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Commercial</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" name="commercialPercentage" [value]="commercialPercentage" />
              </div>
              <div class="col-md-1">
                <label>%</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Other</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" name="otherVacantPercentage" [value]="otherVacantPercentage" />
              </div>
              <div class="col-md-1">
                <label>%</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label>Market Conditions</label>
          <textarea cols="2" class="form-control" name="marketConditions" [value]="marketConditions"></textarea>
        </div>
      </div>

    </div>

    <div class="well well-sm subject-detail">
      <span class="vert-label">
        <span class="rotated-text">Site</span>
      </span>

      <div class="row">
        <div class="col-md-4">
          <label>Dimensions</label>
          <input type="text" placeholder="" class="form-control" [value]="lotDimensions" />
        </div>
        <div class="col-md-3">
          <label>Area</label>
          <input type="text" placeholder="" class="form-control" [value]="siteArea" />
        </div>
        <div class="col-md-3">
          <label>Shape</label>
          <input type="text" placeholder="" class="form-control" [value]="lotShape" />
        </div>
        <div class="col-md-2">
          <label>View</label>
          <input type="text" placeholder="" class="form-control" [value]="view" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <label>Specific Zoning Classification</label>
          <input type="text" placeholder="" class="form-control" [value]="zoningClassification" />
        </div>
        <div class="col-md-7">
          <label>Zoning Description</label>
          <input type="text" placeholder="" class="form-control" [value]="zoningDescription" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">

          <label>Zoning Compliance</label>
          <div class="row min-text-height">
            <div class="col-md-7 no-right-padding">
              <div class="radio">
                <label class="no-left-padding">
                  <input type="radio" name="ZoningCompliance" value="Legal" class="radio"
                    [checked]="zoningCompliance_Legal" />
                  Legal
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" name="ZoningCompliance" value="legalnonconforming" class="radio"
                    [checked]="zoningCompliance_LegalNoncomforming" />
                  Legal Nonconforming (Grandfathered Use)
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" name="ZoningCompliance" value="nozoning" class="radio"
                    [checked]="zoningCompliance_Illegal" />
                  No Zoning
                </label>
              </div>
              <div class="radio">
                <label>
                  <input type="radio" name="ZoningCompliance" value="illegal" class="radio"
                    [checked]="zoningCompliance_NoZoning" />
                  Illegal (describe)
                </label>
              </div>
            </div>
            <div class="col-md-5 no-right-padding">
              <input type="text" class="form-control" placeholder=""
                [value]="zoningComplianceIllegalDescription" />
            </div>
          </div>

        </div>
      </div>

      <div class="row row-inline-label">
        <div class="col-md-12">
          <label class="inline-label vert-spacing">
            Is the highest and best use of subject property as improved (or as proposed per plans and specifications)
            the present use?
          </label>
          <div class="radio">
            <label>
              <input type="radio" name="BestUse" value="yes" class="radio" [checked]="highestBestUseImproved_Y" />
              Yes
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="BestUse" value="no" class="radio" [checked]="highestBestUseImproved_N" />
              No
            </label>
          </div>
          <label class="inline-label vert-spacing">If No, describe</label>
          <input type="text" class="form-control" placeholder="" [value]="highestBestUseImprovedDescription" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pivot-radio-grid">
          <div class="row no-col-padding header-row">
            <div class="col-md-3">
              <label>Utilities</label>
            </div>
            <div class="col-md-2">
              <label class="sub-header">Public</label>
            </div>
            <div class="col-md-2">
              <label class="sub-header">Other</label>
            </div>
            <div class="col-md-5">
              <label class="sub-header">Other (describe)</label>
            </div>
          </div>
          <div class="row no-col-padding">
            <div class="col-md-3">
              <label>Electricity</label>
            </div>
            <div class="col-md-2">
              <!-- <div class="checkbox"> -->
              <input type="checkbox" class="checkbox" [checked]="electricityPublic">
              <!-- </div> -->
            </div>
            <div class="col-md-2">
              <!-- <div class="checkbox"> -->
              <input type="checkbox" class="checkbox" [checked]="electricityOther">
              <!-- </div> -->
            </div>
            <div class="col-md-5">
              <input type="text" class="form-control" placeholder="" [value]="electricityDescription" />
            </div>

          </div>
          <div class="row no-col-padding">
            <div class="col-md-3">
              <label>Gas</label>
            </div>
            <div class="col-md-2">
              <!-- <div class="checkbox"> -->
              <input type="checkbox" class="checkbox" [checked]="gasPublic">
              <!-- </div> -->
            </div>
            <div class="col-md-2">
              <!-- <div class="checkbox"> -->
              <input type="checkbox" class="checkbox" [checked]="gasOther">
              <!-- </div> -->
            </div>
            <div class="col-md-5">
              <input type="text" class="form-control" placeholder="" [value]="gasDescription" />
            </div>

          </div>
        </div>
        <div class="col-md-4 pivot-radio-grid">
          <div class="row no-col-padding header-row">
            <div class="col-md-3"></div>
            <div class="col-md-2">
              <label class="sub-header">Public</label>
            </div>
            <div class="col-md-7">
              <label class="sub-header">Other (describe)</label>
            </div>
          </div>
          <div class="row no-col-padding">
            <div class="col-md-3">
              <label>Water</label>
            </div>
            <div class="col-md-2">
              <!-- <div class="checkbox"> -->
              <input type="checkbox" class="checkbox" [checked]="waterPublic">
              <!-- </div> -->
            </div>
            <div class="col-md-2">
              <!-- <div class="checkbox"> -->
              <input type="checkbox" class="checkbox" [checked]="waterOther">
              <!-- </div> -->
            </div>
            <div class="col-md-5">
              <input type="text" class="form-control" placeholder="" [value]="waterDescription" />
            </div>

          </div>
          <div class="row no-col-padding">
            <div class="col-md-3">
              <label>Sanitary Sewer</label>
            </div>
            <div class="col-md-2">
              <!-- <div class="checkbox"> -->
              <input type="checkbox" class="checkbox" [checked]="sewerPublic">
              <!-- </div> -->
            </div>
            <div class="col-md-2">
              <!-- <div class="checkbox"> -->
              <input type="checkbox" class="checkbox" [checked]="sewerOther">
              <!-- </div> -->
            </div>
            <div class="col-md-5">
              <input type="text" class="form-control" placeholder="" [value]="sewerDescription" />
            </div>

          </div>
        </div>
        <div class="col-md-4 pivot-radio-grid">
          <div class="row no-col-padding header-row">
            <div class="col-md-8">
              <label>Off-site Improvements - Type</label>
            </div>
            <div class="col-md-2">
              <label class="sub-header align-center">Public</label>
            </div>
            <div class="col-md-2">
              <label class="sub-header align-center">Private</label>
            </div>
          </div>
          <div class="row no-col-padding">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-3">
                  <label>Street</label>
                </div>
                <div class="col-md-9">
                  <input type="text" class="form-control" [value]="streetOffSiteImprovements" />
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="radio no-top-margin align-center">
                <label class="no-left-padding">
                  <!--@Html.RadioButton("OffsiteImprovementsStreet", "public", false, new { @class = "radio" })-->
                  <input type="checkbox" class="checkbox" [checked]="streetPublic">
                </label>
              </div>
            </div>
            <div class="col-md-2">
              <div class="radio no-top-margin align-center">
                <label class="no-left-padding">
                  <!--@Html.RadioButton("OffsiteImprovementsStreet", "private", false, new { @class = "radio" })-->
                  <input type="checkbox" class="checkbox" [checked]="streetPrivate">
                </label>
              </div>
            </div>
          </div>
          <div class="row no-col-padding">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-3">
                  <label>Alley</label>
                </div>
                <div class="col-md-9">
                  <input type="text" class="form-control" [value]="alleyOffSiteImprovements" />
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="radio no-top-margin align-center">
                <label class="no-left-padding">
                  <input type="checkbox" class="checkbox" [checked]="alleyPublic">
                  <!--@Html.RadioButton("OffsiteImprovementsAlley", "public", false, new { @class = "radio" })-->
                </label>
              </div>
            </div>
            <div class="col-md-2">
              <div class="radio no-top-margin align-center">
                <label class="no-left-padding">
                  <input type="checkbox" class="checkbox" [checked]="alleyPrivate">
                  <!--@Html.RadioButton("OffsiteImprovementsAlley", "private", false, new { @class = "radio" })-->
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <label>FEMA Special Flood Hazard Area</label>
          <div class="radio">
            <label class="no-left-padding">
              <input type="radio" name="FemaFloodHazard" value="yes" class="radio" [checked]="femaFloodHazardArea_Y">
              Yes
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="FemaFloodHazard" value="no" class="radio" [checked]="femaFloodHazardArea_N">
              No
            </label>
          </div>
        </div>
        <div class="col-md-3">
          <label>FEMA Flood Zone</label>
          <input type="text" placeholder="" class="form-control" [value]="femaFloodZone" />
        </div>
        <div class="col-md-3">
          <label>FEMA Map #</label>
          <input type="text" placeholder="" class="form-control" [value]="femaMap" />
        </div>
        <div class="col-md-3">
          <label>FEMA Map Date</label>
          <div class="input-group">
            <input id="FemaMapDate" type="text" placeholder="" class="date-picker form-control" [value]="femaMapDate" />
            <span class="input-group-addon"><span class="fa fa-calendar"></span></span>
          </div>
        </div>
      </div>

      <div class="row row-inline-label no-bottom-margin">
        <div class="col-md-12">
          <div class="row">

            <div class="col-md-7 no-right-padding">
              <label class="inline-label vert-spacing">
                Are the utilities and off-site improvements typical for the market area?
              </label>
              <div class="radio">
                <label class="no-left-padding">
                  <input type="radio" name="TypicalUtilities" value="yes" class="radio"
                    [checked]="utilitiesImprovementsTypical_Y" />
                  Yes
                </label>
              </div>
              <div class="radio">
                <label class="no-left-padding">
                  <input type="radio" name="TypicalUtilities" value="no" class="radio"
                    [checked]="utilitiesImprovementsTypical_N" />
                  No
                </label>
              </div>
              <label class="inline-label vert-spacing">If No, describe</label>
            </div>
            <div class="col-md-5 no-right-padding">
              <input type="text" class="form-control" placeholder=""
                [value]="utilitiesImprovementsTypicalDescription" />
            </div>
          </div>
        </div>
      </div>

      <div class="row row-inline-label no-top-margin">
        <div class="col-md-12">
          <label class="inline-label vert-spacing">
            Are there any adverse site conditions or external factors (easements, encroachments, environmental
            conditions, land uses, etc.)?
          </label>
          <div class="radio">
            <label>
              <input type="radio" name="AdverseSiteConditions" value="yes" class="radio"
                [checked]="adverseConditions_Y" />
              Yes
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="AdverseSiteConditions" value="no" class="radio"
                [checked]="adverseConditions_N" />
              No
            </label>
          </div>
          <label class="inline-label vert-spacing">If Yes, describe</label>
          <textarea class="form-control" rows="2" placeholder=""
            name="adverseConditionsDescription" [value]="adverseConditionsDescription"></textarea>
        </div>
      </div>

    </div>

    <div class="well well-sm subject-detail">
      <span class="vert-label">
        <span class="rotated-text">Improvements</span>
      </span>

      <div class="row">
        <div class="col-md-4">
          <h6>General Description</h6>
          <div class="container-fluid improvement-block">
            <div class="row">
              <label>Units</label>
              <div>
                <label>
                  <input type="radio" class="radio" name="generalUnits" value="One" [checked]="generalUnits_One">
                  One
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" class="radio" name="generalUnits" value="OneWithAccessoryUnit"
                    [checked]="generalUnits_OneWithAccessoryUnit">
                  One with Accessory Unit
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <label># of Stories</label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control" [value]="numberOfStories" />
              </div>
            </div>
            <div class="row">
              <label>Type</label>
              <div>
                <label>
                  <input type="radio" class="radio" name="attachment" value="Detached" [checked]="attachment_Detached">
                  Det.
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" class="radio" name="attachment" value="Attached" [checked]="attachment_Attached">
                  Att.
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" class="radio" name="attachment" value="SemiDetachedEndUnit"
                    [checked]="attachment_SemiDetachedEndUnit">
                  S-Det./End Unit
                </label>
              </div>
            </div>

            <div class="row">
              <div>
                <label>
                  <input type="radio" class="radio" name="construction" value="Existing"
                    [checked]="construction_Exisiting">
                  Existing
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" class="radio" name="construction" value="Proposed"
                    [checked]="construction_Proposed">
                  Proposed
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" class="radio" name="construction" value="UnderConstruction"
                    [checked]="construction_UnderConstruction">
                  Under Const.
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <label>Design (Style)</label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control" [value]="style" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <label>Year Built</label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control" [value]="yearBuilt" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <label>Effective Age (Yrs)</label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control" [value]="effectiveAge" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h6>Foundation</h6>
          <div class="container-fluid improvement-block no-right-border">
            <div class="row">
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="concreteSlab" value="ConcreteSlab" class="checkbox"
                      [checked]="concreteSlab">
                    Concrete Slab
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="crawlSpace" value="CrawlSpace" class="checkbox" [checked]="crawlSpace">
                    Crawl Space
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="fullBasement" value="FullBasement" class="checkbox"
                      [checked]="fullBasement">
                    Full Basement
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="partialBasement" value="PartialBasement" class="checkbox"
                      [checked]="partialBasement">
                    Partial Basement
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <label>Basement Area</label>
              </div>
              <div class="col-md-5">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="" [value]="basementTotalSqFt" />
                </div>
              </div>
              <div class="col-md-2">
                <span class="input-group-addon">sq. ft.</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <label>Basement Finish</label>
              </div>
              <div class="col-md-5">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="" [value]="basementFinishedPercent" />
                </div>
              </div>
              <div class="col-md-2">
                <span class="input-group-addon">%</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="outsideEntryExit" value="OutsideEntryExit" class="checkbox"
                      [checked]="outsideEntryExit">
                    Outside Entry/Exit
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="sumpPump" value="SumpPump" class="checkbox" [checked]="sumpPump">
                    Sump Pump
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Evidence of</label>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="evidenceOfInfestation" value="Infestation" class="checkbox"
                      [checked]="evidenceOfInfestation">
                    Infestation
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="evidenceOfDampness" value="Dampness" class="checkbox"
                      [checked]="evidenceOfDampness">
                    Dampness
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="evidenceOfSettlement" value="Settlement" class="checkbox"
                      [checked]="evidenceOfSettlement">
                    Settlement
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <input type="text" class="form-control" placeholder="" [value]="evidenceOfDescription" />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h6>Exterior Description</h6>
          <div class="container-fluid improvement-block">
            <div class="row">
              <div class="col-md-6">
                <label>
                  Foundation Walls
                </label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="foundationWalls" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>
                  Exterior Walls
                </label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="exteriorWalls" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>
                  Roof Surface
                </label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="roofSurface" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>
                  Gutters & Downspout
                </label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="guttersAndDownspouts" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>
                  Window Type
                </label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="windowType" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>
                  Storm Sash/Insulated
                </label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="stormSashAndInsulated" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>
                  Screens
                </label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="screens" />
              </div>
            </div>
            <!--@BuildInlineLabelInput(new int[] { 5, 7 }, "Foundation", Html.TextBox("ExteriorFoundation", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Exterior Walls", Html.TextBox("ExteriorWalls", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Roof Surface", Html.TextBox("ExteriorRoof", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Gutters & Dwnspts", Html.TextBox("ExteriorGutters", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Window Type", Html.TextBox("ExteriorWindowType", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Storm Sash/Insulated", Html.TextBox("ExteriorStormSash", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Screens", Html.TextBox("ExteriorScreens", "", new { @class = "form-control", @placeholder = "materials/condition" }))-->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h6>Interior Description</h6>
          <div class="container-fluid improvement-block">
            <!--@BuildInlineLabelInput(new int[] { 5, 7 }, "Floors", Html.TextBox("InteriorFloors", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Walls", Html.TextBox("InteriorWalls", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Trim/Finish", Html.TextBox("InteriorTrim", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Bath Floor", Html.TextBox("InteriorBathFloor", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Bath Wainscot", Html.TextBox("InteriorBathWainscot", "", new { @class = "form-control", @placeholder = "materials/condition" }))
            @BuildInlineLabelInput(new int[] { 5, 7 }, "Doors", Html.TextBox("InteriorDoors", "", new { @class = "form-control", @placeholder = "materials/condition" }))-->
            <div class="row">
              <div class="col-md-6">
                <label>Floors</label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="floors" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Walls</label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="walls" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Trim/Finish</label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="trimAndFinish" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Bath Floor</label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="bathFloor" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Bath Wainscot</label>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control" placeholder="" [value]="bathWainscot" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h6>Heating/Cooling</h6>
          <div class="container-fluid improvement-block">
            <div class="row">
              <div class="col-md-12">
                <label class="sub-header">Heating</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="checkbox">
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" class="checkbox" [checked]="fWA">
                      FWA
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="hWBB">
                    HWBB
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="radiant">
                    Radiant
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="heatOther">
                    Other
                  </label>
                </div>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" placeholder="" [value]="heatOtherDescription" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label class="heating-fuel">Fuel</label>
              </div>
              <div class="col-md-10">
                <input type="text" class="form-control" placeholder="" [value]="fuelType" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="sub-header">Cooling</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="centralAirConditioning">
                    Central Air Conditioning
                  </label>
                </div>
              </div>
            </div>

              <div class="row">
                <div class="col-md-5">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="individualCooling">
                    Individual
                  </label>
                </div>

              </div>
              <div class="col-md-7">
                <input type="text" class="form-control" placeholder="" [value]="individualCoolingDescription" />

              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="coolingOther">
                    Other
                  </label>
                </div>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" placeholder="" [value]="coolingOtherDescription" />
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-4">
          <h6>Car Storage</h6>
          <div class="container-fluid improvement-block no-right-border">
            <div class="row">
              <div class="col-md-12">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="carStorageNone">
                    None
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="checkbox">
                  <label>
                    <!--@Html.CheckBox("ParkingDriveway", false, new { @class = "checkbox", @ng_model = "models.ParkingDriveway", @icheck = string.Empty })-->
                    <input type="checkbox" class="checkbox" [checked]="driveway">
                    Driveway
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <label class="car-count"># of cars</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" placeholder="" [value]="drivewaySpacesCount" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <label>Driveway Surface</label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control" placeholder="" [value]="drivewaySurface" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="garage">
                    Garage
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <label class="car-count"># of cars</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" placeholder="" [value]="garageCount" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="carport">
                    Carport
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <label class="car-count"># of cars</label>
              </div>
              <div class="col-md-5">
                <input type="text" class="form-control" placeholder="" [value]="carportCount" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="carStorageAttached">
                    Attached
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="carStorageDetached">
                    Detached
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="carStorageBuiltIn">
                    Built-In
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h6>Amenities</h6>
          <div class="container-fluid improvement-block">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <label>
                      <input type="checkbox" class="checkbox" [checked]="woodStoves">
                      Woodstove(s)#
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input type="text" class="form-control" placeholder="" [value]="numberOfWoodStoves" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">

                    <label>
                      <input type="checkbox" class="checkbox" [checked]="fireplaces">
                      Fireplace(s)#
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input type="text" class="form-control" placeholder="" [value]="numberOfFireplaces" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">

                    <label>
                      <input type="checkbox" class="checkbox" [checked]="fence">
                      Fence
                    </label>
                  </div>
                  <div class="col-md-6">

                    <input type="text" class="form-control" placeholder="" [value]="fenceDescription" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <label>
                      <input type="checkbox" class="checkbox" [checked]="patioDeck">
                      Pto/Deck
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input type="text" class="form-control" placeholder="" [value]="patioDeckDescription" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <label>
                      <input type="checkbox" class="checkbox" [checked]="porch">
                      Porch
                    </label>
                  </div>
                  <div class="col-md-6">

                    <input type="text" class="form-control" placeholder="" [value]="porchDescription" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">

                    <label>
                      <input type="checkbox" class="checkbox" [checked]="pool">
                      Pool
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input type="text" class="form-control" placeholder="" [value]="poolDescription" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">

                    <label>
                      <input type="checkbox" class="checkbox" [checked]="amenitiesOther">
                      Other
                    </label>
                  </div>

                  <div class="col-md-6">

                    <input type="text" class="form-control" placeholder="" [value]="amenitiesOtherDescription" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <h6>Attic</h6>
          <div class="container-fluid improvement-block">
            <div class="row">
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="atticNone">
                    None
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="atticStairs">
                    Stairs
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="atticDropStair">
                    Drop Stair
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="atticScuttle">
                    Scuttle
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="atticFloor">
                    Floor
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="atticHeated">
                    Heated
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" class="checkbox" [checked]="atticFinished">
                    Finished
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-3">
          <h6>Appliances</h6>
          <div class="container-fluid improvement-block no-right-border">
            <div class="row">
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    Refridgerator
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    Range/Oven
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    Disposal
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="checkbox">
                  <label>
                    Dishwasher
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    Fan/Hood
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    Microwave
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <div class="checkbox">
                  <label>
                    Other (describe)
                  </label>
                </div>
              </div>
              <div class="col-md-5">
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <div class="row">
        <div class="col-md-12">
          <div style="float: left; margin-right: 5px;">
            <label>
              <b>Appliances</b>
            </label>
          </div>
          <div style="float: left;">
            <label>
              <input type="checkbox" class="checkbox" [checked]="refrigerator">
              Refrigerator
            </label>
          </div>
          <div style="float: left; margin-right: 5px;">
            <label>
              <input type="checkbox" class="checkbox" [checked]="rangeOven">
              Range/Oven
            </label>
          </div>
          <div style="float: left; margin-right: 5px;">

            <label>
              <input type="checkbox" class="checkbox" [checked]="dishwasher">
              Dishwasher
            </label>
          </div>
          <div style="float: left; margin-right: 5px;">

            <label>
              <input type="checkbox" class="checkbox" [checked]="disposal">
              Disposal
            </label>
          </div>
          <div style="float: left; margin-right: 5px;">

            <label>
              <input type="checkbox" class="checkbox" [checked]="microwave">
              Microwave
            </label>
          </div>
          <div style="float: left; margin-right: 5px;">
            <label>
              <input type="checkbox" class="checkbox" [checked]="washerDryer">
              Washer/Dryer
            </label>
          </div>
          <div style="float: left; margin-right: 5px;">
            <label>
              <input type="checkbox" class="checkbox" [checked]="appliancesOther">
              Other(describe)
            </label>
          </div>
          <div style="float: left;">

            <input type="text" class="form-control" placeholder="" [value]="appliancesOtherDescription" />

          </div>

        </div>
      </div>

      <div class="row above-grade-row">
        <div class="col-md-3">
          <label class="sub-header">Finished area <b>above</b> grade contains</label>
        </div>
        <div class="col-md-1">
          <div class="input-group room-count">
            <input type="text" class="form-control" placeholder="" [value]="totalAboveGradeRooms" />
          </div>
        </div>
        <div class="col-md-1">
            <label>Rooms</label>
          </div>

        <div class="col-md-1">
          <div class="input-group room-count">
            <input type="text" class="form-control" placeholder="" [value]="totalAboveGradeBedrooms" />
          </div>
        </div>
        <div class="col-md-1">
          <label>Bedrooms</label>
        </div>

        <div class="col-md-1">
          <div class="input-group room-count">
            <input type="text" class="form-control" placeholder="" [value]="totalAboveGradeBathrooms" />
          </div>
        </div>
        <div class="col-md-1">
          <label>Bath(s)</label>
        </div>
        <div class="col-md-1">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="" [value]="aboveGradeGla" />

          </div>
        </div>
        <div class="col-md-2">
          <label>
            Square Feet of Gross Living Area Above Grade
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label>Additional features (energy efficient items, etc.)</label>
          <input type="text" class="form-control" placeholder="" [value]="additionalFeaturesEnergyEfficientItems" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label>Describe the condition of the property (needed repairs, deterioration, renovations, remodeling,
            etc.)</label>
          <input type="text" class="form-control" placeholder="" [value]="conditionDescription" />
        </div>
      </div>

      <div class="row row-inline-label no-top-margin">
        <div class="col-md-12">
          <label class="inline-label vert-spacing">
            Are there any adverse conditions or physical deficiencies that affect the livability, soundness, or
            structural integrigy of the property?
          </label>
          <div class="radio">
            <label>
              <input type="radio" name="AdversePropertyConditions" value="yes" class="radio"
                [checked]="physicalDeficienciesOrAdverseConditions_Y" />
              Yes
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="AdversePropertyConditions" value="no" class="radio"
                [checked]="physicalDeficienciesOrAdverseConditions_N" />
              No
            </label>
          </div>
          <label class="inline-label vert-spacing">If Yes, describe</label>
          <textarea class="form-control" rows="2" placeholder=""
            [value]="physicalDeficienciesOrAdverseConditionsDescription"></textarea>
        </div>
      </div>

      <div class="row row-inline-label no-top-margin">
        <div class="col-md-12">
          <label class="inline-label vert-spacing">
            Does the property generally conform to the neighborhood (functional utility, style, condition, use,
            construction, etc.)?
          </label>
          <div class="radio">
            <label>
              <input type="radio" name="PropertyFunctionalConform" value="yes" class="radio"
                [checked]="conformToNeighborhood_Y" />
              Yes
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="PropertyFunctionalConform" value="no" class="radio"
                [checked]="conformToNeighborhood_N" />
              No
            </label>
          </div>
          <label class="inline-label vert-spacing">If No, describe</label>
          <textarea class="form-control" rows="2" placeholder="" [value]="conformToNeighborhoodDescription"></textarea>
        </div>
      </div>

    </div>

  </form>
</div>