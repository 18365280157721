import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { SearchCriteria } from '../../models/operation/property-search/search-criteria.model';
import { DataMasterApiService } from './datamaster-api.service';

@Injectable({
  providedIn: 'root'
})
export class PlatMapSearchService {

  searchResult: EventEmitter<string | ArrayBuffer> = new EventEmitter();
  constructor(
    private dataMasterApiService: DataMasterApiService) { }

  getSearchResultEmitter() {
    return this.searchResult;
  }
  search(searchCriteria: SearchCriteria) {

    this.dataMasterApiService.platmapsearch(searchCriteria).subscribe((platMapResult) => {

      let platMapURL: string | ArrayBuffer = null;
      if (platMapResult.platRecords && platMapResult.platRecords.length > 0) {
        if (platMapResult.platRecords[0].fileUrl) {
          //if we want to support multiple results, we should loop through this and have a ui to support it
          platMapURL = platMapResult.platRecords[0].fileUrl;
        } else if (platMapResult.platRecords[0].fileContent) {
          platMapURL = platMapResult.platRecords[0].fileContent;
        }
      }

      this.searchResult.emit(platMapURL);
    });
  }
}
