<app-dialog-header title="Settings"></app-dialog-header>

<mat-dialog-content>

    <mat-drawer-container>
        <mat-drawer mode="side" opened [disableClose]="true">
            <ul class="nav flex-column">
                <li *ngIf="isInternal" class="nav-item">
                    <a class="nav-link active" href="#dataSourceSelectionTabContent" data-toggle="tab" data-cy="dataSourceSelectionTab">Data Source Selection</a>
                </li>
                <li *ngIf="!isPropertySearchApp" class="nav-item">
                    <a class="nav-link" href="#generalTabContent" data-toggle="tab" [ngClass]="{'active' :!isInternal }" data-cy="generalTab">General</a>
                </li>
                <li *ngIf="!isPropertySearchApp" class="nav-item">
                    <a class="nav-link" href="#marketAnalysisTabContent" data-toggle="tab" data-cy="marketAnalysisTab">Market Analysis</a>
                </li>
                <li *ngIf="!isPropertySearchApp" class="nav-item">
                    <a class="nav-link" href="#formattingTabContent" data-toggle="tab" data-cy="formattingTab">Formatting</a>
                </li>
                <li *ngIf="!isPropertySearchApp" class="nav-item">
                    <a class="nav-link" href="#comptrackerTabContent" data-toggle="tab" data-cy="comptrackerTab">CompTracker Settings</a>
                </li>
                <li *ngIf="!isPropertySearchApp" class="nav-item">
                    <a class="nav-link" href="#appraiserInformationTabContent" data-toggle="tab" data-cy="appraiserInformationTab">Your Appraiser
                        Information</a>
                </li>
            </ul>
        </mat-drawer>
        <mat-drawer-content>
            <div class="tab-content">
                <div *ngIf="isInternal" id="dataSourceSelectionTabContent" class="tab-pane active" >
                    <app-settings-data-source-selection #dataSourceSelectionSettings [userDataSources]="userDataSources"
                        [dataSources]="dataSources"></app-settings-data-source-selection>
                </div>
                <div  *ngIf="!isPropertySearchApp" id="generalTabContent" class="tab-pane"  [ngClass]="{'active' : !isInternal}">
                    <div class="settings">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input {{layout==='desktop' ? 'checked' : ''}}"
                                id="desktopLayoutSwitch" (change)="toggleLayout($event)"
                                [checked]="layout==='desktop' ? 'checked' : ''">
                            <label class="custom-control-label" for="desktopLayoutSwitch">Desktop home layout</label>
                        </div>

                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input {{theme==='default' ? 'checked' : ''}}"
                                id="newThemeSwitch" (change)="toggleTheme($event)"
                                [checked]="theme==='default' ? 'checked' : ''">
                            <label class="custom-control-label" for="newThemeSwitch">Use NEW DataMaster theme</label>
                        </div>

                    </div>

                    <app-settings-general #generalSettings [userSettings]="userSettings"></app-settings-general>
                </div>
                <div  *ngIf="!isPropertySearchApp" id="marketAnalysisTabContent" class="tab-pane">

                    <app-settings-market-analysis #marketAnalysisSettings *ngIf="userSettings" [userMarketConditionsMarketAnalysisSettings]="userSettings?.userMarketConditionsMarketAnalysisSettings"></app-settings-market-analysis>
                </div>
                <div id="connectAccountTabContent" class="tab-pane"  [ngClass]="{'active' :isPropertySearchApp && !isInternal}">
                    <app-settings-data-source-credentials #dataSourceCredentialsSettings
                        [dataSourceCredentials]="userDataSourceCredentials" [showInternalOverride]="isInternal">
                    </app-settings-data-source-credentials>
                </div>
                <div  *ngIf="!isPropertySearchApp" id="formattingTabContent" class="tab-pane">
                    <app-settings-formatting #formattingSettings [userSettings]="userSettings">
                    </app-settings-formatting>
                </div>
                <div  *ngIf="!isPropertySearchApp" id="comptrackerTabContent" class="tab-pane">
                    <app-settings-comptracker #comptrackerSettings [userSettings]="userSettings"></app-settings-comptracker>
                </div>
                <div *ngIf="!isPropertySearchApp"  id="appraiserInformationTabContent" class="tab-pane">
                    <app-settings-appraiser #appraiserSettings [userSettings]="userSettings"></app-settings-appraiser>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</mat-dialog-content>
<div mat-dialog-actions class="dialog-footer" align="end">
        <button *ngIf="!isPropertySearchApp" type="button" class="btn btn-secondary" (click)="resetSettingsToDefault()" data-cy="resetSettingsButton">
            <span class="fa fa-redo"></span>
            Reset all settings to default
        </button>

        <button type="submit" class="btn btn-primary" (click)="saveSettings()" data-cy="settingsSaveButton"><span class="fal fa-save"></span>
            Save and Close</button>
        <button type="button" class="btn btn-secondary" (click)="close()" data-cy="settingsCancelButton">Cancel</button>
</div>