import { SearchCriteriaUserData } from '../property-search/search-criteria.model';
import { ListingHistoryPropertyRequest } from './listing-history-property-request.model';

export class ListingHistorySearchRequest {
  dataSourceId: number;
  listingHistoryPropertyRequests: ListingHistoryPropertyRequest[];
}

export class ListingHistorySearchWebRequest {
  listingHistorySearchRequest: ListingHistorySearchRequest;
  userData: SearchCriteriaUserData;
}
