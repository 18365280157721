import { Component } from '@angular/core';
import { ConfigService } from '@datamaster/core';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: [
    './under-construction.component.scss'
  ],
})
export class UnderConstructionComponent {
  propertySearchAppUrl = `${this.configService.api.dmWebUrl}/propertysearch`;

  constructor(private configService: ConfigService) {}
}
