import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
// Models
import { Address } from '../../models/workfile/address.model';
import { SearchCriteriaUserData } from '../../models/operation/property-search/search-criteria.model';
import { PropertySearchResult } from '../../models/operation/property-search/property-search-result.model';
import { SearchResult } from '../../models/operation/property-search/search-result.model';
// Service
import { ConfigService } from '../../dependency-issues';

@Injectable({
  providedIn: 'root',
})
export class AddressSearchService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  searchShallow(
    address: Address,
    dataSource: string,
    userData: SearchCriteriaUserData
  ): Observable<PropertySearchResult[]> {
    //return of(PROPERTYSEARCHRESULTS)    // Mock
    //  .pipe(delay(500));               // Pipe delay makes it mimic search/connection time

    const url =
      this.configService.api.dataMasterApi +
      `datasources/${dataSource == null ? 'CoreLogic' : dataSource
      }/addressSearchShallow` +
      '?address=' +
      address.addressLine1 +
      '&city=' +
      address.city +
      '&state=' +
      address.state +
      '&zip=' +
      address.zip +
      '&county=' +
      address.county;
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const results = this.http.post<PropertySearchResult[]>(
      url,
      userData,
      requestOptions
    );
    return results;

    // TODO:  Add error handling and max search result window height
  }
  search(
    address: Address,
    propertyUseType: string,
    dataSource: string,
    userData: SearchCriteriaUserData
  ): Observable<SearchResult> {
    const url = `${this.configService.api.dataMasterApi}datasources/${dataSource == null ? 'CoreLogic' : dataSource
      }/addressSearch/${propertyUseType}?address=${address.addressLine1}&city=${address.city
      }&state=${address.state}&zip=${address.zip}&county=${address.county}`;
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const results = this.http.post<SearchResult>(url, userData, requestOptions);
    return results;
  }
}
