import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppraisalFormService {

  isCondoForm(formCommonName: string): boolean {
    const condoFormCommonNames = ['1073UAD', '1075UAD', 'ERC2010'];
    return condoFormCommonNames.some((condoForm) => condoForm === formCommonName);
  }
}
