<app-under-construction *ngIf="!doesUserHaveWebAccess"></app-under-construction>
<ng-container *ngTemplateOutlet="layout==='web' ? webLayout : desktopLayout"></ng-container>

<ng-template #webLayout>
  <div class="home-cards container" data-cy="homeCardsContainer">
    <ng-container *ngTemplateOutlet="criticalNotifications"></ng-container>

    <div class="my-row">
      <div class="my-col left-col">

        <ng-container *ngIf="dataSources && dataSources.length > 0; else noDataSources">
          <app-new-file [dataSources]="dataSources" [isPropertySearchApp]="false"></app-new-file>
        </ng-container>

        <app-files></app-files>

      </div>
      <div class="my-col right-col">
        <app-news></app-news>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #desktopLayout>
  <div class="home-cards container desktop">
    <ng-container *ngTemplateOutlet="criticalNotifications"></ng-container>

    <div class="my-row">
      <div class="my-col left-col subject-search">

        <ng-container *ngIf="dataSources && dataSources.length > 0; else noDataSources">
          <app-new-file [dataSources]="dataSources" [isPropertySearchApp]="false"></app-new-file>
        </ng-container>

      </div>
      <div class="my-col right-col new-file-alt">

        <app-new-file-alt></app-new-file-alt>

      </div>
    </div>
    <div class="my-row">
      <div class="my-col">

        <app-files></app-files>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #criticalNotifications>
  <app-critical-notifications></app-critical-notifications>
</ng-template>

<ng-template #noDataSources>
  <app-no-active-services></app-no-active-services>
</ng-template>
