import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
// Models
import { DataMasterFile } from '../../models/workfile/datamaster-file.model';
import { FormattingResult } from '../../models/operation/formatting/formatting-result.model';
import { PlatSearchResult } from '../../models/operation/property-search/plat-search-result.model';
import {
  SearchResult,
  SearchResultRequest,
} from '../../models/operation/property-search/search-result.model';
import {
  TranslationRequestWeb,
} from '../../models/operation/translation/translation-request.model';
import { SearchCriteria } from '../../models/operation/property-search/search-criteria.model';
import { FormatFieldMap } from '../../models/format-field-map.model';
import { MarketConditionsCalculationRequest } from '../../models/operation/market-conditions/market-conditions-calculation-request.model';
import { MarketConditionsCalculationResult } from '../../models/operation/market-conditions/market-conditions-calculation-result.model';
import { AppraisalForm } from '../../models/workfile/appraisal-form.model';
import { AppraisalFormSoftware } from '../../models/workfile/appraisal-form-software.model';
import {
  ListingHistorySearchWebRequest,
} from '../../models/operation/listing-history/listing-history-search-request.model';
import { ListingHistorySearchResult } from '../../models/operation/listing-history/listing-history-search-result.model';
import {
  TranslationResult,
  TranslationResultPrioritizationRequest,
} from '../../models/operation/translation/translation-result.model';
import { RawProperty } from '../../models/workfile/raw-property.model';
import { ExportRequest } from '../../models/operation/export/export-request.model';
import { SearchResultTranslationRequest } from '../../models/operation/translation/search-result-translation-request.model';
import { FormattingRequestWithPrioritizationResult } from '../../models/operation/prioritization/prioritization-result.model';
import { ProcessImportFileRequest } from '../../models/import/process-import-file-request.model';
import { CommentBlock, MCComments } from '../../models/market-conditions/comment-block.model';
import { UserSettings } from '../../models/settings/user-settings.model';
import { DataSource } from '../../models/data-source/data-source';
import { DataSourceCredentials } from '../../models/settings/data-source-credentials.model';
// Formaters
import { FormattingRequest } from '../../models/operation/formatting/formatting-request';
// Dep Issues
import { ConfigService, PropertyDetailReport } from '../../dependency-issues';
import { FillCommentTextRequest } from '../../models/market-conditions/fill-comment-request.model';
import {DeleteCommentBlockRequest} from '../../models/market-conditions/delete-comment-block-request.model';
import {SaveCommentBlockRequest} from '../../models/market-conditions/save-comment-block-request.model';
import {DefaultUserCommentBlock} from '../../models/market-conditions/default-user-comment-block.model';
import {UpdateCommentBlockRequest} from '../../models/market-conditions/update-comment-block-request.model';
import { CommentSectionCommentsRequest } from '../../models/market-conditions/comment-section-comments-request.model';
import { ListItem } from '../../models/operation/market-conditions/list-item.model';
import { Appraisal } from '../../models/workfile/appraisal.model';

const requestOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class DataMasterApiService {
  private readonly dmApiUrl: string = this.configService.api.dataMasterApi;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  getDMASaveFile(dataMasterFile: DataMasterFile): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(`${this.dmApiUrl}operations/getdmafile`, dataMasterFile, {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      }),
    });
  }

  getDataMasterFile(formData: FormData): Observable<DataMasterFile> {
    return this.httpClient.post<DataMasterFile>(
      `${this.dmApiUrl}operations/readfile`,
      formData,
      requestOptions
    );
  }

  //do we want to strongly type these?
  getFileReaderResults(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${this.dmApiUrl}operations/readimportfile`,
      formData,
      requestOptions
    );
  }

  processimportfile(processImportFileRequest: ProcessImportFileRequest): Observable<SearchResult> {
    return this.httpClient.post<any>(
      `${this.dmApiUrl}operations/processimportfile`,
      processImportFileRequest,
      requestOptions
    );
  }

  platmapsearch(searchCriteria: SearchCriteria): Observable<PlatSearchResult> {
    return this.httpClient.post<any>(
      `${this.dmApiUrl}operations/platmapsearch`,
      searchCriteria,
      requestOptions
    );
  }

  getListingHistory(
    listingHistorySearchRequest: ListingHistorySearchWebRequest
  ): Observable<ListingHistorySearchResult> {
    return this.httpClient.post<ListingHistorySearchResult>(
      `${this.dmApiUrl}operations/getlistinghistory`,
      listingHistorySearchRequest,
      requestOptions
    );
  }

  translateListingHistory(
    listingHistorySearchResult: ListingHistorySearchResult
  ): Observable<TranslationResult> {
    return this.httpClient.post<TranslationResult>(
      `${this.dmApiUrl}operations/translatelistinghistory`,
      listingHistorySearchResult,
      requestOptions
    );
  }

  search(searchCriteria: SearchCriteria): Observable<SearchResult> {
    return this.httpClient.post<any>(
      `${this.dmApiUrl}operations/search`,
      searchCriteria,
      requestOptions
    );
  }

  translate(searchResult: SearchResultTranslationRequest): Observable<any> {
    return this.httpClient.post<any>(
      `${this.dmApiUrl}operations/translate`,
      searchResult,
      requestOptions
    );
  }

  translateByRequest(translationRequest: TranslationRequestWeb): Observable<TranslationResult> {
    return this.httpClient.post<TranslationResult>(
      `${this.dmApiUrl}operations/translatebyrequest`,
      translationRequest,
      requestOptions
    );
  }

  translateDeeds(searchResult: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.dmApiUrl}operations/translatedeeds`,
      searchResult,
      requestOptions
    );
  }

  prioritize(
    translationResultPrioritizationRequest: TranslationResultPrioritizationRequest
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.dmApiUrl}operations/prioritize`,
      translationResultPrioritizationRequest,
      requestOptions
    );
  }

  formatByPrioritizationResult(
    prioritizationResultRequest: FormattingRequestWithPrioritizationResult
  ): Observable<FormattingResult> {
    return this.httpClient.post<FormattingResult>(
      `${this.dmApiUrl}operations/formatbyprioritizationresult`,
      prioritizationResultRequest,
      requestOptions
    );
  }

  format(formattingRequest: FormattingRequest): Observable<FormattingResult> {
    return this.httpClient.post<FormattingResult>(
      `${this.dmApiUrl}operations/format`,
      formattingRequest,
      requestOptions
    );
  }

  getPhotoFromRets(searchResult: SearchResultRequest): Observable<SearchResult> {
    return this.httpClient.post<SearchResult>(
      `${this.dmApiUrl}operations/getPhotoFromRets`,
      searchResult,
      requestOptions
    );
  }

  getFormatFieldMaps(formId: number, formSoftwareId: number): Observable<FormatFieldMap[]> {
    return this.httpClient.get<FormatFieldMap[]>(
      `${this.dmApiUrl}form/formatfieldmaps/formId/${formId}/formSoftwareId/${formSoftwareId}`,
      requestOptions
    );
  }

  calculateMarketConditions(
    marketConditionsCalculationRequest: MarketConditionsCalculationRequest
  ): Observable<MarketConditionsCalculationResult> {
    return this.httpClient.post<MarketConditionsCalculationResult>(
      `${this.dmApiUrl}operations/calculatemarketconditions`,
      marketConditionsCalculationRequest,
      requestOptions
    );
  }

  getAppraisalForms(): Observable<AppraisalForm[]> {
    return this.httpClient.get<AppraisalForm[]>(`${this.dmApiUrl}form/forms`, {
      headers: new HttpHeaders({ useCache: 'true', 'Access-Control-Allow-Origin': '*' }),
    });
  }

  getAppraisalFormSoftwares(): Observable<AppraisalFormSoftware[]> {
    return this.httpClient.get<AppraisalFormSoftware[]>(`${this.dmApiUrl}form/formsoftwares`, {
      headers: new HttpHeaders({ useCache: 'true', 'Access-Control-Allow-Origin': '*' }),
    });
  }

  getTotalXmlExport(exportRequest: ExportRequest): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(`${this.dmApiUrl}export/totalxmlexport`, exportRequest, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  saveSession(dataMasterFile: DataMasterFile): Observable<any> {
    return this.httpClient.post<any>(`${this.dmApiUrl}session`, dataMasterFile, requestOptions);
  }

  saveSettings(settings: UserSettings, username: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.dmApiUrl}usersettings?username=${username}`,
      settings,
      requestOptions
    );
  }
  resetSettingsToDefault(username: string): Observable<UserSettings> {
    return this.httpClient.post<UserSettings>(
      `${this.dmApiUrl}usersettings`,
      username,
      requestOptions
    );
  }

  getSettings(username: string): Observable<UserSettings> {
    return this.httpClient.get<UserSettings>(
      `${this.dmApiUrl}usersettings/username/${username}`,
      requestOptions
    );
  }

  getSession(userId: number): Observable<DataMasterFile> {
    return this.httpClient.get<DataMasterFile>(`${this.dmApiUrl}session/${userId}`, requestOptions);
  }

  deleteSession(userId: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.dmApiUrl}session/${userId}`);
  }

  getUserDataSources(planCodes: string[]): Observable<DataSource[]> {
    return this.httpClient.get<DataSource[]>(
      `${this.dmApiUrl}datasources/GetUserDataSourcesFromPlanCodes?planCodes=${planCodes.join(
        '&planCodes='
      )}`,
      requestOptions
    );
  }

  getDataSourcesByIds(ids: number[]): Observable<DataSource[]> {
    return this.httpClient.get<DataSource[]>(
      `${this.dmApiUrl}datasources/GetDataSourcesByIds?ids=${ids?.join('&ids=')}`,
      requestOptions
    );
  }

  getDataSources(): Observable<DataSource[]> {
    return this.httpClient.get<DataSource[]>(
      `${this.dmApiUrl}datasources/GetDataSources`,
      requestOptions
    );
  }

  getDataSourceCredentials(dataSourceIds: number[]): Observable<DataSourceCredentials[]> {
    return this.httpClient.get<DataSourceCredentials[]>(
      `${this.dmApiUrl}usersettings/GetDataSourceCredentials?datasourceids=${dataSourceIds.join(
        '&datasourceids='
      )}`,
      requestOptions
    );
  }

  propertyDetailReportRequest(
    mlsRawProperty: RawProperty,
    publicRecordsRawProperty: RawProperty
  ): Observable<PropertyDetailReport> {
    const body = {
      MlsRawProperty: mlsRawProperty,
      PublicRecordsRawProperty: publicRecordsRawProperty,
    };
    return this.httpClient.post<PropertyDetailReport>(
      `${this.dmApiUrl}operations/propertydetailreport`,
      body,
      requestOptions
    );
  }

  getMCComments(userId: number): Observable<MCComments> {
    const body = {
      userId: userId,
    };
    return this.httpClient.post<MCComments>(`${this.dmApiUrl}mc/getcomments`, body, requestOptions);
  }

  getFilledCommentText(fillCommentTextRequest: FillCommentTextRequest): Observable<any> {
    return this.httpClient.post<any>(`${this.dmApiUrl}mc/getfilledcommenttext`, fillCommentTextRequest, requestOptions);
  }

  deleteUserCommentBlock(request : DeleteCommentBlockRequest): Observable<CommentBlock>{
    return this.httpClient.post<CommentBlock>(`${this.dmApiUrl}mc/deleteusercommentblock`, request, requestOptions);
  }
  updateDefaultUserCommentBlock(request : UpdateCommentBlockRequest): Observable<DefaultUserCommentBlock>{
    return this.httpClient.post<DefaultUserCommentBlock>(`${this.dmApiUrl}mc/updatedefaultusercommentblock`, request, requestOptions);
  }
  updateUserCommentBlock(request : SaveCommentBlockRequest): Observable<any>{
    return this.httpClient.post<any>(`${this.dmApiUrl}mc/updateusercommentblock`, request, requestOptions);
  }
  saveUserCommentBlock(request : SaveCommentBlockRequest): Observable<any>{
    return this.httpClient.post<any>(`${this.dmApiUrl}mc/saveusercommentblock`, request, requestOptions);
  }
  getCommentsSectionComments(request : CommentSectionCommentsRequest): Observable<CommentBlock[]>{
    return this.httpClient.post<CommentBlock[]>(`${this.dmApiUrl}mc/getcommentssectioncomments`, request, requestOptions);
  }
  getSubjectCalculatedTagList(): Observable<ListItem[]>{
    return this.httpClient.get<ListItem[]>(
      `${this.dmApiUrl}mc/subjectcalculatedtaglist/`,
      requestOptions
    );
  }
  getCalculatedCommentList(): Observable<ListItem[]>{
    return this.httpClient.get<ListItem[]>(
      `${this.dmApiUrl}mc/calculatedcommentlist`,
      requestOptions
    );
  }

  getSummaryCommentText(appraisal: Appraisal): Observable<any> {
    return this.httpClient.post<any>(`${this.dmApiUrl}mc/getsummarycommenttext`, appraisal, requestOptions);
  }
}
