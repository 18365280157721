import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ViewEncapsulation,
  Inject,
  Optional,
} from '@angular/core';
import { PropertySearchResult } from '../../models/operation/property-search/property-search-result.model';
import { TranslatedPropertyResult } from '../../models/operation/translation/translated-property-result.model';
import {
  SearchCriteria,
  SearchType,
} from '../../models/operation/property-search/search-criteria.model';
import {
  Address} from '../../models/workfile/address.model';
import { MultipleResultsTableComponent } from '../multiple-results-table/multiple-results-table.component';
import {
  SearchResult,
  SearchResultRequest,
} from '../../models/operation/property-search/search-result.model';
import { DataMasterApiService } from '../../services/property-search/datamaster-api.service';
import { PropertySearchStatusType } from '../../models/operation/enum/property-search-status-type.enum';
import { MlsSearchComponent } from '../mls-search/mls-search.component';
import { AddressSearchComponent } from '../address-search/address-search.component';
import { PropertySearchHelperService } from '../../services/property-search/property-search-helper.service';
import { Router } from '@angular/router';
import { PropertyUsageType } from '../../models/workfile/enum/property-usage-type.enum';

import { map, tap } from 'rxjs/operators';
import {
  ListingHistorySearchRequest,
  ListingHistorySearchWebRequest,
} from '../../models/operation/listing-history/listing-history-search-request.model';
import { OperationStatusType } from '../../models/operation/enum/operation-status-type.enum';
import { ListingHistoryPropertyRequest } from '../../models/operation/listing-history/listing-history-property-request.model';
import { ProcessImportFileRequest } from '../../models/import/process-import-file-request.model';
import { lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { SearchResultTranslationRequest } from '../../models/operation/translation/search-result-translation-request.model';
import {
  TranslationResult,
  TranslationResultPrioritizationRequest,
} from '../../models/operation/translation/translation-result.model';
import {
  FormattingRequestWithPrioritizationResult,
  PrioritizationResult,
} from '../../models/operation/prioritization/prioritization-result.model';
import { DataMasterFileService } from '../../services/datamaster-file.service';
import { AppraisalForm } from '../../models/workfile/appraisal-form.model';
import { AppraisalFormSoftware } from '../../models/workfile/appraisal-form-software.model';
import { FormatFieldMap } from '../../models/format-field-map.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewWorkFileComponent } from '../new-work-file/new-work-file.component';
import { AppraisalFormService } from '../../services/appraisal-form.service';
import { DataSource } from '../../models/data-source/data-source';
import { DataSourceLinkInfo } from '../../models/data-source/data-source-link-info';
import { SpinnerOverlayRef } from '../../utils/spinner-overlay-ref';
import { DataSourceService } from '../../services/data-source.service';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { UserStateService } from '../../services/user/user-state.service';
import { SpinnerService } from '../../services/spinner.service';
import { MarketAnalysisReportService } from '../../services/market-analysis-report.service';
import { GraphsService } from '../../services/graphs.service';
import { ListingHistorySearchResult } from '../../models/operation/listing-history/listing-history-search-result.model';
import { FormattingResult } from '../../models/operation/formatting/formatting-result.model';
import { MarketConditionsCalculationResult } from '../../models/operation/market-conditions/market-conditions-calculation-result.model';
import { MarketConditionsForm } from '../../models/market-conditions/market-conditions-form.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SearchErrorComponent } from '../search-error/search-error.component';

@Component({
  selector: 'app-property-import',
  templateUrl: './property-import.component.html',
  styleUrls: [
    './property-import.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PropertyImportComponent implements OnInit {
  searchResult: SearchResult;
  propertySearchResult: PropertySearchResult;
  translatedPropertyResults: TranslatedPropertyResult[];
  propertyUseType: PropertyUsageType;
  propertyUsageTypeType = PropertyUsageType;
  isDashboardSearch = false;
  usePropertySearchAppFlow  = false;
  @Output() resultsEvent = new EventEmitter<any>();

  @ViewChild(MultipleResultsTableComponent)
  multipleResultsTableComponent: MultipleResultsTableComponent;
  @ViewChild('dialogMlsSearch') mlsSearchChild: MlsSearchComponent;
  @ViewChild('dialogAddressSearch', { static: true })
  addressSearchChild: AddressSearchComponent; // It works when defined like this, where 'addressSearch' is a var on the html element

  searchType: SearchType;

  dataSources: DataSource[];
  dataSourceLinkInfos : DataSourceLinkInfo[];
  prDataSources: DataSource[];

  appraisalFormTypes: AppraisalForm[];
  formSoftwares: AppraisalFormSoftware[];

  showPropertySearch = false;
  showGetFromMlsFile = false;
  showGetFromComparables = false;
  showGetFromMarketAnalysis = false;
  propertyUsageTypeIsMarketConditions: boolean;
  propertyUsageTypeIsNeighborhoodAnalysis: boolean;
  propertyUsageTypeIsProjectMarketConditions: boolean;
  propertyUsageIsSubject : boolean;
  showSubmitButton = false;
  isUsePropertyButtonDisabled = true;
  spinnerRef: SpinnerOverlayRef;

  _subscription: Subscription = new Subscription();
  _t0: any;
  _t1: any;

  constructor(
    private dataMasterApiService: DataMasterApiService,
    private propertySearchHelperService: PropertySearchHelperService,
    private router: Router,
    private dataSourceService: DataSourceService,
    private userStateService: UserStateService,
    private spinnerService: SpinnerService,
    private dataMasterFileService: DataMasterFileService,
    private dialog: MatDialog,
    private appraisalFormService: AppraisalFormService,
    private marketAnalysisReportService : MarketAnalysisReportService,
    private graphService : GraphsService,
    private errorDialogService : ErrorDialogService,
    @Optional() private mdDialogRef: MatDialogRef<PropertyImportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data
  ) {
    
    //TODO: retrieve user data sources from local cache instead. Get rid of waitFor function call
    const p = this.dataSourceService.getUserDataSources().then(((userDataSources) => {
      const mlsDataSources = this.dataSourceService.filterMlsDataSources(userDataSources);
      const prDataSources = this.dataSourceService.filterPrDataSources(userDataSources);

      this.dataSources = mlsDataSources;
      this.prDataSources = prDataSources;
      this.dataSourceLinkInfos  = this.dataSources.map(a=> a.dataSourceLinkInfos).reduce((a,b)=> a.concat(b), []);
    })
  );
  this.dataSourceService.waitFor(p);

    this._t0 = performance.now();
    this._t1 = performance.now();

    this.searchResult = data?.searchResult;
    this.propertyUseType = data?.propertyUseType;
    this.isDashboardSearch = data?.isDashboardSearch;
    this.translatedPropertyResults = data?.translatedPropertyResults;
    this.searchType = data?.searchType;
    this.usePropertySearchAppFlow = data?.usePropertySearchAppFlow;

    this.updatePropertySearchResult();
  }

  public close() {
    if (this.spinnerRef) this.spinnerRef.close();
    this.mdDialogRef.close();
  }

  ngOnInit() {
    if (this.propertyUseType === PropertyUsageType.Subject) {
      this.propertyUsageIsSubject = true;
      this.showPropertySearch = true;
      this.showSubmitButton = true;
      if (!this.isDashboardSearch) {
        this.showGetFromComparables = true;
        this.showGetFromMarketAnalysis = true;
      }
    } else if (this.propertyUseType === PropertyUsageType.Comparable) {
      this.showPropertySearch = true;
      this.showGetFromMlsFile = true;
      this.showGetFromMarketAnalysis = true;
      this.showSubmitButton = true;

    } else if (this.propertyUseType === PropertyUsageType.MarketConditions) {
      this.showGetFromMlsFile = true;
      this.propertyUsageTypeIsMarketConditions = true;
      this.propertyUsageTypeIsProjectMarketConditions = this.appraisalFormService.isCondoForm(
          this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form.commonName);
    } else if (this.propertyUseType === PropertyUsageType.NeighborhoodAnalysis) {
      this.showGetFromMlsFile = true;
      this.propertyUsageTypeIsNeighborhoodAnalysis = true;
    }

    this.dataSourceService.userDataSourcesChanged
      .pipe(
        map((userDataSources) => {
          const mlsDataSources = this.dataSourceService.filterMlsDataSources(userDataSources);
          const prDataSources = this.dataSourceService.filterPrDataSources(userDataSources);

          return { mlsDataSources, prDataSources };
        })
      )
      .subscribe((dataSources) => {
        this.dataSources = dataSources.mlsDataSources;
        this.dataSourceLinkInfos  = this.dataSources.map(a=> a.dataSourceLinkInfos).reduce((a,b)=> a.concat(b), []);
        this.prDataSources = dataSources.prDataSources;
      });

    this.dataMasterApiService.getAppraisalForms().subscribe((forms) => {
      this.appraisalFormTypes = forms;
    });
    this.dataMasterApiService.getAppraisalFormSoftwares().subscribe((formSoftwares) => {
      this.formSoftwares = formSoftwares;
    });
  }

  toggleSubmitButton(value: boolean) {
    this.showSubmitButton = value;
  }

  clearSearchResults(): void { /** */}

  updateAfterPropertyImportSearch(event: {
    searchResult: SearchResult;
    translationResult: TranslationResult;
    searchType: SearchType;
  }) {
    this.searchResult = event.searchResult;
    this.translatedPropertyResults = event.translationResult.translatedPropertyResults;
    this.searchType = event.searchType;

    this.updatePropertySearchResult();
  }

  checkSingleSearchResult() {
    if (
      !this.isDashboardSearch &&
      this.multipleResultsTableComponent.translatedPropertyResults.length == 1
    ) {
      this.multipleResultsTableComponent.selectedTranslatedPropertyResult =
        this.multipleResultsTableComponent.translatedPropertyResults[0];
      this.runReverseSearch();
    }
  }

  async useProperty() {
    if (this.isDashboardSearch) {
      await this.setUpWorkfile();
      if (!this.dataMasterFileService.dataMasterFile) return;
    }

    this.runReverseSearch();
  }

  private async setUpWorkfile() {
    if (this.usePropertySearchAppFlow) {
      const form: AppraisalForm = this.appraisalFormTypes.find((x) => x.commonName == '1004UAD');

      const fileNumber = 'DataMaster Web Generated File';
      const selectedFormSoftware: AppraisalFormSoftware = this.formSoftwares[0];
      this.setUpFormGlobally(null, fileNumber, form, selectedFormSoftware);
    } else {
      const dialogRef = this.dialog.open(NewWorkFileComponent, {
        maxWidth: '50vw',
        maxHeight: '150vh',
        width: '90%',
      });

      await dialogRef.afterClosed().toPromise();
    }
  }

  private setUpFormGlobally(
    formatFieldMaps: FormatFieldMap[],
    fileNumber: string,
    form: AppraisalForm,
    selectedFormSoftware: AppraisalFormSoftware
  ) {
    this.dataMasterFileService.initialize(formatFieldMaps);
    this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.formVendor = form.commonName;
    this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.fileNumber = fileNumber;
    this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form = form;
    this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.formSoftware =
      selectedFormSoftware;
  }

  async runReverseSearch() {
    this.spinnerRef = this.spinnerService.open();
    this.spinnerService.updateMessage('Retrieving listing history...');

    this.logtime('reverse search');
    const rawGuid = this.multipleResultsTableComponent.selectedTranslatedPropertyResult.rawGuid;
    const relaventPropertySearchResults = this.propertySearchResult.propertyResults.filter(
      (x) => x.rawGuid == rawGuid
    );

    this.searchResult.searchProviderResults[0].propertySearchResults = [];
    this.searchResult.searchProviderResults[0].propertySearchResults.push(
      this.propertySearchResult
    );
    this.searchResult.searchProviderResults[0].propertySearchResults[0].propertyResults =
      relaventPropertySearchResults;

    const newSearchCriteria = this.createSearchCriteriaForReverseSearch();

    this.logtime('search web request');

    let searchResult : SearchResult;
    await this.getSearch(newSearchCriteria).then(data=>{
      searchResult = data;
    }).catch(err => {
      this.spinnerRef.close();
      console.log(err);
      throw new Error();
    });
      await this.createParcelsFromSearchResult(searchResult);
      this.completeCoreOperations();
  }
  getSearch(newSearchCriteria: SearchCriteria): Promise<SearchResult> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const searchResult =  await lastValueFrom(this.dataMasterApiService.search(newSearchCriteria));
    if (searchResult) {
      if(searchResult.status == OperationStatusType.AuthenticationError){
        reject();
        this.errorDialogService.open( { title: 'Error', message : searchResult.exception.Message})
        throw new Error(searchResult.exception.Message);
      }else{
        resolve(searchResult);
      }
    }else {
        reject()
        throw new Error('');
    }
  });
  }

  createSearchCriteriaForReverseSearch(): SearchCriteria {
    const searchCriteria: SearchCriteria = new SearchCriteria();
    searchCriteria.userData = this.userStateService.userDataForSearch;

    searchCriteria.dataSourceIds = this.mlsSearchChild.dataSources.map((x) => x.id);

    if (!searchCriteria.dataSourceIds || searchCriteria.dataSourceIds.length == 0)
      searchCriteria.dataSourceIds = this.dataSourceService.getUserDataSourceIds();

    searchCriteria.selectedDataSourceId = this.mlsSearchChild.selectedDataSource?.id;

    this.prDataSources?.map((a) => a.id).forEach((x) => searchCriteria.dataSourceIds.push(x));

    switch (this.searchType) {
      case SearchType.MLS:
        searchCriteria.searchType = SearchType.MLS;
        this.spinnerService.updateMessage('Searching for properties in Public Records');
        break;
      case SearchType.Address:
      case SearchType.APN:
      default:
        searchCriteria.searchType = SearchType.APN;
        this.spinnerService.updateMessage('Searching for properties in Public Records');
        break;
    }
    searchCriteria.mlsNumbers =
      this.multipleResultsTableComponent.selectedTranslatedPropertyResult.translatedParcel.mlsNumber;
    searchCriteria.apnNumbers =
      this.multipleResultsTableComponent.selectedTranslatedPropertyResult.translatedParcel.apn;
    searchCriteria.propertyUsageType = this.searchResult.propertyUsageType;
    searchCriteria.address = new Address();

    searchCriteria.address.addressLine1 =
      this.multipleResultsTableComponent.selectedTranslatedPropertyResult.translatedParcel.addressLine1;
    searchCriteria.address.city =
      this.multipleResultsTableComponent.selectedTranslatedPropertyResult.translatedParcel.city;
    searchCriteria.address.state =
      this.multipleResultsTableComponent.selectedTranslatedPropertyResult.translatedParcel.state;
    searchCriteria.address.zip =
      this.multipleResultsTableComponent.selectedTranslatedPropertyResult.translatedParcel.zip;
    searchCriteria.address.county =
      this.multipleResultsTableComponent.selectedTranslatedPropertyResult.translatedParcel.county;
    //newSearchCriteria.address.fipsCode = this.multipleResultsTableComponent.selectedTranslatedPropertyResult.translatedParcel.;

    searchCriteria.primarySearchResult = this.searchResult;

    return searchCriteria;
  }

  createListingHistorySearch$(searchResult: SearchResult,prioritizationResult: PrioritizationResult) {

    const mlsSearchProviderResult = searchResult.searchProviderResults.find(
      (a) =>
        a.propertySearchResults?.length > 0 &&
        a.propertySearchResults.find((searchResult) => searchResult.propertyResults?.length > 0) &&
        this.mlsSearchChild.dataSources?.some((ds) => ds.id == a.dataSourceId)
    );

    //create request
    const listingHistorySearchRequest = new ListingHistorySearchRequest();

    let dataSourceId = mlsSearchProviderResult?.dataSourceId;

    if (!dataSourceId && this.dataSources && this.dataSources.length > 0) {
      dataSourceId = this.dataSources[0].id;
    } else {
      return of(null);
    }

    listingHistorySearchRequest.dataSourceId = dataSourceId;

    listingHistorySearchRequest.listingHistoryPropertyRequests = [];

    const listingHistoryPropertyRequest = new ListingHistoryPropertyRequest();

    const prioritizedStandardizedParcel =  prioritizationResult.prioritizedPropertyResults[0].standardizedParcel;

    listingHistoryPropertyRequest.mls = prioritizedStandardizedParcel.mlsNumber;
    listingHistoryPropertyRequest.apn = prioritizedStandardizedParcel.apn;
    listingHistoryPropertyRequest.propertyGuid = prioritizedStandardizedParcel.propertyGuid;
    listingHistoryPropertyRequest.county = prioritizedStandardizedParcel.county;
    listingHistoryPropertyRequest.state = prioritizedStandardizedParcel.state;

    listingHistorySearchRequest.listingHistoryPropertyRequests.push(listingHistoryPropertyRequest);

    const listingHistorySearchWebRequest: ListingHistorySearchWebRequest = {
      listingHistorySearchRequest: listingHistorySearchRequest,
      userData: this.userStateService.userDataForSearch,
    };
    this.logtime('listing history web request');
    return this.dataMasterApiService.getListingHistory(listingHistorySearchWebRequest);
  }

  retsPhotoSearch(searchResult: SearchResult): Observable<SearchResult> {
    if (
      this.dataSources.some(
        (a) =>
          searchResult.searchProviderResults.find((b) => b.dataSourceId === a.id) &&
          (searchResult.status === OperationStatusType.Success ||
            searchResult.status === OperationStatusType.PartialSuccess) &&
          a.type.toLocaleLowerCase() === 'rets'
      )
    ) {
      const searchResultRequest: SearchResultRequest = new SearchResultRequest(
        searchResult,
        this.userStateService.userDataForSearch
      );
      return this.dataMasterApiService.getPhotoFromRets(searchResultRequest);
    } else {
      return of(searchResult);
    }
  }

  private completeCoreOperations() {
    this.close();

    switch (this.propertyUseType) {
      case PropertyUsageType.Subject: {
        if (this.usePropertySearchAppFlow) {
          this.router.navigate(['/propertysearch/pdr']);
          break;
        }
        this.router.navigate(['/subject']);
        break;
      }
      case PropertyUsageType.Comparable: {
        this.router.navigate(['/comparables']);
        break;
      }
    }
  }

  private getTranslation(
    searchResult: SearchResult,
    form: string
  ): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        const searchResultTranslationRequest : SearchResultTranslationRequest = {
            searchResult,
            userData: this.userStateService.userDataForSearch,
            selectedFormCommonName: form
        };
        this.logtime('translate web request');
        this.OnProgressChange('Translating properties...');
        const translation = await lastValueFrom(this.dataMasterApiService.translate(searchResultTranslationRequest));
        if (translation) {
            resolve(translation);
        }else {
            reject()
            throw new Error('');
        }
    });
  }
  private getPrioritization(translationResult: TranslationResult,
    formCommonName: string): Promise<PrioritizationResult> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) =>{
      const translationResultPrioritizationRequest: TranslationResultPrioritizationRequest =
      {
        translationResult: translationResult,
        userData: this.userStateService.userDataForSearch,
        formCommonName: formCommonName
      };
      const prioritizationResult = await lastValueFrom(this.dataMasterApiService.prioritize(translationResultPrioritizationRequest));
      if(prioritizationResult) {
        resolve(prioritizationResult);
    }else {
        reject()
        throw new Error('');
    }
    });
  }
  getListingHistory(searchResult: SearchResult, prioritizationResult: PrioritizationResult) : Promise<PrioritizationResult> {

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) =>{
    const listingHistorySearchResult = await lastValueFrom(this.createListingHistorySearch$(searchResult, prioritizationResult));
    
        if (listingHistorySearchResult == null) {
          resolve(prioritizationResult);
          return;
        }

        this.logtime('translate listing history');
        //translate listing history
        const translationResult = await lastValueFrom(this.dataMasterApiService.translateListingHistory(listingHistorySearchResult));
          
        //save
        this.propertySearchHelperService.setTranslatedListingHistoryParcels(translationResult);

        resolve(prioritizationResult);
    });
  }
  private OnProgressChange(message : string){
    this.spinnerService.updateMessage(message);
  }
  private async createParcelsFromSearchResult(
    searchResult: SearchResult
  ) {
    const form = this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form.commonName;

    this.logtime('rets photo search web request');

    this.OnProgressChange('Downloading Photo(s)...');

    searchResult = await lastValueFrom(this.retsPhotoSearch(searchResult));
    this.logtime('set raw properties');
    this.propertySearchHelperService.setRawProperties(searchResult);

    let translationResult;
    await this.getTranslation(searchResult, form).then(data => {
      translationResult = data;
      this.logtime('set translate parcels');
    }).catch(e => {
      throw new Error('');
    });

    this.propertySearchHelperService.setTranslatedParcels(translationResult);

    this.OnProgressChange('Translating deeds...');
    this.logtime('translate deeds web request');
    let deedTranslationResult;
    await this.getTranslateDeeds(searchResult).then(data => {
      deedTranslationResult = data;
    }).catch(e => {
      throw new Error('');
    });
    this.logtime('set deeds');
    this.propertySearchHelperService.setDeeds(deedTranslationResult);
    
    this.OnProgressChange('Prioritizing properties...');
    this.logtime('prioritize web request');
    let prioritizationResult : PrioritizationResult;
    await this.getPrioritization(translationResult, form).then(data =>{
      prioritizationResult = data;
      this.logtime('set prioritize');
    });
    
    this.propertySearchHelperService.setPrioritizedParcels(prioritizationResult);
    this.propertySearchHelperService.prepPrioritizedDeedsForFormatting(
      prioritizationResult,
      this.dataSources.concat(this.prDataSources)
    );
    
    if (this.propertyUseType== PropertyUsageType.Subject) {
      this.spinnerService.updateMessage('Retrieving listing history...');

      await this.getListingHistory(searchResult, prioritizationResult); 
    }

    const formSoftware =
      this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.formSoftware
        .commonName;
    const prioritizationResultRequest: FormattingRequestWithPrioritizationResult =
      new FormattingRequestWithPrioritizationResult(
        prioritizationResult,
        this.userStateService.userDataForSearch,
        form,
        formSoftware
      );
      this.OnProgressChange('Formatting properties...');
    this.logtime('format web request');
    const formattingResult = await lastValueFrom(this.dataMasterApiService.formatByPrioritizationResult(prioritizationResultRequest));
    this.logtime('set format');
    this.propertySearchHelperService.setFormattedResults(formattingResult);
    this.logtime('set format done');

    return of(formattingResult);
  }
  
  updatePropertySearchResult() {
    if (!this.searchResult) return;
    this.propertySearchResult =
      this.searchResult.searchProviderResults[0].propertySearchResults.find(
        (x) => x.status != PropertySearchStatusType.NoResults
      );
  }

  openFileDialog() {
    //if import condo form
    const element: HTMLElement = document.getElementById('propertyImportMlsFileInput') as HTMLElement;
    element.click();
  }

  openProjectMarketConditionsFileDialog() {
    this.propertyUseType = PropertyUsageType.ProjectMarketConditions;
    this.openFileDialog();
  }

  openMarketConditionsFileDialog(){
    this.propertyUseType = PropertyUsageType.MarketConditions;    
    this.openFileDialog();
  }

  openNeighborhoodAnalysisFileDialog(){
    this.propertyUseType = PropertyUsageType.NeighborhoodAnalysis;
    this.openFileDialog();
  }

  async openFileChangeEvent(fileInput: any) {
    this.spinnerRef = this.spinnerService.open();
    if (!(fileInput.target.files && fileInput.target.files[0])) return;
      const form =
        this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form.commonName;

      const formData = new FormData();
      formData.append('file', fileInput.target.files[0], fileInput.target.files[0].name);
      formData.append('dataSourceCommonName', this.dataSources[0].commonName);
      formData.append('propertyUsageType', PropertyUsageType[this.propertyUseType]);

      let fileReaderResult;
      await this.getFileReaderResults(formData).then(data=>{
        fileReaderResult = data;
      }).catch(err => {
        this.spinnerRef.close();
        console.log(err);
        throw new Error();
      });
      
      const processImportFileRequest: ProcessImportFileRequest = new ProcessImportFileRequest(
        fileReaderResult,
        this.userStateService.userDataForSearch
      );

      let searchResult;
      await this.getProcessImportFile(processImportFileRequest).then(data=>{
        searchResult = data;
      }).catch(err => {
        this.spinnerRef.close();
        console.log(err);
        throw new Error();
      });
      
      this.propertySearchHelperService.setRawProperties(searchResult);

      let translationResult;

      await this.getTranslation(searchResult, form).then(data => {
        translationResult = data;
      }).catch(e => {
        throw new Error('');
      });

      this.propertySearchHelperService.setTranslatedParcels(translationResult);

      if (this.propertyUseType === PropertyUsageType.Comparable) {
        let deedTranslationResult;
        await this.getTranslateDeeds(searchResult).then(data => {
          deedTranslationResult = data;
        }).catch(e => {
          throw new Error('');
        });
        this.propertySearchHelperService.setDeeds(deedTranslationResult);
      }    

      const translationResultPrioritizationRequest: TranslationResultPrioritizationRequest =
        new TranslationResultPrioritizationRequest(
          translationResult,
          this.userStateService.userDataForSearch,
          form
        );

        let prioritizationResult : PrioritizationResult;
      await this.getPrioritization(translationResult, form).then(data =>{
        prioritizationResult = data;
        this.logtime('set prioritize');
      }).catch(e => {
        throw new Error('');
      });
    
      this.propertySearchHelperService.setPrioritizedParcels(prioritizationResult);
      
      const formSoftware =
        this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.formSoftware
          .commonName;
      
      let formattingResult : FormattingResult;
      await this.getFormatByPrioritizationResult(prioritizationResult, form, formSoftware).then(data =>{
        formattingResult = data;
      }).catch(e => {
        throw new Error('');
      });        

      this.propertySearchHelperService.setFormattedResults(formattingResult);
      if (
        this.propertyUseType !== PropertyUsageType.MarketConditions &&
        this.propertyUseType !== PropertyUsageType.ProjectMarketConditions &&
        this.propertyUseType !== PropertyUsageType.NeighborhoodAnalysis
      ) {
        this.close();
        this.router.navigate(['/comparables']);
      }
      this.marketAnalysisReportService.clearCalculatedTags(false);

      let marketConditionsCalculationResult : MarketConditionsCalculationResult;
      await this.getCalculateMarketConditions().then(data =>{
        marketConditionsCalculationResult = data;
      }).catch(e => {
        throw new Error('');
      });        

      this.propertySearchHelperService.setMarketConditionsCalculations(
        marketConditionsCalculationResult
      );

      let marketConditionsGraphCalculationResult;
      await this.getCalculateGraphs(marketConditionsCalculationResult.marketConditionsForm, marketConditionsCalculationResult.propertyUsageType).then(data =>{
        marketConditionsGraphCalculationResult = data;
      }).catch(e => {
        throw new Error('');
      });        
      
      const dir = this.propertyUseType === PropertyUsageType.NeighborhoodAnalysis;

      if (this.marketAnalysisReportService.marketConditionsGraphs) {
        const graphsExists = this.marketAnalysisReportService.marketConditionsGraphs.filter((a) => a.graphDetails.reportUsageType == this.propertyUseType).length > 0;
        if (!graphsExists) {
        this.marketAnalysisReportService.marketConditionsGraphs = this.marketAnalysisReportService.marketConditionsGraphs.concat(marketConditionsGraphCalculationResult.marketConditionsGraphs);
        }            
      } else {
        this.marketAnalysisReportService.marketConditionsGraphs = marketConditionsGraphCalculationResult.marketConditionsGraphs;
      }          

      this.close();
      this.router.navigate(['/market-analysis', {neighborhoodAnalysis:dir}]);
  }
  getCalculateGraphs(marketConditionsForm: MarketConditionsForm, propertyUsageType: PropertyUsageType) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const marketConditionsGraphCalculationResult =  await lastValueFrom(this.graphService.calculateGraphs(marketConditionsForm, this.userStateService.userDataForSearch, propertyUsageType));
      if (marketConditionsGraphCalculationResult) {
        resolve(marketConditionsGraphCalculationResult);
      }else {
          reject()
          throw new Error('');
      }
    });
  }
  getCalculateMarketConditions() : Promise<MarketConditionsCalculationResult> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
    const request = this.propertySearchHelperService.getMarketConditionsCalculationRequest(
      this.propertyUseType,
      this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate
    );
    const marketConditionsCalculationResult =  await lastValueFrom(this.dataMasterApiService.calculateMarketConditions(request));
     if (marketConditionsCalculationResult) {
      resolve(marketConditionsCalculationResult);
    }else {
        reject()
        throw new Error('');
    }
  });
  }
  getFormatByPrioritizationResult(prioritizationResult: PrioritizationResult, form: string, formSoftware: string) : Promise<FormattingResult> {
     // eslint-disable-next-line no-async-promise-executor
     return new Promise(async (resolve, reject)=>{
      const prioritizationResultRequest: FormattingRequestWithPrioritizationResult =
      new FormattingRequestWithPrioritizationResult(
        prioritizationResult,
        this.userStateService.userDataForSearch,
        form,
        formSoftware
      );
      const formattingResult =  await lastValueFrom(this.dataMasterApiService.formatByPrioritizationResult(prioritizationResultRequest));
    if (formattingResult) {
      resolve(formattingResult);
    }else {
        reject()
        throw new Error('');
    }
  });
  }
  getTranslateDeeds(searchResult: any): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const deedTranslationResult =  await lastValueFrom(this.dataMasterApiService.translateDeeds(searchResult));
    if (deedTranslationResult) {
      resolve(deedTranslationResult);
    }else {
        reject()
        throw new Error('');
    }
  });
  }
  getProcessImportFile(processImportFileRequest: ProcessImportFileRequest): Promise<SearchResult> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const searchResult =  await lastValueFrom(this.dataMasterApiService.processimportfile(processImportFileRequest));
    if (searchResult) {
      resolve(searchResult);
    }else {
        reject()
        throw new Error('');
    }
  });
  }
  getFileReaderResults(formData: FormData): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const fileReaderResult =  await lastValueFrom(this.dataMasterApiService.getFileReaderResults(formData));
    if (fileReaderResult) {
      resolve(fileReaderResult);
    }else {
        reject()
        throw new Error('');
    }
  });
  }

  toggleUsePropertyButton(propertySelected: boolean) {
    this.isUsePropertyButtonDisabled = !propertySelected;
  }
  logtime(message: string) {
    this._t0 = this._t1;
    this._t1 = performance.now();
    console.log(
      `performing: ${message} , took ${this._t1 - this._t0} milliseconds since last action`
    );
  }
}