import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Parcel } from '@datamaster/core';
import { CompDeedsComponent } from '../components/comparables/comp-deeds/comp-deeds.component';

@Injectable({
  providedIn: 'root'
})
export class DeedsWindowServiceService {
  dialogRef: MatDialogRef<CompDeedsComponent>;
  constructor(private dialog: MatDialog) { }
  openDeeds(parcel: Parcel) {
    this.dialogRef = this.dialog.open(CompDeedsComponent, {
      data: {
        parcel : parcel
      },

    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    });
  }
}
