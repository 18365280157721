// Angular
import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// State
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { UserState } from './services/user/state/user.state';
import { AppState } from './state/app.state';
// Service
import { AuthInterceptorService } from './components/auth/services/auth-interceptor.service';
import { AuthService } from './components/auth/services/auth.service';
import { CacheInterceptor } from './injectables/cache-interceptor';
import { HttpErrorInterceptor } from './injectables/http-error-interceptor';
// Other
import { environment } from './environments/environment';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        // 3rd Party
        NgxsModule.forRoot([AppState, UserState], {
            developmentMode: !environment.production
        }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        // NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
        NgxsStoragePluginModule.forRoot()
    ],
    exports: [],
    declarations: [],
    providers: [
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        // { provide: ErrorHandler, useClass: GlobalErrorHandler, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
    ],
})
export class DMCoreModule { }
