import { Component, Input, OnInit} from '@angular/core';
import { MarketConditionGraphGroupType } from '../../../models/enums/market-conditions-graph-group-type.enum';
import { UserMarketConditionsMarketAnalysisSettings } from '../../../models/settings/user-settings.model';

@Component({
  selector: 'app-settings-market-analysis',
  templateUrl: './settings-market-analysis.component.html',
  styleUrls: ['./settings-market-analysis.component.scss']
})
export class SettingsMarketAnalysisComponent implements OnInit {

  @Input() userMarketConditionsMarketAnalysisSettings : UserMarketConditionsMarketAnalysisSettings;
  marketConditionGraphGroupType = MarketConditionGraphGroupType;
  
  constructor() {
  }
  ngOnInit() {
  }
  // Genterate and array of numbers up to num
  effectiveDate(num: number): number[]{
    return [...Array(num).keys()].slice(0);
  }

  getUserMarketConditionsMarketAnalysisSettings(marketConditionGraphGroupType : MarketConditionGraphGroupType){
    return this.userMarketConditionsMarketAnalysisSettings.userMarketConditionsGraphSettings.find(a=> a.marketConditionGraphGroupType === marketConditionGraphGroupType);
  }
}
