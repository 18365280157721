import { Component, OnInit, ElementRef, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

import { SidebarLogoService } from '../../services/sidebar-logo.service';
import { UserStateModel } from '../../models/user/user-state.model';
import { UserStateService } from '../../services/user/user-state.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewChecked, AfterViewInit {
    sidebarExpanded = false;
    @ViewChild('sideNavBar', { static: true }) sideNavbar: ElementRef;
    oldNavbarWidth = 0;
    get userState(): UserStateModel {
        return this.userStateService.user;
    };
    hasUserIcon = false;

    constructor(private sidebarLogoService: SidebarLogoService, private userStateService: UserStateService) { }

    ngOnInit() {
        this.getUserInfo();
    }

    ngAfterViewInit() {
        this.oldNavbarWidth = this.sideNavbar.nativeElement.offsetWidth;
        this.sidebarLogoService.setSidebarWidth(this.sideNavbar.nativeElement.offsetWidth);
    }

    ngAfterViewChecked() {
        //this.sidebarLogoService.setSidebarWidth(this.sideNavbar);
        if (this.oldNavbarWidth !== this.sideNavbar.nativeElement.offsetWidth) {
            this.sidebarLogoService.setSidebarWidth(this.sideNavbar.nativeElement.offsetWidth);
            this.oldNavbarWidth = this.sideNavbar.nativeElement.offsetWidth;
        }
    }

    toggleMenu(): void {
        this.sidebarExpanded = this.sidebarExpanded == true ? false : true;
        this.sidebarLogoService.setSidebarExpanded(this.sidebarExpanded);
    }

    getUserInfo(): void {
        if (this.userState.iconPath !== undefined && this.userState.iconPath.length > 0) {
            this.hasUserIcon = this.imageExists(this.userState.iconPath);

            // If the userInfo.iconPath is not empty, but the image doesn't exist (i.e., hasUserIcon === false)
            if (this.hasUserIcon === false) {
                this.checkForGravatar(this.userState.user.userName);
            }
        } else {
            this.checkForGravatar(this.userState.user.userName);
        }
    }

    private checkForGravatar(email: string): void {
        //email = "ryanlifferth@gmail.com";
        if (email.length > 0) {
            const gravatarUrl = 'http://www.gravatar.com/avatar/' + Md5.hashStr(email.toLowerCase().trim()) + '?size=110&d=404';
            if (this.imageExists(gravatarUrl)) {
                this.hasUserIcon = true;
                this.userState.iconPath = gravatarUrl;
            };
        }
    }

    private imageExists(imageUrl: string): boolean {
        const http = new XMLHttpRequest();

        http.open('HEAD', imageUrl, false);
        http.send();

        const exists = http.status != 404;
        this.hasUserIcon = exists;
        return exists;
    }

    private imageExists2(imageUrl: string): boolean {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const pageThis = this;
        return this.imageExistsWithCallback(imageUrl, function (exists) {
            pageThis.hasUserIcon = exists;
            return exists;
        });
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private imageExistsWithCallback(imageUrl: string, callback: Function): boolean {
        const img = new Image();
        img.onload = function () {
            return true;
        };
        img.onerror = function () {
            return false;
        };
        img.src = imageUrl;
        return false;
    }

}
