import { Injectable } from '@angular/core';
import { PropertyImportComponent } from '../components/property-import/property-import.component';
import { MatDialog } from '@angular/material/dialog';
import { PropertyUsageType } from '../models/workfile/enum/property-usage-type.enum';

@Injectable({
  providedIn: 'root'
})
export class PropertyImportDialogService {

  constructor(private dialog: MatDialog) { }

  openPropertyImportModal(propertyUsageType: PropertyUsageType) {
    this.dialog.open(PropertyImportComponent, {
      maxWidth: '95vw',
      minWidth: '73vw',
      data: {
        propertyUseType: propertyUsageType,
      },
    });
  }
}
