import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Modules
import { MaterialSharedModule } from '../utils/material.module';
// Components
import { MlsCredentialsComponent } from './components/mls-credentials/mls-credentials.component';

@NgModule({
  declarations: [
    MlsCredentialsComponent
  ],
  exports: [
    MlsCredentialsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialSharedModule,
    ReactiveFormsModule
  ]
})
export class DependencyIssuesModule { }
