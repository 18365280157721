import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { SpinnerOverlayRef } from '../utils/spinner-overlay-ref';
import { SpinnerMessagingService } from './spinner-messaging.service';
@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  // Inject overlay service
  constructor(
    private overlay: Overlay,
    private spinnerMessagingService: SpinnerMessagingService
  ) { }

  open(spinnerMessage?: string): SpinnerOverlayRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    // Returns an OverlayRef (which is a PortalHost, or placeholder for component/template)+
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    // Instantiate remote control
    const dialogRef = new SpinnerOverlayRef(overlayRef);

    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerPortal = new ComponentPortal(SpinnerComponent);

    // Attach ComponentPortal to PortalHost
    const componentRef = overlayRef.attach(spinnerPortal);

    if (spinnerMessage) {
      componentRef.instance.message = spinnerMessage;
    }

    return dialogRef;
  }

  updateMessage(message: string) {
    this.spinnerMessagingService.updateSpinnerMessage(message);
  }
}
