import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerMessagingService } from '../../services/spinner-messaging.service';
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, OnDestroy {
  private defaultMmessage = 'Loading, Please Wait...';
  message: string;
  private subscription: Subscription = new Subscription();

  constructor(private spinnerMessagingService: SpinnerMessagingService) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.message = this.defaultMmessage;

    const sub = this.spinnerMessagingService.messageChanged
      .pipe(
        tap((message) => {
          this.message = message;
        })
      )
      .subscribe((_) => {
        //
      });
    this.subscription.add(sub);
  }
}
