<span>
    <div class="coming-soon">
      <div class="coming-message">
        <h1>Thanks for using our Property Search App</h1>
        <p> 
          We're currently working on bringing our full functionality to the web. 
          Soon our Subject, Comparables Grid and Market Analysis will be functional here.
        </p>
        <a class="btn btn-primary" href="{{propertySearchAppUrl}}">Click here for the Property Search App <i class="fal fa-arrow-circle-right"></i></a>
        <!-- <p>
          Please enter your email for updates on our progress. 
        </p> -->
      </div>
      <!-- <div class="email-form">
        <input type="text" placeholder="Email"/>  
        <button type="submit">Get Updates</button>  
      </div> -->
    </div>
  </span>