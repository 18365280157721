import { UserNotification, NotificationStatus } from '@datamaster/core';

export const USERNOTIFICATIONS: UserNotification[] = [
  {
    'userId': 1234,
    'notificationId': null,
    'legacyProductBulletinId': 55,
    'priorityName': 'Normal',
    'productName': 'RYANMLS',
    'notificationMessage': 'New message again',
    'messageExpiration': new Date('9999-12-31T00:00:00'),
    'notificationStatus': NotificationStatus.Unread,
    //"createdDate": new Date("2020-09-16T13:25:05")
    'createdDate': new Date()
  },
  {
    'userId': 1234,
    'notificationId': 3001,
    'legacyProductBulletinId': null,
    'priorityName': 'Normal',
    'productName': null,
    'notificationMessage': 'Your bill is due for $20.00 Disney Dollars on 10-23-2020.',
    'messageExpiration': new Date('2021-02-10T14:21:55.703'),
    'notificationStatus': NotificationStatus.Unread,
    //"createdDate": new Date("2020-10-13T14:21:55.703")
    'createdDate': new Date(new Date().setHours(new Date().getHours() - 6))
  },
  {
    'userId': 1234,
    'notificationId': 3002,
    'legacyProductBulletinId': null,
    'priorityName': 'Critical',
    'productName': null,
    'notificationMessage': 'Reminder that DataMaster will be down tomorrow (10-14-2020) from 2:00 am - 3:00 am for maintenance.',
    'messageExpiration': new Date('2020-10-14T14:21:55.72'),
    'notificationStatus': NotificationStatus.Read,
    //"createdDate": new Date("2020-10-13T14:21:55.72")
    'createdDate': new Date(new Date().setDate(new Date().getDate() - 1))

  },
  {
    'userId': 1234,
    'notificationId': 3003,
    'legacyProductBulletinId': null,
    'priorityName': 'Outage',
    'productName': 'WFR',
    'notificationMessage': 'UTAHREALESTATE.com is currently down.  We are working on getting our connection restored and will notify you once it is up.',
    'messageExpiration': new Date('2020-10-14T14:21:55.72'),
    'notificationStatus': NotificationStatus.Unread,
    'createdDate': new Date('2020-10-13T14:21:55.72')
  }
];
