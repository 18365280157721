
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
// Service
import { WindowSizeService, WindowSize } from '@datamaster/cv.datamaster.ux';//./core/services/window-size.service
import {
    DataMasterFileService,
    DataMasterApiService,
    ConfirmDialogService,
    UserStateService,
    SpinnerService,
    SpinnerOverlayRef,
    ThemeService,
    AuthService
} from '@datamaster/core';
// State
import { Store } from '@ngxs/store';
import { RefreshUserData, UserStateModel, AppStateModel } from '@datamaster/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '(window:resize)': 'onResize($event)',
        'window:beforeunload': 'onExit',
    },
})
export class AppComponent implements OnInit, OnDestroy {
    userState$: Observable<UserStateModel> = this.store.select(state => state.user);
    configLoaded$: Observable<AppStateModel> = this.store.select(state => state.app.appName);

    isLoaded = false;

    title = 'DataMaster';
    currentTheme: string = this.themeService.startupTheme;

    private _subscription: Subscription = new Subscription();
    private spinner: SpinnerOverlayRef;

    constructor(
        private windowSizeService: WindowSizeService,
        private themeService: ThemeService,
        private dataMasterFileService: DataMasterFileService,
        private dataMasterApiService: DataMasterApiService,
        private confirmDialogService: ConfirmDialogService,
        private userStateService: UserStateService,
        private authService: AuthService,
        private router: Router,
        private spinnerService: SpinnerService,
        private store: Store
    ) {
        this.windowSizeService.setWindowSize(<WindowSize>{
            height: window.innerHeight,
            width: window.innerWidth,
        });

        this._subscription.add(
            this.userState$.subscribe(user => {
                if (user.userId === null) {
                    this.router.navigate['unathorized'];
                }
            })
        );

        this._subscription.add(
            this.configLoaded$.subscribe(appName => {
                this.isLoaded = true;
            })
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    ngOnInit() {
        this._subscription.add(
            this.router.events.subscribe((ev) => {
                if (ev instanceof NavigationStart) {
                    this.spinner = this.spinnerService.open();
                }
                if (
                    ev instanceof NavigationEnd ||
                    ev instanceof NavigationCancel ||
                    ev instanceof NavigationError
                ) {
                    this.spinner.close();
                }
            })
        );

        this._subscription.add(
            this.themeService.currentTheme.subscribe((val) => {
                this.currentTheme = val;
            })
        );

    }

    onResize(event) {
        this.windowSizeService.setWindowSize(<WindowSize>{
            height: event.target.innerHeight,
            width: event.target.innerWidth,
        });
    }

    @HostListener('window:beforeunload')
    onExit() {
        console.log('Exit');

        if (sessionStorage.getItem('callbackUrl')) {
            return true;
        }

        if (this.doesSessionNeedToBeSaved()) {
            this.dataMasterApiService.saveSession(this.dataMasterFileService.dataMasterFile).subscribe();
            return false;
        }

        return true;
    }

    doesSessionNeedToBeSaved(): boolean {
        //add is auto generated flag to distinguish what feature set created the datamasterfile. BPO and PSA does not need this
        if (!this.dataMasterFileService?.dataMasterFile) {
            return false;
        }
        if (
            this.dataMasterFileService.dataMasterFile.appraisal.comparables.length > 0 ||
            this.dataMasterFileService.dataMasterFile.appraisal.subject ||
            this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.length > 0
        ) {
            return true;
        }
        return false;
    }
}
