import { Component, OnInit } from '@angular/core';
import { AddressSearchComponent } from '../address-search/address-search.component';

@Component({
  selector: 'app-dialog-address-search',
  templateUrl: './dialog-address-search.component.html',
  styleUrls: [
    './dialog-address-search.component.scss'
  ]
})
export class DialogAddressSearchComponent extends AddressSearchComponent {

}
