import { SearchCriteriaUserData } from '../operation/property-search/search-criteria.model';

export class ProcessImportFileRequest {
  fileReaderResult: any;

  userData: SearchCriteriaUserData;
  constructor(fileReaderResult: any, userData: SearchCriteriaUserData) {
    this.fileReaderResult = fileReaderResult;
    this.userData = userData;
  }
}
