import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { stringify } from 'querystring';
import { Subscription } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListOption, MatSelectionList, } from '@angular/material/list';
import { DataMasterFileService,
  MarketAnalysisReportService,
  DataMasterApiService,
  CommentBlock,
  SaveCommentBlockRequest,
  CommentSectionType, 
  FillCommentTextRequest,
  TextInputDialogComponent,
  TextInputDialogService,
  DialogHeaderComponent,
  ListItem,
  PropertyUsageType,
  DefaultUserCommentBlock
  } from '@datamaster/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-mc-comment-edit',
  templateUrl: './mc-comment-edit.component.html',
  styleUrls: ['./mc-comment-edit.component.scss'],
})
export class McCommentEditComponent implements OnInit {
  @ViewChild('commentTextArea') commentTextArea : ElementRef;
  @ViewChild('dataTemplatesList') dataTemplatesList : MatSelectionList;
  @ViewChild('noDataTemplatesList') noDataTemplatesList : MatSelectionList;
  @ViewChild('noDataCommentTextArea') noDataCommentTextArea : ElementRef;

  @Output() commentChanged = new EventEmitter<boolean>();
  
  propertyUsageType = PropertyUsageType;
  CommentSectionType: CommentSectionType;
  _requestCommentSubscription: Subscription;
  private _noDataComment: CommentBlock = null;
  private _dataComment: CommentBlock = null;

  private _noDataPropertyUsageType: PropertyUsageType = PropertyUsageType.MarketConditions;
  public get noDataPropertyUsageType(): PropertyUsageType {
    return this._noDataPropertyUsageType;
  }
  public set noDataPropertyUsageType(value: PropertyUsageType) {
    this._noDataPropertyUsageType = value;
  }
  private _dataPropertyUsageType: PropertyUsageType = PropertyUsageType.MarketConditions;
  selectedCalculatedTagNoData: ListItem;
  public get dataPropertyUsageType(): PropertyUsageType {
    return this._dataPropertyUsageType;
  }
  public set dataPropertyUsageType(value: PropertyUsageType) {
    this._dataPropertyUsageType = value;
  }
  public get NoDataComment(): CommentBlock {
    return this._noDataComment;
  }
  public set NoDataComment(value: CommentBlock) {
    this._noDataComment = value;
    if(this._noDataComment){
      this.isDeleteNoDataCommentEnabled = this._noDataComment.isUserCreated;
      this.isSaveNoDataCommentEnabled = this._noDataComment.isUserCreated;
      this.isDuplicateSelectedNoDataCommentEnabled = true;
      this.isRenameSelectedNoDataCommentEnabled = this._noDataComment.isUserCreated;
      this.isUseNoDataTemplateEnabled = true;
    }else{
      this.isDeleteNoDataCommentEnabled = false;
      this.isSaveNoDataCommentEnabled = false;
      this.isDuplicateSelectedNoDataCommentEnabled = false;
      this.isRenameSelectedNoDataCommentEnabled = false;
      this.isUseNoDataTemplateEnabled = false;
    }
  }
  public get DataComment(): CommentBlock {
    return this._dataComment;
  }
  public set DataComment(value: CommentBlock) {
    this._dataComment = value;
    if(this._dataComment){
      this.isDeleteDataCommentEnabled = this._dataComment.isUserCreated;
      this.isSaveDataCommentEnabled = this._dataComment.isUserCreated;
      this.isDuplicateSelectedDataCommentEnabled = true;
      this.isRenameSelectedDataCommentEnabled = this._dataComment.isUserCreated;
      this.isUseDataTemplateEnabled = true;
    } else{
      this.isDeleteDataCommentEnabled =  false;
      this.isSaveDataCommentEnabled = false;
      this.isDuplicateSelectedDataCommentEnabled = false;
      this.isRenameSelectedDataCommentEnabled = false;
      this.isUseDataTemplateEnabled = false;
    }
  }
  public get DataCommentText(): string{
    if(!this._dataComment)
      return '';
    return this._dataComment.text;
  }
  public set DataCommentText(value : string){
    if(!this._dataComment)
      return;
      this._dataComment.text = value;
  }
  public get NoDataCommentText(): string{
    if(!this._noDataComment)
      return '';
    return this._noDataComment.text;
  }
  public set NoDataCommentText(value : string){
    if(!this._noDataComment)
      return;
      this._noDataComment.text = value;
  }
  selectedCalculatedTag: ListItem;
  calculatedCommentList: ListItem[];
  subjectCalculatedTagsList: ListItem[];
  NoDataActiveCalculatedTagList : ListItem[];
  DataActiveCalculatedTagList : ListItem[];
  DataCommentBlockList: CommentBlock[];
  NoDataCommentBlockList: CommentBlock[];
  private _commentBlockListSubscription: Subscription;
  CalculatedTags: string[];
  isAddDataCommentEnabled : boolean;
  isDeleteDataCommentEnabled : boolean;
  isSaveDataCommentEnabled : boolean;
  isDuplicateSelectedDataCommentEnabled : boolean;
  isRenameSelectedDataCommentEnabled : boolean;
  isAddCalculatedTagEnabled: boolean;
  isUseDataTemplateEnabled : boolean;

  isAddNoDataCommentEnabled : boolean;
  isDeleteNoDataCommentEnabled : boolean;
  isDuplicateSelectedNoDataCommentEnabled : boolean;
  isRenameSelectedNoDataCommentEnabled : boolean;
  isAddCalculatedTagNoDataEnabled : boolean;
  isUseNoDataTemplateEnabled : boolean;
  isSaveNoDataCommentEnabled : boolean;
  constructor(
    private dialog: MatDialog,
    private dataMasterFileService: DataMasterFileService,
    private maReportService: MarketAnalysisReportService,
    private datamasterApi: DataMasterApiService,
    private textInputDialogService: TextInputDialogService,
    @Inject(MAT_DIALOG_DATA) public data: {
      commentSectionType: CommentSectionType
  },
  ) {
    this.CommentSectionType = data.commentSectionType;
  }

  ngOnInit(): void {
    this.isAddDataCommentEnabled = true;
    this.isDeleteDataCommentEnabled = false;
    this.isSaveDataCommentEnabled = false;
    this.isDuplicateSelectedDataCommentEnabled = false;
    this.isRenameSelectedDataCommentEnabled = false;
    this.isUseDataTemplateEnabled = false;

    this.isAddCalculatedTagEnabled = false;

    this.isAddNoDataCommentEnabled = true;
    this.isDeleteNoDataCommentEnabled = false;
    this.isDuplicateSelectedNoDataCommentEnabled = false;
    this.isRenameSelectedNoDataCommentEnabled = false;
    this.isUseNoDataTemplateEnabled = false;
    this.isSaveNoDataCommentEnabled = false;
    this.isAddCalculatedTagNoDataEnabled = false;

    const calculatedCommentListSub = this.datamasterApi.getCalculatedCommentList().subscribe(list=>{
      calculatedCommentListSub.unsubscribe();
      this.calculatedCommentList = list;
      this.fillCalculatedTagsList(false);
      this.fillCalculatedTagsList(true);
    });
    const subjectCalculatedTagsListSub = this.datamasterApi.getSubjectCalculatedTagList().subscribe(list=>{
      subjectCalculatedTagsListSub.unsubscribe();
      this.subjectCalculatedTagsList = list;
      this.fillCalculatedTagsList(false);
      this.fillCalculatedTagsList(true);
    });

    this._requestCommentSubscription = this.maReportService
      .requestMCComments()
      .subscribe((mcComments) => {
        if (mcComments) {
          switch (this.CommentSectionType) {
            case CommentSectionType.McSellerConcessions:
              this.DataComment = mcComments.mcSellerConcessions;
              this.NoDataComment = mcComments.noDataMcSellerConcessions;
              break;
            case CommentSectionType.McReoSales:
              this.DataComment = mcComments.mcReoSales;
              this.NoDataComment = mcComments.noDataMcReoSales;
              break;
            case CommentSectionType.McCiteDataSources:
              this.DataComment = mcComments.mcCiteDataSources;
              break;
            case CommentSectionType.McSummary:
              this.DataComment = mcComments.mcSummary;
              break;
            case CommentSectionType.NeighborhoodAnalysisSummary:
              this.DataComment = mcComments.neighborhoodAnalysisSummary;
              break;
            case CommentSectionType.ProReoSales:
              this.DataComment = mcComments.proReoSales;
              this.NoDataComment = mcComments.noDataProReoSales;
              break;
            case CommentSectionType.ProSummary:
              this.DataComment = mcComments.proSummary;
              this.NoDataComment = mcComments.noDataProSummary;
              break;
            case CommentSectionType.DaAppraiserComments:
              this.DataComment = mcComments.daAppraiserComments;
              this.NoDataComment = mcComments.noDataDaAppraiserComments;
              break;
          }  
        this.highlightSelectedListItems();
        }
      });
      this.getSectionCommentBlocks();
  }
  fillCalculatedTagsList(isNoDataComment : boolean): void {
    const propertyUsageType : PropertyUsageType = isNoDataComment ? this.noDataPropertyUsageType : this.dataPropertyUsageType;
    let calculatedTagList :ListItem[];
    switch (propertyUsageType) {
      case PropertyUsageType.Subject:
        calculatedTagList = this.subjectCalculatedTagsList;
          break;
      case PropertyUsageType.NeighborhoodAnalysis:
        calculatedTagList = this.calculatedCommentList;
          break;
      case PropertyUsageType.MarketConditions:
      default:
        calculatedTagList = this.calculatedCommentList;
          break;
  }
  if(isNoDataComment)
    this.NoDataActiveCalculatedTagList = calculatedTagList;
  else
    this.DataActiveCalculatedTagList = calculatedTagList;
  }

  ngOnDestroy(): void {
    this._requestCommentSubscription.unsubscribe();
    this._commentBlockListSubscription.unsubscribe();
  }
  getSectionCommentBlocks(): void {
    this._commentBlockListSubscription = this.maReportService.getCommentsSectionComments(this.CommentSectionType).subscribe(commentBlocks =>
       this.fillCommentBlockLists(commentBlocks));
  }
  fillCommentBlockLists(commentBlocks: CommentBlock[]): void {
    if(!commentBlocks)
      return;
      this.NoDataCommentBlockList = commentBlocks.filter(cb => cb.isNoDataComment);
      this.DataCommentBlockList = commentBlocks.filter(cb => !cb.isNoDataComment);
      this.highlightSelectedListItems();
  }
  highlightSelectedListItems() {
    if(this.DataComment && this.DataCommentBlockList){
      const selectedDataCommentBlock = this.getListItemToHighlight(this.DataComment, this.DataCommentBlockList, this.dataTemplatesList);
      if(selectedDataCommentBlock)
        this.DataComment = selectedDataCommentBlock;
    }
    if(this.NoDataComment && this.NoDataCommentBlockList){
      const selectedNoDataCommentBlock= this.getListItemToHighlight(this.NoDataComment, this.NoDataCommentBlockList, this.noDataTemplatesList);
      if(selectedNoDataCommentBlock)
        this.NoDataComment = selectedNoDataCommentBlock;

    }
  }
  getListItemToHighlight(commentBlock: CommentBlock, commentBlockList: CommentBlock[], uiList: MatSelectionList) : CommentBlock {
    let selectedCommentBlock = commentBlockList.find(cb => cb.id == commentBlock.id);
    if(selectedCommentBlock)
      return selectedCommentBlock;
    selectedCommentBlock = commentBlockList.find(cb => cb.commonName == commentBlock.commonName);
    if(selectedCommentBlock)
      return selectedCommentBlock;
    selectedCommentBlock = commentBlockList.find(cb => cb.displayName == commentBlock.displayName);
    if(selectedCommentBlock)
      return selectedCommentBlock;
    return null;
  }
  noDataCommentListOptionClicked(commentBlock : CommentBlock) : void{
    if(!commentBlock) return;
    this.NoDataComment = commentBlock;
  }
  dataCommentListOptionClicked(commentBlock : CommentBlock) : void{
    if(!commentBlock) return;
    this.DataComment = commentBlock;
  }

  insertCalculatedTag(isNoDataComment : boolean): void {

    if(isNoDataComment ? !this.selectedCalculatedTagNoData : !this.selectedCalculatedTag) {
      //TODO:Display 'No Calculated Tag Selected'
      return;
    }

    let cursorPosition = isNoDataComment ? this.noDataCommentTextArea.nativeElement.selectionStart : this.commentTextArea.nativeElement.selectionStart;
    if(!cursorPosition)
      cursorPosition = isNoDataComment ? this.noDataCommentTextArea.nativeElement.textLength : this.commentTextArea.nativeElement.textLength;

    const propertyUsageType = isNoDataComment ? this.noDataPropertyUsageType : this.dataPropertyUsageType;
    const insertValue = this.codifyCommentElement(isNoDataComment ? this.selectedCalculatedTagNoData.name : this.selectedCalculatedTag.name, propertyUsageType);

    const oldText = isNoDataComment ? this.NoDataComment.text : this.DataComment.text;
    const newtext = oldText.substring(0, cursorPosition) + insertValue + oldText.substring(cursorPosition);
    if(isNoDataComment)
      this.NoDataComment.text = newtext;
    else
      this.DataComment.text = newtext;
    
  }
  calculatedTagClickedData(calculatedTag : ListItem) : void{
    this.selectedCalculatedTag = calculatedTag;
    this.isAddCalculatedTagEnabled = calculatedTag != null;
  }
  calculatedTagClickedNoData(calculatedTag : ListItem) : void{
    this.selectedCalculatedTagNoData = calculatedTag;
    this.isAddCalculatedTagNoDataEnabled = calculatedTag != null;
  }
  duplicateSelectedComment(isNoDataComment : boolean) : void {
    const duplicateBlock : CommentBlock = CommentBlock.duplicate(isNoDataComment? this.NoDataComment : this.DataComment);
    duplicateBlock.commonName = duplicateBlock.commonName+'_copy';
    duplicateBlock.displayName = duplicateBlock.displayName+'_copy';
    duplicateBlock.isUserCreated = true;
    const sub = this.maReportService.saveUserCommentBlock(this.CommentSectionType, duplicateBlock).subscribe(x => {
      this.getSectionCommentBlocks();
      sub.unsubscribe();
});
  }
  renameSelectedCommentClicked(isNoDataComment : boolean) : void {
    this.renameSelectedComment(isNoDataComment? this.NoDataComment : this.DataComment);
  }
  renameSelectedComment(comment: CommentBlock) {
    const selectedName = comment.displayName;
    const options = {
      title: 'Rename Template',
      message:
        '',
      cancelText: 'Cancel',
      confirmText: 'Confirm',
      value: selectedName
    };
    this.textInputDialogService.open(options);

    const sub = this.textInputDialogService.confirmed().subscribe(res => {
      sub.unsubscribe();
      if (res.isConfirmed) {
        comment.displayName = res.value;
        comment.commonName = res.value.replace(' ', '');
        const upSub = this.maReportService.updateUserCommentBlock(this.CommentSectionType, comment).subscribe(x => {
          upSub.unsubscribe();
          this.getSectionCommentBlocks();
        });
      }
});
  }
  useTemplate(isNoDataComment : boolean) : void {
    const selectedComment = this.getSelectedComment(isNoDataComment);
    this.maReportService.setComment(this.CommentSectionType, isNoDataComment, selectedComment);//might need to implement an await here
    const updateSub = this.maReportService.updateDefaultUserCommentBlock(this.CommentSectionType, selectedComment.id, isNoDataComment).subscribe(defaultCommentBlock =>{
      updateSub.unsubscribe();
      this.commentChanged.emit(true);
    });
  }
  addNewComment(isNoDataComment : boolean) : void {
    const newCommentBlock = new CommentBlock();
    newCommentBlock.isUserCreated = true;
    newCommentBlock.isNoDataComment = isNoDataComment;

    const options = {
      title: 'Name New Template',
      message:
        '',
      cancelText: 'Cancel',
      confirmText: 'Confirm',
      value: ''
    };
    this.textInputDialogService.open(options);

    const diagSub = this.textInputDialogService.confirmed().subscribe(res => {
      diagSub.unsubscribe();
        if (res.isConfirmed) {
          newCommentBlock.commonName = res.value;
          newCommentBlock.displayName = res.value;
          const sub = this.maReportService.saveUserCommentBlock(this.CommentSectionType, newCommentBlock).subscribe(x => {
            this.getSectionCommentBlocks();
            sub.unsubscribe();
      });
        }
      });
  }
  deleteSelectedComment(isNoDataComment : boolean) : void {
    const selectedComment = this.getSelectedComment(isNoDataComment);
    if(!selectedComment)
      return;
    const sub = this.maReportService.deleteUserCommentBlock(this.CommentSectionType, selectedComment).subscribe(x => {
      this.getSectionCommentBlocks();//maybe just delete locally from list and not getSectionCommentBlocks again?
      sub.unsubscribe();
});
  }
    
  saveSelectedComment(isNoDataComment : boolean) : void {
    const selectedComment = this.getSelectedComment(isNoDataComment);
    const upSub = this.maReportService.updateUserCommentBlock(this.CommentSectionType, selectedComment).subscribe(x => {
      upSub.unsubscribe();
      this.getSectionCommentBlocks();
    });
  }
  getSelectedComment(isNoDataComment: boolean) {
    return isNoDataComment ? this.NoDataComment : this.DataComment;
  }
  codifyCommentElement(preCodifiedString: string, propertyUsageType : PropertyUsageType) : string{
      let propertyUsageTypeAbbreviatedPrefix = '';

      switch (propertyUsageType) {
          case PropertyUsageType.Subject:
              propertyUsageTypeAbbreviatedPrefix = 'SUB_';
              break;
          case PropertyUsageType.NeighborhoodAnalysis:                    
              propertyUsageTypeAbbreviatedPrefix = 'NBH_';
              break;
          case PropertyUsageType.MarketConditions:
          default:
              propertyUsageTypeAbbreviatedPrefix = 'MC_';
              break;
      }
      return '[' + propertyUsageTypeAbbreviatedPrefix + preCodifiedString + ']';
  }
  
  close() : void{
    this.commentChanged.emit(false);
  }
}