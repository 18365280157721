import { FileDetail } from './file-detail.model';
import { AppraisalDetail } from './appraisal-detail.model';
import { Subject } from './subject.model';
import { Comparable } from './comparable.model';
import { AppraiserInformation } from './appraiserInformation.model';
import { RawProperty } from './raw-property.model';
import { StandardizedParcel } from './standardized-parcel.model';
import { PropertyPhoto } from './property-photo.model';
import { MarketCondition } from './market-condition.model';
import { CommentBlockBase } from './comment-block-base.model';
import { DataMasterFileService } from '../../services/datamaster-file.service';
import { PropertyUsageType } from './enum/property-usage-type.enum';

export class Appraisal {
  fileDetail: FileDetail;
  appraisalDetail: AppraisalDetail;
  subject: Subject;
  comparables: Comparable[];
  marketConditions: MarketCondition[];
  marketConditionsFormattedFieldResults: Map<number, string>; // { [index: number]: string };
  rawProperties: RawProperty[];
  translatedParcels: StandardizedParcel[];
  prioritizedParcels: StandardizedParcel[];
  listingHistoryParcels: StandardizedParcel[];
  propertyPhotos: PropertyPhoto[];
  appraisalComments: { [index: string]: CommentBlockBase[] };
  userSelectedDataSourceID: number;
  appraiserAuthId: number;
  appraiserName: string;
  appraiserInformation: AppraiserInformation;
  includeDataMasterStandardAddendaInReport: boolean;
  includeDataMasterStandardAddendaInFormSoftwareWorkFile: boolean;
  applyNeighborhoodSectionToMarketConditions: boolean;
  applyNeighborhoodSectionToNeighborhoodGreaterMarket: boolean;

  constructor() {
    this.rawProperties = [];
    this.translatedParcels = [];
    this.prioritizedParcels = [];
    this.comparables = [];
    this.subject = new Subject();
    this.fileDetail = new FileDetail();
    this.appraisalDetail = new AppraisalDetail();
    this.marketConditions = [];
    this.listingHistoryParcels = [];
    this.marketConditionsFormattedFieldResults = new Map<number, string>();
  }

  //This is to avoid circular reference when flattening the object, also dataMasterFileService doesn't need to be stringified, it is just used in functions
  //Override of built in function
  toJSON = function (this) {
    const result = {};
    for (const x in this) {
      if (x !== "dataMasterFileService") {
        result[x] = this[x];
      }
    }
    return result;
  };

  get HasMcProperties(): boolean {
    return (
      this.marketConditions?.find(
        (a) => a.propertyUseType === PropertyUsageType.MarketConditions
      ) != null
    );
  }
  get HasNeighborhoodAnalysisMcProperty(): boolean {
    return (
      this.marketConditions?.find(
        (a) => a.propertyUseType === PropertyUsageType.NeighborhoodAnalysis
      ) != null
    );
  }

  get HasProjecMcProperty(): boolean {
    return (
      this.marketConditions?.find(
        (a) => a.propertyUseType === PropertyUsageType.ProjectMarketConditions
      ) != null
    );
  }
}
