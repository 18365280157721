import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { NewsComponent } from '../../common/news/news.component';
import { WindowSizeService } from '../../../services/window-size.service';
import { ScreenSize } from '../../../enums/screen-size';
import { WindowSize } from '../../../models/window-size.model';

@Component({
  selector: 'app-home-alt',
  templateUrl: './home-alt.component.html',
  styleUrls: ['./home-alt.component.scss']
})
export class HomeAltComponent {

  @ViewChild('news', { static: true }) newsChild: NewsComponent;
  newsToggleStateClass: string;
  private window: WindowSize;
  private screenSize: ScreenSize;
  private screenSizeName: string;

  constructor(private windowSizeService: WindowSizeService) { }

  onToggleNews(event: string) {
    this.newsToggleStateClass = this.newsToggleStateClass === 'news-toggled' ? '' : 'news-toggled';
  }

  //getEnumKeyByEnumValue(enumValue: number): string {
  //  let keys = Object.keys(ScreenSize).filter(x => ScreenSize[x] === enumValue);
  //  return keys.length > 0 ? keys[0] : '';
  //}

  //onAddressResults(event: PropertySearchResult[]) {
  //  //this.clearSearchResults();

  //  this.searchResults = event;
  //  this.showResults()  // show the modal with the results
  //  this.searchType = PropertySearchType.PublicRecords;
  //  this.TEMPsearchSource = "CoreLogic";
  //}

}
