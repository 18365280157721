<div class="subject-section-container">
  <div *ngIf="showSummarySection"  class="subject-container">
          <table class="sub-table neighborhood">
            <tr>
              <td colspan="4" class="with-sub-title">Neighborhood Characteristics</td>
            </tr>
            <tr class="flex-row">
              <ng-container>
              <td class="row-label">Location</td>
              <td class="radio">
                <label>
                  <input [(ngModel)]="neighborhoodLocation_Urban" type="radio" class="radio" name="neighborhoodLocation" value="Urban"
                  [checked]="neighborhoodLocation_Urban">
                  Urban
                </label>
              </td>
              <td class="radio">
                <label>
                  <input [(ngModel)]="neighborhoodLocation_Suburban" type="radio" class="radio" name="neighborhoodLocation" value="Suburban"
                  [checked]="neighborhoodLocation_Suburban">
                  Suburban
                </label>
              </td>
              <td class="radio">
                <label>
                  <input [(ngModel)]="neighborhoodLocation_Rural" type="radio" class="radio" name="neighborhoodLocation" value="Rural"
                  [checked]="neighborhoodLocation_Rural">
                  Rural
                </label>
              </td>
              </ng-container>
            </tr>
            <tr class="flex-row">
              <ng-container>
                <td class="row-label">Built-Up</td>
                <td class="radio">
                  <label>
                    <input [(ngModel)]="neighborhoodBuiltUp_PercentOver75" type="radio" class="radio" name="neighborhoodBuiltUp" value="Over75Percent"
                    [checked]="neighborhoodBuiltUp_PercentOver75">
                    Over 75%
                  </label>
                </td>
                <td class="radio">
                  <label>
                    <input [(ngModel)]="neighborhoodBuiltUp_Percent25To75" type="radio" class="radio" name="neighborhoodBuiltUp" value="25to75Percent"
                    [checked]="neighborhoodBuiltUp_Percent25To75">
                    25-75%
                  </label>
                </td>
                <td class="radio">
                  <label>
                    <input [(ngModel)]="neighborhoodBuiltUp_PercentUnder25" type="radio" class="radio" name="neighborhoodBuiltUp" value="Under25Percent"
                    [checked]="neighborhoodBuiltUp_PercentUnder25">
                    Under 25%
                  </label>
                </td>
              </ng-container>
            </tr>
            <tr class="flex-row">
              <ng-container>
                <td class="row-label">Growth</td>
                <td class="radio">
                  <label>
                    <input [(ngModel)]="neighborhoodGrowth_Rapid" type="radio" class="radio" name="neighborhoodGrowth" value="Rapid"
                    [checked]="neighborhoodGrowth_Rapid">
                    Rapid
                  </label>
                </td>
                <td class="radio">
                  <label>
                    <input [(ngModel)]="neighborhoodGrowth_Stable" type="radio" class="radio" name="neighborhoodGrowth" value="Stable"
                    [checked]="neighborhoodGrowth_Stable">
                    Stable
                  </label>
                </td>
                <td class="radio">
                  <label>
                    <input [(ngModel)]="neighborhoodGrowth_Slow" type="radio" class="radio" name="neighborhoodGrowth" value="Slow"
                    [checked]="neighborhoodGrowth_Slow">
                    Slow
                  </label>
                </td>
              </ng-container>
            </tr>
          </table>
          <table class="sub-table one-unit">
            <tr>
              <td colspan="4" class="with-sub-title">One-Unit Housing Trends</td>
            </tr>
          <tr class="flex-row">
          <ng-container>
            <td class="row-label">Property Values</td>
            <td class="radio">
              <label>
                <input [(ngModel)]="propertyValues_Increasing" type="radio" class="radio" name="propertyValues" value="Increasing"
                [checked]="propertyValues_Increasing">
                Increasing
              </label>
            </td>
            <td class="radio">
              <label>
                <input [(ngModel)]="propertyValues_Stable" type="radio" class="radio" name="propertyValues" value="Stable"
                [checked]="propertyValues_Stable">
                Stable
              </label>
            </td>
            <td class="radio">
              <label>
                <input [(ngModel)]="propertyValues_Declining" type="radio" class="radio" name="propertyValues" value="Declining"
                [checked]="propertyValues_Declining">
                Declining
              </label>
            </td>
          </ng-container>
          </tr>
          <tr class="flex-row">
          <ng-container>
            <td class="row-label">Demand/Supply</td>
            <td class="radio">
              <label>
                <input [(ngModel)]="demandSupply_Shortage" type="radio" class="radio" name="demandSupply" value="Shortage"
                [checked]="demandSupply_Shortage">
                Shortage
              </label>
            </td>
            <td class="radio">
              <label>
                <input [(ngModel)]="demandSupply_InBalance" type="radio" class="radio" name="demandSupply" value="InBalance"
                [checked]="demandSupply_InBalance">
                In Balance
              </label>
            </td>
            <td class="radio">
              <label>
                <input [(ngModel)]="demandSupply_OverSupply" type="radio" class="radio" name="demandSupply" value="OverSupply"
                [checked]="demandSupply_OverSupply">
                Over Supply
              </label>
            </td>
          </ng-container>
          </tr>
          <tr class="flex-row">
          <ng-container>
            <td class="row-label">Marketing Time</td>
            <td class="radio">
              <label>
                <input [(ngModel)]="marketingTime_MonthUnder3" type="radio" class="radio" name="marketingTime" value="MonthUnder3"
                [checked]="marketingTime_MonthUnder3">
                Under 3 mths
              </label>
            </td>
            <td class="radio">
              <label>
                <input [(ngModel)]="marketingTime_Month3To6" type="radio" class="radio" name="marketingTime" value="Month3To6"
                [checked]="marketingTime_Month3To6">
                3-6 mths
              </label>
            </td>
            <td class="radio">
              <label>
                <input [(ngModel)]="marketingTime_MonthOver6" type="radio" class="radio" name="marketingTime" value="MonthOver6"
                [checked]="marketingTime_MonthOver6">
                Over 6 mths
              </label>
            </td>
          </ng-container>
          </tr>
        </table>
          <table class="sub-table housing">
            <tr> 
              <td colspan="3" class="with-sub-title">
                One-Unit Housing
              </td>
            </tr>
            <tr class="flex-row sub-title">
              <td>
                <label>Price</label>
              </td>
              <td><span></span></td>
              <td >
                <label>Age</label>
              </td>
            </tr>
            <tr class="flex-row sub-title">
              <td>
                <label>($000)</label>
              </td>
              <td><span></span></td>
              <td>
                <label>(yrs)</label>
              </td>
            </tr>
            <tr class="flex-row">
              <ng-container>
              <td>
                <input [(ngModel)]="oneUnitLowPrice" type="text" class="form-control" name="oneUnitLowPrice" [value]="oneUnitLowPrice" />
              </td>
              <td>
                <label>Low</label>
              </td>
              <td class="">
                <input [(ngModel)]="oneUnitLowAge" type="text" class="form-control" name="oneUnitLowAge" [value]="oneUnitLowAge" />
              </td>
              </ng-container>
            </tr>
            <tr class="flex-row">
            <ng-container>
              <td class="">
                <input [(ngModel)]="oneUnitHighPrice" type="text" class="form-control" name="oneUnitHighPrice" [value]="oneUnitHighPrice" />
              </td>
              <td class="">
                <label>High</label>
              </td>
              <td class="">
                <input [(ngModel)]="oneUnitHighAge" type="text" class="form-control" name="oneUnitHighAge" [value]="oneUnitHighAge" />
              </td>
            </ng-container>
            </tr>
            <tr class="flex-row">
            <ng-container>
              <td class="">
                <input [(ngModel)]="oneUnitPredPrice" type="text" class="form-control" name="oneUnitPredPrice" [value]="oneUnitPredPrice" />
              </td>
              <td class="">
                <label>Pred.</label>
              </td>
              <td class="">
                <input [(ngModel)]="oneUnitPredAge" type="text" class="form-control" name="oneUnitPredAge" [value]="oneUnitPredAge" />
              </td>
            </ng-container>
            </tr>
          </table>
          <table class="sub-table land-use">
            <tr class="with-sub-title">
              <td colspan="2" class="with-sub-title">
                Present Land Use %
              </td>
            </tr>
            <tr class="flex-row ">
              <td class="sub-title">
                <label>One-Unit</label>
              </td>
              <td>
                <label>
                  <input type="text" class="form-control" name="oneUnitPercentage" [value]="oneUnitPercentage" />%</label>
              </td>
            </tr>
            <tr class="flex-row ">
              <td class="sub-title">
                <label>2-4 Unit</label>
              </td>
              <td class="">
                <input type="text" class="form-control" name="twoToFourUnitPercentage" [value]="twoToFourUnitPercentage" />%
              </td>
            </tr>
            <tr class="flex-row ">
              <td class=" sub-title">
                <label>Multi-Family</label>
              </td>
              <td class="">
                <input type="text" class="form-control" name="multiFamilyPercentage" [value]="multiFamilyPercentage" />
                <label>%</label>
              </td>
            </tr>
            <tr class="flex-row ">
              <td class="sub-title">
                <label>Commercial</label>
              </td>
              <td class="">
                <input type="text" class="form-control" name="commercialPercentage" [value]="commercialPercentage" />
                <label>%</label>
              </td>
            </tr>
            <tr class="flex-row ">
              <td class="sub-title">
                <label>Other</label>
              </td>
              <td class="">
                <input type="text" class="form-control" name="otherVacantPercentage" [value]="otherVacantPercentage" />
                 <label>%</label>
              </td>
            </tr>
          </table>
          <div class="nb-row">
            <div >
              <label>Neighborhood Boundaries</label>
            </div>
            <div>
              <textarea class="form-control" name="neighborhoodBoundaries"
                [value]="neighborhoodBoundaries"></textarea>
            </div>
          </div> 
          <div class="nd-row">
            <div class="">
              <label>Neighborhood Description</label>
            </div>
            <div class="mc-text">
              <textarea class="form-control" name="neighborhoodDescription"
                [value]="neighborhoodDescription"></textarea>
            </div>
          </div>
          <div class="mc-row">
            <div class="">
              <label>Market Conditions</label>
            </div>
            <div class="mc-text">
              <textarea class="form-control" name="marketConditions" [value]="marketConditions"></textarea>
            </div>
          </div> 
  </div>
  
</div>

