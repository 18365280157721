<table class="table table-bordered">

    <tr class="header-row" >
        <th class="darkbackground" colspan="5">Annual Comparison/Summary</th>
    </tr>

    <tr class="">
        <th class="">Median</th>
        <th class="" colspan="2">Competing Market</th>
        <th class="" colspan="2">Neighborhood</th>
    </tr>

    <tr class="">
        <th class=" darkbackground"></th>
        <th class=" darkbackground daterangelabel"><div >13-24 Months <div class="daterange">{{EffectiveDatePreviousYearSpan}}</div></div></th>
        <th class=" darkbackground daterangelabel"><div >0-12 Months <div class="daterange">{{EffectiveDateYearSpan}}</div></div></th>
        <th class=" darkbackground daterangelabel"><div >13-24 Months <div class="daterange">{{EffectiveDatePreviousYearSpan}}</div></div></th>
        <th class=" darkbackground daterangelabel"><div >0-12 Months <div class="daterange">{{EffectiveDateYearSpan}}</div></div></th>
    </tr>
    
    
    <div *ngTemplateOutlet="dataRow; context:{name:'# of Sales', cmMedian1:CompetingMarketForm?._13_24MO?.totalSales, cmMedian2:CompetingMarketForm?._0_12MO?.totalSales, neighborhoodMedian1: NeighborHoodAnalysisForm?._13_24MO?.totalSales, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.totalSales}"></div>
    <div *ngTemplateOutlet="dataRow; context:{name:'Sale Price', cmMedian1:CompetingMarketForm?._13_24MO?.medianSalePriceCopy, cmMedian2:CompetingMarketForm?._0_12MO?.medianSalePriceCopy, neighborhoodMedian1: NeighborHoodAnalysisForm?._13_24MO?.medianSalePriceCopy, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianSalePriceCopy}"></div>
    <!-- <div *ngTemplateOutlet="dataRow; context:{name:'Sp/Sqft', cmMedian1:CompetingMarketForm?._13_24MO?.medianSalePricePerSqft, cmMedian2:CompetingMarketForm?._0_12MO?.medianSalePricePerSqft, neighborhoodMedian1: NeighborHoodAnalysisForm?._13_24MO?.medianSalePricePerSqft, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianSalePricePerSqft}"></div> -->
    <div *ngTemplateOutlet="dataRow; context:{name:'Sp/Sqft', cmMedian1:CompetingMarketForm?._13_24MO?.medianSalePricePerTotalBuildingSqft, cmMedian2:CompetingMarketForm?._0_12MO?.medianSalePricePerTotalBuildingSqft, neighborhoodMedian1:NeighborHoodAnalysisForm?._13_24MO?.medianSalePricePerTotalBuildingSqft , neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianSalePricePerTotalBuildingSqft}"></div>
    <div *ngTemplateOutlet="dataRow; context:{name:'DOM', cmMedian1:CompetingMarketForm?._13_24MO?.medianDOM, cmMedian2:CompetingMarketForm?._0_12MO?.medianDOM, neighborhoodMedian1: NeighborHoodAnalysisForm?._13_24MO?.medianDOM, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianDOM}"></div>
    <div *ngTemplateOutlet="dataRow; context:{name:'Lp/Sp Ratio', cmMedian1:CompetingMarketForm?._13_24MO?.medianSaleToListRatioCopy, cmMedian2:CompetingMarketForm?._0_12MO?.medianSaleToListRatioCopy, neighborhoodMedian1: NeighborHoodAnalysisForm?._13_24MO?.medianSaleToListRatioCopy, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianSaleToListRatioCopy}"></div>
    <div *ngTemplateOutlet="dataRow; context:{name:'GLA', cmMedian1:CompetingMarketForm?._13_24MO?.medianSqft, cmMedian2:CompetingMarketForm?._0_12MO?.medianSqft, neighborhoodMedian1: NeighborHoodAnalysisForm?._13_24MO?.medianSqft, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianSqft}"></div>
    <!-- <div *ngTemplateOutlet="dataRow; context:{name:'TBA', cmMedian1:CompetingMarketForm?._13_24MO?.medianTotalBuildingSqft, cmMedian2:CompetingMarketForm?._0_12MO?.medianTotalBuildingSqft, neighborhoodMedian1:NeighborHoodAnalysisForm?._13_24MO?.medianTotalBuildingSqft, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianTotalBuildingSqft}"></div> -->
    <div *ngTemplateOutlet="dataRow; context:{name:'Age', cmMedian1:CompetingMarketForm?._13_24MO?.medianAge, cmMedian2:CompetingMarketForm?._0_12MO?.medianAge, neighborhoodMedian1:NeighborHoodAnalysisForm?._13_24MO?.medianAge, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianAge}"></div>
    <div *ngTemplateOutlet="dataRow; context:{name:'Lot Size', cmMedian1:CompetingMarketForm?._13_24MO?.medianLotSize, cmMedian2:CompetingMarketForm?._0_12MO?.medianLotSize, neighborhoodMedian1:NeighborHoodAnalysisForm?._13_24MO?.medianLotSize, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianLotSize}"></div>
    <!-- <div *ngTemplateOutlet="dataRow; context:{name:'Bds (GLA)', cmMedian1:CompetingMarketForm?._13_24MO?.medianAboveGradeBedrooms, cmMedian2:CompetingMarketForm?._0_12MO?.medianAboveGradeBedrooms, neighborhoodMedian1:NeighborHoodAnalysisForm?._13_24MO?.medianAboveGradeBedrooms, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianAboveGradeBedrooms}"></div> -->
    <div *ngTemplateOutlet="dataRow; context:{name:'Total Bds', cmMedian1:CompetingMarketForm?._13_24MO?.medianTotalBedrooms, cmMedian2:CompetingMarketForm?._0_12MO?.medianTotalBedrooms, neighborhoodMedian1:NeighborHoodAnalysisForm?._13_24MO?.medianTotalBedrooms, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianTotalBedrooms}"></div>
    <!-- <div *ngTemplateOutlet="dataRow; context:{name:'Bths (GLA)', cmMedian1:CompetingMarketForm?._13_24MO?.medianAboveGradeBathrooms, cmMedian2:CompetingMarketForm?._0_12MO?.medianAboveGradeBathrooms, neighborhoodMedian1:NeighborHoodAnalysisForm?._13_24MO?.medianAboveGradeBathrooms, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianAboveGradeBathrooms}"></div> -->
    <div *ngTemplateOutlet="dataRow; context:{name:'Total Bths', cmMedian1:CompetingMarketForm?._13_24MO?.medianTotalBathrooms, cmMedian2:CompetingMarketForm?._0_12MO?.medianTotalBathrooms, neighborhoodMedian1:NeighborHoodAnalysisForm?._13_24MO?.medianTotalBathrooms, neighborhoodMedian2:NeighborHoodAnalysisForm?._0_12MO?.medianTotalBathrooms}"></div>
    
    
</table>

<!--TEMPLATES-->
<ng-template #dataRow let-name="name" 
 let-cmMedian1="cmMedian1"
 let-cmMedian2="cmMedian2"
 let-neighborhoodMedian1="neighborhoodMedian1"
 let-neighborhoodMedian2="neighborhoodMedian2">
    <tr class="">
        <th class="">{{name}}</th>
        <th class="">{{cmMedian1}}</th>
        <th class="">{{cmMedian2}}</th>
        <th class="">{{neighborhoodMedian1}}</th>
        <th class="">{{neighborhoodMedian2}}</th>
    </tr>
</ng-template>
