
export enum PropertyUsageType {
    Subject = 'Subject',
    Comparable = 'Comparable',
    ComparableRental = 'ComparableRental',
    Listing = 'Listing',
    Rental = 'Rental',
    ReoListing = 'ReoListing',
    MarketConditions = 'MarketConditions',
    ProjectMarketConditions = 'ProjectMarketConditions',
    NeighborhoodAnalysis = 'NeighborhoodAnalysis',
    ListingHistory = 'ListingHistory',
    Generic = 'Generic',
    Unassigned = 'Unassigned',
    PlatMap = 'PlatMap',
}

export const PropertyUsageTypeFromServer = {
    [PropertyUsageType.Subject]: 0,
    [PropertyUsageType.Comparable]: 1,
    [PropertyUsageType.ComparableRental]: 2,
    [PropertyUsageType.Listing]: 3,
    [PropertyUsageType.Rental]: 4,
    [PropertyUsageType.ReoListing]: 5,
    [PropertyUsageType.MarketConditions]: 6,
    [PropertyUsageType.ProjectMarketConditions]: 7,
    [PropertyUsageType.NeighborhoodAnalysis]: 8,
    [PropertyUsageType.ListingHistory]: 9,
    [PropertyUsageType.Generic]: 10,
    [PropertyUsageType.Unassigned]: 11,
    [PropertyUsageType.PlatMap]: 12
};
