import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerMessagingService {
  private _messageChangedSubject = new Subject<string>();
  //private _messageChangedSubject = new BehaviorSubject<string>(
  //"Loading. Please Wait.."
  //);
  messageChanged = this._messageChangedSubject.asObservable();

  constructor() {
    //
  }

  updateSpinnerMessage(message: string) {
    this._messageChangedSubject.next(message);
  }
}
