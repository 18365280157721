<div class="info-container">

    <div class="effective-date">
        
        <ng-template #t let-date>
            {{ date.day }}
        </ng-template>

        <label>Effective Date</label>
        <div class="input-group">
            <input type="text" class="date-picker form-control" placeholder="mm-dd-yyyy" name="effectiveDate" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" 
              (ngModelChange)="ngModelChangeEvent($event)"/>
            <div class="input-group-append">
                <span class="input-group-text calendar-button" (click)="d.toggle()">
                    <span class="fad fa-calendar-alt icon"></span>
                </span>
            </div>
            <div class="input-group-append">
                <span id="EffectiveDateReloading" class="d-none">
                    <span class="fa fa-spinner fa-2x fa-pulse"></span>
                </span>
            </div>
            <div class="input-group-append">
                <span class="today input-group-text" (click)="selectToday()">today</span>
            </div>
        </div>
        
    </div>

    <div class="appraiser">
        <label>Appraiser:</label>
        <span class="name">Data Man</span>
    </div>

    <a class="action-item" href="#" onclick="return false" title="Help">
        <span class="fal fa-question-circle"></span>
    </a>
</div>