import { PropertyUsageType } from './enum/property-usage-type.enum';

export class MarketCondition {
  propertyGuid: string; //GUID
  propertyUseType: PropertyUsageType;
  marketConditionsNotListedDate?: Date;
  zeroToThreeStatus: string;
  fourToSixStatus: string;
  sevenToTwelveStatus: string;
}
