import { Component, Input, OnInit } from '@angular/core';
import { DataMasterFileService, FormattingHelper, MarketConditionsForm, Parcel, Subject } from '@datamaster/core';

@Component({
  selector: 'app-search-criteria-summary',
  templateUrl: './search-criteria-summary.component.html',
  styleUrls: ['./search-criteria-summary.component.scss'],
})
export class SearchCriteriaSummaryComponent implements OnInit {
  @Input() CompetingMarketForm : MarketConditionsForm;
  @Input() NeighborHoodAnalysisForm : MarketConditionsForm;
  // get SubjectTotalBedrooms() : number {
  //   
  // }
  constructor(private dataMasterFileService : DataMasterFileService) {}

  ngOnInit(): void {}

  get SubjectSiteArea(){
    return this.getFormattedFieldResultFormattedValue('SiteArea');
  }
  get SubjectActualAge(){
    return this.getFormattedFieldResultFormattedValue('ActualAge');
  }
  get SubjectTotalAboveGradeBedrooms(){
    return this.getFormattedFieldResultFormattedValue('TotalAboveGradeBedrooms');
  }
  get SubjectAboveGradeGla(){
    return this.getFormattedFieldResultFormattedValue('AboveGradeGla');
  }
  
  get SubjectStyle() : string {
    const style = this.getFormattedFieldResultFormattedValue('Style');
    const designStyle = this.getFormattedFieldResultFormattedValue('DesignStyle');
    if (style && style !== '')
        return style;
    if (designStyle && designStyle !== '' && designStyle.includes(';'))
        return designStyle.split(';')[1];

    return designStyle;
  }
  getFormattedFieldResultFormattedValue(field: string): string {
    const subject = this.dataMasterFileService.dataMasterFile.appraisal.subject;
    if (!subject) return '';
    
    return FormattingHelper.getFormattedFieldResultFormattedValue(field, 'Subject', subject.formattedFieldResults, this.dataMasterFileService.formatFieldMaps);

  }
}
