import { Injectable } from '@angular/core';
// Services
import { DataMasterFileService } from '../datamaster-file.service';
import { UserStateService } from '../user/user-state.service';
import { MarketAnalysisReportService } from '../market-analysis-report.service';
// Models
import { SearchResult } from '../../models/operation/property-search/search-result.model';
import { RawProperty } from '../../models/workfile/raw-property.model';
import { FormattedField } from '../../models/workfile/formatted-field.model';
import { PropertyUsageType } from '../../models/workfile/enum/property-usage-type.enum';
import { FormattedDataSource } from '../../models/workfile/formatted-data-source.model';
import { FormattedPropertyResult } from '../../models/operation/formatting/formatted-property-result.model';
import { Comparable } from '../../models/workfile/comparable.model';
import { FormattedSectionType } from '../../models/operation/enum/formatted-section-type.enum';
import { ListingStatusType, ListingStatusTypeDescription } from '../../models/operation/enum/listing-status-type.enum';
import { FormattingResult } from '../../models/operation/formatting/formatting-result.model';
import { OperationStatusType } from '../../models/operation/enum/operation-status-type.enum';
import { StandardizedParcel } from '../../models/workfile/standardized-parcel.model';
import { MarketConditionsCalculationRequest } from '../../models/operation/market-conditions/market-conditions-calculation-request.model';
import { MarketCondition } from '../../models/workfile/market-condition.model';
import { MarketConditionsCalculationResult } from '../../models/operation/market-conditions/market-conditions-calculation-result.model';
import { TranslationResult } from '../../models/operation/translation/translation-result.model';
import { PrioritizationResult } from '../../models/operation/prioritization/prioritization-result.model';
import { Deed } from '../../models/workfile/deed.model';
import {
  TranslationRequest,
  TranslationRequestWeb,
} from '../../models/operation/translation/translation-request.model';
import { PropertyTranslationRequest } from '../../models/operation/translation/property-translation-request.model';
import { DataSourceTranslationRequest } from '../../models/operation/translation/data-source-translation-request.model';
import { PropertySubTable } from '../../models/workfile/property-sub-table.model';
import { DataSource } from '../../models/data-source/data-source';
@Injectable({
  providedIn: 'root',
})
export class PropertySearchHelperService {
  constructor(
    private dataMasterFileService: DataMasterFileService,
    private marketAnalysisReportService: MarketAnalysisReportService,
    private userStateService: UserStateService
  ) { }

  setRawProperties(searchResult: SearchResult) {
    if (!searchResult.searchProviderResults) return;

    //move to shared location
    searchResult.searchProviderResults.forEach((searchProviderResult) => {
      searchProviderResult.propertySearchResults.forEach(
        (propertySearchResult) => {
          propertySearchResult.propertyResults.forEach((propertyResult) => {
            if (
              propertyResult.propertyFields &&
              Object.keys(propertyResult.propertyFields).length > 0
            ) {
              const rawProperty = new RawProperty();
              rawProperty.dataSourceId = searchProviderResult.dataSourceId;
              rawProperty.propertyGuid = propertySearchResult.propertyGuid;
              rawProperty.fieldValues = propertyResult.propertyFields;
              rawProperty.propertySubTables = propertyResult.propertySubTables;
              rawProperty.dataComesFromLimitedDataSource =
                propertySearchResult.propertyResultsFoundUsingLimitedDataSource;

              this.dataMasterFileService.dataMasterFile.appraisal.rawProperties.push(
                rawProperty
              );
            }
          });
        }
      );
    });
    //this.dataMasterFileService.dataMasterFile.Appraisal.rawProperties =
  }

  setDeeds(deedTranslationResult: any) {
    deedTranslationResult.deedTranslatedPropertyResults.forEach(
      (deedTranslatedPropertyResult) => {
        if (deedTranslatedPropertyResult.deeds) {
          const preprioritizedParcel =
            this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.find(
              (x) =>
                x.propertyGuid == deedTranslatedPropertyResult.propertyGuid &&
                x.dataSourceId == deedTranslatedPropertyResult.dataSourceId
            );

          if (preprioritizedParcel) {
            preprioritizedParcel.deeds = deedTranslatedPropertyResult.deeds;
          }
        }
      }
    );
  }

  setTranslatedParcels(translationResult: TranslationResult) {
    const userDataSourceId = 14; //TODO Fix this hardcoded id
    const failedStatus = 'Failed';

    const propertyGuids = translationResult.translatedPropertyResults
      .filter((a) => a.status != failedStatus)
      .map((x) => x.translatedParcel.propertyGuid);

    //remove out-of-date translated parcels to later replace them with the updated translated parcel
    this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels
      .filter(
        (a) =>
          propertyGuids.some((b) => b == a.propertyGuid) &&
          a.dataSourceId != userDataSourceId
      )
      .forEach((tranlsatedParcelToRemove) => {
        const index =
          this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.findIndex(
            (x) => x.propertyGuid == tranlsatedParcelToRemove.propertyGuid
          );

        if (index > -1) {
          this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.splice(
            index,
            1
          );
        }
      });

    translationResult.translatedPropertyResults.forEach((a) =>
      this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.push(
        a.translatedParcel
      )
    );

    // for(var translatedParcelIndex = 0; translatedParcelIndex < translationResult.translatedPropertyResults.length; translatedParcelIndex++)
    // {
    //   var translatedPropertResult = translationResult.translatedPropertyResults[translatedParcelIndex];

    //   if(translatedPropertResult.status == failedStatus || translatedPropertResult.translatedParcel.dataSourceId == userDataSourceId){continue;}

    //   var index = this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.findIndex(x=> x.propertyGuid == translatedPropertResult.translatedParcel.propertyGuid );

    //   if(index > -1){
    //     this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.splice(index, 1);
    //   }

    //   this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.push(translatedPropertResult.translatedParcel);

    // }
  }

  setTranslatedListingHistoryParcels(translationResult: TranslationResult) {
    translationResult.translatedPropertyResults.forEach(
      (translatedPropertyResult) => {
        const listingHistoryParcelAlreadyExists =
          this.dataMasterFileService.dataMasterFile.appraisal.listingHistoryParcels.some(
            (x) =>
              x?.propertyGuid ==
              translatedPropertyResult?.translatedParcel?.propertyGuid &&
              x?.mlsNumber ==
              translatedPropertyResult?.translatedParcel?.mlsNumber
          );

        if (listingHistoryParcelAlreadyExists) {
          return;
        }

        this.dataMasterFileService.dataMasterFile.appraisal.listingHistoryParcels.push(
          translatedPropertyResult.translatedParcel
        );
      }
    );
  }

  setPrioritizedParcels(prioritizationResult: any) {
    prioritizationResult.prioritizedPropertyResults.forEach(
      (prioritizedPropertyResult) => {
        //var matchingPrioritizedParcel = this.dataMasterFileService.dataMasterFile.Appraisal.prioritizedParcels.find(x=> x.propertyGuid == prioritizedPropertyResult.propertyGuid);
        const index =
          this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.findIndex(
            (x) => x.propertyGuid == prioritizedPropertyResult.propertyGuid
          );
        if (index > -1) {
          this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.splice(
            index,
            1
          );
        }
        this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.push(
          prioritizedPropertyResult.standardizedParcel
        );
      }
    );
  }

  setFormattedResults(formattingResult: FormattingResult) {
    if (formattingResult.status == OperationStatusType.Failed) {
      return;
    }

    const subjectPropertyResult = formattingResult.formattedPropertyResults.find(
      (x) => x.propertyUsageType == PropertyUsageType.Subject
    );

    if (subjectPropertyResult) {
      this.updateSubject(subjectPropertyResult);
    }

    const comparablePropertyResults =
      formattingResult.formattedPropertyResults.filter(
        (x) =>
          x.propertyUsageType == PropertyUsageType.Comparable ||
          x.propertyUsageType == PropertyUsageType.ComparableRental ||
          x.propertyUsageType == PropertyUsageType.Listing ||
          x.propertyUsageType == PropertyUsageType.Rental ||
          x.propertyUsageType == PropertyUsageType.ReoListing
      );

    if (comparablePropertyResults.length > 0) {
      const comparables = this.createComparables(comparablePropertyResults);
      this.updateComparables(comparables);
    }

    //TODO: MC
    const marketConditionsPropertyResults =
      formattingResult.formattedPropertyResults.filter(
        (x) =>
          x.propertyUsageType == PropertyUsageType.MarketConditions ||
          x.propertyUsageType == PropertyUsageType.ProjectMarketConditions ||
          x.propertyUsageType == PropertyUsageType.NeighborhoodAnalysis
      );

    if (
      !marketConditionsPropertyResults ||
      marketConditionsPropertyResults.length == 0
    ) {
      return;
    }

    const marketConditions = this.createMarketConditions(
      marketConditionsPropertyResults
    );
    const currentmarketConditionsPropertyGuids =
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.map(
        (x) => x.propertyGuid
      );
    const marketConditionsGuids = marketConditions.map((x) => x.propertyGuid);
    const addNewMarketConditions = currentmarketConditionsPropertyGuids.some(
      (x) => marketConditionsGuids.some((mcg) => mcg == x)
    );
    if (addNewMarketConditions) {
      return;
    }

    const propertyUsageType =
      marketConditionsPropertyResults[0].propertyUsageType;
    const currentMarketConditions =
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.filter(
        (x) => x.propertyUseType == propertyUsageType
      );

    if (currentMarketConditions && currentMarketConditions.length > 0) {
      const dataM = this.dataMasterFileService;
      currentMarketConditions.forEach(function (marketCondition) {
        const index = dataM.dataMasterFile.appraisal.marketConditions.indexOf(
          marketCondition
        );
        dataM.dataMasterFile.appraisal.marketConditions.splice(
          index,
          1
        );

        marketConditions.push(marketCondition);
      });
    }

    const marketConditionParcels =
      this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.filter(
        (x) => marketConditions.some((a) => a.propertyGuid == x.propertyGuid)
      );
    const distinctMCParcels = marketConditionParcels.filter(
      (marketConditionParcel, index, self) => {
        return (
          self.indexOf(
            self.find((x) => x.mlsNumber === marketConditionParcel.mlsNumber)
          ) === index
        );
      }
    );
    const distinctMarketConditions = marketConditions.filter((x) =>
      distinctMCParcels
        .map((d) => d.propertyGuid)
        .some((a) => a == x.propertyGuid)
    );

    distinctMarketConditions.forEach((x) => {
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.push(
        x
      );
    });
  }

  createMarketConditions(
    formattedPropertyResults: FormattedPropertyResult[]
  ): MarketCondition[] {
    const marketConditions = [];

    formattedPropertyResults.forEach(function (formattedPropertyResult) {
      const marketCondition = new MarketCondition();
      marketCondition.propertyUseType =
        formattedPropertyResult.propertyUsageType;
      marketCondition.propertyGuid = formattedPropertyResult.propertyGuid;

      marketConditions.push(marketCondition);
    });

    return marketConditions;
  }

  createComparables(
    formattedPropertyResults: FormattedPropertyResult[]
  ): Comparable[] {
    const comparables = [];

    formattedPropertyResults.forEach((formattedPropertyResult) => {
      const formattedFieldResults = {};

      formattedPropertyResult.formattedFieldResults.forEach(
        (formattedFieldResult) => {
          const formattedField = new FormattedField();
          formattedField.formattedValue = formattedFieldResult.formattedValue;
          formattedFieldResults[
            formattedFieldResult.formSoftwareFormSheetSectionFieldId
          ] = formattedField;
        }
      );

      const newComp = new Comparable();
      newComp.exportToReport = true;
      newComp.propertyUseType = formattedPropertyResult.propertyUsageType;
      newComp.propertyGuid = formattedPropertyResult.propertyGuid;
      newComp.formattedFieldResults = formattedFieldResults;
      const existingComp =
        this.dataMasterFileService.dataMasterFile.appraisal.comparables?.find(
          (x) => x.propertyGuid == formattedPropertyResult.propertyGuid
        );

      if (existingComp) {
        newComp.exportToReport = existingComp.exportToReport;
        newComp.discrepancyFields = existingComp.discrepancyFields;
        newComp.attemptedToGetProximity = existingComp.attemptedToGetProximity;
        newComp.formattedDataSources = existingComp.formattedDataSources;
        newComp.tagPriorities = existingComp.tagPriorities;
        newComp.workFileComments = existingComp.workFileComments;
      }
      let standardizedParcel =
      this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.find(
        (x) =>
          x.propertyGuid == newComp.propertyGuid &&
          x.images &&
          x.images.length > 0
      );
      if(!standardizedParcel)
        standardizedParcel = this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.find(
          (x) =>
            x.propertyGuid == newComp.propertyGuid
        );
      if (standardizedParcel) {
        newComp.images = standardizedParcel.images;
      }
      if (
        !newComp.formattedDataSources.some(
          (x) => x.dataSource == this.mlsPRDataSource
        )
      ) {
        //newComp.formattedDataSources.push(this.AddMLSAndPRDatasourceToParcelFormattedDataSources(FormattedSectionType.Comparable, formattedFieldResults));
      }

      comparables.push(newComp);
    });

    return comparables;
  }

  updateComparables(comparables: Comparable[]) {
    if (!comparables || comparables.length == 0) {
      return;
    }

    const existingComparables =
      this.dataMasterFileService.dataMasterFile.appraisal.comparables;
    const sortedComparables = this.sortParcels(
      comparables.filter(function (comparable) {
        return comparable.propertyPlacementNumber == 0;
      })
    );

    sortedComparables.forEach((parcel) => {
      const existingComparable = existingComparables.find(
        (x) => x.propertyGuid == parcel.propertyGuid
      );

      if (
        existingComparable &&
        existingComparable.propertyPlacementNumber > 0
      ) {
        parcel.propertyPlacementNumber =
          existingComparable.propertyPlacementNumber;
      } else {
        parcel.propertyPlacementNumber =
          existingComparables.filter(
            (x) =>
              x.propertyUseType == parcel.propertyUseType &&
              x.propertyPlacementNumber != 0
          ).length + 1;
      }

      const index =
        this.dataMasterFileService.dataMasterFile.appraisal.comparables.findIndex(
          (x) => x.propertyGuid == parcel.propertyGuid
        );
      if (index > -1) {
        this.dataMasterFileService.dataMasterFile.appraisal.comparables.splice(
          index,
          1
        );
      }
      this.dataMasterFileService.dataMasterFile.appraisal.comparables.push(
        parcel
      );
    });
  }

  sortParcels(comparables: Comparable[]): Comparable[] {
    let sortedComparables: Comparable[] = [];

    sortedComparables = sortedComparables.concat(
      this.getListingStatusList(comparables, ListingStatusType.SettledSale)
    );
    sortedComparables = sortedComparables.concat(
      this.getListingStatusList(comparables, ListingStatusType.Contract)
    );
    sortedComparables = sortedComparables.concat(
      this.getListingStatusList(comparables, ListingStatusType.Active)
    );
    sortedComparables = sortedComparables.concat(
      this.getListingStatusList(comparables, ListingStatusType.Expired)
    );
    sortedComparables = sortedComparables.concat(
      this.getListingStatusList(comparables, ListingStatusType.Withdrawn)
    );

    comparables.forEach((comparable) => {
      const noStatusParcels = sortedComparables.every(function (
        sortedComparable
      ) {
        return sortedComparable.propertyGuid != comparable.propertyGuid;
      });

      if (noStatusParcels) {
        sortedComparables.push(comparable);
      }
    });

    return sortedComparables;
  }

  getListingStatusList(
    comparables: Comparable[],
    status: ListingStatusType
  ): Comparable[] {
    const specificParcelList = [];

    if (!comparables || comparables.length == 0) {
      return specificParcelList;
    }
    const standardizedParcelList = this.getPrioritizedParcels(
      comparables.map((a) => a.propertyGuid)
    );
    for (let i = 0; i < standardizedParcelList.length; i++) {
      const standardizedParcel = standardizedParcelList[i];

      if (!standardizedParcel.listingStatusTypes) {
        continue;
      }
      const statusToCompare = ListingStatusTypeDescription.get(status);
      if (
        standardizedParcel.listingStatusTypes[0].standardValue.toUpperCase() !==
        statusToCompare.toUpperCase()
      ) {
        continue;
      }
      const parcel = comparables.find(
        (x) => x.propertyGuid == standardizedParcel.propertyGuid
      );
      if (parcel == null) {
        continue;
      }
      specificParcelList.push(parcel);
    }

    return this.orderParcelsByDate(specificParcelList, status);
  }

  orderParcelsByDate(
    comparables: Comparable[],
    status: ListingStatusType
  ): Comparable[] {
    const standardizedParcels = this.getPrioritizedParcels(
      comparables.map((a) => a.propertyGuid)
    );

    let sortedGuids = [];
    const sortedParcels = [];
    switch (status) {
      case ListingStatusType.Active:
        sortedGuids = standardizedParcels
          .sort(function (a, b) {
            if (a.originalListDate > b.originalListDate) return 1;
            if (a.originalListDate < b.originalListDate) return -1;
            return 0;
          })
          .map((x) => x.propertyGuid);
        break;
      case ListingStatusType.Expired:
        sortedGuids = standardizedParcels
          .sort(function (a, b) {
            if (a.expirationDate > b.expirationDate) return 1;
            if (a.expirationDate < b.expirationDate) return -1;
            return 0;
          })
          .map((x) => x.propertyGuid);
        break;
      case ListingStatusType.Contract:
        sortedGuids = standardizedParcels
          .sort(function (a, b) {
            if (a.contractDate > b.contractDate) return 1;
            if (a.contractDate < b.contractDate) return -1;
            return 0;
          })
          .map((x) => x.propertyGuid);
        break;
      case ListingStatusType.SettledSale:
        sortedGuids = standardizedParcels
          .sort(function (a, b) {
            if (a.settlementDate > b.settlementDate) return 1;
            if (a.settlementDate < b.settlementDate) return -1;
            return 0;
          })
          .map((x) => x.propertyGuid);
        break;
      case ListingStatusType.Withdrawn:
        sortedGuids = standardizedParcels
          .sort(function (a, b) {
            if (a.withdrawnDate > b.withdrawnDate) return 1;
            if (a.withdrawnDate < b.withdrawnDate) return -1;
            return 0;
          })
          .map((x) => x.propertyGuid);
        break;
    }

    sortedGuids.forEach((guid) => {
      sortedParcels.push(comparables.find((x) => x.propertyGuid == guid));
    });

    return sortedParcels;
  }

  compareDates(dateOne: Date, dateTwo: Date): number {
    if (dateOne > dateTwo) return 1;
    if (dateOne < dateTwo) return -1;
    return 0;
  }

  getPrioritizedParcels(propertyGuids: string[]): StandardizedParcel[] {
    const matchingStandardizedParcels = [];

    propertyGuids.forEach((propertyGuid) => {
      const matchingStandardizedParcel =
        this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(
          (x) => x.propertyGuid == propertyGuid
        );

      if (matchingStandardizedParcel) {
        matchingStandardizedParcels.push(matchingStandardizedParcel);
      }
    });

    return matchingStandardizedParcels;
  }

  updateSubject(subjectPropertyResult: FormattedPropertyResult) {
    const formattedFieldResults = {};

    for (
      let i = 0;
      i < subjectPropertyResult.formattedFieldResults.length;
      i++
    ) {
      const formattedFieldResult = subjectPropertyResult.formattedFieldResults[i];
      if (!formattedFieldResult) {
        continue;
      }

      const formattedField = new FormattedField();
      formattedField.formattedValue = formattedFieldResult.formattedValue;

      formattedFieldResults[
        formattedFieldResult.formSoftwareFormSheetSectionFieldId
      ] = formattedField;
    }

    const subject = this.dataMasterFileService.dataMasterFile.appraisal.subject;
    //not sure on this next line but its in dm6
    subject.subjectLoaded = Object.keys(formattedFieldResults).length > 0;
    subject.propertyUseType = PropertyUsageType.Subject; //in constructor?
    subject.propertyGuid = subjectPropertyResult.propertyGuid;
    subject.formattedFieldResults = formattedFieldResults;
    if (
      subject.formattedDataSources.some(
        (x) => x.dataSource == this.mlsPRDataSource
      )
    ) {
      subject.formattedDataSources.push(
        this.AddMLSAndPRDatasourceToParcelFormattedDataSources(
          FormattedSectionType.Comparable,
          formattedFieldResults
        )
      );
    }
    const standardizedParcel =
      this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.find(
        (x) =>
          x.propertyGuid == subjectPropertyResult.propertyGuid &&
          x.images &&
          x.images.length > 0
      );
    if (standardizedParcel) {
      subject.images = standardizedParcel.images;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly mlsPRDataSource = 'MLS+PR';

  AddMLSAndPRDatasourceToParcelFormattedDataSources(
    formattedSectionType: FormattedSectionType,
    formattedFieldResults
  ): FormattedDataSource {
    let formattedFields: any;
    formattedFieldResults.forEach((formattedField) => {
      const fieldCommonName = ''; //TODO: set up format Field Maps

      if (
        fieldCommonName &&
        !(fieldCommonName in formattedFields) &&
        formattedField.value.formattedValue
      ) {
        formattedFields[fieldCommonName] = formattedField.Value.FormattedValue;
      }
    });

    const formattedDataSource = new FormattedDataSource();
    formattedDataSource.dataSource = this.mlsPRDataSource;
    formattedDataSource.formattedFields = formattedFields;

    return formattedDataSource;
  }

  getMarketConditionsCalculationRequest(
    propertyUsageType: PropertyUsageType,
    effectiveDate: Date
  ): MarketConditionsCalculationRequest {

    const marketConditions = this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.filter((x) => x.propertyUseType == propertyUsageType);
    if (!marketConditions || marketConditions.length == 0) {
      return null;
    }

    const marketConditionsCalculationRequest = new MarketConditionsCalculationRequest();

    marketConditionsCalculationRequest.propertyUsageType = propertyUsageType;
    marketConditionsCalculationRequest.effectiveDate = effectiveDate;
    marketConditionsCalculationRequest.marketConditions = marketConditions;
    marketConditionsCalculationRequest.prioritizedParcels = this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.filter((x) => marketConditionsCalculationRequest.marketConditions.map((x) => x.propertyGuid).some((p) => p == x.propertyGuid));
    const form = this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form.commonName;

    marketConditionsCalculationRequest.userData = this.userStateService.userDataForSearch;
    marketConditionsCalculationRequest.formCommonName = form;

    return marketConditionsCalculationRequest;
  }

  setMarketConditionsForm(
    marketConditionsCalculationResult: MarketConditionsCalculationResult
  ) {
    if (marketConditionsCalculationResult.propertyUsageType == PropertyUsageType.MarketConditions) {
      this.marketAnalysisReportService.marketConditionsForm =
        marketConditionsCalculationResult.marketConditionsForm;
    } else if (marketConditionsCalculationResult.propertyUsageType == PropertyUsageType.NeighborhoodAnalysis) {
      this.marketAnalysisReportService.neighborhoodAnalysisForm =
        marketConditionsCalculationResult.marketConditionsForm;
    } else {
      this.marketAnalysisReportService.projectMarketConditionsForm = marketConditionsCalculationResult.marketConditionsForm;
    }

    marketConditionsCalculationResult.marketConditions.forEach(marketCondition => {
      const existingMarketCondition = this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.find(a => a.propertyGuid == marketCondition.propertyGuid);
      if (existingMarketCondition) {
        const index = this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.indexOf(existingMarketCondition);
        this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.splice(index, 1);
      }
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.push(marketCondition);
    });
    
    this.marketAnalysisReportService.mergeCalculatedTags(marketConditionsCalculationResult.calculatedTags);
  }

  setMarketConditionsCalculations(
    marketConditionsCalculationResult: MarketConditionsCalculationResult
  ) {
    if (marketConditionsCalculationResult.propertyUsageType == PropertyUsageType.MarketConditions) {
      this.marketAnalysisReportService.marketConditionsForm =
        marketConditionsCalculationResult.marketConditionsForm;
    } else if (marketConditionsCalculationResult.propertyUsageType == PropertyUsageType.NeighborhoodAnalysis) {
      this.marketAnalysisReportService.neighborhoodAnalysisForm =
        marketConditionsCalculationResult.marketConditionsForm;
    } else {
      this.marketAnalysisReportService.projectMarketConditionsForm = marketConditionsCalculationResult.marketConditionsForm;
    }

    marketConditionsCalculationResult.marketConditions.forEach(marketCondition => {
      const existingMarketCondition = this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.find(a => a.propertyGuid == marketCondition.propertyGuid);
      if (existingMarketCondition) {
        const index = this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.indexOf(existingMarketCondition);
        this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.splice(index, 1);
      }
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.push(marketCondition);

      const prioritizedParcel = this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(a => a.propertyGuid == marketCondition.propertyGuid);
      const propertyFormattedActualAge =  parseInt(marketConditionsCalculationResult.marketConditionsForm.formattedActualAgeByPropertyGuid[prioritizedParcel.propertyGuid]);
      if(!prioritizedParcel.actualAge && propertyFormattedActualAge){
        prioritizedParcel.actualAge = propertyFormattedActualAge;
      }
    });
    this.marketAnalysisReportService.mergeCalculatedTags(marketConditionsCalculationResult.calculatedTags);
  }

  prepPrioritizedDeedsForFormatting(
    prioritizationResult: PrioritizationResult,
    dataSources: DataSource[]
  ) {
    //let effectiveDate = ;
    prioritizationResult.prioritizedPropertyResults.forEach(
      (prioritizedPropertyResult) => {
        if (
          prioritizedPropertyResult.propertyUsageType !=
          PropertyUsageType.Subject &&
          prioritizedPropertyResult.propertyUsageType !=
          PropertyUsageType.Comparable
        ) {
          return;
        }

        let soldDateCheck: string = null;

        if (
          prioritizedPropertyResult.standardizedParcel.settlementDate != null
        ) {
          soldDateCheck =
            prioritizedPropertyResult.standardizedParcel.settlementDate?.toString();
        }

        if (soldDateCheck) {
          const filteredDeeds =
            prioritizedPropertyResult.standardizedParcel.deeds.filter(
              (x) =>
                x.recordingDate && x.documentType.toLowerCase().includes('deed')
            );
          const soldDate =
            prioritizedPropertyResult.standardizedParcel.settlementDate;

          if (
            filteredDeeds.filter(
              (x) => this.daysDifference(soldDate, x.recordingDate) < 22
            )?.length == 1
          ) {
            const selectedDeed = filteredDeeds.sort((date1, date2) =>
              date1 > date2 ? -1 : 1
            )[0];
            selectedDeed.isCurrentSale = true;
            this.setDeedsInParcel(
              prioritizedPropertyResult.standardizedParcel.propertyGuid,
              null,
              selectedDeed,
              selectedDeed,
              dataSources
            );
          }
        }
      }
    );
  }

  daysDifference(date1: Date, date2: Date): number {
    const diff = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  }

  setDeedsInParcel(
    propertyGuid: string,
    priorSalesDeeds: Deed[],
    saleVerificationDeed: Deed,
    selectedDeed: Deed,
    dataSources: DataSource[]
  ) {
    let listingHistoryParcel;
    const prioritizedParcel =
      this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(
        (a) => a.propertyGuid == propertyGuid
      );

    //TODO : add settings
    const documentNumberSourceSetting = null;
    const standardizedParcel =
      this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(
        (x) => x.propertyGuid == propertyGuid
      );

    const mostRecentPriorSalesDeed =
      priorSalesDeeds
        ?.filter((x) => x.recordingDate || x.saleDate)
        ?.sort()?.[0] ?? null;
    if (
      (!mostRecentPriorSalesDeed ||
        mostRecentPriorSalesDeed.salePrice == null ||
        mostRecentPriorSalesDeed.recordingDate == null) &&
      priorSalesDeeds &&
      priorSalesDeeds.length > 0
    ) {
      listingHistoryParcel =
        this.dataMasterFileService.dataMasterFile.appraisal.listingHistoryParcels.find(
          (x) =>
            x &&
            x.propertyGuid == propertyGuid &&
            x.settlementDate &&
            x.settlementDate ==
            priorSalesDeeds.find((a) => a.saleDate && x.settlementDate)
              ?.saleDate
        );
    }

    const translatedParcels =
      this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.filter(
        (x) =>
          x.propertyGuid == propertyGuid &&
          dataSources.some((a) => a.id == x.dataSourceId)
      );

    const translatedParcelDeeds = translatedParcels
      .map((a) => a.deeds)
      .reduce(function (a, b) {
        return a.concat(b);
      });
    if (
      !translatedParcels.some((a) => a.deeds.length > 0) ||
      translatedParcelDeeds?.length == 0
    ) {
      if (prioritizedParcel) {
        prioritizedParcel.saleVerificationDeedRecordingDate = null;
        prioritizedParcel.saleVerificationDeedSaleDate = null;
        prioritizedParcel.saleVerificationDeedDocumentNumber = null;
        prioritizedParcel.saleVerificationDeedTransferDocumentNumber = null;
        prioritizedParcel.saleVerificationDeedDocumentType = null;
        prioritizedParcel.saleVerificationDeedGrantee = null;
        prioritizedParcel.saleVerificationDeedGrantor = null;
        prioritizedParcel.saleVerificationDeedPrice = null;
        prioritizedParcel.saleVerificationDeed = null;
      }
    } else {
      //let matchingDataSources : DataSource[];
      let matchingDataSourceCommonName: string;

      if (saleVerificationDeed) {
        matchingDataSourceCommonName =
          saleVerificationDeed?.dataSourceCommonName;
      } else if (priorSalesDeeds?.length > 0) {
        matchingDataSourceCommonName =
          priorSalesDeeds[priorSalesDeeds.length - 1].dataSourceCommonName;
      } else {
        matchingDataSourceCommonName = selectedDeed.dataSourceCommonName;
      }

      const matchingDataSource = dataSources.find(
        (x) => x.commonName == matchingDataSourceCommonName
      );

      if (matchingDataSource) {
        //get the translated parcel with matching deeds
        const prParcelWithMatchingDeeds = translatedParcels.find(
          (x) => x.dataSourceId == matchingDataSource.id
        );

        //Clear verification deed from other data sources.
        if (saleVerificationDeed && translatedParcels.length > 1) {
          translatedParcels
            .filter((a) => a.dataSourceId != matchingDataSource.id)
            .forEach((translatedParcel) => {
              translatedParcel.saleVerificationDeedRecordingDate = null;
              translatedParcel.saleVerificationDeedSaleDate = null;
              translatedParcel.saleVerificationDeedDocumentNumber = null;
              translatedParcel.saleVerificationDeedTransferDocumentNumber =
                null;
              translatedParcel.saleVerificationDeedDocumentType = null;
              translatedParcel.saleVerificationDeedGrantee = null;
              translatedParcel.saleVerificationDeedGrantor = null;
              translatedParcel.saleVerificationDeedPrice = null;
              translatedParcel.saleVerificationDeed = null;
            });
        }

        if (standardizedParcel.deeds.length != translatedParcelDeeds.length) {
          standardizedParcel.deeds = translatedParcelDeeds;
        }

        if (prParcelWithMatchingDeeds) {
          prParcelWithMatchingDeeds.saleVerificationDeed = saleVerificationDeed;
          if (saleVerificationDeed != null) {
            prParcelWithMatchingDeeds.saleVerificationDeed.docNumSourceSetting =
              documentNumberSourceSetting;
          }
          prParcelWithMatchingDeeds.saleVerificationDeedRecordingDate =
            saleVerificationDeed?.recordingDate;
          prParcelWithMatchingDeeds.saleVerificationDeedSaleDate =
            saleVerificationDeed?.saleDate;
          prParcelWithMatchingDeeds.settlementDate =
            saleVerificationDeed?.saleDate;
          prParcelWithMatchingDeeds.saleVerificationDeedDocumentNumber =
            saleVerificationDeed?.documentNumber;
          prParcelWithMatchingDeeds.saleVerificationDeedTransferDocumentNumber =
            saleVerificationDeed?.transferDocumentNumber;
          prParcelWithMatchingDeeds.saleVerificationDeedDocumentType =
            saleVerificationDeed?.documentType;
          prParcelWithMatchingDeeds.saleVerificationDeedGrantee =
            saleVerificationDeed?.sellerName;
          prParcelWithMatchingDeeds.saleVerificationDeedGrantor =
            saleVerificationDeed?.buyerName;
          prParcelWithMatchingDeeds.saleVerificationDeedPrice =
            saleVerificationDeed?.salePrice;
          prParcelWithMatchingDeeds.salePrice = saleVerificationDeed?.salePrice;

          prParcelWithMatchingDeeds.priorSalesDeeds = priorSalesDeeds;
          prParcelWithMatchingDeeds.mostRecentPriorSaleRecordingDate =
            mostRecentPriorSalesDeed?.recordingDate;
          prParcelWithMatchingDeeds.mostRecentPriorSaleDate =
            mostRecentPriorSalesDeed?.saleDate ??
            listingHistoryParcel?.settlementDate;
          prParcelWithMatchingDeeds.mostRecentPriorSaleDocumentNumber =
            mostRecentPriorSalesDeed?.documentNumber
              ? mostRecentPriorSalesDeed?.transferDocumentNumber
              : mostRecentPriorSalesDeed?.documentNumber;
          prParcelWithMatchingDeeds.mostRecentPriorSaleDocumentType =
            mostRecentPriorSalesDeed?.documentType;
          prParcelWithMatchingDeeds.mostRecentPriorSaleGrantee =
            mostRecentPriorSalesDeed?.sellerName;
          prParcelWithMatchingDeeds.mostRecentPriorSaleGrantor =
            mostRecentPriorSalesDeed?.buyerName;
          prParcelWithMatchingDeeds.mostRecentPriorSalePrice =
            mostRecentPriorSalesDeed?.salePrice ??
            listingHistoryParcel?.salePrice;
        }
      }
    }

    if (!standardizedParcel) {
      return;
    }

    standardizedParcel.saleVerificationDeedRecordingDate =
      saleVerificationDeed?.recordingDate;
    standardizedParcel.saleVerificationDeedSaleDate =
      saleVerificationDeed?.saleDate;
    standardizedParcel.saleVerificationDeedDocumentNumber =
      saleVerificationDeed?.documentNumber;
    standardizedParcel.saleVerificationDeedTransferDocumentNumber =
      saleVerificationDeed?.transferDocumentNumber;
    standardizedParcel.saleVerificationDeedDocumentType =
      saleVerificationDeed?.documentType;
    standardizedParcel.saleVerificationDeedGrantee =
      saleVerificationDeed?.sellerName;
    standardizedParcel.saleVerificationDeedGrantor =
      saleVerificationDeed?.buyerName;
    standardizedParcel.saleVerificationDeedPrice =
      saleVerificationDeed?.salePrice;

    standardizedParcel.mostRecentPriorSaleRecordingDate =
      mostRecentPriorSalesDeed?.recordingDate;
    standardizedParcel.mostRecentPriorSaleDate =
      mostRecentPriorSalesDeed?.saleDate ??
      listingHistoryParcel?.settlementDate;
    standardizedParcel.mostRecentPriorSaleDocumentNumber =
      mostRecentPriorSalesDeed?.documentNumber
        ? mostRecentPriorSalesDeed?.transferDocumentNumber
        : mostRecentPriorSalesDeed?.documentNumber;
    standardizedParcel.mostRecentPriorSaleDocumentType =
      mostRecentPriorSalesDeed?.documentType;
    standardizedParcel.mostRecentPriorSaleGrantee =
      mostRecentPriorSalesDeed?.sellerName;
    standardizedParcel.mostRecentPriorSaleGrantor =
      mostRecentPriorSalesDeed?.buyerName;
    standardizedParcel.mostRecentPriorSalePrice =
      mostRecentPriorSalesDeed?.salePrice ?? listingHistoryParcel?.salePrice;

    standardizedParcel.saleVerificationDeed = saleVerificationDeed;
    if (saleVerificationDeed != null) {
      standardizedParcel.saleVerificationDeed.docNumSourceSetting =
        documentNumberSourceSetting;
    }
    standardizedParcel.priorSalesDeeds = priorSalesDeeds;
  }

  setDeedsInTranslatedParcelsFromOld(
    propertyGuids: string[],
    oldTranslatedParcels: StandardizedParcel[],
    prioritizedParcels: StandardizedParcel[],
    prDataSources: DataSource[]
  ) {
    if (!prioritizedParcels || prioritizedParcels.length < 1) return;
    if (!oldTranslatedParcels || oldTranslatedParcels.length < 1) return;

    propertyGuids.forEach((propertyGuid) => {
      const oldTranslatedParcel = oldTranslatedParcels.find(
        (translatedParcel) => translatedParcel.propertyGuid == propertyGuid
      );
      if (!oldTranslatedParcel) return;
      const translatedParcel =
        this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.find(
          (translatedParcel) =>
            translatedParcel.propertyGuid == propertyGuid &&
            translatedParcel.dataSourceId == oldTranslatedParcel.dataSourceId
        );
      if (!translatedParcel) return;
      const prioritizedParcel =
        this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(
          (prioritizedParcel) => prioritizedParcel.propertyGuid == propertyGuid
        );
      if (!prioritizedParcel) return;
      const translatedParcelDataSourceCommonName = prDataSources.find(
        (dataSource) => dataSource.id == oldTranslatedParcel.dataSourceId
      ).commonName;
      translatedParcel.deeds = prioritizedParcel.deeds.filter(
        (deed) =>
          deed.dataSourceCommonName == translatedParcelDataSourceCommonName
      );
    });
  }

  createTranslationRequestWeb(propertyGuids: string[]): TranslationRequestWeb {
    if (!propertyGuids || propertyGuids.length == 0) return null;

    const rawProperties =
      this.dataMasterFileService.dataMasterFile.appraisal.rawProperties.filter(
        (a) => propertyGuids.some((b) => b === a.propertyGuid)
      );

    if (!rawProperties || rawProperties.length == 0) return null;

    const propertyTranslationRequests: PropertyTranslationRequest[] = [];

    const dataSourceIds = rawProperties.map((a) => a.dataSourceId);

    propertyGuids.forEach((propertyGuid) => {
      const dataSourceTranslationRequests: DataSourceTranslationRequest[] = [];
      dataSourceIds.forEach((dataSourceId) => {
        const rawProperty = rawProperties.find(
          (a) =>
            a.dataSourceId == dataSourceId && a.propertyGuid == propertyGuid
        );

        if (!rawProperty) return;

        const newDataSourceRequest = new DataSourceTranslationRequest();
        newDataSourceRequest.dataSourceId = dataSourceId;
        // newDataSourceRequest.propertyFields =
        //   this.getAllPropertyFieldsFromPropertyResult(
        //     rawProperty.fieldValues,
        //     rawProperty.propertySubTables
        //   );
        newDataSourceRequest.propertyFields = rawProperty.fieldValues;
        newDataSourceRequest.propertySubTables = rawProperty.propertySubTables;
        newDataSourceRequest.propertyResultsFoundUsingLimitedDataSource =
          rawProperty.dataComesFromLimitedDataSource;
        dataSourceTranslationRequests.push(newDataSourceRequest);
      });

      if (
        !dataSourceTranslationRequests ||
        dataSourceTranslationRequests.length == 0
      )
        return null;

      const propertyTranslationRequest: PropertyTranslationRequest = {
        dataSourceTranslationRequests: dataSourceTranslationRequests,
        propertyGuid: propertyGuid,
        propertyUsageType: this.getPropertyUsageType(propertyGuid),
      };

      propertyTranslationRequests.push(propertyTranslationRequest);
    });

    if (propertyTranslationRequests.length == 0) return null;
    const form =
      this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form
        .commonName;
    const translationRequest: TranslationRequest = {
      propertyUsageType: propertyTranslationRequests[0].propertyUsageType,
      propertyTranslationRequests: propertyTranslationRequests,
      formCommonName: form,
      userData: null, //not used
    };

    const translationRequestWeb: TranslationRequestWeb = {
      userData: this.userStateService.userDataForSearch,
      translationRequest: translationRequest,
    };
    return translationRequestWeb;
  }

  getPropertyUsageType(propertyGuid: string): PropertyUsageType {
    if (
      this.dataMasterFileService.dataMasterFile.appraisal.subject
        .propertyGuid == propertyGuid
    )
      return PropertyUsageType.Subject;
    if (
      this.dataMasterFileService.dataMasterFile.appraisal.comparables.find(
        (a) => a.propertyGuid == propertyGuid
      )
    )
      return PropertyUsageType.Comparable;
    if (
      this.dataMasterFileService.dataMasterFile.appraisal.marketConditions.find(
        (a) => a.propertyGuid == propertyGuid
      )
    )
      return PropertyUsageType.MarketConditions;

    return PropertyUsageType.Unassigned;
  }

  getAllPropertyFieldsFromPropertyResult(
    propertyFields: { [index: string]: string },
    propertySubTables: PropertySubTable[]
  ): { [index: string]: string } {
    if (!propertyFields) return (propertyFields = {});
    propertySubTables.forEach((propertySubTable) => {
      this.flattenPropertySubTable(propertySubTable, propertyFields);
    });
    return propertyFields;
  }

  flattenPropertySubTable(
    propertySubTable: PropertySubTable,
    propertyFields: { [index: string]: string }
  ) {
    const subtableType = propertySubTable.propertyFieldType.toString();

    if (!propertySubTable || propertySubTable.propertySubRecords.length < 1)
      return;

    let propertyUnit = 1;

    propertySubTable.propertySubRecords.forEach((propertySubRecord) => {
      for (const key in propertySubRecord.fieldValues) {
        const value = propertySubRecord.fieldValues[key];
        const newKey = `${subtableType}${propertyUnit}_${key}`;
        // eslint-disable-next-line no-prototype-builtins
        if (propertyFields.hasOwnProperty(newKey)) {
          propertyFields[newKey] = value;
        } else {
          //propertyFields.push({key: newKey, value: value});
          propertyFields[newKey] = value;
        }
      }

      propertyUnit++;
    });
  }

  resetDeedsInPrioritizedParcels(
    propertyGuids: string[],
    prioritizedParcels: StandardizedParcel[]
  ) {
    if (!prioritizedParcels || prioritizedParcels.length < 1) return;

    propertyGuids.forEach((propertyGuid) => {
      const prioritizedParcel =
        this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(
          (a) => a.propertyGuid === propertyGuid
        );

      if (!prioritizedParcel || prioritizedParcel.deeds.length > 0) return;

      prioritizedParcel.deeds = prioritizedParcels
        .filter((a) => a.propertyGuid === propertyGuid)
        .map((a) => a.deeds)
        .reduce(function (a, b) {
          return a.concat(b);
        });
    });
  }

  setSelectedDeedsInTranslatedParcels(
    propertyGuids: string[],
    oldPrioritizedParcels: StandardizedParcel[],
    prDataSources: DataSource[]
  ) {
    if (!propertyGuids || propertyGuids.length < 1) return;

    propertyGuids.forEach((propertyGuid) => {
      const matchingPrioritizedParcel = oldPrioritizedParcels.find(
        (a) => a.propertyGuid === propertyGuid
      );
      const saleVerificationDeed =
        matchingPrioritizedParcel?.saleVerificationDeed;
      const priorSalesDeeds = matchingPrioritizedParcel?.priorSalesDeeds;

      this.setDeedsInParcel(
        propertyGuid,
        priorSalesDeeds,
        saleVerificationDeed,
        saleVerificationDeed,
        prDataSources
      );
    });
  }
}
