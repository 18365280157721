<div>
    <label class="comp-export">
        <input type="checkbox" class="checkbox" [(ngModel)]="parcel.exportToReport"/>
        {{parcel.propertyUseType}}#{{index}}
        <div class="custom-export-comp">
            <b>{{mlsNumber}}</b><br>
            {{addressLine1}}
        </div>
    </label>
    <input type="text" class="export-position" [(ngModel)]="ExportPosition" (change)="exportPosition($event.target)"
            pattern="[^1-9]+" maxlength="2" [disabled]="!parcel.exportToReport" [ngClass]="{'positionError' : exportPositionExists}"/>
</div>