import { Component, Input } from '@angular/core';
import { DataSourceCredentials } from '../../../models/settings/data-source-credentials.model';

@Component({
  selector: 'app-settings-data-source-credentials',
  templateUrl: './settings-data-source-credentials.component.html',
  styleUrls: ['./settings-data-source-credentials.component.scss']
})
export class SettingsDataSourceCredentialsComponent {

  @Input() dataSourceCredentials : DataSourceCredentials[];
  @Input() showInternalOverride : boolean;

  constructor() { 
    //
  }

}
