import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { NotFoundComponent, ErrorComponent} from '@datamaster/cv.datamaster.ux';
import { AuthCallbackComponent, AuthGuard, UnauthorizedComponent } from '@datamaster/core';

//paths are case sensitive
//redirectTos are only one hop
const routes: Routes = [
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'unathorized',
    component: UnauthorizedComponent,
  },

  {
    path: 'notfound',
    component: NotFoundComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('@datamaster/cv.datamaster.ux').then((m) => m.CvDatamasterUxModule),
  },
  {
    path: 'bpo',
    canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('@datamaster/cv.datamaster-bpo.ux').then((a) => a.CvDatamasterBpoUxModule),
  },
  {
    path: 'propertysearch',
    canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('@datamaster/cv.dm-property-search.ux').then((a) => a.CvDmPropertySearchUxModule),
  },
  {
    path: '**',
    redirectTo: '/notfound',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
