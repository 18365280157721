import { MarketConditionsForm } from '../../market-conditions/market-conditions-form.model';
import { Comparable } from '../../workfile/comparable.model';
import { PropertyUsageType } from '../../workfile/enum/property-usage-type.enum';
import { SearchCriteriaUserData } from '../property-search/search-criteria.model';
import { SubjectExportRequest } from './subject-export-request.model';

export class GraphExportRequest{
  encodedGraph : string;
  graphDisplayName : string;
  propertyUsageType : PropertyUsageType;
}
export class ExportRequest {
  comparables: Comparable[];
  subjectExportRequest: SubjectExportRequest;
  marketConditionsForm: MarketConditionsForm;
  projectMarketConditionsForm: MarketConditionsForm;
  neighborhoodAnalysisForm: MarketConditionsForm;
  graphExportRequests : GraphExportRequest[];
  marketConditionsFormattedFieldResults: { [index: number]: string };
  formId: number;
  formSoftwareId: number;
  fileNumber: string;
  fileId: string;
  userData: SearchCriteriaUserData;

  constructor(){
    this.subjectExportRequest = new SubjectExportRequest()
    this.graphExportRequests = [];
  }
}
