<h3>Market Analysis Settings</h3>

<div class="setting-group">
    <mat-checkbox [(ngModel)]="userMarketConditionsMarketAnalysisSettings.getPublicRecordsDuringMarketAnalysisSearch">Get public records during markey analysis search</mat-checkbox>
    <mat-checkbox [(ngModel)]="userMarketConditionsMarketAnalysisSettings.countPendingsAsActives">Count pending properties as actives</mat-checkbox>
</div>

<section class="setting-group">
    <h6 class="setting-group-heading">Market Data Setting</h6>
    <mat-radio-group [(ngModel)]="userMarketConditionsMarketAnalysisSettings.applyMarketDataForNeighborhoodSection" aria-label="Select an option">
        <mat-radio-button value="Competing Market">Apply 1004 MC data to Form Neighborhood section</mat-radio-button>
        <mat-radio-button value="Larger Market">Apply Neighborhood data to Form Neighborhood section</mat-radio-button>
    </mat-radio-group>
</section>

<section class="setting-group">
    <h6 class="setting-group-heading">Graph Date Display Range (x-axis)</h6>
    <div class="select-list-input-group">
        <label class="setting-drop-down-label">Months from Effective Date:</label>
        <select name="effectiveDate" [(ngModel)]="userMarketConditionsMarketAnalysisSettings.monthsFromEffectiveDate">
            <option *ngFor="let effectiveDate of effectiveDate(73)" [value]="effectiveDate">
                {{effectiveDate}}
            </option>
        </select>
    </div>
    <i class="small">Graphs are most accurate (meaningful) if you pull at least 3 months beyond the desired date range.</i>
</section>

<section class="setting-group">
    <div id="graphSettingsHeaderSection">
        <h6 class="setting-group-heading">Graph Settings</h6>
        <span class="select-graph-type-hint">Select graph type to toggle settings</span>
    </div>
    <ul class="nav nav-tabs">
        <li class="tab">
            <a href="#columnTab" data-toggle="tab" class="active">Column</a>
        </li>
        <li class="tab">
            <a href="#lineTab" data-toggle="tab">Line</a>
        </li>
        <li class="tab">
            <a href="#scatterTab" data-toggle="tab">Scatter</a>
        </li>
    </ul>

    <div class="tab-content">
        <div id="columnTab" class="tab-pane active">
            <datamaster-core-settings-market-analysis-graph-group 
            [userMarketConditionsGraphSetting]="getUserMarketConditionsMarketAnalysisSettings(marketConditionGraphGroupType.Column)">
            </datamaster-core-settings-market-analysis-graph-group>
        </div>
        <div id="lineTab" class="tab-pane">
            <datamaster-core-settings-market-analysis-graph-group 
            [userMarketConditionsGraphSetting]="getUserMarketConditionsMarketAnalysisSettings(marketConditionGraphGroupType.Line)">
            </datamaster-core-settings-market-analysis-graph-group>
        </div>
        <div id="scatterTab" class="tab-pane">
            <datamaster-core-settings-market-analysis-graph-group 
            [userMarketConditionsGraphSetting]="getUserMarketConditionsMarketAnalysisSettings(marketConditionGraphGroupType.Scatter)">
            </datamaster-core-settings-market-analysis-graph-group>
        </div>
    </div>
</section>