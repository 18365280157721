import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigFileModel } from '../models/config/config-file.model';
import { ConfigServiceModel } from '../models/config/config-service.model';

/**
 * Constant with the injection token, needed for the dynamic perApp service injection
 */
export const APP_CONFIG_SERVICE = new InjectionToken<any>('app-config.service');

/**
 * AppService load factory
 *
 * @param data
 * @returns
 */
export function loadAppConfigService(appConfigService: ConfigServiceModel): ConfigService {
    const service = new ConfigService(appConfigService);

    return service;
}

export function loadAppConfigService2(appConfigService: ConfigServiceModel): any {
    console.log('loadAppConfigService2');
    return () => new Promise((resolve, reject) => {
        const service = loadAppConfigService(appConfigService);

        service.loadConfigFile().toPromise().then((data) => {
            // console.log(data);
            resolve({});
        });
    });
};

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    get ids(): any {
        return this.config.oidc_configuration;
    }

    get api(): any {
        return this.config.api;
    }

    get options(): any {
        return this.config.options;
    }

    private appConfigServiceWrapper: ConfigServiceModel;
    private config: ConfigFileModel = null;

    constructor(@Inject(APP_CONFIG_SERVICE) perAppConfigService: ConfigServiceModel) {
        this.appConfigServiceWrapper = perAppConfigService;
    }

    /**
     * Loads the API configuration file from the Proxy
     */
    loadConfigFile(): Observable<ConfigFileModel> {
        return this.appConfigServiceWrapper.loadConfigFile()
            .pipe(
                tap((config: ConfigFileModel) => {
                    // console.log('loaded');
                    localStorage.setItem('config', JSON.stringify(config));
                    this.config = config;
                })
            );
    }

    /**
     * Get the current Config File Model
     * @param name
     */
    getConfig(): Observable<ConfigFileModel> {
        if (this.config === null) {
            return new Observable((observer: any) => {
                this.loadConfigFile().subscribe((configFile: ConfigFileModel) => {
                    observer.next(configFile);
                    observer.complete();
                });
            });
        } else {
            return of(this.config);
        }
    }

    /**
     * get idsConfi from proxy
     */
    getIdsConfig(): Observable<ConfigFileModel> {
        return this.appConfigServiceWrapper.getIdsConfig();
    }

    /**
     * get dependencies versions
     */
    getDependenciesVersions(environment: string): Observable<any> {
        return this.appConfigServiceWrapper.getDependenciesVersions(environment);
    }

    /**
     * Get release notes
     *
     * @returns
     */
    getReleaseNotes(): Observable<any> {
        return this.appConfigServiceWrapper.getReleaseNotes();
    }
}
