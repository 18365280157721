import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import {
  PropertyUsageType,
  PropertySearchHelperService,
  DataMasterApiService,
  PropertyImportDialogService,
  PropertyImportComponent,
  UserStateService,
  SidebarLogoService,
  SpinnerService,
  ThemeService,
  MarketAnalysisReportService,
  GraphsService,
  DataMasterFileService
} from '@datamaster/core';
import { forkJoin, lastValueFrom, of, Subscription } from 'rxjs';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MarketConditionsCalculationResult } from 'libs/cv.datamaster.core/src/lib/models/operation/market-conditions/market-conditions-calculation-result.model';

@Component({
  selector: 'app-market-conditions',
  templateUrl: './market-conditions.component.html',
  styleUrls: [
    './market-conditions.component.scss'
  ],
})
export class MarketConditionsComponent implements OnInit {
  isActiveLink!: string;
  sidebarWidth = '-92px'; // default width of the sidebar (1/2 of the width)
  sidebarWidthValue!: number;
  theme: string = this.themeService.startupTheme;
  get doesUserHaveWebAccess(): boolean {
    return this.userStateService.user.isInternal;
  }
  @ViewChild(PropertyImportComponent)
  propertyImportComponent!: PropertyImportComponent;
  private _subscription: Subscription = new Subscription();
  isNeighborhoodLargerMarket = false;
  isCompetingMarket = true;
  currentPropertyUseType!: PropertyUsageType;
  reportLabel!: string;

  constructor(
    private router: Router,
    private sidebarLogoService: SidebarLogoService,
    private themeService: ThemeService,
    private userStateService: UserStateService,
    private propertyImportDialogService: PropertyImportDialogService,
    private propertySearchHelperService: PropertySearchHelperService,
    private dataMasterApiService: DataMasterApiService,
    private spinnerService: SpinnerService,
    private marketAnalysisReportService : MarketAnalysisReportService,
    private graphService : GraphsService,
    private dataMasterFileService : DataMasterFileService
  ) {
  }

  ngOnInit() {
    // this._subscription.add(
    //   this.userStateService.getUserState().pipe(tap(userState =>{
    //   this.doesUserHaveWebAccess = userState.isInternal;
    // })).subscribe());

    this.isActiveLink = this.isActive();
    this.setReportLabel();
    this._subscription.add(
      this.themeService.currentTheme.subscribe((val) => {
        this.theme = val;
      })
    );
    this.currentPropertyUseType = PropertyUsageType.MarketConditions;
    this._subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((value: any) => {
          this.isActiveLink =
            value.urlAfterRedirects === '/market-analysis' || value.urlAfterRedirects === '/market-analysis/report'
              ? 'active'
              : '';
        })
    );

    this.sidebarWidth = '-' + this.sidebarLogoService.currentSidebarWidth.getValue() / 2 + 'px'; // Get the initial value (BehaviorSubject)
    this.sidebarWidthValue = this.sidebarLogoService.currentSidebarWidth.getValue();
    this.sidebarLogoService.getSidebarWidth().subscribe((width) => {
      this.sidebarWidth = '-' + width / 2 + 'px';
      this.sidebarWidthValue = width;
    });
  }

  private isActive(): string {
    // This seems like a hack
    // Just to get two routes to show active on the same tab
    return this.router.isActive('/market-analysis', true) ||
      this.router.isActive('/market-analysis/report', false)
      ? 'active'
      : '';
  }

  reProcessMarketConditions(event:any) {    
    this.marketAnalysisReportService.clearCalculatedTags(false);
    const temp = this.setMarketConditionsCalcuations(event);  
  }

  getMarketConditionsCalculationRequest$(propertyUsageType : PropertyUsageType, effectiveDate : Date ){
    const marketConditionsCalculationRequest = this.propertySearchHelperService.getMarketConditionsCalculationRequest(propertyUsageType,effectiveDate);

    if(!marketConditionsCalculationRequest){
      return of(null);
    }

    return this.dataMasterApiService.calculateMarketConditions(marketConditionsCalculationRequest).pipe(tap(marketConditionsCalculationResult =>{
      this.propertySearchHelperService.setMarketConditionsForm(
        marketConditionsCalculationResult
      );
    }));
  }

  async setMarketConditionsCalcuations(event: any) {
    const spinnerRef = this.spinnerService.open('Updating Effective Date Related Fields');
    let marketConditionsCalculationResult;
    await this.getMarketConditionsCalculation(PropertyUsageType.MarketConditions, event).then((data) => {
        marketConditionsCalculationResult = data;
      }).catch(e => {
        throw new Error(e);
      });
    if (marketConditionsCalculationResult) {
      const marketConditionsGraphCalculationResult = await lastValueFrom(this.graphService.calculateGraphs(marketConditionsCalculationResult.marketConditionsForm, this.userStateService.userDataForSearch, marketConditionsCalculationResult.propertyUsageType));
      if (marketConditionsGraphCalculationResult) {
        this.marketAnalysisReportService.marketConditionsGraphs = marketConditionsGraphCalculationResult.marketConditionsGraphs;
      }
    }
    
    if (this.marketAnalysisReportService.neighborhoodAnalysisForm) {
      this.marketAnalysisReportService.clearCalculatedTags(false);
      let neighborhoodAnalysisCalculationResult;
      await this.getMarketConditionsCalculation(PropertyUsageType.NeighborhoodAnalysis, event).then((data) => {
        neighborhoodAnalysisCalculationResult = data;        
      }).catch(e => {
        throw new Error(e);
      });
      const neighborhoodAnalysisGraphCalculationResult = await lastValueFrom(this.graphService.calculateGraphs(neighborhoodAnalysisCalculationResult.marketConditionsForm, this.userStateService.userDataForSearch, neighborhoodAnalysisCalculationResult.propertyUsageType));
      if (neighborhoodAnalysisCalculationResult && neighborhoodAnalysisGraphCalculationResult) {
          const graphsExists = this.marketAnalysisReportService.marketConditionsGraphs.filter((a) => a.graphDetails.reportUsageType == PropertyUsageType.NeighborhoodAnalysis).length > 0;
          if (!graphsExists) {
          this.marketAnalysisReportService.marketConditionsGraphs = this.marketAnalysisReportService.marketConditionsGraphs.concat(neighborhoodAnalysisGraphCalculationResult.marketConditionsGraphs);
          }            
        } else {
          this.marketAnalysisReportService.marketConditionsGraphs = neighborhoodAnalysisGraphCalculationResult.marketConditionsGraphs;
        } 
    } 
    
    spinnerRef.close();
    this.router.navigate(['/market-analysis', {neighborhoodAnalysis:this.isNeighborhoodLargerMarket}]);
  }

  private async getMarketConditionsCalculation(propertyUsageType : PropertyUsageType, effectiveDate : Date ): Promise<any>{
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const marketConditionsCalculationRequest = this.propertySearchHelperService.getMarketConditionsCalculationRequest(propertyUsageType,effectiveDate);
      if(marketConditionsCalculationRequest){
        const marketConditionsCalculationResult = await lastValueFrom(this.dataMasterApiService.calculateMarketConditions(marketConditionsCalculationRequest));
        if (marketConditionsCalculationResult) {
          this.propertySearchHelperService.setMarketConditionsForm(marketConditionsCalculationResult);
          }
            resolve(marketConditionsCalculationResult);
      } else if (!marketConditionsCalculationRequest) {
        resolve(null);
      } else {
        reject()
        throw new Error('Market Condition-Calculation');
      }
    });    
  }

  setNeighborhoodLargerMarket(tab: boolean) {
    if (tab) {
      this.isCompetingMarket = false;
      this.isNeighborhoodLargerMarket = tab;
      this.currentPropertyUseType = PropertyUsageType.NeighborhoodAnalysis;
    } else {
      this.isCompetingMarket = true;
      this.isNeighborhoodLargerMarket = false;
      this.currentPropertyUseType = PropertyUsageType.MarketConditions;
    }      
    this.setReportLabel();
  }

  setReportLabel() {
    if (!this.isNeighborhoodLargerMarket) {
      this.reportLabel = '1004 MC';
    } else {
      this.reportLabel = 'Larger Market';
    }
  }

  openPropertyImportModal() {
    this.propertyImportDialogService.openPropertyImportModal(this.currentPropertyUseType);
  }

  get isSummaryTabActivated(){
    return  this.dataMasterFileService.dataMasterFile?.appraisal.marketConditions.length >  0;
  }
}
