import { Component, OnInit } from '@angular/core';
import { ApnSearchComponent } from '../apn-search/apn-search.component';

@Component({
  selector: 'app-dialog-apn-search',
  templateUrl: './dialog-apn-search.component.html',
  styleUrls: ['./dialog-apn-search.component.scss']
})
export class DialogApnSearchComponent extends ApnSearchComponent {

}
