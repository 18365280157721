<div class="content">

  <div class="header-section">
    <h5 class="header">Add the graphs you would like to include in your appraisal</h5>
    <!-- <app-mc-graph-select (afterGraphsSaved)="reInitGraphs()"></app-mc-graph-select> -->

    <div class="controls">
      <button id="graphListToggleButton" class="btn btn-primary" type="button" (click)="toggleGraphList()">
          <i class="fa" [ngClass]="graphListExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
          {{ graphListExpanded ? 'Hide' : 'Show' }} Graph List</button>
      <button *ngIf="hasMarketConditionsData" class="btn btn-primary" type="button" (click)="copyGraphsToClipboard()"><i class="fa fa-copy"></i> Copy Graphs to Clipboard</button>
    </div>
    <div id="addGraphs" class="collapse" [ngClass]="{'show': graphListExpanded}">
      <h6>Select the graphs you would like to include in your appraisal</h6>
      <app-tell-me-more class="tellMeMore" [template]="tellMeMoreDescriptionTemplate"></app-tell-me-more>
      <ng-template #tellMeMoreDescriptionTemplate>
          <p>Select from the available graph list on the left side and add them to the right side to be included in the report.</p>
          <p>Rearrange the included graphs's order by selecting a graph from the list on the right and clicking the up and down arrows.</p>
      </ng-template>
  
      <div class="mc-graph-select-container">
          <app-dual-list-select #duallistselect (notifyOnEdited)="onEditedList($event)" [defaultListItems]="graphs"
              [userListItems]="userGraphs"></app-dual-list-select>
          <div class="controls controls-right">
              <button class="btn btn-primary" type="button" (click)="save()" [disabled]="!saveEnabled"><i
                      class="fa fa-save"></i> Save</button>
              <button class="btn btn-secondary" type="button" (click)="cancel()">Cancel</button>
          </div>
      </div>
  </div>

  </div>

  <ng-container *ngIf="!hasMarketConditionsData; else marketConditionsGraphContainer" >
    <h6 class="no-data-header">No market conditions data has been imported.</h6>
  </ng-container>

</div>

<ng-template #marketConditionsGraphContainer >
  <div class="graphs-container">
    <ng-container *ngFor="let marketConditionsGraph of marketConditionsGraphs">
      <app-mc-graph [marketConditionsGraph]="marketConditionsGraph" (removeGraphEvent)="removeGraph($event)" [userSettings]="userSettings" [effectiveDate]="dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate">
      </app-mc-graph>
    </ng-container>
  </div>
</ng-template>