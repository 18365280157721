import { AppraisalForm } from './appraisal-form.model';
import { AppraisalFormSoftware } from './appraisal-form-software.model';
import { EventEmitter } from '@angular/core';

export class AppraisalDetail {
  effectiveDateChanged  = new EventEmitter<Date>();
  form: AppraisalForm;
  formSoftware: AppraisalFormSoftware;
  private _effectiveDate?: Date;
  public get effectiveDate(): Date {
    return this._effectiveDate;
  }
  public set effectiveDate(value: Date) {
    this._effectiveDate = value;
    this.effectiveDateChanged.emit(this._effectiveDate);
  }

}
