import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderNavService {
  isNavVisible = new Subject<boolean>();
  useFullHeader = new Subject<boolean>();

  constructor() {
    //
  }

  getShowNav(): Observable<boolean> {
    return this.isNavVisible.asObservable();
  }

  getUseFullHeader(): Observable<boolean> {
    return this.useFullHeader.asObservable();
  }

  setShowNav(activatedParentRoute: string): void {
    //TODO: Refactor this so NavVisible is based on state of the workfile and userStateService.isSubjectSearchProduct
    //We don't want to mantain this list of approved routes as we add things
    switch (activatedParentRoute) {
      case 'subject':
      case 'comparables':
      case 'market-analysis':
      case 'summary': {
        this.isNavVisible.next(true);
        //this.useFullHeader.next(true);

        break;
      }
      case 'bpo':
        this.useFullHeader.next(false);
        break;
      case 'pdr':
      default:
        this.isNavVisible.next(false);
        this.useFullHeader.next(true);

        break;
    }
  }
}
