<app-dialog-header title="{{ 'Get ' + (propertyUsageIsSubject ? 'Subject': 'Comps') }}"></app-dialog-header>

<div mat-dialog-content>
  <div class="container">
    <div class="row search-type">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li *ngIf="showPropertySearch" class="tab">
            <a href="#propertySearchTab" (click)="toggleSubmitButton(true)"
              [class.active]="!propertyUsageTypeIsMarketConditions && !propertyUsageTypeIsProjectMarketConditions && !propertyUsageTypeIsNeighborhoodAnalysis" data-toggle="tab">Property Search
            </a>
          </li>

          <li *ngIf="showGetFromComparables" class="tab">
            <a href="#getFromComparablesTab" (click)="toggleSubmitButton(false)" data-toggle="tab">
              Get From Comparables
            </a>
          </li>

          <li *ngIf="showGetFromMlsFile" class="tab">
            <a href="#getFromMlsFileTab" (click)="toggleSubmitButton(false)"
              [class.active]="propertyUsageTypeIsMarketConditions || propertyUsageTypeIsProjectMarketConditions || propertyUsageTypeIsNeighborhoodAnalysis" data-toggle="tab">
              Get From MLS File
            </a>
          </li>
          <li *ngIf="showGetFromMarketAnalysis" class="tab">
            <a href="#getFromMarketAnalysis" (click)="toggleSubmitButton(false)" data-toggle="tab">
              Get From Market Analysis
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="tab-content tab-slider-container">
          <div *ngIf="showPropertySearch" id="propertySearchTab" class="tab-pane active">
            <div class="row">
              <div class="col-3">
                <ul class="nav nav-tabs property-search-tab">
                  <li class="nav-item property-search-sub-tab">
                    <a class="nav-link active" href="#propertyImportAddressSearchTab" data-toggle="tab" data-cy="searchResultsAddressTab">
                      Address
                      <!-- <span class="fal fa-sort-down fa-3x tab-item-down-arrow"></span> -->
                    </a>
                  </li>
                  <li class="nav-item property-search-sub-tab">
                    <a class="nav-link" href="#propertyImportApnSearchTab" data-toggle="tab" data-cy="searchResultsApnTab">APN Number</a>
                  </li>
                  <li class="nav-item property-search-sub-tab last">
                    <a class="nav-link" href="#propertyImportMlsSearchTab" data-toggle="tab" data-cy="searchResultsMlsTab">MLS Number</a>
                  </li>
                </ul>

                <div class="tab-content tab-slider-container dialog-search">
                  <div id="propertyImportAddressSearchTab" class="tab-pane active">
                    <app-dialog-address-search #dialogAddressSearch
                      (resultsEvent)="updateAfterPropertyImportSearch($event)"
                      (clearAllSearchesEvent)="clearSearchResults()" propertyUseType="{{ propertyUseType }}"
                      [isDashboardSearch]="false">
                    </app-dialog-address-search>
                  </div>
                  <div id="propertyImportApnSearchTab" class="tab-pane">
                    <app-dialog-apn-search #dialogApnSearch (resultsEvent)="updateAfterPropertyImportSearch($event)"
                      (clearAllSearchesEvent)="clearSearchResults()" propertyUseType="{{ propertyUseType }}"
                      [isDashboardSearch]="false" [dataSources]="prDataSources"></app-dialog-apn-search>
                  </div>
                  <div id="propertyImportMlsSearchTab" class="tab-pane">
                    <app-dialog-mls-search #dialogMlsSearch (resultsEvent)="updateAfterPropertyImportSearch($event)"
                      (clearAllSearchesEvent)="clearSearchResults()" propertyUseType="{{ propertyUseType }}"
                      [isDashboardSearch]="false" [dataSources]="dataSources"></app-dialog-mls-search>
                  </div>
                </div>
              </div>

              <div class="col-9">
                <app-multiple-results-table [translatedPropertyResults]="translatedPropertyResults" [isAddressSearch]="searchType === 2"
                  (resultsEvent)="checkSingleSearchResult()" (propertySelectedEvent)="toggleUsePropertyButton($event)">
                </app-multiple-results-table>
              </div>
            </div>
          </div>

          <div id="getFromComparablesTab" class="tab-pane">getFromComparablesTab</div>

          <div id="getFromMlsFileTab" class="tab-pane" [ngClass]="{ active: propertyUsageTypeIsMarketConditions || propertyUsageTypeIsNeighborhoodAnalysis }">
            <input type="file" class="file-input" id="propertyImportMlsFileInput" style="display: none" (change)="openFileChangeEvent($event)" />
            <app-mls-data-source-media [dataSourceLinkInfos]="dataSourceLinkInfos"></app-mls-data-source-media>

            <ng-container *ngIf="propertyUsageTypeIsMarketConditions || propertyUsageTypeIsNeighborhoodAnalysis; else comparablesFileImportButton">
              <div class="mc-buttons-container">
                <button *ngIf="propertyUsageTypeIsNeighborhoodAnalysis" class="big-button--wide" (click)="openNeighborhoodAnalysisFileDialog()">
                  <div>
                    <div>
                      <span class="fa fa-folder-open fa-2x"></span>
                    </div>
                    <div class="big-button-header">
                      <b>Neighborhood</b>
                    </div>
                    <div class="big-button-header">
                      <b>(Larger Market)</b>
                    </div>
                    <div class="big-button-description">Open File</div>
                  </div>
                </button>
                <button *ngIf="propertyUsageTypeIsMarketConditions" class="big-button--wide" (click)="openMarketConditionsFileDialog()">
                    <div>
                      <div>
                        <span class="fa fa-folder-open fa-2x"></span>
                      </div>
                      <div class="big-button-header">
                        <b>Competing Market</b>
                      </div>
                      <div class="big-button-header">
                        <b>(1004 MC)</b>
                      </div>
                      <div class="big-button-description">Open File</div>
                    </div>
                  </button>
                  <button *ngIf="propertyUsageTypeIsProjectMarketConditions" id="proj-mc-import-button" class="big-button--wide " (click)="openProjectMarketConditionsFileDialog()">
                    <div>
                      <div>
                        <span class="fa fa-folder-open fa-2x"></span>
                      </div>
                      <div class="big-button-header">
                        <b>Condo Market</b>
                      </div>
                      <div class="big-button-description">Open File</div>
                    </div>
                  </button>
              </div>
            </ng-container>
            </div>
          <div id="getFromMarketAnalysis" class="tab-pane">getFromMarketAnalysis</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="dialog-footer" align="end">
  <button *ngIf="showSubmitButton" type="submit" class="btn btn-light use-property-btn" (click)="useProperty()"
    [disabled]="isUsePropertyButtonDisabled" data-cy="searchResultsUseAsSubjectButton">
    <span class="fal fa-check-square"></span>
    Use as {{ propertyUsageTypeType[propertyUseType] }}
  </button>
  <button type="button" class="btn btn-secondary" (click)="close()" data-cy="searchResultsCancelButton">Cancel</button>
</div>

<ng-template #comparablesFileImportButton>
  <div class="row row-margin">
    <div class="col-md-12">
      <button class="big-button" (click)="openFileDialog()">
        <div>
          <div>
            <span class="fa fa-folder-open fa-2x"></span>
          </div>
          <div class="big-button-header">
            <b>Open</b>
          </div>
          <div class="big-button-description">MLS File</div>
        </div>
      </button>
    </div>
  </div>
</ng-template>
