import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { DataMasterFileService,
  CommentBlock,
  CommentSectionType,
  MarketAnalysisReportService,
  DataMasterApiService,
  FillCommentTextRequest,
  PropertyUsageType
  } from '@datamaster/core';
import { McCommentEditComponent } from '../mc-comment-edit/mc-comment-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mc-comment',
  templateUrl: './mc-comment.component.html',
  styleUrls: ['./mc-comment.component.scss'],
})
export class McCommentComponent implements OnInit {
  Comment: CommentBlock;
  @Input() CommentSectionType: CommentSectionType;
  
  _requestCommentSubscription: Subscription;
  NoDataComment: CommentBlock = null;
  DataComment: CommentBlock = null;
  private _calculatedTagsSubscription : Subscription;
  private _getFilledCommentTextSubscription: Subscription;
  commentChangedSubscription: Subscription;
  shouldUpdateCommentsOnDialogClose: boolean;
  constructor(
    private dataMasterFileService: DataMasterFileService,
    private maReportService: MarketAnalysisReportService,
    private datamasterApi: DataMasterApiService,
    private changeDetector : ChangeDetectorRef,
    private dialog: MatDialog 
  ) {}

  ngOnInit(): void {
    this.requestMCComments();
    this._calculatedTagsSubscription = this.maReportService.calculatedTagsChanged.subscribe(calculatedTags => this.OnMCDataChanged());

    //TODO: On MC Import fire OnMCDataChanged
  }

  ngOnDestroy(): void {
    this._requestCommentSubscription.unsubscribe();
    this._calculatedTagsSubscription.unsubscribe();
    this._getFilledCommentTextSubscription?.unsubscribe();
  }

  OnMCDataChanged(): void {
    if (this.DetermineIfUseNoDataComment(this.CommentSectionType))
      this.Comment = this.NoDataComment;
    else this.Comment = this.DataComment;
    
    this.setCommentText();
  }

  DetermineIfUseNoDataComment(commentName: CommentSectionType): boolean {
    //TODO:Calculated Tags should be taken into account below
    switch (commentName) {
      case CommentSectionType.McSellerConcessions:
        return !this.dataMasterFileService.dataMasterFile.appraisal.HasMcProperties
      || (this.maReportService.calculatedTags != null && this.CheckStringLessThanOne(this.maReportService.calculatedTags.concessions))
      case CommentSectionType.McReoSales:
        return !this.dataMasterFileService.dataMasterFile.appraisal.HasMcProperties
      || (this.maReportService.calculatedTags != null && this.CheckStringLessThanOne(this.maReportService.calculatedTags.foreclosures))
      case CommentSectionType.McCiteDataSources:
        return false;
      case CommentSectionType.McSummary:
        return false;
      case CommentSectionType.NeighborhoodAnalysisSummary:
        return false;
      case CommentSectionType.ProReoSales:
        return !this.dataMasterFileService.dataMasterFile.appraisal.HasMcProperties
      || (this.maReportService.calculatedTags != null && this.CheckStringLessThanOne(this.maReportService.calculatedTags.condoForeclosures))
      case CommentSectionType.ProSummary:
        return false;
      case CommentSectionType.DaAppraiserComments:
        return false; //TODO
      // return this.dataMasterFileService.dataMasterFile.appraisal.MarketConditions.All(
      //   (a) => a.PropertyUseType != PropertyUsageType.MarketConditions
      // ); //when data elements from the standardized parcel are added later, this will need to include a conditions for that
      default:
        return false;
    }
  }
  CheckStringLessThanOne(intValue : string) : boolean {
            if (!intValue) return false;
            const parsed = parseInt(intValue, 10);
            if (isNaN(parsed)) return false;
            return parsed <= 0;
        }
        
  setCommentText() : void {
    if(this.Comment == this.DataComment)
      this.getFilledCommentCalculatedValues();
    else 
      this.Comment.displayComment = this.Comment.text;
    
  }
  getFilledCommentCalculatedValues() {
    if(this._getFilledCommentTextSubscription != null)
      this._getFilledCommentTextSubscription.unsubscribe();
    const fillCommentRequest : FillCommentTextRequest = {
      Text : this.Comment.text,
      MCCalculatedTags : this.maReportService.calculatedTags,
      NeighborhoodAnalysisCalculatedTags : this.maReportService.neighborhoodAnalysisCalculatedTags,
      Subject : this.dataMasterFileService.getTranslatedStandardizedParcel(this.dataMasterFileService.dataMasterFile.appraisal.subject.propertyGuid),
    };
     this._getFilledCommentTextSubscription = this.datamasterApi.getFilledCommentText(fillCommentRequest)
     .subscribe((filledText) => {
            this.Comment.calculatedComment = filledText?.text ?? '';
            this.Comment.displayComment = this.Comment.calculatedComment;
            this.changeDetector.detectChanges();
          }
        );
        
  }
  get DisplayComment() : string{
    if(this.Comment == null) return '';
    return this.Comment.displayComment;
  }
  set DisplayComment(value : string) {
    if(this.Comment == null) return;
    this.Comment.text = value;
    this.DisplayComment = this.Comment.text;

  }

  OpenTemplateEditor() : void {
    const dialogRef = this.dialog.open(McCommentEditComponent, {
      // maxWidth: '95vw',
      // minWidth: '73vw',
      height: '80%',
      width: '80%',
      data: {
        commentSectionType: this.CommentSectionType
      },
    });
    this.commentChangedSubscription = dialogRef.componentInstance.commentChanged.subscribe((commentChanged) => {
      if(commentChanged)
        this.shouldUpdateCommentsOnDialogClose = commentChanged;
      else
        dialogRef.close();
    });
    const dialogClosed = dialogRef.afterClosed().subscribe(_ =>{
      this.commentChangedSubscription.unsubscribe();
      dialogClosed.unsubscribe;
      if(this.shouldUpdateCommentsOnDialogClose)
        this.requestMCComments();
    });
  }
  requestMCComments() {
    this._requestCommentSubscription?.unsubscribe();
    this._requestCommentSubscription = this.maReportService
      .requestMCComments()
      .subscribe((mcComments) => {
        if (mcComments) {
          switch (this.CommentSectionType) {
            case CommentSectionType.McSellerConcessions:
              this.DataComment = mcComments.mcSellerConcessions;
              this.NoDataComment = mcComments.noDataMcSellerConcessions;
              break;
            case CommentSectionType.McReoSales:
              this.DataComment = mcComments.mcReoSales;
              this.NoDataComment = mcComments.noDataMcReoSales;
              break;
            case CommentSectionType.McCiteDataSources:
              this.DataComment = mcComments.mcCiteDataSources;
              break;
            case CommentSectionType.McSummary:
              this.DataComment = mcComments.mcSummary;
              break;
            case CommentSectionType.NeighborhoodAnalysisSummary:
              this.DataComment = mcComments.neighborhoodAnalysisSummary;
              break;
            case CommentSectionType.ProReoSales:
              this.DataComment = mcComments.proReoSales;
              this.NoDataComment = mcComments.noDataProReoSales;
              break;
            case CommentSectionType.ProSummary:
              this.DataComment = mcComments.proSummary;
              this.NoDataComment = mcComments.noDataProSummary;
              break;
            case CommentSectionType.DaAppraiserComments:
              this.DataComment = mcComments.daAppraiserComments;
              this.NoDataComment = mcComments.noDataDaAppraiserComments;
              break;
          }
        }
        this.OnMCDataChanged();
      });
  }
}

