import { Component, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService, UserStateModel } from '@datamaster/core';
import { Store } from '@ngxs/store';

@Component({
    selector: 'app-no-active-services',
    templateUrl: './no-active-services.component.html',
    styleUrls: ['./no-active-services.component.scss'],
})
export class NoActiveServicesComponent implements OnInit {
    billingPortalUrl$: Observable<string>;
    userState$: Observable<UserStateModel> = this.store.select(state => state.user);
    user = new UserStateModel();

    private atComponentDestroy = new Subject();

    constructor(private store: Store, private configService: ConfigService) {
        this.userState$.pipe(takeUntil(this.atComponentDestroy)).subscribe((userState: UserStateModel) => {
            this.user = userState;
            this.updateBillingPortalUrl();
        });
    }

    ngOnInit(): void {
        this.updateBillingPortalUrl();
    }

    /**
     * Update billingPortalUrl$ variable, every user change
     */
    updateBillingPortalUrl(): void {
        let url = `${this.user.billingPortalUrl}`;
        this.billingPortalUrl$ = of(url);
    }
}
