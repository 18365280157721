import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { 
  Comparable,
  DataMasterFileService,
  CompHeaderService,
  Subject,
  FormattingService,
  PropertyImportDialogService,
  PropertyUsageType,
  Parcel
} from '@datamaster/core';
import { DeedsWindowServiceService } from '../../../services/deeds-window-service.service';
import { PhotosDialogService } from '../../../services/photos-dialog.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  @ViewChild('subject', { static: false }) subjectGrid!: ElementRef;   // static set to false now because element comes from a template
  @ViewChild('compsContainer', { static: true }) compsContainer!: ElementRef;
  @ViewChild('comps', { static: true }) comps!: ElementRef;

  sectionType= 'Comparables';

  compViewableAreaWidth = 0;
  comparables!: Comparable[];
  subj!: Subject;

  compCount!: number;
  addSubjectScrollMargin = false;
  //subjectAddress: Address;

  constructor(private compHeaderService: CompHeaderService, 
    private dataMasterFileService: DataMasterFileService,
    private formattingService : FormattingService,
    private propertyImportDialogService: PropertyImportDialogService,
    private deedsWindowService: DeedsWindowServiceService,
    private photosDialogService: PhotosDialogService){}

  ngOnInit() {
    this.compCount = 0;

    if (this.comparables) {
      this.compCount = this.comparables.length + 1;

      //if the subject format fields are null, fill them with empty data
      if (!this.subj.formattedFieldResults) {
        this.subj.formattedFieldResults = Array(400).fill('');
      }
      // Remove last comp for demo purposes
      // this.comparables.pop();
      // this.compCount = this.compCount - 1;
    }

    //this.subjectAddress = { addressLine1: '123 Somewhere Ave.', city: 'Layton', state: 'UT', zip: '84045' };

    this.compHeaderService.compHeaderWidth.subscribe(headerWidth => {
      //console.log('Comp body width: ' + headerWidth);
      this.compViewableAreaWidth = headerWidth;
      let subjectGridWidth: number = this.subjectGrid.nativeElement.offsetWidth;
      subjectGridWidth += 10; // for the left margin
      const newMaxWidth: number = headerWidth - subjectGridWidth;
      //this.comps.nativeElement.style.maxWidth = '1709px';
      this.compsContainer.nativeElement.style.maxWidth = newMaxWidth > 0 ? newMaxWidth + 'px' : '1100px';

      // Add the top margin to the subject grid if there is a scrollbar present
      if (this.comps.nativeElement.offsetWidth > this.compsContainer.nativeElement.offsetWidth) {
        this.subjectGrid.nativeElement.style.marginTop = '17px';  //Code below gives an error.....not sure why
        //this.addSubjectScrollMargin = true;
      } else {
        this.subjectGrid.nativeElement.style.marginTop = '-1';  //Code below gives an error.....not sure why
        //this.addSubjectScrollMargin = false;
      }
    });
  }

  getFormattedFieldResultFormattedValue(comp: Comparable, field: string): string {

    const formSoftwareFormSheetSectionFieldId = this.formattingService.getFormSoftwareFormSheetSectionFieldId(this.dataMasterFileService.formatFieldMaps, field, this.sectionType);
    if (!formSoftwareFormSheetSectionFieldId) {
      return '';
    }

    if(!comp.formattedFieldResults || !(formSoftwareFormSheetSectionFieldId in comp.formattedFieldResults)){
      return '';
    }

    const formattedFieldResult = comp.formattedFieldResults[formSoftwareFormSheetSectionFieldId];

    if (!formattedFieldResult) {
      return '';
    }
    return formattedFieldResult.formattedValue

  }

  openPropertyImportModal(){
  this.propertyImportDialogService.openPropertyImportModal(PropertyUsageType.Comparable);
  }

  openDeeds(parcel: Parcel) : void {
    if(!parcel)
      return;
    this.deedsWindowService.openDeeds(parcel);
  }
  openPhotosDialog(parcel: Parcel) : void {
    if(!parcel)
      return;
    this.photosDialogService.openPhotos(parcel);
  }

  // getPhotoStreamAsString(propertyGuid : string) : any {

  //   //console.log(this.propertyPhotos);
  //   console.log(propertyGuid);

  //   // if(!this.propertyPhotos){
  //   //   return "";
  //   // }
  //   // var photos = this.propertyPhotos.filter(x=> x.propertyGuid === propertyGuid && x.index === 0 );
  //   // if(!photos || photos.length == 0) {
  //   //   return "";
  //   // }
  //   // console.log(photos);
  //   let byteStream = this.propertyPhotos[0].photoStreamAsString;
  // "/9j/4AAQSkZJRgABAgAAAQABAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4n
  //ICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL
  //wAARCABkAHgDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL
  //8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc
  //3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL
  //8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEh
  //YaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwDhEWrCLTUWrCLQAqLVhFpqLVhFoAVFqwi0iLU6LQAqL
  //VhFpqLVhFoAVFqwi0iLU6LQAqLVhFpEWp0WgBUWrCLSItTotACotFTotFAHjiLU6LSItWEWgBUWp0WkRasItACotTotIi1Oi0AORanRaRFqwi0AKi1Oi0iLVhFoAVFqdFpEWrCLQAqLU6LSItWEWgBUW
  //ipkWigDxxFqwi0iLU6LQAqLVhFpqLVhFoAVFqwi1i3vijRdMmMNzfIJR1RFLkfXAOK0NJ1nTdYVjYXccxX7yjIYfgeaANFFqwi0iLTb29ttLsJb28k8u3iALttJxk46DnqaALSLVhFrI0PxFpXiBphpl
  //yZjDtMmY2XGc46gehrdRaAFRasItIi1Oi0AKi1YRaRFrN13xRo3hdLdtXuzbicsI8RO+cYz90HHUUAbSLRUGi6tY69pkWo6bN51rKSEfYVzgkHggHqDRQB5Ui1Oi1G8TPC6I2x2UhW9D615Rcvq+la01
  //t9sle7jcAFJCwYkcdevWgD2NFrH8X6pJpHh6WWFis8rCGNh1UnOT+QNef6zoWtaHFBqN3dZeRtu9JWLI2M4J/A9PStHxFqU2qeB9HuZzmUzMsh9SoIz+I5oAi8HeEIvEcdxdXk8scEbbF8sjczYyeSD0
  //yPzrU0rwVr+jeKI7mzVWtYZh+8aVR5keeQR16VheHPDureJbWWC3uhDZwNk+Yx2lz7DqcAfpVvRNc1Twd4jOnX0jvbrII54S2QAejLnp1B9xQB6fq/ifR9AZUv7oLMwyIkUs2PoOn41zviPxdouveDNU
  //hsrr/SBGh8mRSrEb16Z4P4VweiWMvjHxgsd3KQbl2lmdeoUDJA/kPSuv8deAdM0nw82p6WkkLW7KJUZywdSQuee+SP1oAd8GhmbWP8Adh/9nr1xFr5Whubi23eRPLFu67HK5/Kva9M8TW3gTw3plpr93
  //PeXV0pnQwDzNkbHjJYjj/69AHoyLU6LXz98R/7TsPEn2xdWkktdQQXFsIpmG2PAwMdB+FdeV1vwd8Hr6W9vzLeXbL5B3sxhSQKCMnkHG48cA0AdVqnxM8K6PePaTXzTTRttcW8ZcKfr0/I15/8AFvxFp
  //PiTSdDutJvEuI1knDgAqyHCdVPIrO+Ffgax8W3V7dapveztNqiJGK+YzZ6kc4AHb1FRfFPwTZ+ENUs5NNLiyvUYrG7bjGy4yAeuMMOvvQB678H1z8NdM/3pv/RrUVL8HVz8MtLP+1N/6NeigDlAAqliQ
  //AOSTXlWpX1rL46F6kytai5iYyDpgbcn9DXrRhWWJo3GUcFWHqDXMj4aaMzZ+0XwHoJE/wDiaAKvxB1Gyn0C2hiuY3klkSZFU5ymGG76VhxxRal8NzDBIr3dhObiSIfeCHIJ+nOfwrtr3wBo+oLbKzXMX
  //2eIQr5TjlRk85B55NaHh/wbpnh+WaW2M8rypsYzsG+XrjAAoA4j4eeKNO0eC6stRl8hJHEschUkE4wQcdOg/WsXWp18V+OHOnozJdSpHHxgkABd36E/SvQb/wCF2jXty00EtxaBjkxxkFB9ARxW74c8E
  //6R4ckM1rG8tyRjz5iGYD0GAAKAPIPDWoHwl4yjk1CNlEDvDOoGSoIIJHrg813PxB8baPe+GX03TbpbqW6K7igOEUENznuSAMfWun8Q+AdH8SzC5nWS3usYM0BAL+m4EEH+dU9P+EugW1tPHM9zcSyoUE
  //rlQY891GMA+5zQB4nHaGTSrm8A4hmijP/Aw5/8AZK3o4Lzxo87ruA0nSF298iIAY/EljXrNt8KtDt9Iv9OjuL5kvPLLPI6lkKEkFcKPU5zWx4R8Cab4Tgukt3luJLrAlebHKjPygAcDk0AeCrdTeKdV8
  //PabIDuijisB7jzDg/8AfLAfhXvPxM0iXU/h9qEVtFvlgCzqg9EILY/4DuqloXwm0XRPEUerw3F1IYXLwwORtQ9snGTjPH9a9EVARgjINAHz98H/ABnpnhu5v7DVpvs8F1seOcglVZcgg46ZBHPtUPxf8
  //Y6d4n1SxtdKl8+1sUfM4BAd3xkDPUAKOfc16PrHwT8N6tevdW0t1p7SNlooCpj98KRx+Bx7VYPwQ8KSaTFY5vo3R/Me5SRfNkOMYJKkAewAoAvfBpc/DDS/96b/ANGvRXVeF/Ddp4V0G30exknkt4CxV
  //p2Bc7mLHJAA6n0ooA8zRanRaRFqwi0AKi1Oi0iLVhFoAVFqdFpEWrCLQAqLU6LSItWEWgBUWp0WkRasItACotTotIi1YRaAFRanRaRFqwi0AKi0VMi0UAeNoBVhBRRQBOgFWEAoooAnQVYQCiigCwgFToKKKALCAVOgFFFAE6AVYQUUUAWEAqdBRRQBYQCiiigD/9k=";;
  //   return "data:image/png;base64," + byteStream;
  // }

  //ngAfterViewInit() {
  //  //this.oldNavbarWidth = this.sideNavbar.nativeElement.offsetWidth;
  //  //this.sidebarLogoService.setSidebarWidth(this.sideNavbar.nativeElement.offsetWidth);
  //  // Get the initial max width for the #comps element (for overflow)
  //  //this.logo.nativeElement.style.width = val + 'px';
  //  this.comps.nativeElement.style.maxWidth = '1709px';

  //  let subjectGridWidth: number = this.subjectGrid.nativeElement.offsetWidth;
  //  subjectGridWidth += 10; // for the left margin
  //  if ()
  //}

  //ngAfterViewChecked() {
  //  if (this.oldNavbarWidth !== this.sideNavbar.nativeElement.offsetWidth) {
  //    this.sidebarLogoService.setSidebarWidth(this.sideNavbar.nativeElement.offsetWidth);
  //    this.oldNavbarWidth = this.sideNavbar.nativeElement.offsetWidth;
  //  }
  //}
}