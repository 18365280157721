
<div class="report container-fluid">
  <div >
      <!--Subject-->
    <div class="mc-subject flex-row">
      <ng-container *ngTemplateOutlet="subject; context:{marketConditionsCount: mcCount}"></ng-container>
    </div>
    <div class="row container-fluid justify-content-between {{isNeighborhoodAnalysis ? 'neighborhood' : ''}}">      
      <!--1004MC-->
      <div class="flex-col">
        <div *ngIf="!isNeighborhoodAnalysis">
          <div class="header-row">
            <div class="label-header">Inventory Analysis</div>
            <div class="input-header">Prior 7-12</div>
            <div class="input-header">Prior 4-6</div>
            <div class="input-header">Current - 3</div>
            <div class="input-header trend">Overall trend</div>
          </div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Total Sales', reportItemName: 'totalSales', reportItem: 'totalSales', radioOrder: 'increasing',  marketConditionsForm: marketConditionsForm,  mcCheckboxName: 'McBoxTotalSales'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Absorption Rate', reportItemName: 'absorptionRate', reportItem: 'absorptionRate',radioOrder: 'increasing',  marketConditionsForm: marketConditionsForm, mcCheckboxName: 'McBoxAbsorptionRate'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Active Listings', reportItemName: 'totalListings', reportItem: 'totalListings', radioOrder: 'declining',  marketConditionsForm: marketConditionsForm, mcCheckboxName: 'McBoxTotalListings'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Housing Supply', reportItemName: 'housingSupply',  reportItem: 'housingSupply',radioOrder: 'declining',  marketConditionsForm: marketConditionsForm, mcCheckboxName: 'McBoxHousingSupply'}"></div>

          <div class="header-row mt-4">
            <div class="label-header">Median Values</div>
            <div class="input-header">Prior 7-12</div>
            <div class="input-header">Prior 4-6</div>
            <div class="input-header">Current - 3</div>
            <div class="input-header trend">Overall trend</div>
          </div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Median Sale Price', reportItemName: 'medianSalePrice', reportItem: 'medianSalePrice', radioOrder: 'increasing',  marketConditionsForm: marketConditionsForm, mcCheckboxName: 'McBoxSalePrice'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Median Sale DOM', reportItemName: 'medianSalesDOM', reportItem: 'medianSalesDOM', radioOrder: 'declining',  marketConditionsForm: marketConditionsForm, mcCheckboxName: 'McBoxSalesDom'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Median List Price', reportItemName: 'medianListPrice',  reportItem: 'medianListPrice',radioOrder: 'increasing',  marketConditionsForm: marketConditionsForm, mcCheckboxName: 'McBoxListPrice'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Median Listing DOM', reportItemName: 'medianListingDOM', reportItem: 'medianListingDOM',radioOrder: 'declining',  marketConditionsForm: marketConditionsForm, mcCheckboxName: 'McBoxListingDom'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Median. Sale-to-List Ratio', reportItemName: 'medianSaleToListRatio',reportItem: 'medianSaleToListRatio',  radioOrder: 'increasing',  marketConditionsForm: marketConditionsForm, mcCheckboxName: 'McBoxSaleToListRatio'}"></div>

          <div class="row mc-row yes-no">
            <div class="question-container">
              <label class="row-label no-width">
                Seller paid financial assistance prevelant?
              </label>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="sellerPaidYes" name="sellerPaid" class="custom-control-input">
                <label class="custom-control-label" for="sellerPaidYes">Yes</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="sellerPaidNo" name="sellerPaid" class="custom-control-input">
                <label class="custom-control-label" for="sellerPaidNo">No</label>
              </div>
            </div>

            <div *ngTemplateOutlet="trendRadioGroup; context: {reportItemName: 'sellerPaidAssistance', radioOrder: 'declining', mcCheckboxName: 'McBoxSellerAssistance'}"></div>

          </div>

        <div class="row mc-row textarea-row flex-col">
          <label class="row-label no-width">Explain in detail the seller concessions trends for the past 12 months</label>
          <app-mc-comment rows="5" [CommentSectionType]="commentSectionType.McSellerConcessions"  ></app-mc-comment>
        </div>

        <div class="row mc-row textarea-row yes-no">
          <label class="row-label no-width">Are foreclosure sales (REO Sales) a factor in the market?</label>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="foreclosureSalesFactorYes" name="foreclosureSalesFactor" class="custom-control-input">
            <label class="custom-control-label" for="foreclosureSalesFactorYes">Yes</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="foreclosureSalesFactorNo" name="foreclosureSalesFactor" class="custom-control-input">
            <label class="custom-control-label" for="foreclosureSalesFactorNo">No</label>
          </div>
          <label class="row-label no-width explain">if yes, explain</label>
        </div>
        <div class="row mc-row textarea-row flex-col">
          <app-mc-comment rows="5" [CommentSectionType]="commentSectionType.McReoSales"  ></app-mc-comment>
        </div>
        <div class="row mc-row textarea-row flex-col">
          <label class="row-label no-width">Cite data sources for above information</label>
          <app-mc-comment rows="5" [CommentSectionType]="commentSectionType.McCiteDataSources"  ></app-mc-comment>
        </div>
        
        <div class="row mc-row textarea-row flex-col">
          <label class="row-label no-width">Summary of the above information</label>
          <app-mc-comment  rows="5" [CommentSectionType]="commentSectionType.McSummary"  ></app-mc-comment>
        </div>       
      </div>
      </div> 
      <div class="flex-col"> 
        <ng-container *ngIf="isNeighborhoodAnalysis">
          <app-neighborhood #neighborhoodComponent [CompetingMarketForm]="marketConditionsForm" [NeighborhoodAnalysisForm]="neighborhoodAnalysisForm" ></app-neighborhood>          
        </ng-container>
      </div> 
      <div class="flex-col">
        <app-search-criteria-summary [CompetingMarketForm]="marketConditionsForm" [NeighborHoodAnalysisForm]="neighborhoodAnalysisForm"></app-search-criteria-summary>
        <app-annual-comparison-summary [CompetingMarketForm]="marketConditionsForm" [NeighborHoodAnalysisForm]="neighborhoodAnalysisForm"></app-annual-comparison-summary>
      </div>        
    </div>
    <!--PROJECT MC-->
    <ng-container *ngIf="showProjectMarketConditions">
        <div class="proj-mc-container">
          <div class="row proj-mc-row">
            <div class="col-auto pl-0">
              <label class="inline-label">
                Condominium or Cooperative Project
              </label>
            </div>
            <div class="col proj-col">
              <input type="text" name="projectType" class="form-control" value="projectType" [(ngModel)]="projectType" (ngModelChange)="updateInputModelChange($event)"/>
            </div>
            <div class="col-auto">
              <label class="inline-label">
                Project Name
              </label>
            </div>
            <div class="col proj-col">
              <input type="text" name="projectName" class="form-control" value="projectName" [(ngModel)]="projectName" (ngModelChange)="updateInputModelChange($event)"/>
            </div>
          </div>
          <div class="row prop-count-description-row">
            <ng-container *ngTemplateOutlet="propertyCount; context:{marketConditionsCount:getProjecMarketConditionsPropertyCount()}"></ng-container>
          </div>
          <div class="header-row">
            <div class="label-header">Subject Project Data</div>
            <div class="input-header">Prior 7-12</div>
            <div class="input-header">Prior 4-6</div>
            <div class="input-header">Current - 3</div>
            <div class="input-header trend">Overall trend</div>
          </div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Total Sales', reportItemName: 'projTotalSales', reportItem: 'totalSales', radioOrder: 'increasing',  marketConditionsForm: projectMarketConditionsForm , mcCheckboxName: 'ProBoxTotalSales'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Absorption Rate', reportItemName: 'projAbsorptionRate',reportItem: 'absorptionRate', radioOrder: 'increasing',  marketConditionsForm: projectMarketConditionsForm, mcCheckboxName: 'ProBoxAbsorptionRate'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Active Listings', reportItemName: 'projTotalListings',reportItem: 'totalListings', radioOrder: 'declining',  marketConditionsForm: projectMarketConditionsForm, mcCheckboxName: 'ProBoxTotalListings'}"></div>
          <div *ngTemplateOutlet="reportRow; context:{label:'Monthly of Unit Supply', reportItemName: 'projHousingSupply', reportItem: 'housingSupply', radioOrder: 'declining',  marketConditionsForm: projectMarketConditionsForm, mcCheckboxName: 'ProBoxUnitSupply'}"></div>

          <div class="row mc-row textarea-row yes-no">
            <label class="row-label no-width">Are foreclosure sales (REO Sales) a factor in the market?</label>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="projectForeclosureSalesFactorYes" name="projectForeclosureSalesFactor" class="custom-control-input">
                <label class="custom-control-label" for="projectForeclosureSalesFactorYes">Yes</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="projectForeclosureSalesFactorNo" name="projectForeclosureSalesFactor" class="custom-control-input">
                <label class="custom-control-label" for="projectForeclosureSalesFactorNo">No</label>
              </div>

              <label class="row-label no-width explain">if yes, explain</label>

              <textarea class="form-control" rows="5"></textarea>
          </div>

          <div class="row mc-row textarea-row flex-col">
            <label class="row-label no-width">Summary of the above information</label>
            <textarea class="form-control" rows="5"></textarea>
          </div>
        </div>
    </ng-container> 
  </div>
</div>  

<!--TEMPLATES-->
<ng-template #reportRow let-rowLabel="label"
let-reportItemName="reportItemName"
let-reportItem="reportItem"
let-radioOrder="radioOrder"
let-marketConditionsForm="marketConditionsForm"
let-mcCheckboxName="mcCheckboxName">

  <div class="row mc-row row-hover">
    <label class="row-label">{{rowLabel}}</label>
    <div class="calculation-values">
      <input readonly type="text" name="{{reportItemName}}SevenToTwelve" class="form-control" value="{{ getPropertyValueByName(marketConditionsForm?._7_12MO, reportItem) }}" />
    </div>
    <div class="calculation-values">
      <input readonly type="text" name="{{reportItemName}}FourToSix" class="form-control" value="{{getPropertyValueByName(marketConditionsForm?._4_6MO, reportItem) }}" />
    </div>
    <div class="calculation-values">
      <input readonly type="text" name="{{reportItemName}}CurrentToThree" class="form-control" value="{{ getPropertyValueByName(marketConditionsForm?._0_3MO, reportItem) }}" />
    </div>

    <div *ngTemplateOutlet="trendRadioGroup; context: {reportItemName: mcCheckboxName, radioOrder: radioOrder}"></div>

  </div>

</ng-template>

<ng-template #trendRadioGroup let-reportItemName="reportItemName" let-radioOrder="radioOrder">
  <div class="col-md">
    <ng-container *ngTemplateOutlet="trendRadio; context : {reportItemName: reportItemName, radioOrder: (radioOrder === 'increasing' ? 'Increasing' : 'Declining') , order:1 }">
    </ng-container>

    <ng-container *ngTemplateOutlet="trendRadio; context : {reportItemName: reportItemName, radioOrder: 'Stable' , order:2 }">
    </ng-container>

    <ng-container *ngTemplateOutlet="trendRadio; context : {reportItemName: reportItemName, radioOrder: (radioOrder === 'increasing' ? 'Declining' : 'Increasing'), order:3  }">
    </ng-container>
  </div>
</ng-template>

<ng-template #trendRadio let-reportItemName="reportItemName" let-radioOrder="radioOrder" let-order="order">
    <div class="custom-control custom-radio custom-control-inline increasing-decreasing">
      <input type="radio" id="customRadioInline{{order +reportItemName}}" name="customRadioInline1{{reportItemName}}" class="custom-control-input" (change)="setTrendUserInput($event, reportItemName)" [checked]="getTrendUserInput(radioOrder, reportItemName)"  value="{{radioOrder}}">
      <label class="custom-control-label" for="customRadioInline{{order + reportItemName}}">{{radioOrder}}</label>
    </div>
</ng-template>

<ng-template #propertyCount let-marketConditionsCount="marketConditionsCount">

  <div class="description" [ngSwitch]="marketConditionsCount">
    <div *ngSwitchCase="0">No properties were included in the market conditions report.</div>
    <div *ngSwitchCase="1">1 property included.</div>
    <div *ngSwitchDefault>{{marketConditionsCount }} properties included.</div>
  </div>

</ng-template>

<ng-template #subject let-marketConditionsCount="mcCount" let-isLargerMarket="isLargerMarket">

  <div class="flex-row checkbox-input">
    <div *ngIf="this.mcCount === 0">No properties were included in the market conditions report.</div>
    <div *ngIf="this.mcCount > 0">
        <label>
            <input type="checkbox" name="applyToNeighborhoodSection" [(ngModel)]="showSummarySection" 
              (change)="applyToNeighborhoodSectionChecked($event)">
                Apply this to the Neighborhood Section
        </label>
          <span>{{mcCount}} properties were included in the market conditions report.</span>
          <label>Graph Data Range:</label>
          {{lastDate | date : "MM.dd.y"}} - {{effectiveDate | date : "MM.dd.y"}}
      </div>
    </div>
    <ng-container >
      <app-mc-subject *ngIf="this.showSummarySection" [mcCount]="mcCount" [isNeighborhoodLargerMarket]="isNeighborhoodAnalysis" ></app-mc-subject>
    </ng-container>
</ng-template>