import { Component, Input, OnInit } from '@angular/core';
import { DataMasterFileService, MarketConditionsForm } from '@datamaster/core';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-annual-comparison-summary',
  templateUrl: './annual-comparison-summary.component.html',
  styleUrls: ['./annual-comparison-summary.component.scss'],
})
export class AnnualComparisonSummaryComponent implements OnInit {
  @Input() CompetingMarketForm : MarketConditionsForm;
  @Input() NeighborHoodAnalysisForm : MarketConditionsForm;
  effectiveDate : Date;
  subscription: Subscription;
  constructor(private datePipe: DatePipe,
    private dataMasterFileService : DataMasterFileService) {}

  ngOnInit(): void {
    this.effectiveDate = this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDate;
    this.subscription = this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.effectiveDateChanged.subscribe((date)=>{
      this.effectiveDate = date;
    })
  }
  ngOnDestroy():void{
    this.subscription.unsubscribe();
  }

  get EffectiveDateYearSpan() : string{
    if(!this.effectiveDate)
      return '';
    const date = new Date(this.effectiveDate);
    date.setFullYear(date.getFullYear() -1);
    date.setDate(date.getDate()+1);
    return  this.getYearSpanString(date, this.effectiveDate);
   
  }
  get EffectiveDatePreviousYearSpan() : string {
    if(!this.effectiveDate)
      return '';
    const date = new Date(this.effectiveDate);
    date.setFullYear(date.getFullYear() -2);
    date.setDate(date.getDate()+1);
    const secondDate = new Date(this.effectiveDate);
    secondDate.setFullYear(secondDate.getFullYear() -1);
    return  this.getYearSpanString(date, secondDate);   
  }

  // get CompetingMarketEffectiveDateYearSpan() : string{
  //   return this.getYearSpanString(this.CompetingMarketForm._0_12MO.lastDay, this.CompetingMarketForm._0_12MO.firstDay);
  // }
  // get CompetingMarketEffectiveDatePreviousYearSpan() : string{
  //   return this.getYearSpanString(this.CompetingMarketForm._13_24MO.lastDay, this.CompetingMarketForm._13_24MO.firstDay);
  // }

  // get LargerMarketEffectiveDateYearSpan() : string{
  //   return this.getYearSpanString(this.NeighborHoodAnalysisForm._0_12MO.lastDay, this.NeighborHoodAnalysisForm._0_12MO.firstDay);
  // }

  // get LargerMarketEffectiveDatePreviousYearSpan() : string{
  //   return this.getYearSpanString(this.NeighborHoodAnalysisForm._13_24MO.lastDay, this.NeighborHoodAnalysisForm._13_24MO.firstDay);
  // }
  
  getYearSpanString(lastDay : Date, firstDay: Date, dateTimeFormat  : string = null ): string  {
      if (!lastDay || !firstDay  || lastDay > firstDay)
          return '';

      if (!dateTimeFormat)
          dateTimeFormat = 'MM/dd/yy';
      
      return this.datePipe.transform(lastDay, dateTimeFormat) + ' - ' + this.datePipe.transform(firstDay, dateTimeFormat);
  }
}
