import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-tell-me-more',
  templateUrl: './tell-me-more.component.html',
  styleUrls: ['./tell-me-more.component.scss']
})
export class TellMeMoreComponent implements OnInit {

  @Input() template : TemplateRef<any>;
  constructor() { }

  ngOnInit(): void {
  }

}
