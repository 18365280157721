import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { MarketConditionsPage } from '../models/market-conditions/market-conditions-page.model';
import { MarketConditionsForm } from '../models/market-conditions/market-conditions-form.model';
import { CommentBlock, CommentSectionType, MCComments } from '../models/market-conditions/comment-block.model';
import { DataMasterApiService } from './property-search/datamaster-api.service';
import { shareReplay } from 'rxjs/operators';
import { UserStateService } from './user/user-state.service';
import { CalculatedTags } from '../models/operation/market-conditions/calculated-tags.model';
import { CommentSectionCommentsRequest } from '../models/market-conditions/comment-section-comments-request.model';
import { SaveCommentBlockRequest } from '../models/market-conditions/save-comment-block-request.model';
import { DeleteCommentBlockRequest } from '../models/market-conditions/delete-comment-block-request.model';
import { UpdateCommentBlockRequest } from '../models/market-conditions/update-comment-block-request.model';
import { MarketConditionsGraph } from '../models/market-conditions/market-conditions-graph.model';

@Injectable({
  providedIn: 'root',
})
export class MarketAnalysisReportService implements OnDestroy{

  marketConditionsPage: MarketConditionsPage;
  marketConditionsForm: MarketConditionsForm;
  neighborhoodAnalysisForm: MarketConditionsForm;
  projectMarketConditionsForm: MarketConditionsForm;
  calculatedTags : CalculatedTags = null;
  neighborhoodAnalysisCalculatedTags : CalculatedTags = null;//TODO implement with Neighborhood analysis
  marketConditionsGraphs: MarketConditionsGraph[];
  calculatedTagsChanged: EventEmitter<CalculatedTags> = new EventEmitter();
  private _mcCommentsObservable: Observable<MCComments> = null;
  private _mcComments: MCComments = null;
  private _mcCommentsObservableSubscription: Subscription;

  constructor(
    private datamasterApi: DataMasterApiService,
    private userStateService: UserStateService
  ) {}

  public clearCalculatedTags(shouldNotifyOfChange : boolean): void{
    this.calculatedTags = null;
    if(shouldNotifyOfChange)
      this.emitCalculatedTagsChanged();
  }

  public mergeCalculatedTags(newTags : CalculatedTags){
    if(this.calculatedTags == null){
      this.calculatedTags = newTags;
      this.emitCalculatedTagsChanged();
      return;
    }
    for( const key in newTags){
      this.calculatedTags = newTags[key] ?? this.calculatedTags[key];
    }
    this.emitCalculatedTagsChanged();
  }

  emitCalculatedTagsChanged(){
    this.calculatedTagsChanged.emit(this.calculatedTags);
  }

  get mcComments(): MCComments {
    return this._mcComments;
  }
  updateDefaultUserCommentBlock(commentSectionType: CommentSectionType, id: number, isNoDataComment: boolean) {
    const request = new UpdateCommentBlockRequest();
    request.userData = this.userStateService.userDataForSearch;
    request.commentSectionType = commentSectionType;
    request.newCommentBlockId = id;
    request.isNoDataComment = isNoDataComment;
    return this.datamasterApi.updateDefaultUserCommentBlock(request);
  }
  saveUserCommentBlock(commentSectionType : CommentSectionType, commentBlock : CommentBlock): Observable<any> {
    const request = new SaveCommentBlockRequest();
    request.userData = this.userStateService.userDataForSearch;
    request.commentSectionType = commentSectionType;
    request.commentBlock = commentBlock;
    return this.datamasterApi.saveUserCommentBlock(request);

  }
  updateUserCommentBlock(commentSectionType : CommentSectionType, commentBlock : CommentBlock) : Observable<any> {
    const request = new SaveCommentBlockRequest();
    request.userData = this.userStateService.userDataForSearch;
    request.commentSectionType = commentSectionType;
    request.commentBlock = commentBlock;
    return this.datamasterApi.updateUserCommentBlock(request);

  }
  deleteUserCommentBlock(commentSectionType: CommentSectionType, commentBlock: CommentBlock): Observable<CommentBlock> {
    const request = new DeleteCommentBlockRequest();
    request.userData = this.userStateService.userDataForSearch;
    request.commentBlock = commentBlock;
    request.commentSectionType = commentSectionType;
    request.isNoDataComment = commentBlock.isNoDataComment;
    return this.datamasterApi.deleteUserCommentBlock(request);
  }
  requestMCComments(): Observable<MCComments> {
    if (this._mcComments != null) {
      return of(this._mcComments);
    }
    if (this._mcCommentsObservable == null) {
      const userId = this.userStateService.userDataForSearch?.authUserId;
      this._mcCommentsObservable = this.datamasterApi.getMCComments(userId).pipe(shareReplay(1));

      this._mcCommentsObservableSubscription = this._mcCommentsObservable.subscribe(
        (mcComments) => {
          this._mcComments = mcComments;
        }
      );
    }
    return this._mcCommentsObservable;
  }
  getCommentsSectionComments(commentSectionType: CommentSectionType): Observable<CommentBlock[]> {

    const userId = this.userStateService.userDataForSearch?.authUserId;
    const commentSectionCommentsRequest : CommentSectionCommentsRequest = {userId : userId, commentSectionType : commentSectionType};
    return this.datamasterApi.getCommentsSectionComments(commentSectionCommentsRequest);
  }
  setComment(commentSectionType: CommentSectionType, isNoDataComment: boolean, commentBlock: CommentBlock) {
    switch (commentSectionType) {
      case CommentSectionType.McSellerConcessions:
        if(isNoDataComment)
          this._mcComments.noDataMcSellerConcessions = commentBlock;
        else
          this._mcComments.mcSellerConcessions = commentBlock;
        break;
      case CommentSectionType.McReoSales:
        if(isNoDataComment)
          this._mcComments.noDataMcReoSales = commentBlock;
        else
          this._mcComments.mcReoSales = commentBlock;
        break;
      case CommentSectionType.McCiteDataSources:
        this._mcComments.mcCiteDataSources = commentBlock;
        break;
      case CommentSectionType.McSummary:
        this._mcComments.mcSummary = commentBlock;
        break;
      case CommentSectionType.NeighborhoodAnalysisSummary:
        this._mcComments.neighborhoodAnalysisSummary = commentBlock;
        break;
      case CommentSectionType.ProReoSales:
        if(isNoDataComment)
          this._mcComments.noDataProReoSales = commentBlock;
        else
          this._mcComments.proReoSales = commentBlock;
        break;
      case CommentSectionType.ProSummary:
        if(isNoDataComment)
          this._mcComments.noDataProSummary = commentBlock;
        else
          this._mcComments.proSummary = commentBlock;
        break;
      case CommentSectionType.DaAppraiserComments:
        if(isNoDataComment)
          this._mcComments.noDataDaAppraiserComments = commentBlock;
        else
          this._mcComments.daAppraiserComments = commentBlock;
        break;
    }
  }

  ngOnDestroy(): void {
    this._mcCommentsObservableSubscription.unsubscribe();
  }
}
