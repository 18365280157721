<!-- <div class="modal fade" [id]="id" tabindex="-1" role="dialog" aria-labelledby="customExportModalLabel" -->
<div class="modal fade" id="customExport{{hasAddenda ? 'Addenda' : '' }}Modal" tabindex="-1" role="dialog" aria-labelledby="customExportModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Which Properties would you like to include in your report?
                </h5>
            </div>
            <div class="modal-body">

                <div class="container">
                    <div class="row">
                        <div *ngIf="hasComparables" class="col">
                            <div>
                                <h5>Comparables<span class="position-title">Form Software Position</span></h5>                               
                            </div>
                            <label>
                                <input type="checkbox"  value="comparable" (change)="onChange($event)"/>
                                Select All
                            </label><br>
                            <ng-template ngFor let-comparable [ngForOf]="mainComparables" let-i="index">
                                <app-summary-comparable-export [parcel]="comparable" [index]="i+1" (positionChanged)="changeExportNumber($event)" >
                                </app-summary-comparable-export>
                            </ng-template>
                        </div>
                        <div *ngIf="hasListings" class="col">
                            <div>
                                <h5>Listings<span class="position-title">Form Software Position</span></h5>
                            </div>
                            <label>
                                <input type="checkbox"  value="listing" (change)="onChange($event)"/>
                                Select All
                            </label>
                            <ng-template ngFor let-listing [ngForOf]="listings" let-i="index">
                                <app-summary-comparable-export [parcel]="listing" [index]="i+1" (positionChanged)="changeExportNumber($event)">
                                </app-summary-comparable-export>
                            </ng-template>
                        </div>

                        <div *ngIf="hasReoListings" class="col">
                            <div>
                                <h5>Reo Listings<span class="position-title">Form Software Position</span></h5>
                            </div>
                            <label>
                                <input type="checkbox"   value="reoListing"  (change)="onChange($event)"/>
                                Select All
                            </label>
                            <ng-template ngFor let-reoListing [ngForOf]="reoListings" let-i="index">
                                <app-summary-comparable-export [parcel]="reoListing" [index]="i+1" (positionChanged)="changeExportNumber($event)">
                                </app-summary-comparable-export>
                            </ng-template>
                        </div>
                        <div *ngIf="hasRentals" class="col">
                            <div>
                                <h5>Rentals<span class="position-title">Form Software Position</span></h5>
                            </div>
                            <label>
                                <input type="checkbox"  value="rental"   (change)="onChange($event)"/>
                                Select All
                            </label>
                            <ng-template ngFor let-rental [ngForOf]="rentals" let-i="index">
                                <app-summary-comparable-export [parcel]="rental" [index]="i+1" (positionChanged)="changeExportNumber($event)">
                                </app-summary-comparable-export>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" [disabled]="!canExport" class="btn btn-secondary" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>