import { Component, Input, OnInit } from '@angular/core';
import { Comparable, DataMasterFileService, DataSource, Parcel, PropertyUsageType, StandardizedParcel } from '@datamaster/core';

@Component({
    selector: 'app-comp-details',
    templateUrl: './comparable-details.component.html',
    styleUrls: ['./comparable-details.component.scss'],
})
export class ComparableDetailsComponent implements OnInit {
    
    @Input() parcel: Parcel;
    @Input() mlsDataSources: DataSource[];
    Parcel : Comparable;
    FileName : string;
    DataSourceDisplayName : string;
    MlsNumber : string;
    ApnNumber : string;
    public get HasProximityToSubject(): boolean {
        
        if(!this.StandardizedParcel) 
            return false;
        if(typeof this.StandardizedParcel.proximityToSubject !='undefined' && this.StandardizedParcel.proximityToSubject)
            return true;
        return false;
    }
    StandardizedParcel : StandardizedParcel;
    PropertyUseType : string;

    constructor(private dataMasterFileService : DataMasterFileService) {}

    ngOnInit(): void {
        if(!this.parcel)
            return;
        this.Parcel = this.parcel as Comparable;
        this.FileName = this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.fileNumber;
        this.StandardizedParcel = this.dataMasterFileService.getTranslatedStandardizedParcel(this.parcel.propertyGuid);
        //this.DataSourceDisplayName = this.mlsDataSources.find(ds => ds.id == this.StandardizedParcel.dataSourceId)?.displayName;
        const dataSources = this.Parcel?.formattedFieldResults[311]?.formattedValue?.split(/(#|;)/);
        this.DataSourceDisplayName = dataSources.length > 2 ? dataSources[0] : '';
        this.MlsNumber = dataSources.length > 2 ?dataSources[2] : '';
        const propertyUsageType = this.parcel.propertyUseType;
        switch (propertyUsageType) {
            case PropertyUsageType.Comparable:
                this.PropertyUseType = 'Comp ';
                break;
            case PropertyUsageType.Subject:
                this.PropertyUseType = 'Subject';
                break;
            case PropertyUsageType.ComparableRental:
                this.PropertyUseType = 'Comp Rental ';
                break;
            case PropertyUsageType.Listing:
                this.PropertyUseType = 'Listing ';
                break;
            case PropertyUsageType.Rental:
                this.PropertyUseType = 'Rental ';
                break;
            case PropertyUsageType.ReoListing:
                this.PropertyUseType = 'REO ';
                break;
            // case PropertyUsageType.ComparableListing:
            //     this.PropertyUseType = 'Competing ';
            //     break;
            default:
                this.PropertyUseType = 'Comp ';
                break;
        }
    }
}
