
<div class="results-header">
  <h5 class="multiple-results-title">Which property would you like to use?</h5>
  
  <h5 class="multiple-results-count">
    <span class="number">{{getResultCount()}}</span> Result(s) Found</h5>
</div>

<table class="table table-striped" data-cy="searchResultsTable">
    <thead>
      <tr>
        <th scope="col">MLS #</th>
        <th scope="col">Tax ID</th>
        <th scope="col" class="left">Address</th>
        <th scope="col" class="left">City/State/Zip</th>
        <div *ngIf="!isAddressSearch">
          <th scope="col">Lot Size</th>
          <th scope="col">Sq. Feet</th>
          <th scope="col">Year Built</th>
          <th scope="col">Bedrooms</th>
          <th scope="col">Bathrooms</th>
        </div>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let translatedPropertyResult of translatedPropertyResults; let i = index;" (click)="rowSelected(translatedPropertyResult);" [class.active]="selectedTranslatedPropertyResult === translatedPropertyResult">
        <td class="small-text">{{translatedPropertyResult.translatedParcel.mlsNumber || '--'}}</td>
        <td class="small-text">{{translatedPropertyResult.translatedParcel.apn || '--'}}</td>
        <td class="left">{{translatedPropertyResult.translatedParcel.addressLine1}}</td>
        <td class="left small-text">{{translatedPropertyResult.translatedParcel.city}}, {{translatedPropertyResult.translatedParcel.state}}  {{translatedPropertyResult.translatedParcel.zip}}</td>
        <div *ngIf="!isAddressSearch">
          <td class="left">{{translatedPropertyResult.translatedParcel.siteArea | number}}</td>
          <td class="left">{{translatedPropertyResult.translatedParcel.aboveGradeGla | number}}</td>
          <td>{{translatedPropertyResult.translatedParcel.yearBuilt}}</td>
          <td>{{translatedPropertyResult.translatedParcel.totalAboveGradeBedrooms}}</td>
          <td>{{translatedPropertyResult.translatedParcel.totalAboveGradeBathrooms}}</td>
        </div>
      </tr>
    </tbody>
  </table>    