
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  SpinnerService,
  UserSettingsService,
  DataSource,
  AuthService,
  UserSettings,
  DataSourceService,
  DataMasterFileService,
  Parcel
} from '@datamaster/core';
@Component({
  selector: 'app-subject-deeds',
  templateUrl: './subject-deeds.component.html',
  styleUrls: ['./subject-deeds.component.scss'],
})
export class SubjectDeedsComponent implements OnInit {
  parcel!: Parcel;
  mlsDataSources!: DataSource[];
  prDataSources!: DataSource[];
  userSettings!: UserSettings;

  private username!: string;

  constructor(
    private dataMasterFileService: DataMasterFileService,
    private dataSourceService: DataSourceService,
    private userSettingsService: UserSettingsService,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {
    //TODO: retrieve user data sources from local cache instead. Get rid of waitFor function call
    const p = this.dataSourceService.getUserDataSources().then(((userDataSources) => {
      const mlsDataSources = this.dataSourceService.filterMlsDataSources(userDataSources);
      const prDataSources = this.dataSourceService.filterPrDataSources(userDataSources);

      this.mlsDataSources = mlsDataSources;
      this.prDataSources = prDataSources;
    }));
    this.dataSourceService.waitFor(p);
  }

  ngOnInit() {
    if (!this.dataMasterFileService.dataMasterFile.appraisal.subject.subjectLoaded) {
      return;
    }

    this.parcel = this.dataMasterFileService.dataMasterFile.appraisal.subject;

    this.username = this.authService.getClaims()?.name;

    this.userSettingsService
      .getUserSettings(this.username)
      .pipe(tap((userSettings) => (this.userSettings = userSettings)))
      .subscribe();
  }

  saveUserSettings(event) {
    const spinnerRef = this.spinnerService.open();
    this.userSettingsService.saveUserSettings(this.userSettings, this.username).subscribe(() => {
      spinnerRef.close();
    });
  }

  loading(obsv: Observable<any>) {
    const spinnerRef = this.spinnerService.open();

    obsv.subscribe(() => {
      spinnerRef.close();
    });
  }
}
