import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, Observable, Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
// Services
import { ApnSearchService } from '../../services/property-search/apn-search.service';
import { UserStateService } from '../../services/user/user-state.service';
import { SearchService } from '../../services/property-search/search.service';
// Models
import usaCounties from '../../assets/data/usa-counties.json';
import { PropertySearchResult } from '../../models/operation/property-search/property-search-result.model';
import { SearchResult } from '../../models/operation/property-search/search-result.model';
import { TranslationResult } from '../../models/operation/translation/translation-result.model';
import { SearchType } from '../../models/operation/property-search/search-criteria.model';
import { DataSourceGroupType } from '../../models/operation/enum/data-source-group-type.enum';
import { DataSource } from '../../models/data-source/data-source';
import { ApnSearchStateModel } from './../../models/search/apn-search.model';
// Actions
import { UpdateAPN } from './../../services/property-search/state/model/apn-search.actions';

@Component({
    selector: 'app-apn-search',
    templateUrl: './apn-search.component.html',
    styleUrls: ['./apn-search.component.scss'],
    encapsulation: ViewEncapsulation.None, // TODO: shared CSS not working without this - not sure why
})
export class ApnSearchComponent implements OnInit, OnDestroy {
    searchResults: PropertySearchResult[];
    searching = false;
    hasSearchResultsApn = false; // flag used to show search results if the modal gets closed

    @Input() apnSearchForm: FormGroup = new FormGroup({
        apnNumber: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        county: new FormControl('', Validators.required),
    });
    @Input() public propertyUseType: string;
    @Output() resultsEvent = new EventEmitter<{
        searchResult: SearchResult;
        translationResult: TranslationResult;
        searchType: SearchType;
    }>();
    @Output() noResultsEvent = new EventEmitter();
    @Output() showResultsEvent = new EventEmitter<any>();
    @Output() clearAllSearchesEvent = new EventEmitter<any>();
    @ViewChild('searchError', { static: true }) searchErrorChild;
    @Input() public isDashboardSearch = true;
    stateJson: any = usaCounties;
    countiesJsonApnSearch: any;

    private _subscription = new Subscription();

    apnSearchState$: Observable<ApnSearchStateModel> = this.store.select(
        (state) => state.search.apnSearch
    );

    private atComponentDestroy = new Subject();

    constructor(
        private apnSearchService: ApnSearchService,
        private userStateService: UserStateService,
        private searchService: SearchService,
        private store: Store
    ) {
        // apn-search state change listener
        this.apnSearchState$
            .pipe(takeUntil(this.atComponentDestroy))
            .subscribe((apn) => {
                // add valueChanges observable to subscription, to listen state change event.
                this.stateChangeEventForCountyList();

                if (apn) {
                    // if apn data is not exist in the apn-search state then, set apnSearchForm data to initial values
                    if (apn.apnNumber === '') {
                        this.apnSearchForm.reset();

                        // set 'UT' to initial value for 'state'
                        this.apnSearchForm.controls['state'].setValue('UT');
                    } else {
                        // set values from apn-search state
                        this.apnSearchForm.controls['state'].setValue(
                            apn.state
                        );
                        this.apnSearchForm.controls['apnNumber'].setValue(
                            apn.apnNumber
                        );
                        this.apnSearchForm.controls['county'].setValue(
                            apn.county
                        );
                    }
                }
            });
    }
    private _dataSources: DataSource[];

    public get dataSources(): DataSource[] {
        return this._dataSources;
    }

    @Input()
    public set dataSources(value) {
        this._dataSources = value;
    }

    ngOnInit() {
        this.searching = false;

        // this.apnSearchForm = new FormGroup({
        //   apnNumber: new FormControl('', Validators.required),
        //   state: new FormControl('', Validators.required),
        //   county: new FormControl('', Validators.required),
        // });

        // Not sure if this is the right way to do this.  The json file is used to build the dropdowns
        this.stateJson = this.stateJson.filter(
            (state) => state.UsaTerritory === undefined
        );

        //Set the default state of the APNSearchForm
        // this.apnSearchForm.controls['state'].setValue('UT');
        // this.countiesJsonApnSearch = this.stateJson.filter(
        //   (state) => state.Abbreviation === 'UT'
        // )[0].Counties;

        // this.stateChangeEventForCountyList();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();

        this.atComponentDestroy.next({});
        this.atComponentDestroy.unsubscribe();
    }

    resetForm() {
        this.apnSearchForm.reset();
        this.stateChangeEventForCountyList();
    }

    stateChangeEventForCountyList() {
        this._subscription.add(
            this.apnSearchForm.controls['state'].valueChanges.subscribe(
                (selectedState) => {
                    this.countiesJsonApnSearch = this.stateJson.filter(
                        (state) => state.Abbreviation === selectedState
                    )[0]?.Counties;
                }
            )
        );
    }

    searchValidate(formGroup) {
        this.clearAllSearchesEvent.emit();

        if (formGroup.valid === false) {
            for (const item in formGroup.controls) {
                formGroup.controls[item].markAsTouched();
            }
        } else {
            this.searching = true;
            this.apnSearch();
        }
    }

    getDataSource(): DataSource {
        if (!this._dataSources) {
            return null;
        }
        if (this._dataSources.length > 1) {
            return this._dataSources[0]; //TODO: Add drop down for apn search so user can select data source instead of just grabbing first
        }

        return this._dataSources.find(
            (a) =>
                a.dataSourceGroup.commonName ==
                DataSourceGroupType[
                    DataSourceGroupType.PublicRecords.toString()
                ]
        );
    }

    async apnSearch(): Promise<void> {
        const apnNumber: ApnSearchStateModel = {
            apnNumber: this.apnSearchForm.controls['apnNumber'].value,
            state: this.apnSearchForm.controls['state'].value,
            county: this.apnSearchForm.controls['county'].value,
        };

        // const apnNumber: string = this.apnSearchForm.controls['apnNumber'].value;
        // const state: string = this.apnSearchForm.controls['state'].value;
        // const county: string = this.apnSearchForm.controls['county'].value;

        const searchType = SearchType.APN;

        const dataSourceCommonName = this.getDataSource();

        if (!dataSourceCommonName) {
            this.searchErrorChild.searchHttpError = {
                message: 'Unable to perform the search.',
            };
            this.searching = false;
            return;
        }

        let searchResult: SearchResult;
        await this.getApnSearch(apnNumber, dataSourceCommonName?.commonName)
            .then((data) => {
                searchResult = data;
            })
            .catch((err) => {
                this.searching = false;
                const errorString = `${err.statusText} (${err.status}):  ${err.message}`;
                console.log(errorString); // Log to the console - just for fun
                this.searchErrorChild.searchHttpError = err; // Now display to the user
                throw new Error(errorString);
            });
        this.hasSearchResultsApn =
            this.searchService.doesSearchResultHaveResults(searchResult);
        if (!this.hasSearchResultsApn) {
            this.searchErrorChild.searchHttpError = {
                message: 'Search returned no results.',
            };
        }

        if (this.hasSearchResultsApn) {
            // update apn-search state
            this.store.dispatch(new UpdateAPN(apnNumber));
            const translationResult=  await lastValueFrom( this.searchService
                .translateResults(
                    searchResult,
                    this.getDataSource()?.id,
                    this.userStateService.userDataForSearch
                ));
                this.searching = false;

                        this.resultsEvent.emit({
                            searchResult,
                            translationResult,
                            searchType,
                        });
        } else {
            this.noResultsEvent.emit();
        }
    }
    getApnSearch(
        apnNumber: ApnSearchStateModel,
        commonName: string
    ): Promise<SearchResult> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const searchResult = await lastValueFrom(
                this.apnSearchService.search(
                    apnNumber,
                    this.propertyUseType,
                    commonName,
                    this.userStateService.userDataForSearch
                )
            );

            if (searchResult) {
                resolve(searchResult);
            } else {
                reject();
                throw new Error('');
            }
        });
    }

    // This is a bit of a hack, prob need a more robust solution
    isFormSearching(formGroup): boolean {
        if (formGroup.valid && this.searching == true) {
            return true;
        } else {
            return false;
        }
    }

    showResults(): void {
        this.showResultsEvent.emit();
    }

    clearSearchResults(): void {
        this.searchResults = [];
        this.searching = false;
        this.hasSearchResultsApn = false;
        this.searchErrorChild.searchHttpError = null;
    }
}
