import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
// 3rd party
import { MatDialog } from '@angular/material/dialog';
import { Subscription, forkJoin, Observable, Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
// Services
import { MlsSearchService } from '../../services/property-search/mls-search.service';
import { UserStateService } from '../../services/user/user-state.service';
import { SearchService } from '../../services/property-search/search.service';
import { UserSettingsService } from '../../services/user/user-settings.service';
import { DataSourceService } from '../../services/data-source.service';
// Models
import { DataSource } from '../../models/data-source/data-source';
import { SearchResult } from '../../models/operation/property-search/search-result.model';
import { TranslationResult } from '../../models/operation/translation/translation-result.model';
import { SearchType } from '../../models/operation/property-search/search-criteria.model';
import { DataSourceCredentials } from '../../models/settings/data-source-credentials.model';
import { UserSettings } from '../../models/settings/user-settings.model';
import { OperationStatusType } from '../../models/operation/enum/operation-status-type.enum';
import { MlsSearchStateModel } from '../../models/search/mls-search.model';
import { UserStateModel } from '../../models/user/user-state.model';
// Components
import { MlsCredentialsComponent } from '../../dependency-issues';
// Actions
import { UpdateMLS } from '../../services/property-search/state/model/mls-search.actions';

@Component({
  selector: 'app-mls-search',
  templateUrl: './mls-search.component.html',
  styleUrls: [
    './mls-search.component.scss'
  ],
  encapsulation: ViewEncapsulation.None, // TODO: shared CSS not working without this - not sure why
})
export class MlsSearchComponent implements OnInit, OnDestroy {
  // @Select(state => state.user) userState$: Observable<UserStateModel>;
  // userState = new UserStateModel();
  // Test MLS: 1505252, 2323423
  searching = false;

  hasSearchResultsMls = false; // flag used to show search results if the modal gets closed
  searchHttpError: HttpErrorResponse;

  private _dataSources: DataSource[];

  public get dataSources(): DataSource[] {
    return this._dataSources;
  }

  private _subscription = new Subscription();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  @Input()
  public set dataSources(value) {
    if (value) {
      this._dataSources = value;
      if (this.dataSources && this.dataSources.length > 0) {
        this.selectedDataSource = this.dataSources[0];
      }
    }
  }

  @Input() mlsSearchForm: FormGroup = new FormGroup({
    mlsNumber: new FormControl('', Validators.required),
  });
  @Input() public propertyUseType: string;
  @Output() resultsEvent = new EventEmitter<{
    searchResult: SearchResult;
    translationResult: TranslationResult;
    searchType: SearchType;
  }>();
  @Output() noResultsEvent = new EventEmitter();

  @Output() showResultsEvent = new EventEmitter<any>();
  @Output() clearAllSearchesEvent = new EventEmitter<any>();
  @ViewChild('searchError', { static: true }) searchErrorChild;

  @Input() public isDashboardSearch = true;

  selectedDataSource: DataSource;
  get username(): string {
    return this.userStateService.user.user.userName
  }
  dataSourceCredentials: DataSourceCredentials[];
  userDataSources: DataSource[] = [];
  userSettings: UserSettings;

  private searchResult: SearchResult;
  private get isInternal(): boolean {
    return this.userStateService.user.isInternal;
  }
  private isRetryingSearch = false;

  private atComponentDestroy = new Subject();

  mlsSearchState$: Observable<MlsSearchStateModel> = this.store.select(state => state.search.mlsSearch);

  constructor(
    // private store: Store,
    private mlsSearchService: MlsSearchService,
    private userStateService: UserStateService,
    private searchService: SearchService,
    private userSettingsService: UserSettingsService,
    private dataSourceService: DataSourceService,
    private store: Store,
    public dialog: MatDialog
  ) {
    // this.userState$.pipe(takeUntil(this.atComponentDestroy)).subscribe((userState: UserStateModel) => this.userState = userState);

    // mls-search state change listener
    this.mlsSearchState$.pipe(takeUntil(this.atComponentDestroy)).subscribe(mls => {

      if (mls) {
        // if mls data is not exist in the mls-search state then, set mlsSearchForm data to initial values
        if (mls.mlsNumber === null) {
          this.mlsSearchForm.reset();
        } else {
          // set values from mls-search state data
          this.mlsSearchForm.controls['mlsNumber'].setValue(mls.mlsNumber.toString());
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.atComponentDestroy.next({});
    this.atComponentDestroy.unsubscribe();

    this._subscription.unsubscribe();
  }

  ngOnInit() {
    this.searching = false;

    // this.mlsSearchForm = new FormGroup({
    //   mlsNumber: new FormControl('', Validators.required),
    // });

    if (this.dataSources && this.dataSources.length > 0) {
      this.selectedDataSource = this.dataSources[0];
    }
  }

  resetForm() {
    this.mlsSearchForm.reset();
  }

  searchValidate(formGroup) {
    this.clearAllSearchesEvent.emit();

    if (formGroup.valid === false) {
      for (const item in formGroup.controls) {
        formGroup.controls[item].markAsTouched();
      }
    } else {
      this.mlsSearch();
    }
  }

  private async mlsSearch(): Promise<void> {
    this.searching = true;
    const mlsNumber: string[] | number[] = this.mlsSearchForm.controls['mlsNumber'].value.split(',');
    const searchType = SearchType.MLS;

    let searchResult : SearchResult;
    await this.getMlsSearchResult(mlsNumber).then(data=>{
      searchResult = data;
    }).catch(err => {
      const errorString = `${err.statusText} (${err.status}):  ${err.message}`;
      this.searching = false;
      console.log(errorString); // Log to the console - just for fun
          this.searchErrorChild.searchHttpError = err; // Now display to the user
      throw new Error(errorString);
    });

    let isCredentialUpdated;
    await this.verifyAuthentication(searchResult).then(data=>{
      isCredentialUpdated = data;
    }).catch(err => {
      const errorString = `${err.statusText} (${err.status}):  ${err.message}`;
      this.searching = false;
      console.log(errorString); // Log to the console - just for fun
          this.searchErrorChild.searchHttpError = err; // Now display to the user
      throw new Error(errorString);
    });;
    
    if (!isCredentialUpdated) {
    //successful search
    const hasSearchResultsMls = this.searchService.doesSearchResultHaveResults(this.searchResult);
    if (!hasSearchResultsMls) {
      if (this.searchResult.exception) {
        this.searchErrorChild.searchHttpError = {
          message: this.searchResult.exception.Message,
        };
      } else {
        this.searchErrorChild.searchHttpError = {
          message: 'Search returned no results.',
        };
      }

      this.noResultsEvent.emit();
      this.searching = false;
    } else {
      // update mls-search state.
      this.store.dispatch(new UpdateMLS(mlsNumber));
      const translationResult = await lastValueFrom(this.searchService
        .translateResults(
          this.searchResult,
          this.selectedDataSource.id,
          this.userStateService.userDataForSearch
        ));
        this.resultsEvent.emit({
          searchResult: this.searchResult,
          translationResult,
          searchType,
        });
    }
    this.searching = false;
  } else if (isCredentialUpdated) {
    this.isRetryingSearch = true;
    this.mlsSearch();
  };
  }
  verifyAuthentication(searchResult : SearchResult) : Promise<boolean> {
    
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve, reject) =>{
      if (searchResult.status !== OperationStatusType.AuthenticationError) {
        this.searchResult = searchResult;
        this.isRetryingSearch = false;
        resolve(false);
        return;
      }

      const userSettings = await lastValueFrom(this.userSettingsService.getUserSettings(this.username));
      
      this.userSettings = userSettings;
      this.dataSourceCredentials = userSettings.dataSourceCredentials ?? [];//Do we need this?

      let userDataSources : DataSource[];
      await this.dataSourceService.getUserDataSources().then((data)=>{
        userDataSources = data;
      });
      userDataSources?.forEach((a) => this.userDataSources.push(a));

      if (
        !userDataSources ||
        (userSettings.dataSourceCredentials?.length ?? 0) ==
        (userDataSources?.length ?? 0)
      ) {
        resolve(false);
        return;
      }
      const dataSourceIds = userDataSources
      .filter(
        (a) =>
          !userSettings.dataSourceCredentials?.some(
            (b) => b.dataSourceCommonName == a.commonName
          ) ?? true
      )
      .map((a) => a.id);

      this.dataSourceCredentials  = await lastValueFrom(this.dataSourceService.getDataSourceCredentials(dataSourceIds));
      
      const mlsCredentialsDialogRef = this.dialog.open(MlsCredentialsComponent, {
        width: '500px',
        data: {
          dataSource: this.selectedDataSource,
          loginMessage: searchResult.exception.Message,
          isPasswordRequired: this.dataSourceCredentials.find((e) => true)
            ?.isUserPasswordRequired,
          username: this.username,
          isInternal: this.isInternal,
          userSettings: this.userSettings,
        },
      });
      resolve(await lastValueFrom(mlsCredentialsDialogRef.componentInstance.isCredentialUpdated));  
    });
  }
  getMlsSearchResult(mlsNumber: string[] | number[]): Promise<SearchResult>  {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const searchResult = await lastValueFrom(this.mlsSearchService.search(
      mlsNumber,
      this.selectedDataSource.commonName,
      this.propertyUseType,
      this.userStateService.userDataForSearch
      ));
      if (searchResult) {
        resolve(searchResult);
    }else {
        reject()
        throw new Error('');
    }
    });
  }

  // This is a bit of a hack, prob need a more robust solution
  isFormSearching(formGroup): boolean {
    if (formGroup.valid && this.searching == true) {
      return true;
    } else {
      return false;
    }
  }

  showResults(): void {
    this.showResultsEvent.emit();
  }

  clearSearchResults(): void {
    this.searching = false;
    this.hasSearchResultsMls = false;
    this.searchErrorChild.searchHttpError = null;
  }

  handleChange(target: any) {
    const index = target.selectedIndex;
    this.selectedDataSource = this.dataSources[index];
    console.log(this.selectedDataSource);
  }
}
