
<div class="widget">
    <div class="graph-actions">
        <label class="include-in-report">
            <input type="checkbox" [(ngModel)]="marketConditionsGraph.includeInExport" (change)="saveIncludeInExportPreference($event)">
            Include In Report
        </label>
        <button class="btn btn-primary copy-graph-button" (click)="copyToClipboard()">
            <small><i class="fa fa-copy"></i> Copy Graph to Clipboard</small>
        </button>
    </div>
    <div class="graph-container">
        <button type="reset" class="btn remove-graph-button" (click)="removeGraph()">
            <i class="fa fa-times"></i>
        </button>
        <canvas #canvasForChart class="graph-canvas"></canvas>
    </div>
</div>