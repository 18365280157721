import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

// Actions
import { UpdateAPN, ResetAPN } from './model/apn-search.actions';
import { ResetAddress } from './model/address-search.actions';
import { ResetMLS } from './model/mls-search.actions';

// Model
import { ApnSearchStateModel } from '../../../models/search/apn-search.model';

@State<ApnSearchStateModel>({
    name: 'apnSearch',
    defaults: new ApnSearchStateModel()
})
@Injectable()
export class ApnSearchState {

    constructor(private store: Store) { }

    /**
     * Get APN search data.
     * @param state: @see ApnSearchStateModel
     */
    @Selector()
    static getApnSearchData(state: ApnSearchStateModel): ApnSearchStateModel {
        return state;
    }

    /**
     * update APN state
     * @param ctx 
     * @param apn
     * @returns 
     */
    @Action(UpdateAPN)
    updateAPN(ctx: StateContext<ApnSearchStateModel>, { apn }: UpdateAPN): void {
        // Update APN State
        ctx.patchState({
            ...apn
        });

        // Reset Address and MLS state
        this.store.dispatch(new ResetAddress());
        this.store.dispatch(new ResetMLS());
    }

    /**
     * reset APN state
     * @param ctx 
     * @returns 
     */
    @Action(ResetAPN)
    resetAPN(ctx: StateContext<ApnSearchStateModel>): void {
        const resetState = new ApnSearchStateModel();
        ctx.patchState({
            ...resetState
        });
    }
}