<h3>Formatting</h3>

<div class="setting-group">
    <h6 class="setting-group-heading">Verification Document #</h6>
    <label class="setting-drop-down-label">Verification Document #:</label>
    <select name="defaultVerificationDocumentNumber" [value]="defaultVerificationDocumentNumber">
        <option *ngFor="let defaultVerificationDocumentNumberOption of defaultVerificationDocumentNumberOptions" [value]="defaultVerificationDocumentNumberOption">
            {{defaultVerificationDocumentNumberOption}}
        </option>
    </select>
</div>

<div class="setting-group">
    <h6 class="setting-group-heading">Days On Market (Sales Grid)</h6>
    <label class="setting-drop-down-label">Days On Market:</label>
    <select name="daysOnMarket" [value]="daysOnMarket">
        <option *ngFor="let defaultDaysOnMarketOption of defaultDaysOnMarketOptions" [value]="defaultDaysOnMarketOption">
            {{defaultDaysOnMarketOption}}
        </option>
    </select>
</div>

<div class="setting-group">
    <h6 class="setting-group-heading">Actual Age</h6>
    <label class="setting-drop-down-label">Calculate Actual Age on:</label>
    <select name="defaultActualAgeCalculation" [value]="defaultActualAgeCalculation">
        <option *ngFor="let defaultActualAgeOption of defaultActualAgeOptions" [value]="defaultActualAgeOption">
            {{defaultActualAgeOption}}
        </option>
    </select>
</div>

<div class="setting-group">
    <h6 class="setting-group-heading">Text Casing</h6>
    <mat-checkbox [checked]="textCasingEnabled">Make all text:</mat-checkbox>
    <mat-radio-group [value]="textCasing">
        <mat-radio-button value="UpperCase">UPPER CASE</mat-radio-button>
        <mat-radio-button value="LowerCase">lower case</mat-radio-button>
        <mat-radio-button value="TitleCase">Title Case</mat-radio-button>
    </mat-radio-group>
</div>

<div class="setting-group">
    <h6 class="setting-group-heading">Current Formatting Profile</h6>
    <label>Revert ALL customizations to the default.</label>
    <button class="btn btn-secondary" disabled><span class="fa fa-undo"></span> Reset</button>
</div>

<div class="setting-group">
    <h6 class="setting-group-heading">Current Prioritization Profile</h6>
    <label>Revert ALL field Prioritizations to the default.</label>
    <button class="btn btn-secondary" disabled><span class="fa fa-undo"></span> Reset</button>
</div>

