import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PropertyUsageType, PropertyImportDialogService,  UserStateService, SidebarLogoService, ThemeService } from '@datamaster/core';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: [
    './subject.component.scss'
  ]
})
export class SubjectComponent implements OnInit, OnDestroy {
  isActiveLink: string = this.isActive();
  sidebarWidth: string | undefined;
  sidebarWidthValue: number | undefined;
  get doesUserHaveWebAccess(): boolean {
    return this.userStateService.user.isInternal;
  }
  theme: string = this.themeService.startupTheme;
  private _subscriptions: Subscription = new Subscription();

  constructor(private router: Router,
    private sidebarLogoService: SidebarLogoService,
    private themeService: ThemeService,
    private userStateService : UserStateService,
    private propertyImportDialogService: PropertyImportDialogService) {
    this.sidebarLogoService.sidebarWidth.subscribe(width => {
      this.sidebarWidth = '-' + (width / 2) + 'px';
      this.sidebarWidthValue = width;
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit() {
    // this._subscriptions.add(
    //   this.userStateService.getUserState().pipe(tap((userState:any) =>{
    //   this.doesUserHaveWebAccess = userState.isInternal;
    // })).subscribe());

    console.log(this.doesUserHaveWebAccess);

    this.themeService.currentTheme.subscribe(val => {
      this.theme = val;
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((value: any ) => {
        //this.isActiveLink = this.activatedRoute.firstChild.routeConfig.path === '' || this.activatedRoute.firstChild.routeConfig.path === 'grid' ? 'active' : '';
        this.isActiveLink = value.url === '/subject' || value.url === '/subject/detail' ? 'active' : '';
      });

    // This is a display hack to make the submenu align left with main menu items, which is offset exactly by the width of the sidebar
    // since the main menu is 100% width of the visible area and the submenu is only the width of the component
    this.sidebarWidth = '-' + (this.sidebarLogoService.currentSidebarWidth.getValue() / 2) + 'px';  // Get the initial value (BehaviorSubject)
    this.sidebarWidthValue = this.sidebarLogoService.currentSidebarWidth.getValue();
    this.sidebarLogoService.sidebarWidth.subscribe(width => {
      this.sidebarWidth = '-' + (width / 2) + 'px';
      this.sidebarWidthValue = width;
    });

  }

  private isActive(): string {
    // This seems like a hack
    // Just to get two routes to show active on the same tab
    return this.router.isActive('/subject', true) || this.router.isActive('/subject/detail', false) ? 'active' : '';
  }

  openPropertyImportModal() {
    this.propertyImportDialogService.openPropertyImportModal(PropertyUsageType.Subject);
  }

}
