import { AppErrorModel } from './app-state.model';

export class LoadConfigFile {
	static readonly type = '[App] LoadConfigFile';
	constructor(public appname: string) {}
}

export class SetAppName {
	static readonly type = '[App] Set Current App Name';
    constructor(public appName: string) {}
}

export class SetAppLoading {
	static readonly type = '[App] Set Loading';
    constructor(public isLoading: boolean) {}
}

export class SetAppError {
	static readonly type = '[App] Set AppError';
    constructor(public error: AppErrorModel) {}
}
