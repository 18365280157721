<div class="container">
    <div class="header">
        <h1 mat-dialog-title>{{ data.title }}</h1>
    </div>
    <div mat-dialog-content>
        <p class="dialog-message">{{ data.message }}</p>
        <input id="textvalue" name="textvalue" type="text" class="form-control" [(ngModel)]="data.value" (input)="inputValidator($event)" />
    </div>
    <div mat-dialog-actions>
        <div class="button-container">
            <button class="btn btn-confirm" (click)="confirm()">{{ data.confirmText }}</button>
            <button class="btn" *ngIf="data.cancelText" (click)="cancel()">{{ data.cancelText }}</button>
        </div>  
    </div>
</div>