import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from "@angular/core";
import { MatSelectionList } from "@angular/material/list";
import { ListItem } from "../../../interfaces/list-item.interface";

@Component({
  selector: "app-dual-list-select",
  templateUrl: "./dual-list-select.component.html",
  styleUrls: ["./dual-list-select.component.scss"],
})
export class DualListSelectComponent implements OnInit {

  private _defaultListItems : ListItem[];
  get defaultListItems() {
    return this._defaultListItems;
  }
  @Input() set defaultListItems(value : ListItem[]){
    this._defaultListItems = value;
    this._defaultUneditedList = value?.slice();

  };
  private selectedListItem: ListItem | null;

  private _userListItems : ListItem[];
  get userListItems() {
    return this._userListItems;
  }
  @Input() set userListItems(value : ListItem[]){
    this._userListItems = value;
    this._userUneditedList = value?.slice();
  }

  private _userUneditedList: ListItem[];
  private _defaultUneditedList: ListItem[];
  private selectedUserListItem: ListItem | null;

  addEnabled: boolean = false;
  removeEnabled: boolean = false;
  upEnabled: boolean = false;
  downEnabled: boolean = false;

  @Output() notifyOnEdited: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('defaultMatSelectionList') defaultListItemsMatSelectionList :  MatSelectionList;
  @ViewChild('userMatSelectionList') userMatSelectionList :  MatSelectionList;

  constructor() {
  }

  ngOnInit(): void {
  }

  add() {
    this.moveItemToDestinationList(
      this.defaultListItems,
      this.userListItems,
      this.selectedListItem as ListItem
    );
    this.addEnabled = false;
    this.deselectItems();
    this.deselectUserItems();
    this.updatedUserListEdited();
  }

  remove() {
    this.moveItemToDestinationList(
      this.userListItems,
      this.defaultListItems,
      this.selectedUserListItem as ListItem
    );
    this.deselectUserItems();
    this.deselectItems();
    this.onUserItemSelectionChange();
    this.updatedUserListEdited();
  }

  up() {
    this.changeOrder(this.userListItems, -1, this.selectedUserListItem as ListItem);
  }

  down() {
    this.changeOrder(this.userListItems, 1, this.selectedUserListItem as ListItem);
  }

  listOptionClicked(item: ListItem) {
    if (this.selectedListItem && this.selectedListItem.id == item.id) {
      this.add();
    } else {
      this.selectedListItem = item;
      this.onDefaultItemSelectionChange();
    }
  }

  userlistOptionClicked(item: ListItem) {
    if (this.selectedUserListItem) {
      this.remove();
    } else {
      this.selectedUserListItem = item;
      this.onUserItemSelectionChange();
    }
  }

  resetLists(){
    this.userListItems= this._userUneditedList;
    this.defaultListItems = this._defaultUneditedList;

  }

  resetSelection(){
    this.deselectItems();
    this.deselectUserItems();
  }

  private deselectItems(){
    this.selectedListItem = null;
    this.defaultListItemsMatSelectionList.deselectAll();
  }

  private deselectUserItems(){
    this.selectedUserListItem = null;
    this.userMatSelectionList.deselectAll();
  }

  private changeOrder(list: ListItem[], indexMove: number, item: ListItem) {
    let originalIndex = list.indexOf(item);
    let newIndex = originalIndex + indexMove;
    let removedItems = list.splice(originalIndex, 1);
    list.splice(newIndex, 0, removedItems[0]);
    this.onUserItemSelectionChange();
    this.updatedUserListEdited();
  }

  private moveItemToDestinationList(
    itemsSource: ListItem[],
    itemsDestination: ListItem[],
    selectedOption: ListItem
  ) {
    let index = itemsSource.indexOf(selectedOption);
    itemsDestination.push(selectedOption);
    itemsSource.splice(index, 1);
  }

  private onDefaultItemSelectionChange() {
    this.deselectUserItems();
    if (!this.selectedListItem) {
      return;
    }
    this.addEnabled = true;
    this.removeEnabled = false;
  }

  private onUserItemSelectionChange() {
    if (!this.selectedUserListItem) {
      this.removeEnabled = this.addEnabled = this.upEnabled = this.downEnabled = false;
      return;
    }
    this.deselectItems();
    this.removeEnabled = true;
    this.addEnabled = false;

    let selectedIndex = this.userListItems.indexOf(this.selectedUserListItem as ListItem);

    if (this.userListItems.length === 1) {
      return;
    } else if (selectedIndex === 0) {
      this.downEnabled = true;
      this.upEnabled = false;
    } else if (selectedIndex === this.userListItems.length - 1) {
      this.upEnabled = true;
      this.downEnabled = false;
    } else {
      this.upEnabled = true;
      this.downEnabled = true;
    }
  }

  private updatedUserListEdited() {
    if (this.userListItems.length !== this._userUneditedList.length) {
      this.notifyOnEdited.emit(true);
      return;
    }

    for (var i = 0; i < this.userListItems.length; i++) {
      if (this.userListItems[i] !== this._userUneditedList[0]) {
        this.notifyOnEdited.emit(true);
        return;
      }
    }

    this.notifyOnEdited.emit(false);
    return;
  }
}
