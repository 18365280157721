//Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import {  lastValueFrom} from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
// Components
import { SettingsGeneralComponent } from './settings-general/settings-general.component';
import { SettingsFormattingComponent } from './settings-formatting/settings-formatting.component';
import { SettingsMarketAnalysisComponent } from './settings-market-analysis/settings-market-analysis.component';
import { SettingsAppraiserComponent } from './settings-appraiser/settings-appraiser.component';
import { SettingsComptrackerComponent } from './settings-comptracker/settings-comptracker.component';
import { SettingsDataSourceSelectionComponent } from './settings-data-source-selection/settings-data-source-selection.component';
import { SettingsDataSourceCredentialsComponent } from './settings-data-source-credentials/settings-data-source-credentials.component';
// Service and model
import { UserSettings } from '../../models/settings/user-settings.model';
import { DataSourceCredentials } from '../../models/settings/data-source-credentials.model';
import { DataSource } from '../../models/data-source/data-source';
import { ThemeService } from '../../services/theme.service';
import { UserStateService } from '../../services/user/user-state.service';
import { UserSettingsService } from '../../services/user/user-settings.service';
import { SpinnerService } from '../../services/spinner.service';
import { DataSourceService } from '../../services/data-source.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  layout: string = this.themeService.startupDashboardLayout;
  theme: string = this.themeService.startupTheme;

  get username(): string {
    return this.userStateService.user.user.userName
  }
  userSettings!: UserSettings;
  get isInternal(): boolean {
    return this.userStateService.user.isInternal;
  }
  dataSources!: DataSource[];
  userDataSources: DataSource[] = [];
  userDataSourceCredentials!: DataSourceCredentials[];

  @ViewChild('generalSettings')
  generalSettings!: SettingsGeneralComponent;
  @ViewChild('appraiserSettings')
  appraiserSettings!: SettingsAppraiserComponent;
  @ViewChild('comptrackerSettings')
  comptrackerSettings!: SettingsComptrackerComponent;
  @ViewChild('marketAnalysisSettings')
  marketAnalysisSettings!: SettingsMarketAnalysisComponent;
  @ViewChild('dataSourceSelectionSettings')
  dataSourceSelectionSettings!: SettingsDataSourceSelectionComponent;
  @ViewChild('dataSourceCredentialsSettings')
  dataSourceCredentialsSettings!: SettingsDataSourceCredentialsComponent;
  @ViewChild('formattingSettings')
  formattingSettings!: SettingsFormattingComponent;

  get isPropertySearchApp(): boolean {
    return this.userStateService.user.userHasPropertySearchAppAccess;
  }

  constructor(
    private userStateService: UserStateService,
    private themeService: ThemeService,
    private userSettingsService: UserSettingsService,
    private spinnerService: SpinnerService,
    private mdDialogRef: MatDialogRef<SettingsComponent>,
    private dataSourceService: DataSourceService,
  ) {
    //TODO: retrieve user data sources from local cache instead or use route resolvers. Get rid of waitFor function call. 
    // eslint-disable-next-line no-async-promise-executor
    const p = new Promise(async ()=>{
    const userSettings = await lastValueFrom( this.userSettingsService
      .getUserSettings(this.username));
    this.userSettings = userSettings;
    this.userDataSourceCredentials =
      userSettings.dataSourceCredentials ?? [];

      let dataSourceCredentials : DataSourceCredentials[] = null;
      this.dataSourceService.getUserDataSources().then((async (userDataSources) => {
        userDataSources?.forEach((a) => this.userDataSources.push(a)); //passes array by value instead of reference.' this.userDataSources = userDataSources', uses the reference.
        if (!(
          !userDataSources ||
          (userSettings.dataSourceCredentials?.length ?? 0) ==
            (userDataSources?.length ?? 0))
        ){
        const dataSourceIds = userDataSources
          .filter(
            (a) =>
              !userSettings.dataSourceCredentials?.some(
                (b) => b.dataSourceCommonName == a.commonName
              ) ?? true
          )
          .map((a) => a.id);

        if (!(!dataSourceIds || dataSourceIds.length == 0)) {
        dataSourceCredentials = await lastValueFrom(this.dataSourceService.getDataSourceCredentials(dataSourceIds));
        dataSourceCredentials?.forEach((a) => this.userDataSourceCredentials.push(a));
        }
      }
      })
    ).catch((err)=>{
      console.error(err);
    }).finally(()=>{
      this.dataSourceService.getDataSources().then((data)=>{
        this.dataSources = data;
        //spinnerRef.close();
      }).catch((err)=>{
        console.error(err);
        //spinnerRef.close();
      });
    });
  });
  
  this.dataSourceService.waitFor(p);
  }

  ngOnInit() {
    //const spinnerRef = this.spinnerService.open('Loading Settings...');

    this.themeService.currentDashboardLayout.subscribe((val) => {
      this.layout = val;
    });

  }

  public get hasMLSDataSource() {
    return this.dataSourceService.getMLSDataSourceIds().length > 0 || this.userDataSourceCredentials?.length > 0;
  }
  toggleLayout($event:any): void {
    if ($event.target.checked === true) {
      this.themeService.setCurrentDashboardLayout('desktop');
    } else {
      this.themeService.setCurrentDashboardLayout('web');
    }
  }

  toggleTheme($event:any): void {
    if ($event.target.checked === true) {
      this.themeService.setCurrentTheme('default');
    } else {
      this.themeService.setCurrentTheme('classic');
    }
  }

  saveSettings() {
    const spinnerRef = this.spinnerService.open('Saving settings...');

    try {
      //Internal
      if (this.isInternal) {
        this.userSettings.selectedDataSourceIds =
          this.dataSourceSelectionSettings.userDataSources.map((a) => a.id);
      }
      //Data Source Credentials
      this.userSettings.dataSourceCredentials =
        this.dataSourceCredentialsSettings.dataSourceCredentials;

      //General
      if (!this.isPropertySearchApp) {
        this.userSettings.importPhotosFromRets =
          this.generalSettings.importPhotosFromRets;
        this.userSettings.getMainPhotoOnly =
          this.generalSettings.getMainPhotoOnly;
        this.userSettings.includePhotosFromReport =
          this.generalSettings.includePhotosFromReport;
        this.userSettings.importPhotosSize =
          this.generalSettings.importPhotosSize;
        this.userSettings.importPhotosFromWeb =
          this.generalSettings.importPhotosFromWeb;
        this.userSettings.skipDeedSelection =
          this.generalSettings.skipDeedSelection;
        this.userSettings.alwaysGetListingHistory =
          this.generalSettings.alwaysGetListingHistory;
        this.userSettings.alwaysShowExceptionsReport =
          this.generalSettings.alwaysShowExceptionsReport;
        this.userSettings.includeRealEstateAgentRemarksInExceptionsReport =
          this.generalSettings.includeRealEstateAgentRemarksInExceptionsReport;
        this.userSettings.defaultNavigationLocation =
          this.generalSettings.selectedDefaultNavigationLocation;
        this.userSettings.sendDeedAndListingHistory =
          this.generalSettings.sendDeedAndListingHistory;
        this.userSettings.sendMlsSheets = this.generalSettings.sendMlsSheets;
        this.userSettings.overwriteFieldsWithBlanks =
          this.generalSettings.overwriteFieldsWithBlanks;

        //Formatting
        this.userSettings.defaultVerificationDocumentNumber =
          this.formattingSettings.defaultVerificationDocumentNumber;
        this.userSettings.daysOnMarket = this.formattingSettings.daysOnMarket;
        this.userSettings.defaultActualAgeCalculation =
          this.formattingSettings.defaultActualAgeCalculation;
        this.userSettings.textCasing = this.formattingSettings.textCasing;
      }
      this.userSettingsService
        .saveUserSettings(this.userSettings, this.username)
        .subscribe(
          (_) => {
            if (this.isInternal) {
              this.dataSourceService.updateUserDataSources(
                this.dataSourceSelectionSettings.userDataSources
              );
            }

            spinnerRef.close();
            this.mdDialogRef.close();
          },
          () => {
            spinnerRef.close();
          }
        );
    } catch (error) {
      spinnerRef.close();
    }
  }

  close() {
    this.mdDialogRef.close();
  }

  resetSettingsToDefault() {
    const spinnerRef = this.spinnerService.open('Resetting settings...');
    try {
      this.userSettingsService
        .resetSettingsToDefault(this.username)
        .subscribe(() => {
          spinnerRef.close();
          this.mdDialogRef.close();
        });
    } catch (error) {
      spinnerRef.close();
    }
  }
}
