import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// Models
import { UserSettings } from '../../../models/settings/user-settings.model';
import { requestOptions } from '../../../models/http/constant.model';
// Services
import { ConfigService } from '../../../dependency-issues';

@Injectable()
export class SettingsService {
    private readonly dmApiUrl: string = this.configService.api.dataMasterApi;

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService
    ) { }

    getSettings(username: string): Observable<UserSettings> {
        return this.httpClient.get<UserSettings>(
            `${this.dmApiUrl}usersettings/username/${username}`,
            requestOptions
        );
    }

    saveSettings(settings: UserSettings, username: string): Observable<any> {
        return this.httpClient.post<any>(
            `${this.dmApiUrl}usersettings?username=${username}`,
            settings,
            requestOptions
        );
    }

    resetSettingsToDefault(username: string): Observable<UserSettings> {
        return this.httpClient.post<UserSettings>(
            `${this.dmApiUrl}usersettings`,
            username,
            requestOptions
        );
    }
}
