<div class="subject-deeds-header-section">
  <h3 class="deeds-header"> Review and Select Prior Sales Deed(s)</h3>
  
  <app-tell-me-more [template]="tellMeMoreDescriptionTemplate"></app-tell-me-more>
  <ng-template #tellMeMoreDescriptionTemplate>
    <p>Select any prior sales from the list of deeds for this property.</p>
    <p>Select a deed from the list of deeds displayed to set as the Sale Verification Deed. Highlighting the deed and clicking the &quot;Set as Sale Verification Deed&quot; will set this deed as the Sale Verification. Additionally you may select any deed to be included as a Prior Sale Deed by selecting a deed, then once highlighted, click the &quot;Add to prior sales deeds.&quot; Your selected deeds will appear below. To remove a deed from the prior sale deed's list select the deed within the &quot;Prior Sale Deed&quot; box and click the &quot;Remove selected prior sales deed&quot; button. To remove the Sale Verification Deed click on the &quot;x&quot; button in the top right corner of the Sale Verification Deed box. DataMaster has also provided the MLS listing History. To include these in your report select the &quot;Get Listing History&quot; button. To automatically retrieve the listing history when pulling a property, select the &quot;Always automatically retrieve listing history&quot; checkbox. By clicking the &quot;Always automatically retrieve listing history&quot; checkbox, the MLS listing history for that property will automatically appear above the list of deeds. To skip this section select the skip deeds button to proceed to the next step.</p>
  </ng-template>
  
  </div>
  <app-deeds 
      [parcel]="parcel" 
      [mlsDataSources]="mlsDataSources" 
      [prDataSources]="prDataSources"
      [userSettings]="userSettings"
      (saveUserSettings)="saveUserSettings($event)"
      (loading)="loading($event)">
  </app-deeds>