import { Component } from '@angular/core';

@Component({
  selector: 'app-addenda',
  templateUrl: './addenda.component.html',
  styleUrls: ['./addenda.component.scss']
})
export class AddendaComponent {

  constructor() { 
    //
  }
}
