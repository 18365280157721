import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// State
import { Store } from '@ngxs/store';
import { RefreshUserData } from '../../../services/user/state';
// Other
import { LoginResponse } from 'angular-auth-oidc-client';
import { AuthService } from '../services/auth.service';
import { SpinnerComponent } from '../../spinner/spinner.component';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit, OnDestroy {
    @ViewChild(SpinnerComponent) spinner: SpinnerComponent;

    private _subcription: Subscription;

    constructor(
        private store: Store,
        private router: Router,
        private authService: AuthService
    ) { }

    ngOnDestroy(): void {
        this._subcription.unsubscribe();
    }

    ngOnInit() {
        //CompleteAuthentication handles the redirect back to page that triggered the authentication
        this._subcription = this.authService.completeAuthentication().subscribe({
            next: async (resp: LoginResponse) => {
                if (resp.isAuthenticated) {
                    const data = this.authService.getClaims();
                    await this.store.dispatch(new RefreshUserData(data.name));
                } else {
                    this.router.navigate([`unathorized`])
                }
            },
            error: (err) => {
                console.warn('Error while completing the atuhentication, but we will try to continue');
                const data = this.authService.getClaims();
                this.store.dispatch(new RefreshUserData(data.name));
            }
        });
    }
}
