import { Component, Input } from '@angular/core';
import { UserSettings } from '../../../models/settings/user-settings.model';

@Component({
  selector: 'app-settings-comptracker',
  templateUrl: './settings-comptracker.component.html',
  styleUrls: ['./settings-comptracker.component.scss']
})
export class SettingsComptrackerComponent  {

  @Input() userSettings: UserSettings;

  constructor() {
    //
  }
}
