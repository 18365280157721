import { FileVersion } from './file-version.model';
import { Appraisal } from './appraisal.model';
export class DataMasterFile {
    fileVersion: FileVersion;
    appraisal: Appraisal;

    constructor() {
        this.fileVersion = new FileVersion();
        this.appraisal = new Appraisal();
    }
}
