import { Component, Inject, OnInit } from '@angular/core';
import { AuthService, DataMasterFileService, DataSource, DataSourceService, Parcel, StandardizedParcel } from '@datamaster/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs';
import { QualityOfConstructionType } from 'libs/cv.datamaster.core/src/lib/models/workfile/enum/quality-of-construction-type.enum';
import { ConditionType } from 'libs/cv.datamaster.core/src/lib/models/workfile/enum/condition-type.enum';
import { ParcelImage } from 'libs/cv.datamaster.core/src/lib/models/workfile/parcel-image.model';

@Component({
    selector: 'app-photos',
    templateUrl: './photos.component.html',
    styleUrls: ['./photos.component.scss'],
})
export class PhotosComponent implements OnInit {
  parcel!: Parcel;
  mlsDataSources!: DataSource[];
  StandardizedParcel : StandardizedParcel;
  QualityOfConstructionType = QualityOfConstructionType;
  QualityOfConstructionValues = Object.values(QualityOfConstructionType).filter( e => typeof( e ) == 'number' );
  ConditionType = ConditionType;
  ConditionValues = Object.values(ConditionType).filter( e => typeof( e ) == 'number' );
  DisplayPhoto: ParcelImage;
  VirtualTourLink = 'https://www.insidemaps.com/project/ym3VpDjuJi/listing';
  VirtualTourIsVisible = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
        parcel: Parcel,
      },
      private dataSourceService: DataSourceService,
      private dataMasterFileService : DataMasterFileService,
      private authService : AuthService) {
        this.parcel = data.parcel;
        //TODO: retrieve user data sources from local cache instead. Get rid of waitFor function call
    const p = this.dataSourceService.getUserDataSources().then(((userDataSources) => {
          const mlsDataSources = this.dataSourceService.filterMlsDataSources(userDataSources);
    
          this.mlsDataSources = mlsDataSources;
        }));
      this.dataSourceService.waitFor(p);
    }

    ngOnInit(): void {
      if(!this.parcel)
          return;      
      const username = this.authService.getClaims()?.name;
      this.authService.getUserStateFromService(username).pipe(tap((userState)=> (this.VirtualTourIsVisible = userState.isInternal && (userState.Permissions.indexOf('ViewProofOfConcept')>= 0))))
      .subscribe();
      this.StandardizedParcel = this.dataMasterFileService.getTranslatedStandardizedParcel(this.parcel.propertyGuid);
      //TODO:Set as displayPhoto this.parcel.primaryPhotoUrl;
      this.DisplayPhoto = this.parcel.images.find(image => image.isPrimaryPhoto);

    }

    setMainPhoto() : void{

    }
}
