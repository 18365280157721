<app-under-construction *ngIf="!doesUserHaveWebAccess"></app-under-construction>
  <div class="sub-menu mc-main-nav" id="mc-side-menu">
      
      <a class="mc-main-item" (click)="setNeighborhoodLargerMarket(false)" [routerLink]="['.', {neighborhoodAnalysis:false}]" 
        [ngClass]="{'active':isCompetingMarket}">
        <div class="big-button-header">
          <b>Competing Market</b>
        </div>
        <div class="big-button-description">
          <b>(1004 MC)</b>
        </div>   
      </a>
      <a class="mc-main-item" (click)="setNeighborhoodLargerMarket(true)" [routerLink]="['.', {neighborhoodAnalysis:true}]" 
        [ngClass]="{'active':isNeighborhoodLargerMarket}">
        <div class="big-button-header">
          <b>Neighborhood</b>
        </div>
        <div class="big-button-description">
          <b>(Larger Market)</b>
        </div>   
      </a>
  </div>
  <div class="sub-menu {{theme}} top-menu" [ngStyle]="theme === 'default' ? {'max-width': 'calc(100vw - '+sidebarWidthValue+'px)'} : {}" >
    
  <div class="navbar-nav {{theme !== 'classic' ? '' : 'mc-classic'}}" [ngStyle]="theme !== 'classic' ? {'margin-right': sidebarWidth} : {}">
    <!-- <a class="nav-item navbar-link " routerLink="./import" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <span class="far fa-file-import"></span>
      Import
    </a> -->
    <div *ngIf="doesUserHaveWebAccess; else disabledButtons">
      <a class="nav-item navbar-link"  (click)="openPropertyImportModal()" style="cursor: pointer;">
        <span class="far fa-search"></span>
        Get Comp(s)
      </a>
      <a class="nav-item navbar-link {{isActiveLink}}" [routerLink]="['.', {neighborhoodAnalysis:isNeighborhoodLargerMarket}]">
        <span class="far fa-file-alt"></span>  
        {{reportLabel}}    
      </a>
      <a class="nav-item navbar-link " [routerLink]="['.', {neighborhoodAnalysis:isNeighborhoodLargerMarket}, 'detail']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <span class="far fa-bars"></span>
        Details
      </a>
      <a class="nav-item navbar-link " [routerLink]="['.', {neighborhoodAnalysis:isNeighborhoodLargerMarket}, 'graphs']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <span class="far fa-chart-line"></span>
        Graphs
      </a>
      <span data-toggle="tooltip" data-placement="bottom" title="{{isSummaryTabActivated ? '' : 'Import comps into Market Analysis to enable tab'}}">
        <a class="nav-item navbar-link" [ngClass]="isSummaryTabActivated ? '' : 'disabled-nav-item'" routerLink="./summary" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <span class="far fa-clipboard-list-check"></span>
        Summary
      </a>
      </span>
    </div>
  </div>

  <div class="right-content">
    <app-effective-date-info (effectiveDateChange)="reProcessMarketConditions($event)" ></app-effective-date-info>
  </div>

</div>
<div class="clear"></div>

<ng-template #disabledButtons>
  <a class="nav-item navbar-link ">
    <span class="far fa-file-import"></span>
    Get Comp(s)
  </a>
  <a class="nav-item navbar-link {{isActiveLink}}">
    <span class="far fa-file-alt"></span>
    1004 MC
  </a>
  <a class="nav-item navbar-link ">
    <span class="far fa-bars"></span>
    Details
  </a>
  <a class="nav-item navbar-link ">
    <span class="far fa-chart-line"></span>
    Graphs
  </a>
  <a class="nav-item navbar-link ">
    <span class="far fa-clipboard-list-check"></span>
    Summary
  </a>
</ng-template>

<router-outlet></router-outlet>
