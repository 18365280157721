import { PropertyUsageType } from '../../workfile/enum/property-usage-type.enum';
import { FormattedDataSource } from '../../workfile/formatted-data-source.model';
import { RawProperty } from '../../workfile/raw-property.model';
import { StandardizedParcel } from '../../workfile/standardized-parcel.model';

export class FormattingPropertyRequest{
    propertyUsageType : PropertyUsageType;
    rawProperties : RawProperty[];
    standardizedParcel : StandardizedParcel;
    dataSourceFormattedFields : FormattedDataSource[];
    tagsToIgnore : string[];
}
