<div class="news-app {{newsToggleStateClass}}">
  <app-news #news (toggleNewsEvent)="onToggleNews($event)"></app-news>
</div>
<div class="home-cards {{newsToggleStateClass}}">
  <div class="my-row">
    <div class="my-col left-col" style="flex: 3; min-width: 814px;">

      <app-new-file></app-new-file>

    </div>
    <div class="my-col right-col analytics" style="flex: 2;">

      <app-analytics></app-analytics>

    </div>
  </div>

  <div class="my-row">
    <div class="my-col left-col">
      <app-files></app-files>
    </div>
    <div class="my-col right-col">
      <!-- <app-files-map></app-files-map> -->
    </div>
  </div>
</div>

<!--<div style="position: absolute; right: 0; top: 0;">
  height: {{window.height}}<br />
  width: {{window.width}}
</div>-->
<!--<div style="position: absolute; right: 5px; top: 5px; border: 1px solid rgba(0,0,0,0.7); background-color: rgba(255,255,255,0.6); font-weight: 600; padding: 2px 7px; z-index: 999;">
  {{screenSizeName}}
</div>-->
