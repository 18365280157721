import { Component, Input, OnInit } from '@angular/core';
import { DataMasterFileService } from '@datamaster/core';

@Component({
  selector: 'app-deeds-header',
  templateUrl: './deeds-header.component.html',
  styleUrls: ['./deeds-header.component.scss'],
})
export class DeedsHeaderComponent implements OnInit {
  addressLine1: string;
  addressLine2: string;
  apn: string;

  @Input() propertyGuid: string;
  @Input() primaryPhotoUrl: string;
  constructor(private dataMasterFileService: DataMasterFileService) {}

  ngOnInit(): void {
    const prioritizedStandardizedParcel =
      this.dataMasterFileService.dataMasterFile.appraisal.prioritizedParcels.find(
        (x) => x.propertyGuid
      );

    if (!prioritizedStandardizedParcel) {
      return;
    }

    this.addressLine1 = prioritizedStandardizedParcel.addressLine1;
    this.addressLine2 = `${prioritizedStandardizedParcel.city}, ${prioritizedStandardizedParcel.state} ${prioritizedStandardizedParcel.zip}`;
    this.apn = prioritizedStandardizedParcel.apn;

    const translatedParcels =
      this.dataMasterFileService.dataMasterFile.appraisal.translatedParcels.filter(
        (x) => x.propertyGuid == this.propertyGuid
      );

    if (!this.apn) {
      this.apn = translatedParcels.find((x) => x.apn)?.apn;
    }
  }
}
