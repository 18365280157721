<div class="container">
    <div class="row justify-content-between">
        <div class="col address">
            <h4>
                <span class="bold">{{PropertyUseType}}{{Parcel?.propertyPlacementNumber}} {{StandardizedParcel?.addressLine1}} </span>
                <span>{{StandardizedParcel?.addressLine2 ??  StandardizedParcel?.city + ", " + StandardizedParcel?.state + " " + StandardizedParcel?.zip}}</span>
            </h4>
        </div>
        <div class="col text-end">File: {{FileName}}</div>
    </div>
    <div class="row justify-content-between">
        <div class="col">
            <h5>
                <span>{{DataSourceDisplayName}}# </span>
                <span>{{MlsNumber}}</span>
            </h5>
        </div>
        <div class="col">
            <h5>
                <div>APN# {{StandardizedParcel?.apn}}</div>
            </h5>
        <div class="col text-end">
            <span *ngIf="HasProximityToSubject">Proximity to Subject: {{StandardizedParcel?.proximityToSubject}}</span>
        </div>
    </div>
</div>
