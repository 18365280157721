
import { Component,  Input, Output, EventEmitter } from '@angular/core';
import { TranslatedPropertyResult } from '../../models/operation/translation/translated-property-result.model';

@Component({
  selector: 'app-multiple-results-table',
  templateUrl: './multiple-results-table.component.html',
  styleUrls: ['./multiple-results-table.component.scss'],
})
export class MultipleResultsTableComponent{
  @Input() translatedPropertyResults: TranslatedPropertyResult[];
  @Input() isAddressSearch: boolean;
  selectedTranslatedPropertyResult: TranslatedPropertyResult;
  dataSourceId: number;
  @Output() resultsEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() propertySelectedEvent: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    //
  }

  getResultCount(): number {
    if (!this.translatedPropertyResults) return 0;

    return this.translatedPropertyResults.length;
  }

  rowSelected(selectedTranslatedPropertyResult: TranslatedPropertyResult) {
    if (
      this.selectedTranslatedPropertyResult === selectedTranslatedPropertyResult
    ) {
      this.selectedTranslatedPropertyResult = null;
      this.propertySelectedEvent.next(false);
    } else {
      this.selectedTranslatedPropertyResult = selectedTranslatedPropertyResult; // declare variable in component.
      this.propertySelectedEvent.next(true);
    }
  }
}
