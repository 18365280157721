import { Component, Input, OnInit } from '@angular/core';
import { MarketConditionGraphGroupType } from 'libs/cv.datamaster.core/src/lib/models/enums/market-conditions-graph-group-type.enum';
import { UserMarketConditionsGraphSetting } from 'libs/cv.datamaster.core/src/lib/models/settings/user-settings.model';

@Component({
  selector: 'datamaster-core-settings-market-analysis-graph-group',
  templateUrl: './settings-market-analysis-graph-group.component.html',
  styleUrls: ['./settings-market-analysis-graph-group.component.scss'],
})
export class SettingsMarketAnalysisGraphGroupComponent implements OnInit {
  firstDataSeriesColorToggle : boolean;
  secondDataSeriesColorToggle: boolean;
  firstTrendSeriesColorToggle: boolean;
  secondTrendSeriesColorToggle: boolean;

  displaySecondTrendLineType = true;
  displaySecondPolynomailDegree = true;
  displaySecondSeriesColors = true;

  @Input() userMarketConditionsGraphSetting : UserMarketConditionsGraphSetting;

  ngOnInit(): void {
    if(this.userMarketConditionsGraphSetting.marketConditionGraphGroupType === MarketConditionGraphGroupType.Scatter){
      this.displaySecondTrendLineType = false;
      this.displaySecondPolynomailDegree = false;
      this.displaySecondSeriesColors = false;
    }
  }
}
