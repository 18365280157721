export enum FormattedSectionType {
  Subject = 'Subject',
  Comparable = 'Comparable',
  SubjectGrid = 'SubjectGrid',
  Listing = 'Listing',
  ReoListing = 'ReoListing',
  Rental = 'Rental',
  ComparableRental = 'ComparableRental',
  DataMasterAddendumParcelarable = 'DataMasterAddendumParcelarable',
  Unk = 'Unk'
}
