// Models
import { TagPriority } from './tag-priority.model';
import { DiscrepancyField } from './discrepancy-field.model';
import { FormattedDataSource } from './formatted-data-source.model';
import { FormattedField } from './formatted-field.model';
import { ParcelImage } from './parcel-image.model';
import { ParcelBase } from './parcel-base.model';

export class Parcel extends ParcelBase {
  tagPriorities: TagPriority[];
  discrepancyFields: DiscrepancyField[];
  formattedFieldResults: { [index: number]: FormattedField };
  formattedDataSources: FormattedDataSource[];
  workFileComments: string;
  appraisalComments: string;
  images: ParcelImage[];
  private _primaryPhotoUrl: string;

  constructor() {
    super();
    this.formattedDataSources = [];
    this._primaryPhotoUrl = null;
    this.images = null;
  }

  public get primaryPhotoUrl() {
    if (this._primaryPhotoUrl) {
      return this._primaryPhotoUrl;
    }
    if (this.images) {
      this._primaryPhotoUrl = this.images.find(x => x.isPrimaryPhoto)?.url;
    }
    return this._primaryPhotoUrl;
  }
}
