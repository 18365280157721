import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
// Actions
import { SetAppError, SetAppLoading } from '../../../state/model/app.actions';
import {
    Logout,
    RefreshUserData,
    SetUserData,
    SetUserIsImpersonating,
    SetUserPropertySearchAccess,
    SetUserBillingPortalUrl
} from './model/user.actions';
// Service
import { AuthService } from '../../../components/auth/services/auth.service';
// Model
import { UserStateModel } from '../../../models/user/user-state.model';
import { SubscriptionModel } from '../../../models/subscription.model';

@State<UserStateModel>({
    name: 'user',
    defaults: new UserStateModel()
})
@Injectable()
export class UserState {

    constructor(
        private store: Store,
        private authService: AuthService
    ) { }

    /**
     * Get user data.
     * @param state: @see UserStateModel
     */
    @Selector()
    static getUserData(state: UserStateModel): UserStateModel {
        return state;
    }

    /**
     * Returns token.
     * @param state: UserStateModel
     */
    @Selector()
    static token(state: UserStateModel): string {
        return state.billingPortalUrl;
    }

    /**
     * Set the user info from the IDS
     * @param ctx
     * @param userData @see UserStateModel
     */
    @Action(SetUserData)
    setUserData(ctx: StateContext<UserStateModel>, { userData }: SetUserData): any {
        ctx.patchState({
            ...userData
        });

        this.store.dispatch(new SetUserPropertySearchAccess())
    }

    /**
     * Refresh the user info from the IDS
     * @param ctx
     * @param username
     */
    @Action(RefreshUserData)
    refreshUserData(ctx: StateContext<UserStateModel>, { username }: RefreshUserData): any {
        this.store.dispatch(new SetAppLoading(true));

        if (!username) {
            const nameClaim = this.authService.getClaims()?.name;
            if (!nameClaim) {
                this.store.dispatch(new SetAppError({
                    message: 'No valid username'
                }));
                return of(null);
            }
            username = nameClaim;
        }

        return this.authService.getUserStateFromService(username)
            .pipe(
                tap(userData => {
                    const state = ctx.getState();
                    ctx.setState({ ...state });
                    this.store.dispatch(new SetUserData(userData));
                    this.store.dispatch(new SetAppLoading(false));
                })
            );
    }

    /**
     * Set the user info from the ids
     * @param ctx
     * @param token
     */
    @Action(SetUserBillingPortalUrl)
    setBillingPortalUrl(ctx: StateContext<UserStateModel>, { url }: SetUserBillingPortalUrl): any {
        ctx.patchState({
            billingPortalUrl: url
        });
    }

    /**
     * Set the user info from the ids
     * @param ctx
     * @param haveAccess
     */
    @Action(SetUserPropertySearchAccess)
    setUserPropertySearchAccess(ctx: StateContext<UserStateModel>): any {
        const state = ctx.getState();
        const haveAccess = state.subscriptions?.some((subscription: SubscriptionModel) =>
            subscription.productCode === 'psa web');

        ctx.patchState({
            userHasPropertySearchAppAccess: haveAccess
        });
    }

    /**
     * Set the user info from the ids
     * @param ctx
     * @param isImpersonating
     */
    @Action(SetUserIsImpersonating)
    setUserIsImpersonating(ctx: StateContext<UserStateModel>, { isImpersonating }: SetUserIsImpersonating): any {
        ctx.patchState({
            isImpersonating: isImpersonating
        });
    }

    /**
     * Set the user info from the ids
     * @param ctx
     * @param isImpersonating
     */
    @Action(Logout)
    logout(ctx: StateContext<UserStateModel>): any {
        const resetState = new UserStateModel();
        ctx.setState({
            ...resetState
        });
    }
}
