<app-under-construction *ngIf="!doesUserHaveWebAccess"></app-under-construction>

<div class="sub-menu {{theme}}" [ngStyle]="theme==='default' ? {'max-width': 'calc(100vw - '+sidebarWidthValue+'px)'} : {}">
  
  <div class="navbar-nav {{theme !== 'classic' ? '' : 'subject-classic'}}" [ngStyle]="theme !== 'classic' ? {'margin-right': sidebarWidth} : {}">
    <a class="nav-item navbar-link"  (click)="openPropertyImportModal()" style="cursor: pointer;" data-cy="subjectInfoTab">
      <span class="far fa-file-import"></span>
      Get Subject Info
    </a>
    <a class="nav-item navbar-link " routerLink="./deeds" routerLinkActive="active" data-cy="subjectDeedsTab">
      <span class="far fa-file-alt"></span>
      Deeds
    </a>
    <a class="nav-item navbar-link {{isActiveLink}}" routerLink="." data-cy="subjectPropertyDetailTab">
      <span class="far fa-bars"></span>
      Property Detail
    </a>
    <a class="nav-item navbar-link " routerLink="./platmap" routerLinkActive="active" data-cy="subjectPlatMapTab">
      <span class="far fa-map"></span>
      Plat Map
    </a>
  </div>

  <div class="right-content">
    <app-effective-date-info></app-effective-date-info>
  </div>
  
</div>
<div class="clear"></div>

<router-outlet></router-outlet>
