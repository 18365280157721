<div class="comps-grid-container">


  <ng-container *ngTemplateOutlet="subjectGrid"></ng-container>

  <div class="comp-container top-scrollbar" #compsContainer>
    <div class="comps" id="Comps" #comps>


      <ng-container *ngFor="let comp of comparables; index as compCount">
        <ng-container *ngTemplateOutlet="compGrid; context: { comp: comp, compCount: compCount }"></ng-container>

      </ng-container>

      <a class="add-comp grid-item" (click)="openPropertyImportModal()">
        <span class="fas fa-plus-circle fa-2x icon"></span>
        <span class="add-text">Add Property</span>
      </a>

    </div>
  </div>
</div>

<ng-template #subjectGrid>
  <div class="grid-item subject {{addSubjectScrollMargin ? 'scrolling-top-margin' : ''}}" #subject>

    <ng-container *ngTemplateOutlet="gridHeaderSection; context: { parcel: subj, type: 'subject', headerText: 'Subject', primaryPhotoUrl: subj?.primaryPhotoUrl, address: subj?.formattedFieldResults[303]?.formattedValue, addressLine2: subj?.formattedFieldResults[304]?.formattedValue, disabledTEMP: false}"></ng-container>

    <div class="row grid-row">
      <div class="col-md-12 grid-label">
        Proximity to Subject
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Sale Price
      </div>
      <div class="col-md-6 grid-input">
        <div class="input-group">
          <span class="input-group-addon">$</span>
          <input type="text" id="SalePriceSubject" value="{{subj?.formattedFieldResults[309]?.formattedValue}}" pattern="[0-9]*" class="form-control">
        </div>
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "SalePrice" }, GetSelectedDataSourceId(fieldDataSourcePairList, "SalePrice"), item => String.Format("{0:0,0}", item))-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Sale Price/GLA
      </div>
      <div class="col-md-6 grid-input">
        <div class="input-group">
          <span class="input-group-addon">$</span>
          <input type="number" id="GlaPriceSubject" value="{{subj?.formattedFieldResults[310].formattedValue}}" class="form-control">
          <span class="input-group-addon trailing sm">sqft</span>
        </div>
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "SalePricePerGrossLivingArea" }, GetSelectedDataSourceId(fieldDataSourcePairList, "SalePricePerGrossLivingArea"), item => String.Format("{0:0,0.00}", item))-->
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-label">
        Data Source(s)
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-label">
        Verification Source(s)
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-label ">
        <i>Value Adjustments</i>
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-label">
        Sale or Financing
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-label">
        Concessions
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-label">
        Date of Sale
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Location
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="LocationSubject" value="" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "Location" }, GetSelectedDataSourceId(fieldDataSourcePairList, "Location"), null)-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Leasehold/Fee Simple
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="FeeSimpleSubject" value="{{subj?.formattedFieldResults[334].formattedValue}}" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "LeaseholdFeeSimple" }, GetSelectedDataSourceId(fieldDataSourcePairList, "LeaseholdFeeSimple"), null)-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Site
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="SiteSubject" value="{{subj?.formattedFieldResults[335].formattedValue}}" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "SiteArea" }, GetSelectedDataSourceId(fieldDataSourcePairList, "SiteArea"), item => String.Format("{0:0,0.00}", item))-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        View
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="ViewSubject" value="" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "View" }, GetSelectedDataSourceId(fieldDataSourcePairList, "View"), null)-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Design (Style)
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="DesignSubject" value="{{subj?.formattedFieldResults[342].formattedValue}}" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "Style" }, GetSelectedDataSourceId(fieldDataSourcePairList, "Style"), null)-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Quality of Construction
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="QualitySubject" value="" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "QualityOfConstruction" }, GetSelectedDataSourceId(fieldDataSourcePairList, "QualityOfConstruction"), null)-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Actual Age
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="AgeSubject" value="{{subj?.formattedFieldResults[349].formattedValue}}" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "EffectiveAge" }, GetSelectedDataSourceId(fieldDataSourcePairList, "EffectiveAge"), item => String.Format("{0:0,0}", item))-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Condition
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="ConditionSubject" value="" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "Condition" }, GetSelectedDataSourceId(fieldDataSourcePairList, "Condition"), null)-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label room-count">
        Above Grade Room Count
      </div>
      <div class="col-md-6 room-count grid-input">
        <div class="container-fluid">
          <div class="row label-row">
            <div class="col-md-4 grid-label">total</div>
            <div class="col-md-4 grid-label">bed</div>
            <div class="col-md-4 grid-label last-room-count-label">bath</div>
          </div>
          <div class="row">
            <div class="col-md-4 grid-input">
              <input type="text" id="TotalRoomCountSubject" value="{{subj?.formattedFieldResults[351].formattedValue}}" class="form-control">
            </div>
            <div class="col-md-4 grid-input">
              <input type="text" id="BedroomCountSubject" value="{{subj?.formattedFieldResults[352].formattedValue}}" class="form-control">
            </div>
            <div class="col-md-4 grid-input">
              <input type="text" id="BathroomCountSubject" value="{{subj?.formattedFieldResults[353].formattedValue}}" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Gross Living Area
      </div>
      <div class="col-md-6 grid-input">
        <div class="input-group">
          <input type="text" id="GlaSubject" value="{{subj?.formattedFieldResults[354].formattedValue}}" class="form-control">
          <span class="input-group-addon trailing sm">sqft</span>
        </div>
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "AboveGradeGla" }, GetSelectedDataSourceId(fieldDataSourcePairList, "AboveGradeGla"), item => String.Format("{0:0,0}", item))-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Basement & Finished
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="BasementAndFinishedSubject" value="{{subj?.formattedFieldResults[355].formattedValue}}" class="form-control">
      </div>

    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Rooms Below Grade
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="RoomsBelowGradeSubject" value="{{subj?.formattedFieldResults[356].formattedValue}}" class="form-control">
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Functional Utility
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="FunctionalUtilitySubject" value="{{subj?.formattedFieldResults[364].formattedValue}}" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "FunctionalUtility" }, GetSelectedDataSourceId(fieldDataSourcePairList, "FunctionalUtility"), null)-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Heating/Cooling
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="HeatingCoolingSubject" value="{{subj?.formattedFieldResults[365].formattedValue}}" class="form-control">
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Energy Efficient Items
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="EnergyEfficientSubject" value="{{subj?.formattedFieldResults[366].formattedValue}}" class="form-control">
      </div>
      <!--@Helpers.ParcelHelpers.BuildInputFlyout(prePrioritizedList, new List<string> { "EnergyEffecientItemsAdjustment" }, GetSelectedDataSourceId(fieldDataSourcePairList, "EnergyEffecientItemsAdjustment"), null)-->
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Garage/Carport
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="ParkingSubject" value="{{subj?.formattedFieldResults[367].formattedValue}}" class="form-control">
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Porch/Patio/Deck
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="PorchSubject" value="{{subj?.formattedFieldResults[378].formattedValue}}" class="form-control">
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Extra Item 1
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="ExtraItem1Subject" value="{{subj?.formattedFieldResults[380].formattedValue}}" class="form-control">
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Extra Item 2
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="ExtraItem2Subject" value="{{subj?.formattedFieldResults[382].formattedValue}}" class="form-control">
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label">
        Extra Item 3
      </div>
      <div class="col-md-6 grid-input">
        <input type="text" id="ExtraItem3Subject" value="{{subj?.formattedFieldResults[384].formattedValue}}" class="form-control">
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-label">
        Net Adjustment (Total)
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-label adjusted-sales-price">
        Adjusted Sale Price of Comparables
      </div>
    </div>

  </div>
</ng-template>


<ng-template #compGrid let-comp="comp" let-compCount="compCount">
  <div class="grid-item" draggable="true">

    <ng-container *ngTemplateOutlet="gridHeaderSection; context: {parcel: comp, type: 'comp', headerText: 'Comp ' + (compCount + 1), primaryPhotoUrl: comp.primaryPhotoUrl, address: comp.formattedFieldResults[303].formattedValue, addressLine2: comp.formattedFieldResults[304].formattedValue, disabledTEMP: compCount%2===0 }"></ng-container>

    <div class="row grid-row">
      <div class="col-md-12 grid-input">
        <input type="text" value="" placeholder="proximity to subject" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-input">
        <div class="input-group">
          <span class="input-group-addon">$</span>
          <input type="text" value="{{comp.formattedFieldResults[309].formattedValue}}" placeholder="sale price" class="form-control" />
        </div>

      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-input">
        <div class="input-group">
          <span class="input-group-addon">$</span>
          <input type="text" value="{{comp.formattedFieldResults[310].formattedValue}}" placeholder="sale price/GLA" class="form-control" />
          <span class="input-group-addon trailing sm">sqft</span>
        </div>
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-input">
        <input type="text" value="{{comp.formattedFieldResults[311].formattedValue}}" placeholder="data source(s)" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-12 grid-input">
        <input type="text" value="{{comp.formattedFieldResults[314].formattedValue}}" placeholder="verification source(s)" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-label label-header">
      </div>
      <div class="col-md-6 grid-label label-header">
        Adjustment
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="SaleFinance{{compCount + 1}}" value="{{comp.formattedFieldResults[315].formattedValue}}" placeholder="sale or financing" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="SaleFinanceAdjust{{compCount + 1}}" placeholder="{{comp.formattedFieldResults[315].formattedValue}}" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="Concessions{{compCount + 1}}" value="{{comp.formattedFieldResults[316].formattedValue}}" placeholder="concessions" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="ConcessionsAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="DateOfSale{{compCount + 1}}" value="{{comp.formattedFieldResults[320].formattedValue}}" placeholder="date of sale" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="DateOfSaleAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="Location{{compCount + 1}}" value="{{comp.formattedFieldResults[328].formattedValue}}" placeholder="location" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="LocationAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="FeeSimple{{compCount + 1}}" value="{{comp.formattedFieldResults[334].formattedValue}}" placeholder="leasehold/fee simple" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="FeeSimpleAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="Site{{compCount + 1}}" value="{{comp.formattedFieldResults[335].formattedValue}}" placeholder="site" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="SiteAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="View{{compCount + 1}}" value="{{comp.formattedFieldResults[336].formattedValue}}" placeholder="view" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="ViewAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="Design{{compCount + 1}}" value="{{comp.formattedFieldResults[342].formattedValue}}" placeholder="design (style)" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="DesignAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="Quality{{compCount + 1}}" value="{{comp.formattedFieldResults[348].formattedValue}}" placeholder="quality of const" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="QualityAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="Age{{compCount + 1}}" value="{{comp.formattedFieldResults[349].formattedValue}}" placeholder="actual age" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="AgeAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="Condition{{compCount + 1}}" value="{{comp.formattedFieldResults[350].formattedValue}}" placeholder="condition" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="ConditionAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input room-count">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4 grid-label">total</div>
            <div class="col-md-4 grid-label">bed</div>
            <div class="col-md-4 grid-label last-room-count-label">bath</div>
          </div>

          <div class="row">
            <div class="col-md-4 grid-input">
              <input type="text" id="TotalRoomCount{{compCount + 1}}" value="{{comp.formattedFieldResults[351].formattedValue}}" class="form-control" />
            </div>
            <div class="col-md-4 grid-input">
              <input type="text" id="BedroomCount{{compCount + 1}}" value="{{comp.formattedFieldResults[352].formattedValue}}" class="form-control" />
            </div>
            <div class="col-md-4 grid-input">
              <input type="text" id="BathroomCount{{compCount + 1}}" value="{{comp.formattedFieldResults[353].formattedValue}}" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 grid-input room-count">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 grid-label">&nbsp;</div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-adjust grid-input">
              <input type="number" id="RoomCountAdjust{{compCount + 1}}" placeholder="" class="form-control" />
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <div class="input-group">
          <input type="text" id="Gla{{compCount + 1}}" value="{{comp.formattedFieldResults[354].formattedValue}}" placeholder="GLA" class="form-control" />
          <span class="input-group-addon trailing sm">sqft</span>
        </div>
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="GlaAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="BasementAndFinished{{compCount + 1}}" value="{{comp.formattedFieldResults[355].formattedValue}}" placeholder="basement/finished" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="BasementAndFinishedAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="RoomsBelowGrade{{compCount + 1}}" placeholder="rooms below grd" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="RoomsBelowGradeAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="FunctionalUtility{{compCount + 1}}" value="{{comp.formattedFieldResults[364].formattedValue}}" placeholder="functional util" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="FunctionalUtilityAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="HeatingCooling{{compCount + 1}}" value="{{comp.formattedFieldResults[365].formattedValue}}" placeholder="heat/cool" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="HeatingCoolingAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="EnergyEfficient{{compCount + 1}}" value="{{comp.formattedFieldResults[366].formattedValue}}" placeholder="energy effct items" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="EnergyEfficientAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="Parking{{compCount + 1}}" value="{{comp.formattedFieldResults[367].formattedValue}}" placeholder="garage/carport" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="ParkingAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="Porch{{compCount + 1}}" value="{{comp.formattedFieldResults[378].formattedValue}}" placeholder="porch/patio/deck" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="PorchAdjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="ExtraItem1{{compCount + 1}}" value="{{comp.formattedFieldResults[294].formattedValue}}" placeholder="extra item 1" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="ExtraItem1Adjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="ExtraItem2{{compCount + 1}}" value="{{comp.formattedFieldResults[296].formattedValue}}" placeholder="extra item 2" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="ExtraItem2Adjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input">
        <input type="text" id="ExtraItem3{{compCount + 1}}" value="{{comp.formattedFieldResults[382].formattedValue}}" placeholder="extra item 3" class="form-control" />
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <input type="number" id="ExtraItem3Adjust{{compCount + 1}}" placeholder="" class="form-control" />
      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input adjust-plus-minus">
        <a href="#" class="fa fa-plus fa-lg"></a>
        <a href="#" class="fa fa-minus fa-lg selected"></a>
      </div>
      <div class="col-md-6 grid-input grid-adjust">
        <div class="input-group">
          <span class="input-group-addon">$</span>
          <input type="text" id="NetAdjustTotal{{compCount + 1}}" placeholder="" class="form-control" />
        </div>

      </div>
    </div>
    <div class="row grid-row">
      <div class="col-md-6 grid-input adjusted-sales-price">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 grid-label">net adj.</div>
            <div class="col-md-6 grid-input">
              <div class="input-group">
                <input type="number" id="NetAdjust{{compCount + 1}}" placeholder="" class="form-control" />
                <span class="input-group-addon">%</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 grid-label">gross adj.</div>
            <div class="col-md-6 grid-input">
              <div class="input-group">
                <input type="number" id="GrossAdjust{{compCount + 1}}" placeholder="" class="form-control" />
                <span class="input-group-addon">%</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6 grid-input grid-adjust adjusted-sales-price">
        <div class="input-group vert-align">
          <span class="input-group-addon">$</span>
          <input type="text" id="AdjustedSalesPrice{{compCount + 1}}" placeholder="" class="form-control" />
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #gridHeaderSection let-parcel="parcel" let-type="type" let-headerText="headerText" let-primaryPhotoUrl="primaryPhotoUrl" let-address="address" let-addressLine2="addressLine2" let-disabledTEMP="disabledTEMP">

  <h1>
    <span *ngIf="type === 'comp'" class="draggable-icon"></span>
    {{headerText}}
    <ng-container *ngTemplateOutlet="rightHeaderItems; context: {disabled: disabledTEMP}"></ng-container>
  </h1>

  <div class="row" *ngIf="type === 'subject22'">
    <div class="col-md-12">
      <!--@Helpers.ParcelHelpers.ParcelPhoto(selectedPhoto, "Subject")-->
      <div class="item-photo">
        <span class="fal fa-image fa-5x photo-icon"></span>
        <span class="fal fa-plus-circle fa-2x add-photo-icon"></span>
      </div>

      <div class="parcel-detail-link-container">
        <a href="#" (click)="$event.stopPropagation()">Listing</a>
        <button (click)="openDeeds(parcel); $event.stopPropagation()">Deeds</button>
      </div>

    </div>
  </div>


  <div class="row">
    <div class="col-md-12 comp-summary {{primaryPhotoUrl ? '' : 'no-pic'}}" (click)="openPhotosDialog(parcel)">
      <!-- <i class="comp-photo" class="bi bi-box-arrow-up-right"></i> todo: icon for open photos link-->
      <span class="comp-photo" *ngIf="primaryPhotoUrl" [ngStyle]="{'background-image': 'url(' + primaryPhotoUrl + ')'}">
      </span>

      <div class="item-photo" *ngIf="!primaryPhotoUrl">
        <span class="fal fa-image fa-5x photo-icon"></span>
        <span class="fal fa-plus-circle fa-2x add-photo-icon"></span>
      </div>

      <div class="detail-links">
        <a href="#" (click)="$event.stopPropagation()">Listing</a>
        <button (click)="openDeeds(parcel); $event.stopPropagation()">Deeds</button>
        <!--<a href="#">Map</a>-->
      </div>

      <address>
        <div class="address">{{address}}</div>
        <div class="address-sub">{{addressLine2}}</div>
      </address>
      <!--@Html.Hidden("FullAddressComp" + compNumber, AddressUtil.BuildAddressString(Model).ToLower(), new { @class = "full-comp-address" })-->
    </div>
    <!--@Helpers.ParcelHelpers.BuildInputFlyout(compPrePrioritizedList, new List<string> { "" }, GetSelectedDataSourceId(fieldDataSourcePairList, "AddressLine1"), null, "address")-->
  </div>

</ng-template>

<ng-template #rightHeaderItems let-disabled="disabled">
  <div class="right-header-items">
    <a href="#" onclick="return false;" class="header-link comp-tracker-link {{disabled ? 'disabled' : ''}}" title="">
      <img src="../../../../assets/images/dm-icon-white.png">
    </a>
    <a href="#" onclick="return false;" class="header-link change" title="">
      <span class="far fa-exchange header-icon"></span>
    </a>
    <a href="#" onclick="return false;" class="header-link delete-comp" title="Delete this comparable">
      <span aria-hidden="true">×</span>
    </a>
  </div>
</ng-template>
