<div>
    <h3 class="table-header">{{deedDataSourceDisplayName}} Transfer History Deeds and Mortgage History</h3>
    <small class="deeds-caption">Click 'Current' Sale or 'Prior Sale' to select deed</small>
</div>
<table matSort class="table table-hover" (matSortChange)="sortDeeds($event)">
    <thead>
    <tr>
        <th *ngIf="showCurrSale" class="checkbox-header" width="60px" scope="col">Curr. Sale</th>
        <th class="checkbox-header" width="60px" scope="col">Prior Sale</th>
        <th mat-sort-header="docNum" width="125px" scope="col">Doc #</th>
        <th mat-sort-header="soldDate" width="100px" scope="col">Sold Date</th>
        <th mat-sort-header="amount" width="100px" scope="col">Amount</th>
        <th mat-sort-header="type" width="150px" scope="col">Type</th>
        <th mat-sort-header="mortgageType" width="150px" scope="col">Mortgage Type</th>
        <th mat-sort-header="recorded" width="100px" scope="col">Recorded</th>
        <th mat-sort-header="grantorSeller" width="300px" scope="col">Grantor (Seller)</th>
        <th mat-sort-header="grantorBuyer" width="300px" scope="col">Grantor (Buyer)</th>
        <th mat-sort-header="alternateNum" width="108px" scope="col">Alternate #</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let deed of deeds"
        [ngClass]="{'prior-sale-deed': deed.isPriorSale, 'sale-verification-deed' : deed.isCurrentSale}">
        <td *ngIf="showCurrSale" class="checkbox-cell">
                <input *ngIf="deed.documentType !== 'Mortgage'" type="checkbox" class="checkbox"
                [checked]="deed.isCurrentSale" (change)="onDeedCurrSaleChange($event, deed)" />
        </td>
        <td class="checkbox-cell">
            <div *ngIf="!readonly">
                <input *ngIf="deed.documentType !== 'Mortgage'" type="checkbox" class="checkbox"
                [checked]="deed.isPriorSale" (change)="onDeedPriorSaleChange($event, deed)" />
            </div>
            <div *ngIf="readonly">
                <span *ngIf="deed.isPriorSale" class="far fa-check"></span>
            </div>
        </td>
        <td>{{deed.documentNumber}}</td>
        <td>{{deed.saleDate | date : 'MM/dd/yyyy'}}</td>
        <td>{{deed.salePrice | currency:'USD':'symbol':'1.0'}}</td>
        <td>{{deed.documentType}}</td>
        <td>{{deed.mortgageType}}</td>
        <td>{{deed.recordingDate | date : 'MM/dd/yyyy'}}</td>
        <td>{{deed.sellerName}}</td>
        <td>{{deed.buyerName}}</td>
        <td>{{deed.transferDocumentNumber}}</td>
    </tr>
    </tbody>
</table>

<ng-template #isPriorSaleDeedDisplay let-isPriorSaleDeed="isPriorSale">

</ng-template>
