import { Component, Input, OnInit } from '@angular/core';
import { UserSettings } from '../../../models/settings/user-settings.model';

@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.scss']
})
export class SettingsGeneralComponent implements OnInit {

  @Input() userSettings!: UserSettings;

  //need these backing fields to not modify the UserSettings object if user clicks cancel
  alwaysGetListingHistory!: boolean;
  skipDeedSelection!: boolean;
  sendDeedAndListingHistory!: boolean;
  sendMlsSheets!: boolean;
  getMainPhotoOnly!: boolean;
  importPhotosFromRets!: boolean;
  includePhotosFromReport!: boolean;
  importPhotosSize! : string;

  importPhotosFromWeb!: boolean;
  alwaysShowExceptionsReport!: boolean;
  includeRealEstateAgentRemarksInExceptionsReport!: boolean;
  overwriteFieldsWithBlanks!: boolean;
  selectedDefaultNavigationLocation!: string;

  //Other fields
  getMainPhotoOnlyIsEnabled! : boolean;
  defaultNavigationLocationOptions : string[] = ['Subject','Market Conditions','Comparables'];

  constructor() {
    //
  }

  ngOnInit(): void {
    this.getMainPhotoOnlyIsEnabled = true; // TODO: false for Dm6 express
    this.alwaysGetListingHistory = this.userSettings.alwaysGetListingHistory;
    this.skipDeedSelection = this.userSettings.skipDeedSelection;
    this.sendDeedAndListingHistory = this.userSettings.sendDeedAndListingHistory;
    this.sendMlsSheets = this.userSettings.sendMlsSheets;
    this.getMainPhotoOnly = this.userSettings.getMainPhotoOnly;
    this.importPhotosFromRets = this.userSettings.importPhotosFromRets;
    this.includePhotosFromReport = this.userSettings.includePhotosFromReport;
    this.importPhotosSize = this.userSettings.importPhotosSize;

    this.importPhotosFromWeb = this.userSettings.importPhotosFromWeb;
    this.alwaysShowExceptionsReport = this.userSettings.alwaysShowExceptionsReport;
    this.includeRealEstateAgentRemarksInExceptionsReport = this.userSettings.includeRealEstateAgentRemarksInExceptionsReport;
    this.overwriteFieldsWithBlanks = this.userSettings.overwriteFieldsWithBlanks;
    this.selectedDefaultNavigationLocation = this.userSettings.defaultNavigationLocation
  }

}
