import { AddressSearchStateModel } from './../../../../models/search/address-search.model';

export class UpdateAddress {
    static readonly type = '[SEARCH] Update Address';
    constructor(public address: AddressSearchStateModel) { }
}

export class ResetAddress {
    static readonly type = '[SEARCH] Reset Address';
}
