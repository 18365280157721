import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
// Modules
import { DMSharedModule } from '@datamaster/core';
import { SafePipeModule } from 'safe-pipe';
import { CommonComponentsModule } from './components/common/common-components.module';
import { MarketConditionsModule } from './components/market-conditions/market-conditions.module';
import { CvDataMasterUxRoutingModule } from './cv.datamaster-routing.ux.module';
// Components
import { CvDataMasterUxComponent } from './cv.datamaster.ux.component';
import { HomeComponent } from './components/home/home.component';
import { FilesComponent } from './components/home/files/files.component';
import { AnalyticsComponent } from './components/home/analytics/analytics.component';
import { ComparablesComponent } from './components/comparables/comparables.component';
import { AddendaComponent } from './components/comparables/addenda/addenda.component';
import { PhotosComponent } from './components/comparables/photos/photos.component';
import { CompDeedsComponent } from './components/comparables/comp-deeds/comp-deeds.component';
import { GridComponent } from './components/comparables/grid/grid.component';
import { MapComponent } from './components/comparables/map/map.component';
import { SubjectComponent } from './components/subject/subject.component';
import { SubjectDetailComponent } from './components/subject/subject-detail/subject-detail.component';
import { SubjectDeedsComponent } from './components/subject/subject-deeds/subject-deeds.component';
import { PlatMapComponent } from './components/subject/platmap/platmap.component';
import { UploadComponent } from './components/comparables/upload/upload.component';
import { SubjectSearchComponent } from './components/subject/subject-search/subject-search.component';
import { SummaryComponent } from './components/summary/summary.component';
import { HomeAltComponent } from './components/home/home-alt/home-alt.component';
import { NewFileAltComponent } from './components/home/new-file-alt/new-file-alt.component';
import { CriticalNotificationsComponent } from './components/home/critical-notifications/critical-notifications.component';
import { SummaryCustomExportComponent } from './components/summary/summary-custom-export/summary-custom-export.component';
import { NoActiveServicesComponent } from './components/home/no-active-services/no-active-services.component';
import { SummaryComparableExportComponent } from './components/summary/summary-custom-export/summary-comparable-export/summary-comparable-export.component';
// State
import { NgxsModule } from '@ngxs/store';
import { DatamasterMainState } from './state/datamaster-main.state';
import { ComparableDetailsComponent } from './components/comparables/comparable-details/comparable-details.component';

@NgModule({
    imports: [
        CommonModule,
        DMSharedModule,
        CommonComponentsModule,
        CvDataMasterUxRoutingModule,
        FormsModule,
        MarketConditionsModule,
        ReactiveFormsModule,
        SafePipeModule,
        ClipboardModule,
        NgxsModule.forFeature([DatamasterMainState]),
    ],
    declarations: [
        CvDataMasterUxComponent,
        HomeComponent,
        FilesComponent,
        AnalyticsComponent,
        ComparablesComponent,
        AddendaComponent,
        CompDeedsComponent,
        PhotosComponent,
        GridComponent,
        MapComponent,
        SubjectComponent,
        SubjectDetailComponent,
        SubjectDeedsComponent,
        PlatMapComponent,
        UploadComponent,
        SubjectSearchComponent,
        SummaryComponent,
        MapComponent,
        HomeAltComponent,
        NewFileAltComponent,
        CriticalNotificationsComponent,
        SummaryCustomExportComponent,
        SummaryComparableExportComponent,
        NoActiveServicesComponent,
        ComparableDetailsComponent,
    ],
})
export class CvDatamasterUxModule {}
