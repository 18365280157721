import { ConfigFileModel } from '../../dependency-issues';

export const APP_PATH = 'app';

export class AppStateModel {
    isLoading: boolean;
    appError: AppErrorModel;
    appName: string;
    [appName: string]: any | AppNameConfigModel;

    constructor() {
        this.isLoading = false;
        this.appError = new AppErrorModel();
    }
}

export class AppNameConfigModel {
    [appName: string]: ConfigFileModel
}

export class AppErrorModel {
    message?: string;
    stopLoading?: boolean;
}
