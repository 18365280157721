export class AuthUser {
    maxioId: number;
    firstName: string;
    lastName: string;
    userName: string;

    constructor() {
        this.maxioId = null;
        this.firstName = '';
        this.lastName = '';
        this.userName = '';
    }
}
