<app-dialog-header title="Market Conditions Comment Select"></app-dialog-header>
<div mat-dialog-content>
  <div class="container">
    <h5>Data Comment</h5>
    <div class="list-container">
      <div class="col list-container-child">
        <h6>Templates</h6>
      <!-- <cdk-virtual-scroll-viewport itemSize="20"> -->
        <mat-selection-list #dataTemplatesList  [multiple]="false" [disableRipple]="true">
          <mat-list-option
            *ngFor="let commentBlock of DataCommentBlockList"
            [selected]="commentBlock.id === DataComment.id"
            [value]="commentBlock"
            (click)="dataCommentListOptionClicked(commentBlock)"
          >
            {{commentBlock.commonName}}
          </mat-list-option>
        </mat-selection-list>
        <div class="button-container">
          <button class="btn" type="button" (click)="addNewComment(false)" [disabled]="!isAddDataCommentEnabled">
          <i class="fa fa-plus"></i>Add
          </button>
          <button class="btn" type="button" (click)="deleteSelectedComment(false)" [disabled]="!isDeleteDataCommentEnabled" >
          <i class="fa fa-minus"></i>Delete
          </button>
          <button class="btn" type="button" (click)="duplicateSelectedComment(false)" [disabled]="!isDuplicateSelectedDataCommentEnabled" >
          <i class="fa fa-clone"></i>Duplicate
          </button>
          <button class="btn" type="button" (click)="renameSelectedCommentClicked(false)" [disabled]="!isRenameSelectedDataCommentEnabled">
          <i class="fa fa-file-signature"></i>Rename
          </button>
        </div>
      </div>      
      <div class="col list-container-child">
        <div>
          <h6>CalculatedTags</h6>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Item Property Type</mat-label>
          <select matNativeControl (change)="fillCalculatedTagsList(false)" [(ngModel)]="dataPropertyUsageType" >
            <option [value]="propertyUsageType.MarketConditions">Competing Market (1004 MC)</option>
            <option [value]="propertyUsageType.NeighborhoodAnalysis">Neighborhood (Larger Market)</option>
            <option [value]="propertyUsageType.Subject">Subject</option>
          </select>
        </mat-form-field>
        <mat-selection-list #dataCalculatedTagsList [multiple]="false" [disableRipple]="true" [ngModel]="DataActiveCalculatedTagList" >
          <mat-list-option
            *ngFor="let calculatedTag of DataActiveCalculatedTagList"
            [selected]="calculatedTag === selectedCalculatedTag"
            [value]="calculatedTag"
            (click)="calculatedTagClickedData(calculatedTag)"
          >
            {{calculatedTag.displayName}}
          </mat-list-option>
        </mat-selection-list>
        <div class="button-container">
          <button class="btn" type="button" (click)="insertCalculatedTag(false)" [disabled]="!isAddCalculatedTagEnabled">
              <i class="fa fa-plus"></i>Insert Calculated Tag
          </button>
        </div>
      </div> 
    </div>
  </div>
  <div class="container">
    <textarea #commentTextArea class="form-control" [(ngModel)]="DataCommentText" [disabled]="!isSaveDataCommentEnabled" ></textarea>
  </div>
  <div class="container">
    <div class="button-container">
      <button class="btn" type="button" (click)="useTemplate(false)" [disabled]="!isUseDataTemplateEnabled">
      <i class="fa fa-square-check"></i>Use Template
      </button>
      <button class="btn" type="button" (click)="saveSelectedComment(false)" [disabled]="!isSaveDataCommentEnabled">
      <i class="fa fa-floppy-disk"></i>Save
      </button>
    </div>
  </div>
  <div class="container">
    <h5>No Data Comment</h5>
    <div class="list-container">
      <div class="col list-container-child">
        <h6>Templates</h6>
        <!-- <cdk-virtual-scroll-viewport itemSize="20"> -->
        <mat-selection-list #noDataTemplatesList  [multiple]="false" [disableRipple]="true">
          <mat-list-option
            *ngFor="let commentBlock of NoDataCommentBlockList"
            [selected]="commentBlock.id === NoDataComment.id"
            [value]="commentBlock"
            (click)="noDataCommentListOptionClicked(commentBlock)"
          >
            {{commentBlock.commonName}}
          </mat-list-option>
        </mat-selection-list>
        <div class="button-container">
          <button class="btn" type="button" (click)="addNewComment(true)" [disabled]="!isAddNoDataCommentEnabled">
          <i class="fa fa-plus"></i>Add
          </button>
          <button class="btn" type="button" (click)="deleteSelectedComment(true)" [disabled]="!isDeleteNoDataCommentEnabled" >
          <i class="fa fa-minus"></i>Delete
          </button>
          <button class="btn" type="button" (click)="duplicateSelectedComment(true)" [disabled]="!isDuplicateSelectedNoDataCommentEnabled" >
          <i class="fa fa-clone"></i>Duplicate
          </button>
          <button class="btn" type="button" (click)="renameSelectedCommentClicked(true)" [disabled]="!isRenameSelectedNoDataCommentEnabled">
          <i class="fa fa-file-signature"></i>Rename
          </button>
        </div>
      </div>      
      <div class="col list-container-child">
        <div>
          <h6>CalculatedTags</h6>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Item Property Type</mat-label>
          <select matNativeControl (change)="fillCalculatedTagsList(true)" [(ngModel)]="noDataPropertyUsageType" >
            <option [value]="propertyUsageType.MarketConditions">Competing Market (1004 MC)</option>
            <option [value]="propertyUsageType.NeighborhoodAnalysis">Neighborhood (Larger Market)</option>
            <option [value]="propertyUsageType.Subject">Subject</option>
          </select>
        </mat-form-field>
        <mat-selection-list [multiple]="false" [disableRipple]="true" [ngModel]="NoDataActiveCalculatedTagList" >
          <mat-list-option
            *ngFor="let calculatedTag of NoDataActiveCalculatedTagList"
            [selected]="calculatedTag === selectedCalculatedTagNoData"
            [value]="calculatedTag"
            (click)="calculatedTagClickedNoData(calculatedTag)"
          >
            {{calculatedTag.displayName}}
          </mat-list-option>
        </mat-selection-list>
        <div class="button-container">
          <button class="btn" type="button" (click)="insertCalculatedTag(true)" [disabled]="!isAddCalculatedTagNoDataEnabled">
              <i class="fa fa-plus"></i>Insert Calculated Tag
          </button>
        </div>
      </div>
    </div>  
  </div>
  <div class="container">
    <div>
        <textarea #noDataCommentTextArea class="form-control" [(ngModel)]="NoDataCommentText" [disabled]="!isSaveNoDataCommentEnabled"></textarea>
    </div>
  </div>
  <div class="container">
    <div class="button-container">
      <button class="btn" type="button" (click)="useTemplate(true)" [disabled]="!isUseNoDataTemplateEnabled">
      <i class="fa fa-square-check"></i>Use Template
      </button>
      <button class="btn" type="button" (click)="saveSelectedComment(true)" [disabled]="!isSaveNoDataCommentEnabled">
      <i class="fa fa-floppy-disk"></i>Save
      </button>
    </div>
  </div>  
</div>
<div class="container">
  <div class="button-container">
    <button class="btn" type="button" (click)="close()">
      <i class=""></i>Finish
      </button>
  </div>
</div>  
