<app-search-error #searchError></app-search-error>

<form id="apnSearchForm" [formGroup]="apnSearchForm">

  <div class="form-group">
    <label for="apnNumber">
      APN Number
      <span class="required"
        *ngIf="(apnSearchForm.controls['apnNumber'].invalid && apnSearchForm.controls['apnNumber'].touched)">required</span>
    </label>
    <input type="text" placeholder="APN Number" formControlName="apnNumber" class="full-width" data-cy="searchResultsApnInput"/>
  </div>
  <div class="form-row">
    <div class="form-group col-6">
      <label for="state">
        State
        <span class="required"
          *ngIf="(apnSearchForm.controls['state'].invalid && apnSearchForm.controls['state'].touched)">required</span>
      </label>
      <select formControlName="state" class="full-width" data-cy="searchResultsApnStateSelect">
        <option *ngFor="let state of stateJson" [ngValue]="state.Abbreviation">{{state.State}}</option>
      </select>
    </div>
    <div class="form-group col-6">
      <label for="county">
        County
        <span class="required"
          *ngIf="(apnSearchForm.controls['county'].invalid && apnSearchForm.controls['county'].touched)">required</span>
      </label>
      <select formControlName="county" class="full-width" data-cy="searchResultsApnCountySelect">
        <!--<option *ngFor="let county of countiesJson" [ngValue]="county.CountyName">{{county.CountyName}}</option>-->
        <option *ngFor="let county of countiesJsonApnSearch" [ngValue]="county.CountyName">{{county.CountyName}}
        </option>
      </select>
    </div>
  </div>

  <div class="submit search">

    <button type="button" class="btn reset-button" (click)="resetForm()" data-cy="searchResultsApnResetButton">
      <span class="far fa-redo icon"></span>
      Reset
    </button>

    <button type="submit" class="btn btn-primary btn-search" (click)="searchValidate(apnSearchForm)"
      [disabled]="isFormSearching(apnSearchForm)" data-cy="searchResultsApnSearchButton">
      <span class="fal fa-search icon" *ngIf="!isFormSearching(apnSearchForm)"></span>
      <span *ngIf="!isFormSearching(apnSearchForm)">Search</span>
      <span class="far fa-spinner fa-pulse spinner icon" *ngIf="isFormSearching(apnSearchForm)"></span>
      <span *ngIf="isFormSearching(apnSearchForm)">Searching...</span>
    </button>
  </div>

</form>