export class ListingHistoryPropertyRequest {
  propertyGuid: string;
  apn: string;
  mls: string;
  //fipsCode : string;
  houseNumber: string;
  streetPreDirection: string;
  streetName: string;
  streetSuffix: string;
  streetPostDirection: string;
  unitNumber: string;
  city: string;
  county: string;
  state: string;
  zip: string;
  addressLine1: string;
  addressLine2: string;
  fullAddress: string; //check if we need this
  latitude: string; //check if we need this
  longitude: string; //check if we need this
}
