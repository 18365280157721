<div>
    <div class="series-color-setting input-group">
        <label class="series-color-picker-label">First data series color</label>
        <input  #ignoredInput class="series-color-picker" [cpPosition]="'bottom'" [(colorPicker)]="userMarketConditionsGraphSetting.firstDataSeriesColor" readonly  [cpIgnoredElements]="[ignoredButton, ignoredInput]" [style.background]="userMarketConditionsGraphSetting.firstDataSeriesColor" [(cpToggle)]="firstDataSeriesColorToggle"/>
        <button #ignoredButton class="btn btn-secondary color-picker-toggle-btn" (click)="firstDataSeriesColorToggle=!firstDataSeriesColorToggle">Change</button>
    </div>
    <div *ngIf="displaySecondSeriesColors" class="series-color-setting input-group">
        <label class="series-color-picker-label">Second data series color</label>
        <input #ignoredInput2 class="series-color-picker" [cpPosition]="'bottom'" [(colorPicker)]="userMarketConditionsGraphSetting.secondDataSeriesColor" readonly [cpIgnoredElements]="[ignoredButton2, ignoredInput2]"  [style.background]="userMarketConditionsGraphSetting.secondDataSeriesColor" [(cpToggle)]="secondDataSeriesColorToggle"/>
        <button #ignoredButton2 class="btn btn-secondary color-picker-toggle-btn" (click)="secondDataSeriesColorToggle=!secondDataSeriesColorToggle">Change</button>
    </div>
</div>
<div>
    <div class="series-color-setting input-group">
        <label  class="series-color-picker-label">First trend series color</label>
        <input #ignoredInput3 class="series-color-picker" [cpPosition]="'bottom'"  [(colorPicker)]="userMarketConditionsGraphSetting.firstTrendLineColor"  readonly [cpIgnoredElements]="[ignoredButton3, ignoredInput3]" [style.background]="userMarketConditionsGraphSetting.firstTrendLineColor" [(cpToggle)]="firstTrendSeriesColorToggle"/>
        <button #ignoredButton3 class="btn btn-secondary color-picker-toggle-btn" (click)="firstTrendSeriesColorToggle=!firstTrendSeriesColorToggle">Change</button>
    </div>
    <div *ngIf="displaySecondSeriesColors" class="series-color-setting input-group">
        <label  class="series-color-picker-label">Second trend series color</label>
        <input #ignoredInput4 class="series-color-picker" [cpPosition]="'bottom'" [(colorPicker)]="userMarketConditionsGraphSetting.secondTrendLineColor"  readonly [cpIgnoredElements]="[ignoredButton4, ignoredInput4]" [style.background]="userMarketConditionsGraphSetting.secondTrendLineColor" [(cpToggle)]="secondTrendSeriesColorToggle"/>
        <button #ignoredButton4 class="btn btn-secondary color-picker-toggle-btn" (click)="secondTrendSeriesColorToggle=!secondTrendSeriesColorToggle">Change</button>
    </div>
</div>

<div>
    <mat-checkbox [(ngModel)]="userMarketConditionsGraphSetting.animatedGraph">Animated Graph Label</mat-checkbox>
    <mat-checkbox  [(ngModel)]="userMarketConditionsGraphSetting.removeLeadingZerosFromTrendLine">Remove leading zeroes from trend line</mat-checkbox>
    <mat-checkbox [(ngModel)]="userMarketConditionsGraphSetting.removeTrailingZerosFromTrendLine">Remove trailing zeroes from trend line</mat-checkbox>
    <mat-checkbox [(ngModel)]="userMarketConditionsGraphSetting.startAtZero">Start at 0</mat-checkbox>
</div>

<div>
    <div class="polynomial-degree-input-group">
        <input type="text" class="polynomial-degree-input" [(ngModel)]="userMarketConditionsGraphSetting.firstPolynomialDegree"/>
        <label>First Polynomial degree</label>
    </div>
    <div class="polynomial-degree-input-group">
        <input type="text" class="polynomial-degree-input"  [(ngModel)]="userMarketConditionsGraphSetting.secondPolynomialDegree"/>
        <label>Second Polynomial degree</label>
    </div>
</div>

<div>
    <div class="dropdown-input-group">
        <label class="setting-drop-down-label">First trend line type</label>
        
        <select name="effectiveDate" disabled [(ngModel)]="userMarketConditionsGraphSetting.firstTrendLineType">
            <option value="line">
                Line
            </option>
        </select>
    </div>

    <div class="dropdown-input-group" *ngIf="displaySecondTrendLineType">
        <label class="setting-drop-down-label" >Second trend line type</label>
        
        <select name="effectiveDate" disabled [(ngModel)]="userMarketConditionsGraphSetting.secondTrendLineType">
            <option value="line">
                Line
            </option>
        </select>
    </div>
</div>