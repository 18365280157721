import { FormattedDataSource } from './formatted-data-source.model';
import { Parcel } from './parcel.model';

export class Comparable extends Parcel {
  propertyPlacementNumber: number;
  attemptedToGetProximity: boolean;
  exportToReport: boolean;
  exportPropertyPlacementNumber: number;

  constructor() {
    super();

    const userValuesFormattedDataSource = new FormattedDataSource();
    userValuesFormattedDataSource.dataSource = 'UserValues';

    this.formattedDataSources = [userValuesFormattedDataSource];

    this.propertyPlacementNumber = 0;
  }
}
