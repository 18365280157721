import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<string, HttpResponse<any>> = new Map();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    if (!req.headers.get('useCache')) {
      return next.handle(req);
    }

    const cachedResponse: HttpResponse<any> = this.cache.get(req.urlWithParams);

    if (cachedResponse) {
      return of(cachedResponse.clone());
    } else {
      return next.handle(req).pipe(
        tap((httpEvent: HttpEvent<any>) => {
          if (httpEvent instanceof HttpResponse) {
            this.cache.set(req.urlWithParams, httpEvent);
          }
        }),
        share()
      );
    }
  }
}
