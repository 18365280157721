<table class="table table-bordered">

    <tr class="header-row" >
        <th class="darkbackground" colspan="8">Search Criteria Summary</th>
    </tr>

    <tr class="">
        <th class=""></th>
        <th class="" colspan="4">Competing Market<div class="sublabel">(1004 MC)</div></th>
        <th class="" colspan="3">Neighborhood<div class="sublabel">(Larger Market)</div></th>
    </tr>

    <tr class="">
        <th class=" darkbackground"></th>
        <th class=" darkbackground">Subject</th>
        <th class=" darkbackground" colspan="3">Criteria Ranges</th>
        <th class=" darkbackground" colspan="3">Criteria Ranges</th>
    </tr>

    <tr class="">
        <th class="">Style</th>
        <td class="data">{{SubjectStyle}}</td>
        <td class="data" colspan="3" [title]="CompetingMarketForm?._0_24MO?.distinctStyles">{{CompetingMarketForm?._0_24MO?.distinctStyles}}</td>
        <td class="data" colspan="3" [title]="NeighborHoodAnalysisForm?._0_24MO?.distinctStyles">{{NeighborHoodAnalysisForm?._0_24MO?.distinctStyles}}</td>
    </tr>
    <div *ngTemplateOutlet="rangeRow; context:{name:'Lot Size', subjectValue:SubjectSiteArea, cmStartRange:CompetingMarketForm?._0_24MO?.minSite, cmEndRange:CompetingMarketForm?._0_24MO?.maxSite, neighborhoodStartRange:NeighborHoodAnalysisForm?._0_24MO?.minSite, neighborhoodEndRange:NeighborHoodAnalysisForm?._0_24MO?.maxSite}"></div>
    <div *ngTemplateOutlet="rangeRow; context:{name:'Age', subjectValue:SubjectActualAge, cmStartRange:CompetingMarketForm?._0_24MO?.minAge, cmEndRange:CompetingMarketForm?._0_24MO?.maxAge, neighborhoodStartRange:NeighborHoodAnalysisForm?._0_24MO?.minAge, neighborhoodEndRange:NeighborHoodAnalysisForm?._0_24MO?.maxAge}"></div>
    <!-- <div *ngTemplateOutlet="rangeRow; context:{name:'Bds (GLA)', subjectValue:SubjectTotalBedrooms, cmStartRange:CompetingMarketForm?._0_24MO?.minAboveGradeBedrooms, cmEndRange:CompetingMarketForm?._0_24MO?.maxAboveGradeBedrooms, neighborhoodStartRange:NeighborHoodAnalysisForm?._0_24MO?.minAboveGradeBedrooms, neighborhoodEndRange:NeighborHoodAnalysisForm?._0_24MO?.maxAboveGradeBedrooms}"></div> -->
    <div *ngTemplateOutlet="rangeRow; context:{name:'Total Bds', subjectValue:SubjectTotalAboveGradeBedrooms, cmStartRange:CompetingMarketForm?._0_24MO?.minTotalBedrooms, cmEndRange:CompetingMarketForm?._0_24MO?.maxTotalBedrooms, neighborhoodStartRange:NeighborHoodAnalysisForm?._0_24MO?.minTotalBedrooms, neighborhoodEndRange:NeighborHoodAnalysisForm?._0_24MO?.maxTotalBedrooms}"></div>
    <div *ngTemplateOutlet="rangeRow; context:{name:'GLA', subjectValue:SubjectAboveGradeGla, cmStartRange:CompetingMarketForm?._0_24MO?.minSqft, cmEndRange:CompetingMarketForm?._0_24MO?.maxSqft, neighborhoodStartRange:NeighborHoodAnalysisForm?._0_24MO?.minSqft, neighborhoodEndRange:NeighborHoodAnalysisForm?._0_24MO?.maxSqft}"></div>
    <!-- <div *ngTemplateOutlet="rangeRow; context:{name:'TBA', SubjectTotalBuildingArea, cmStartRange:CompetingMarketForm?._0_24MO?.minTotalBuildingSqft, cmEndRange:CompetingMarketForm?._0_24MO?.maxTotalBuildingSqft, neighborhoodStartRange:NeighborHoodAnalysisForm?._0_24MO?.minTotalBuildingSqft, neighborhoodEndRange:NeighborHoodAnalysisForm?._0_24MO?.maxTotalBuildingSqft}"></div> -->
    
    
</table>

<!--TEMPLATES-->
<ng-template #rangeRow let-name="name" 
let-subjectValue="subjectValue"
let-cmStartRange="cmStartRange"
let-cmEndRange="cmEndRange"
let-neighborhoodStartRange="neighborhoodStartRange"
let-neighborhoodEndRange="neighborhoodEndRange">
    <tr class="">
        <th class="">{{name}}</th>
        <td class="data" [title]="subjectValue">{{subjectValue}}</td>
        
        <td class="data" [title]="cmStartRange">{{cmStartRange}}</td>
        <td class="box">To</td>
        <td class="data" [title]="cmEndRange">{{cmEndRange}}</td>
        
        <td class="data" [title]="neighborhoodStartRange">{{neighborhoodStartRange}}</td>
        <td class="box">To</td>
        <td class="data" [title]="neighborhoodEndRange">{{neighborhoodEndRange}}</td>
    </tr>
</ng-template>