import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserDataSourcesResolver } from '@datamaster/core';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { CvDataMasterUxComponent } from './cv.datamaster.ux.component';
import { AddendaComponent } from './components/comparables/addenda/addenda.component';
import { CompDeedsComponent } from './components/comparables/comp-deeds/comp-deeds.component';
import { ComparablesComponent } from './components/comparables/comparables.component';
import { GridComponent } from './components/comparables/grid/grid.component';
import { MapComponent } from './components/comparables/map/map.component';
import { UploadComponent } from './components/comparables/upload/upload.component';
import { HomeAltComponent } from './components/home/home-alt/home-alt.component';
import { HomeComponent } from './components/home/home.component';
import { GraphsComponent } from './components/market-conditions/graphs/graphs.component';
import { ImportComponent } from './components/market-conditions/import/import.component';
import { MarketConditionsComponent } from './components/market-conditions/market-conditions.component';
import { McDetailComponent } from './components/market-conditions/mc-detail/mc-detail.component';
import { ReportComponent } from './components/market-conditions/report/report.component';
import { PlatMapComponent } from './components/subject/platmap/platmap.component';
import { SubjectDeedsComponent } from './components/subject/subject-deeds/subject-deeds.component';
import { SubjectDetailComponent } from './components/subject/subject-detail/subject-detail.component';
import { SubjectSearchComponent } from './components/subject/subject-search/subject-search.component';
import { SubjectComponent } from './components/subject/subject.component';
import { SummaryComponent } from './components/summary/summary.component';
import { McSummaryComponent } from './components/market-conditions/mc-summary/mc-summary.component';

//paths are case sensitive
//redirectTos are only one hop
const routes: Routes = [
  {
    path: '',
    component: CvDataMasterUxComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    canActivateChild: [AutoLoginPartialRoutesGuard],
    data: {
      module: 'datamaster-ux'
    },
    children: [
      {	path: '', redirectTo: 'home' },
      {
        path: 'home',
        component: HomeComponent,
        resolve: {
          userDataSources: UserDataSourcesResolver,
        }
      },
      {
        path: 'home-alt',
        component: HomeAltComponent,
      },
      {
        path: 'summary',
        component: SummaryComponent,
      },
    ]
  },

  {
    path: 'comparables',
    canActivate: [AutoLoginPartialRoutesGuard],
    component: ComparablesComponent,
    children: [
      { path: 'addenda', component: AddendaComponent },
      {
        path: 'grid',
        component: GridComponent,
        children: [
          // hack to allow a default id value
          { path: ':id', component: GridComponent },
          { path: '**', redirectTo: '/Comparables/grid/1' },
        ],
      },
      { path: 'deeds', component: CompDeedsComponent },
      { path: 'map', component: MapComponent },
      { path: 'upload', component: UploadComponent },
      { path: '', component: GridComponent },
    ],
  },
  {
    path: 'subject',
    canActivate: [AutoLoginPartialRoutesGuard],
    component: SubjectComponent,
    children: [
      {
        // path: 'detail/:id', component: DetailComponent,
        path: 'detail',
        component: SubjectDetailComponent,
        children: [
          // hack to allow a default id value
          { path: ':id', component: SubjectDetailComponent },
          { path: '**', redirectTo: '/Subject/detail/1' },
        ],
      },
      { path: 'deeds', component: SubjectDeedsComponent },
      { path: 'search', component: SubjectSearchComponent },
      { path: '', component: SubjectDetailComponent },
      { path: 'platmap', component: PlatMapComponent },
      { path: '**', redirectTo: '/Subject' },
    ],
  },

  {
    path: 'market-analysis',
    canActivate: [AutoLoginPartialRoutesGuard],
    component: MarketConditionsComponent,
    children: [
      { path: 'graphs', component: GraphsComponent },
      { path: 'detail', component: McDetailComponent },
      { path: 'report', component: ReportComponent },
      { path: 'import', component: ImportComponent },
      { path: 'summary', component: McSummaryComponent },
      { path: '', component: ReportComponent,
        children: [
          { path: ':neighborhoodAnalysis', component: ReportComponent },
          { path: '**', redirectTo: '/market-analysis' },
        ],
      },
      { path: ':neighborhoodAnalysis', component: ReportComponent,
        children: [
        { path: 'graphs', component: GraphsComponent, redirectTo: '/graphs' },
        { path: 'detail', component: McDetailComponent, redirectTo: '/detail' },
      ],
     },
      { path: '**', redirectTo: '/market-analysis' },
    ],
    runGuardsAndResolvers: 'always',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
  // imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  //exports: [RouterModule],
})
export class CvDataMasterUxRoutingModule { }
