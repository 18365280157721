import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import {
  UserStateService,
  SidebarLogoService,
  ThemeService,
  DataSource,
  DataMasterFileService,
  PropertyUsageType,
  CompHeaderService,
  PropertyImportDialogService
 } from '@datamaster/core';

import { filter, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-comparables',
  templateUrl: './comparables.component.html',
  styleUrls: ['./comparables.component.scss'],
})
export class ComparablesComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('compHeader', { static: true }) compHeader!: ElementRef;
  oldCompHeaderWidth = 0;
  isActiveLink!: string;
  dataSources!: DataSource[];
  formType!: string;
  compCount!: number;
  sidebarWidth!: string; // default width of the sidebar (1/2 of the width)
  sidebarWidthValue!: number;
  theme: string = this.themeService.startupTheme;
  get doesUserHaveWebAccess(): boolean {
    return this.userStateService.user.isInternal;
  }
  private _subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private compHeaderService: CompHeaderService,
    private sidebarLogoService: SidebarLogoService,
    private themeService: ThemeService,
    private dataMasterFileService: DataMasterFileService,
    private userStateService: UserStateService,
    private propertyImportDialogService: PropertyImportDialogService,
  ) {
  }

  ngOnInit() {

    this.isActiveLink = this.isActive();
    this.getDataSources();
    this.formType = this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form.displayName;
    this.compCount = this.dataMasterFileService.dataMasterFile.appraisal.comparables.length;

    this.themeService.currentTheme.subscribe((val) => {
      this.theme = val;
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((value: any) => {
        //this.isActiveLink = this.activatedRoute.firstChild.routeConfig.path === '' || this.activatedRoute.firstChild.routeConfig.path === 'grid' ? 'active' : '';
        this.isActiveLink =
          value.url === '/comparables' || value.url === '/comparables/grid' ? 'active' : '';
      });

    this.sidebarLogoService.getSidebarExpanded().subscribe((val) => {
      if (val === false) {
        this.updateCompHeaderWidth();
      }
    });

    this.sidebarWidth = '-' + this.sidebarLogoService.currentSidebarWidth.getValue() / 2 + 'px'; // Get the initial value (BehaviorSubject)
    this.sidebarWidthValue = this.sidebarLogoService.currentSidebarWidth.getValue();
    this.sidebarLogoService.getSidebarWidth().subscribe((width) => {
      this.sidebarWidth = '-' + width / 2 + 'px';
      this.sidebarWidthValue = width;
    });

    // this._subscription.add(this.userStateService.getUserState().pipe(tap(userState =>{
    //   this.doesUserHaveWebAccess = userState.isInternal;
    // })).subscribe());
  }

  ngAfterViewInit() {
    this.oldCompHeaderWidth = this.compHeader.nativeElement.offsetWidth;
    this.compHeaderService.setCompHeaderWidth(this.compHeader.nativeElement.offsetWidth);
    //console.log('width from DoCheck: ' + this.compHeader.nativeElement.offsetWidth);
  }

  ngAfterViewChecked() {
    this.updateCompHeaderWidth();
  }

  public onResize(event: Event) {
    this.updateCompHeaderWidth();
  }

  private updateCompHeaderWidth(): void {
    if (this.oldCompHeaderWidth !== this.compHeader.nativeElement.offsetWidth) {
      this.compHeaderService.setCompHeaderWidth(this.compHeader.nativeElement.offsetWidth);
      this.oldCompHeaderWidth = this.compHeader.nativeElement.offsetWidth;
      console.log(
        'Width from updateCompHeaderWidth function: ' + this.compHeader.nativeElement.offsetWidth
      );
    }
  }

  private isActive(): string {
    // This seems like a hack
    // Just to get two routes to show active on the same tab
    return this.router.isActive('/comparables', false) ||
      this.router.isActive('/comparables/grid', false)
      ? 'active'
      : '';
  }

  getDataSources(): void {
    // this.dataSourceService.dataSources
    //   .subscribe(dataSources => {
    //     this.dataSources = dataSources;
    //   });
  }

  onActivate(componentReference:any) {
    //maybe pass ion the datamaster file and have that be a property in an interface
    componentReference.comparables =
      this.dataMasterFileService.dataMasterFile.appraisal.comparables;
    componentReference.subj = this.dataMasterFileService.dataMasterFile.appraisal.subject;
  }
  openPropertyImportModal() {
    this.propertyImportDialogService.openPropertyImportModal(PropertyUsageType.Comparable);
  }
}
