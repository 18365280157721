import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  ColDef,
  Column,
  ColumnApi,
  GridApi,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-community';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'checkbox-renderer',
  template: ` <input type="checkbox" (click)="checkedHandler($event)" [checked]="params.value" />`,
})
export class CheckboxRendererComponent implements ICellRendererAngularComp, ICellRendererParams {
  value: any;
  valueFormatted: any;
  fullWidth?: boolean;
  pinned?: string;
  data: any;
  node: RowNode;
  rowIndex: number;
  colDef?: ColDef;
  column?: Column;
  $scope: any;
  api: GridApi;
  columnApi: ColumnApi;
  context: any;
  eGridCell: HTMLElement;
  eParentOfValue: HTMLElement;
  getValue?: () => any;
  setValue?: (value: any) => void;
  formatValue?: (value: any) => any;
  refreshCell?: () => void;
  registerRowDragger: (
    rowDraggerElement: HTMLElement,
    dragStartPixels?: number,
    value?: string,
    suppressVisibilityChange?: boolean
  ) => void;
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event) {
    const checked = event.target.checked;
    const colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
