<h4 *ngIf="isDashboardSearch === true">Start a new file by retrieving subject property info</h4>

<app-search-error #searchError></app-search-error>

<form id="addressSearchForm" [formGroup]="addressSearchForm" #addressSearch="ngForm">
  <div class="form-row">
    <div class="form-group col-7">
      <label for="address">
        Address
        <span class="required" [style.visibility]="(addressSearchForm.controls['address'].invalid && addressSearchForm.controls['address'].touched) ? 'visible' : 'hidden' " data-cy="homeAddressRequiredLabel">required</span>
      </label>
      <input type="text" formControlName="address" data-cy="homeAddressInput"/>
    </div>
    <div class="form-group col">
      <label for="city">
        City
      </label>
      <input type="text" formControlName="city" data-cy="homeAddressCityInput"/>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-4">
      <label for="state">
        State
        <span class="required" [style.visibility]="(addressSearchForm.controls['state'].invalid && addressSearchForm.controls['state'].touched) ? 'visible' : 'hidden' ">required</span>
      </label>
      <select formControlName="state" data-cy="homeAddressStateSelect">
        <option *ngFor="let state of stateJson " [ngValue]="state.Abbreviation">{{state.State}}</option>
      </select>
    </div>
    <div class="form-group col">
      <label for="county">
        County
        <span class="required" [style.visibility]="(addressSearchForm.controls['county'].invalid && addressSearchForm.controls['county'].touched)? 'visible' : 'hidden'">required</span>
      </label>
      <select formControlName="county" data-cy="homeAddressCountySelect">
        <option *ngFor="let county of countiesJsonAddressSearch" [ngValue]="county.CountyName">{{county.CountyName}}</option>
      </select>
    </div>

    <div class="form-group col">
      <label for="zip">
        Zip
      </label>
      <input type="text" formControlName="zip" data-cy="homeAddressZipInput"/>
    </div>
  </div>
  <div class="submit search">
    <button type="submit" class="btn btn-primary btn-search" (click)="searchValidate(addressSearchForm)" [disabled]="isFormSearching(addressSearchForm)" data-cy="homeAddressSearchButton">
      <span class="fal fa-search" *ngIf="!isFormSearching(addressSearchForm)"></span>
      <span *ngIf="!isFormSearching(addressSearchForm)">Search</span>
      <span class="far fa-spinner fa-pulse spinner" *ngIf="isFormSearching(addressSearchForm)"></span>
      <span *ngIf="isFormSearching(addressSearchForm)">Searching...</span>
    </button>
  </div>
</form>