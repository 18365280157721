    <h2 class="title" data-cy="credentialsModal">MLS Credentials</h2>
    <form>
        <p class="dialog-message">{{data.loginMessage}}</p>
        <label for="username">Username</label>
        <input id="username" type="text" [(ngModel)]="username" name="username" data-cy="credentialsModalUsernameInput"/>
        <div *ngIf="data.isPasswordRequired">
            <label for="password">Password</label>
            <input id="password" type="password" [(ngModel)]="password" name="password" />
        </div>
        <div class="actions">
            <button class="btn btn-confirm save-button" (click)="saveCredentials($event)" data-cy="credentialsModalSaveButton">Save</button>
            <button [mat-dialog-close]="true" class="btn cancel-button" data-cy="credentialsModalCancelButton" (click)="addIsCredentialUpdated(false)">Cancel</button>
        </div>
    </form>
