import { PropertyUsageType } from '../../workfile/enum/property-usage-type.enum';
import { StandardizedParcel } from '../../workfile/standardized-parcel.model';
import { TagPriority } from '../../workfile/tag-priority.model';
import { OperationResult } from '../operation-result.model';
import { SearchCriteriaUserData } from '../property-search/search-criteria.model';
import { ResultBase } from '../result-base.model';

export class PrioritizedPropertyResult extends ResultBase {
  propertyUsageType: PropertyUsageType;
  standardizedParcel: StandardizedParcel;
  tagPriorities: TagPriority[];
}

export class PrioritizationResult extends OperationResult {
  prioritizedPropertyResults: PrioritizedPropertyResult[];
}

export class FormattingRequestWithPrioritizationResult {
  prioritizationResult: PrioritizationResult;
  userData: SearchCriteriaUserData;
  formCommonName: string;
  formSoftware: string;

  constructor(
    prioritizationResult: PrioritizationResult,
    userData: SearchCriteriaUserData,
    formCommonName: string,
    formSoftware: string
  ) {
    this.prioritizationResult = prioritizationResult;
    this.userData = userData;
    this.formCommonName = formCommonName;
    this.formSoftware = formSoftware;
  }
}
