import { RenderFlags } from "@angular/compiler/src/core";
import { EntityFrameworkBase } from "../data-source/entity-framework-base.model";
import { MarketConditionGraphGroupType } from "../enums/market-conditions-graph-group-type.enum";
import { PropertyUsageType } from "../workfile/enum/property-usage-type.enum";
import { GraphDataPoint } from "./graph-data-point.model";
import { Graph } from "./graph.model";

export class GraphGroup extends EntityFrameworkBase{
    commonName: string;
    displayName: string;
}

export class MatrixColumn{
    value : number;
}
export class MatrixRow{
    columns : MatrixColumn[];
}
export class GraphDataSeries{
    dataPointList : GraphDataPoint[];
    equationCoefficents : MatrixRow;
    dataSeriesName : string;
    graphType : RenderFlags;
    allowZeros : boolean;
}

export interface GraphSetting extends EntityFrameworkBase {
    commonName: string;
    displayName: string;
}

export class DataMasterGraphGroupSetting extends EntityFrameworkBase {
    graphSettingId: number;
    graphGroupId: number;
    value: string;
}
export class UserGraphGroupSetting extends EntityFrameworkBase {
    graphSettingId: number;
    graphGroupId: number;
    userId: number;
    value: string;
    isModified: boolean;
}
export class GraphDetails{
    graph : Graph;
    graphGroup : GraphGroup;
    addtrendLine : boolean;
    graphDataSeriesList : GraphDataSeries[];
    dataMasterGraphGroupSettingList : DataMasterGraphGroupSetting[]; //not following naming convention, but name to match server side
    userGraphGroupSettingList : UserGraphGroupSetting[];//not following naming convention, but name to match server side
    export : boolean;
    reportUsageType : PropertyUsageType;
    marketConditionGraphGroupType : MarketConditionGraphGroupType;

}
export class MarketConditionsGraph {
    graphDetails: GraphDetails;
    commonName: string;
    displayName: string;
    includeInExport: boolean;
}