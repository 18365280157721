
<div class="card search-card">
  <div class="card-header">
    <ng-container *ngTemplateOutlet="layout==='web' ? webHeader : desktopHeader"></ng-container>

    <ul class="nav nav-tabs" >
      <li class="tab" *ngIf="layout!=='desktop' && !isPropertySearchApp">
        <a href="#dashboardNewFileTab" class="{{layout!=='desktop' && !isPropertySearchApp ? 'active' : ''}}" data-toggle="tab" data-cy="homeNewFileTab">New file</a>
      </li>
      <li class="tab">
        <a href="#dashboardAddressSearchTab" class="{{(layout==='desktop' && !isPropertySearchApp) || (isPropertySearchApp && mlsDataSources?.length === 0)? 'active' : ''}}" data-toggle="tab" data-cy="homeAddressTab"><span
            class="far fa-search"></span> Address</a>
      </li>
      <li *ngIf="mlsDataSources?.length > 0" class="tab">
        <a href="#dashboardMlsSearchTab" class="{{isPropertySearchApp ? 'active' : ''}}" data-toggle="tab" data-cy="homeMlsTab"><span class="far fa-search"></span> MLS</a>
      </li>
      <li class="tab">
        <a href="#dashboardApnSearchTab" data-toggle="tab" data-cy="homeApnTab"><span class="far fa-search"></span> APN</a>
      </li>
    </ul>

  </div>
  <div class="card-body">

    <div class="tab-content tab-slider-container">

      <div id="dashboardNewFileTab" class="tab-pane new-file {{layout!=='desktop' && !isPropertySearchApp ? 'active' : ''}}"
        *ngIf="layout!=='desktop' && !isPropertySearchApp">

        <form id="newFileForm" [formGroup]="newFileForm">

          <div class="new-file-form-layout">

            <div class="new-file-inputs">

              <div class="form-group file-name">
                <label for="fileName">
                  Enter your file name/number
                  <span class="required"
                    *ngIf="(newFileForm.controls['fileName'].invalid && newFileForm.controls['fileName'].touched)">required</span>
                </label>
                <input type="text" placeholder="file name/number" formControlName="fileName" data-cy="fileNameInput"/>
                <a href="#" onclick="return false;" class="create-file-name" data-cy="createWorkFileForMe">[create one for me]</a>
                <!--<small class="form-text text-muted">That's ok if you don't have one, we can create one for you.</small>-->
              </div>

              <div class="form-group appraisal-form-info">

                <div class="custom-input-group">
                  <label for="formType">
                    Select your appraisal type
                    <span class="required"
                      *ngIf="(newFileForm.controls['formType'].invalid && newFileForm.controls['formType'].touched)">required</span>
                  </label>
                  <select formControlName="formType" data-cy="formTypeSelect">
                    <!-- <option *ngFor="let type of appraisalFormTypes" [ngValue]="type.value">{{type.text}}</option> -->
                    <option *ngFor="let form of appraisalFormTypes" [ngValue]="form">
                      {{ form.displayName }}
                    </option>
                  </select>
                </div>

                <div class="custom-input-group form-vendor">
                  <div class="dropdown show" data-cy="formSoftwareDropdown">
                    <div class="selected-item dropdown-toggle" data-toggle="dropdown">
                      <img src="{{selectedVendorImgSrc}}" alt="{{selectedVendorImgAlt}}" #selectedVendor
                        height="{{selectedVendorImgHeight}}" />
                      <a href="#" onclick="return false;" class="change">[change]</a>
                    </div>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" (click)="updateVendor($event)">
                        <img src="/assets/images/vendors/alamode.png" alt="AlaMode" height="25" />
                      </a>
                      <a class="dropdown-item" (click)="updateVendor($event)" >
                        <img src="/assets/images/vendors/aci.png" alt="Aci" width="40" />
                      </a>
                      <a class="dropdown-item" (click)="updateVendor($event)" >
                        <img src="/assets/images/vendors/bradford.png" alt="Bradford" height="30" />
                      </a>
                      <a class="dropdown-item" (click)="updateVendor($event)">
                        <img src="/assets/images/vendors/sfrep.png" alt="SFREP" height="18" />
                      </a>
                    </div>
                  </div>

                </div>

              </div>

            </div>

            <div class="new-file-upload">

              <app-file-upload formControlName="file"></app-file-upload>

            </div>

          </div>

          <div class="d-flex flex-row-reverse submit-row">

            <button type="submit" class="btn btn-success btn-go" (click)="validate()" data-cy="createWorkFileButton">
              Create new file
              <span class="fal fa-arrow-circle-right"></span>
            </button>

          </div>


        </form>

      </div>

      <div id="dashboardAddressSearchTab" class="tab-pane search-forms {{(layout==='desktop' && !isPropertySearchApp) || (isPropertySearchApp && mlsDataSources?.length === 0) ? 'active' : ''}}">

        <app-address-search #addressSearch (resultsEvent)="openPropertyImport($event)" (showResultsEvent)="showResults()"
          (clearAllSearchesEvent)="clearSearchResults()" propertyUseType="Subject"></app-address-search>

      </div>

      <div *ngIf="mlsDataSources?.length > 0" id="dashboardMlsSearchTab" class="tab-pane search-forms {{isPropertySearchApp ? 'active' : ''}}">

          <app-mls-search #mlsSearch (resultsEvent)="openPropertyImport($event)" (showResultsEvent)="showResults()"
          (clearAllSearchesEvent)="clearSearchResults()" propertyUseType="Subject" [dataSources]="mlsDataSources"></app-mls-search>

      </div>

      <div id="dashboardApnSearchTab" class="tab-pane search-forms">

        <app-apn-search #apnSearch (resultsEvent)="openPropertyImport($event)" (showResultsEvent)="showResults()"
          (clearAllSearchesEvent)="clearSearchResults()" propertyUseType="Subject" [dataSources]="prDataSources"></app-apn-search>

      </div>

    </div>

    <!-- <app-property-import [searchResult]="searchResult" propertyUseType="Subject" [isDashboardSearch]="true" (resultsEvent)="usePropertyAsSubject()"></app-property-import> -->

    <!-- Modal -->
    <div class="modal fade" id="searchResultsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true" data-cy="searchResultsModal">
      <div class="modal-dialog modal-lg" role="document">
        <!-- class="... modal-dialog-scrollable" -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Property Search Results</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body search-results-modal">
            <div class="results {{showNewFile[i] ? 'active' : ''}}"
              *ngFor="let result of translatedPropertyResults; let i = index;">

              <div class="sub-row first">
                <div class="address">
                  <span class="address-line1">
                    {{result.translatedParcel.addressLine1}}
                    <span *ngIf="result.translatedParcel.addressLine2">, {{result.translatedParcel.addressLine2}}</span>
                  </span>
                  <span class="address-line2">
                    {{result.translatedParcel.city}}, {{result.translatedParcel.state}} {{result.translatedParcel.zip}}
                  </span>
                </div>
                <div class="ids" *ngIf="result.translatedParcel.apn"><span class="label">apn</span>
                  {{result.translatedParcel.apn}}</div>
                <div class="ids" *ngIf="result.translatedParcel.mlsNumber"><span class="label">mls num.</span>
                  {{result.translatedParcel.mlsNumber}}</div>
              </div>

              <div class="sub-row second">

                <div class="info">
                  <span class="item">
                    <span class="label">built in</span>
                    {{result.translatedParcel.yearBuilt}}
                  </span>
                  <span class="item" *ngIf="result.translatedParcel.lotSize">
                    {{result.translatedParcel.siteArea}}
                    <span class="label">acres</span>
                  </span>
                  <span class="item">
                    {{result.translatedParcel.aboveGradeGla}}
                    <span class="label">sq. ft.</span>
                  </span>
                </div>

                <div class="info">
                  <span class="item">
                    <span class="fal fa-bed fa-lg icon"></span>
                    {{result.translatedParcel.totalAboveGradeBedrooms}}
                    <span class="label">bedrooms</span>
                  </span>
                  <span class="item">
                    <span class="fal fa-bath fa-lg icon"></span>
                    {{result.translatedParcel.totalAboveGradeBathrooms}}
                    <span class="label">bathrooms</span>
                  </span>
                </div>

                <div class="info">
                  <span class="item ">
                    <span class="fal fa-building fa-lg icon"></span>
                    {{result.translatedParcel.propertyType}}
                  </span>
                  <span class="item">
                    <span class="fal fa-home fa-lg icon"></span>
                    {{getOriginalValues(result.translatedParcel.architecturalStyleTypes)}}
                  </span>
                </div>

              </div>

              <div class="select-this view-detail d-flex h-100" data-toggle="modal" data-target="#propertyDetailModal"
                (click)="initDetail(result)">
                <span class="fal fa-list-alt fa-2x icon justify-content-center align-self-center"></span>
                <div class="use">
                  <span class="as">view</span>
                  <span class="subject">detail</span>
                </div>
              </div>

              <div class="select-this d-flex h-100 {{showNewFile[i] ? 'active' : ''}}"
                (click)="useAsSubject($event, i, selectThis)" #selectThis>
                <span class="fal fa-arrow-circle-right fa-2x icon justify-content-center align-self-center"></span>
                <div class="use">
                  <span class="as">use as</span>
                  <span class="subject">subject</span>
                </div>
              </div>

              <div class="new-file-subject" *ngIf="showNewFile[i]">

                <form id="newFileForm" [formGroup]="newFileForm">
                  <div class="form-row">
                    <div class="form-group col-4">
                      <label for="fileName">
                        file name/number
                      </label>
                      <input type="text" placeholder="file name/number" formControlName="fileName" id="fileName" />
                      <a href="#" onclick="return false;" class="create-file-name">[create one for me]</a>
                    </div>
                    <div class="form-group">
                      <label for="formType">
                        appraisal type
                      </label>

                      <select formControlName="formType">
                        <option *ngFor="let type of appraisalFormTypes" [ngValue]="type.value">{{type.text}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="formType" class="vendor-label">
                        form vendor
                      </label>

                      <div class="dropdown show">
                        <div class="selected-item dropdown-toggle" data-toggle="dropdown">
                          <img src="{{selectedVendorImgSrc}}" alt="{{selectedVendorImgAlt}}" #selectedVendor
                            height="{{selectedVendorImgHeightResults}}" />
                          <a href="#" onclick="return false;" class="change">[change]</a>
                        </div>

                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <a class="dropdown-item" (click)="updateVendor($event)">
                            <img src="/assets/images/vendors/alamode.png" alt="a la mode" height="25" />
                          </a>
                          <a class="dropdown-item" (click)="updateVendor($event)">
                            <img src="/assets/images/vendors/aci.png" alt="ACI" width="40" />
                          </a>
                          <a class="dropdown-item" (click)="updateVendor($event)">
                            <img src="/assets/images/vendors/bradford.png" alt="Bradford" height="30" />
                          </a>
                          <a class="dropdown-item" (click)="updateVendor($event)">
                            <img src="/assets/images/vendors/sfrep.png" alt="SFREP" height="18" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button type="button" class="close" aria-label="Close" (click)="showNewFile[i] = false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </form>


              </div>


            </div>

            <div class="not-found" *ngIf="!searchResult">
              <!-- <div class="not-found" *ngIf="searchResults?.length <= 0"> -->
            <!-- <div class="not-found" *ngIf="translatedPropertyResults?.length <= 0"> -->
              <span class="fal fa-frown fa-5x emoji"></span>
              <div class="err-information">
                <div class="err-exclamation">Oh no!</div>
                <div class="err-message">No results found.</div>
              </div>
            </div>

          </div>

          <!-- <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="usePropertyAsSubject()"><span
              class="fal fa-check-square"></span> Use As Subject</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        </div> -->

          <!--<div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>-->
        </div>
      </div>
    </div>

    <!--Create New Work File-->
    <!-- <div class="modal fade" id="createNewWorkFileModal" tabindex="-1" role="dialog"
      aria-labelledby="createNewWorkFileModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <app-new-work-file (resultsEvent)="runReverseSearchAfterWorkFileCreate()" (closeEvent)="cancelWorkFileCreate()" [isModal]="true"></app-new-work-file>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Property Detail Modal -->
    <div class="modal fade" id="propertyDetailModal" tabindex="-1" role="dialog"
      aria-labelledby="propertyDetailModalLabel" aria-hidden="true">
      <div class="modal-dialog detail-modal modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header property-detail-header">
            <h4 class="modal-title" id="propertyDetailModalLabel">
              PROPERTY DETAIL
            </h4>
            <h5>
              {{searchType}}
              <span class="source-name">{{TEMPsearchSource}}</span>
            </h5>

            <span class="help fal fa-question-circle" [ngbPopover]="popHelpContent" placement="right auto"
              popoverClass="help-popover"></span>
            <ng-template #popHelpContent>
              <p>
                The information shown on this sheet is detail from your <em>{{searchType}}</em> ({{TEMPsearchSource}})
                data source. For
                quick reference and quick results the search you performed looks at that single data source.
              </p>

              <h6>How can I see data from all my data sources on this property?</h6>
              <p>
                The normal DataMaster magic happens when you use this property as a subject or comparable. We contact
                all your data sources (Public Records, MLS, and possibly past appraisal files) on your behalf, merge
                that information according to your preferences, display it to you, and finally when the time is right,
                export
                it to your report.
              </p>
              <p>
                However, if you'd like to see a data sheet for this property with data from all your data sources, click
                on the "View
                DataMaster datasheet for this property" link found on this page. It may take a minute, but we'll reach
                out
                to all of your data sources and display that for you.
                [TBD]
              </p>

            </ng-template>

            <span class="info">
              <span class="bg">&nbsp;</span>
              <span class="icon fas fa-info fa-xs"></span>
            </span>

            <span class="fal fa-print fa-lg print"></span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body search-detail-modal">


            <app-property-detail #propertyDetail [searchType]="searchType"></app-property-detail>


          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Use as Subject</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<ng-template #webHeader>
  <h3>Let's get started -
    <span class="sub-header">Create a new Work File</span>
  </h3>
</ng-template>

<ng-template #desktopHeader>
  <h3>Subject Property Search</h3>
</ng-template>
