import { Component, OnInit,  Inject } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { DataMasterFileService } from '../../services/datamaster-file.service';
import { AppraisalForm } from '../../models/workfile/appraisal-form.model';
import { AppraisalFormSoftware } from '../../models/workfile/appraisal-form-software.model';
import { DataMasterApiService } from '../../services/property-search/datamaster-api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { SpinnerService } from '../../services/spinner.service';
import { AppraisalDetail } from '../../models/workfile/appraisal-detail.model';

@Component({
  selector: 'app-new-work-file',
  templateUrl: './new-work-file.component.html',
  styleUrls: ['./new-work-file.component.scss'],
})
export class NewWorkFileComponent implements OnInit {
  createNewWorkFileForm: FormGroup;
  forms: AppraisalForm[];
  formSoftwares: AppraisalFormSoftware[];
  _subscription: Subscription = new Subscription();
  predefinedFileNumber: string;

  constructor(
    private dataMasterFileService: DataMasterFileService,
    private dataMasterApiService: DataMasterApiService,
    private mdDialogRef: MatDialogRef<NewWorkFileComponent>,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.predefinedFileNumber = data?.fileNumber;
  }

  ngOnInit() {
    const forms$ = this.dataMasterApiService.getAppraisalForms().pipe(
      tap((forms) => {
        this.forms = forms;
      })
    );
    const formSoftwares$ = this.dataMasterApiService
      .getAppraisalFormSoftwares()
      .pipe(
        tap((formSoftwares) => {
          this.formSoftwares = formSoftwares;
        })
      );

    this.createNewWorkFileForm = new FormGroup({
      fileNumber: new FormControl(
        this.predefinedFileNumber,
        Validators.required
      ),
      formSoftware: new FormControl(null, Validators.required),
      form: new FormControl(null, Validators.required),
      //appraiser: new FormControl("", Validators.required),
    });

    const spinnerRef = this.spinnerService.open();

    const sub = forkJoin([forms$, formSoftwares$]).subscribe((_) => {
      spinnerRef.close();
    });

    this._subscription.add(sub);
  }

  onSubmit() {
    if (!this.createNewWorkFileForm.valid) {
      for (const item in this.createNewWorkFileForm.controls) {
        this.createNewWorkFileForm.controls[item].markAsTouched();
      }
    } else {
      const spinnerRef = this.spinnerService.open();

      const sub = this.dataMasterApiService
        .getFormatFieldMaps(
          this.createNewWorkFileForm.value.form.id,
          this.createNewWorkFileForm.value.formSoftware.id
        )
        .pipe(
          tap((formatFieldMaps) => {
            this.dataMasterFileService.initialize(formatFieldMaps);
            const appraisalDetail = new AppraisalDetail(); 
            appraisalDetail.form = this.createNewWorkFileForm.value.form;
            appraisalDetail.formSoftware = this.createNewWorkFileForm.value.formSoftware;
            appraisalDetail.effectiveDate = new Date();
            
            this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail = appraisalDetail;

            this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.formVendor =
              this.createNewWorkFileForm.value.form.commonName;
            this.dataMasterFileService.dataMasterFile.appraisal.fileDetail.fileNumber =
              this.createNewWorkFileForm.value.fileNumber;
            this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.form =
              this.createNewWorkFileForm.value.form;
            this.dataMasterFileService.dataMasterFile.appraisal.appraisalDetail.formSoftware =
              this.createNewWorkFileForm.value.formSoftware;
          })
        )
        .subscribe(
          (_) => {
            spinnerRef.close();
            this.mdDialogRef.close({ event: 'Submit' });
          },
          (error) => console.log(error)
        );
      this._subscription.add(sub);
    }
  }

  onClose() {
    this.mdDialogRef.close({ event: 'Cancel' });
  }
}
