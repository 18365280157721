import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
// Service
import { ConfigService } from '../dependency-issues';
// Model
import { Graph } from '../models/market-conditions/graph.model';
import { MarketConditionsForm } from '../models/market-conditions/market-conditions-form.model';
import { UserGraph } from '../models/market-conditions/user-graph.model';
import { MarketConditionsGraphCalculationResult } from '../models/operation/market-conditions/market-conditions-graph-calculation-result.model';
import { SearchCriteriaUserData } from '../models/operation/property-search/search-criteria.model';
import { PropertyUsageType } from '../models/workfile/enum/property-usage-type.enum';

@Injectable({
  providedIn: 'root'
})
export class GraphsService {
  private readonly dmApiUrl: string = this.configService.api.dataMasterApi;
  private _graphs$ : Observable<{graphs : Graph[], userGraphs : Graph[] }>;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  getGraphs(username : string) {
    if(this._graphs$) return this._graphs$;
    this.createGetGraphs$(username);
    return this._graphs$;
  }

  saveUserSelectedGraphs(graphIds : number[], username : string ){
    const body = {
      userSelectedGraphIds : graphIds,
      username
    }
    this.createGetGraphs$(username);
    return this.httpClient.post(`${this.dmApiUrl}graphs/`, body);
  }

  updateUserGraph(userGraph : UserGraph, searchCriteriaUserData : SearchCriteriaUserData){
    const body = {
      userGraph,
      userData : searchCriteriaUserData
    }

    return this.httpClient.put<MarketConditionsGraphCalculationResult>(`${this.dmApiUrl}graphs`, body);
  }

  calculateGraphs(marketConditionsForm : MarketConditionsForm, searchCriteriaUserData : SearchCriteriaUserData, propertyUsageType : PropertyUsageType) : Observable<MarketConditionsGraphCalculationResult>{
    const body = {
      marketConditionsForm,
      userData : searchCriteriaUserData,
      propertyUsageType
    }
    return this.httpClient.post<MarketConditionsGraphCalculationResult>(`${this.dmApiUrl}graphs/calculate`, body);
  }

  private createGetGraphs$(username : string){
    this._graphs$ = this.httpClient.get< {graphs : Graph[], userGraphs : Graph[] }>(`${this.dmApiUrl}graphs/${username}`)
    .pipe(shareReplay(1));
  }
}
