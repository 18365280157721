import { Injectable } from '@angular/core';
import { FormatFieldMap } from '../models/format-field-map.model';

@Injectable({
  providedIn: 'root'
})
export class FormattingService {

  getFormSoftwareFormSheetSectionFieldId(formatFieldMaps: FormatFieldMap[], field: string, sectionType: string): number {
    const formSoftawreFormSheetSectionField = formatFieldMaps?.find(a => a.fieldCommonName == field && a.sectionPropertyUseType == sectionType);
    if (!formSoftawreFormSheetSectionField) {
      return null;
    }

    return formSoftawreFormSheetSectionField.formSoftwareFormSheetSectionFieldId;
  }
}
