<div class="details-container">
  <div *ngIf="this.mcCount === 0">No properties were included in the market conditions report.</div>
  <div *ngIf="this.mcCount > 0">{{mcCount}} properties were included in the market conditions report.</div>
  <div class="tab-container">
    <div *ngIf="dateRange === '0-12'">
      <div class="selected-tab">
        <div class="tab-header">0-12 Months</div>
        <div class="tab-details">{{zeroToTwelveRange}}</div>
      </div>  
    </div>  
    <div *ngIf="dateRange !== '0-12'" (click)="zeroToTwelveTabSelected('MC')">
      <div class="tab">
        <div class="tab-header">0-12 Months</div>
        <div class="tab-details">{{zeroToTwelveRange}}</div>
      </div>  
    </div>
    <div *ngIf="dateRange === '7-12'">
      <div class="selected-tab">
        <div class="tab-header">7-12 Months</div>
        <div class="tab-details">{{sevenToTwelveRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange !== '7-12'" (click)="sevenToTwelveTabSelected('MC')">
      <div class="tab">
        <div class="tab-header">7-12 Months</div>
        <div class="tab-details">{{sevenToTwelveRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange === '4-6'">
      <div class="selected-tab">
        <div class="tab-header">4-6 Months</div>
        <div class="tab-details">{{fourToSixRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange !== '4-6'" (click)="fourToSixTabSelected('MC')">
      <div class="tab">
        <div class="tab-header">4-6 Months</div>
        <div class="tab-details">{{fourToSixRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange === '0-3'">
      <div class="selected-tab">
        <div class="tab-header">0-3 Months</div>
        <div class="tab-details">{{zeroToThreeRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange !== '0-3'" (click)="zeroToThreeTabSelected('MC')">
      <div class="tab">
        <div class="tab-header">0-3 Months</div>
        <div class="tab-details">{{zeroToThreeRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange === '0-12Condo' && isCondoForm">
      <div class="selected-tab">
        <div class="tab-header">Condo 0-12 Months</div>
        <div class="tab-details">{{zeroToTwelveRange}}</div>
      </div>  
    </div>  
    <div *ngIf="dateRange !== '0-12Condo' && isCondoForm" (click)="zeroToTwelveTabSelected('ProjectMC')">
      <div class="tab">
        <div class="tab-header">Condo 0-12 Months</div>
        <div class="tab-details">{{zeroToTwelveRange}}</div>
      </div>  
    </div>
    <div *ngIf="dateRange === '7-12Condo' && isCondoForm">
      <div class="selected-tab">
        <div class="tab-header">Condo 7-12 Months</div>
        <div class="tab-details">{{sevenToTwelveRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange !== '7-12Condo' && isCondoForm" (click)="sevenToTwelveTabSelected('ProjectMC')">
      <div class="tab">
        <div class="tab-header">Condo 7-12 Months</div>
        <div class="tab-details">{{sevenToTwelveRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange === '4-6Condo' && isCondoForm">
      <div class="selected-tab">
        <div class="tab-header">Condo 4-6 Months</div>
        <div class="tab-details">{{fourToSixRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange !== '4-6Condo' && isCondoForm" (click)="fourToSixTabSelected('ProjectMC')">
      <div class="tab">
        <div class="tab-header">Condo 4-6 Months</div>
        <div class="tab-details">{{fourToSixRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange === '0-3Condo' && isCondoForm">
      <div class="selected-tab">
        <div class="tab-header">Condo 0-3 Months</div>
        <div class="tab-details">{{zeroToThreeRange}}</div>
      </div>
    </div>
    <div *ngIf="dateRange !== '0-3Condo' && isCondoForm" (click)="zeroToThreeTabSelected('ProjectMC')">
      <div class="tab">
        <div class="tab-header">Condo 0-3 Months</div>
        <div class="tab-details">{{zeroToThreeRange}}</div>
      </div>
    </div>
  </div>
  <ag-grid-angular 
      style="width: 100%; height: 65vh;"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [rowSelection]="rowSelection"
      [undoRedoCellEditing]="true"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      (cellKeyDown)="onCellKeyDown($event)">
  </ag-grid-angular>
  <div class="controls">
    <div>
      <button class="btn undo-button" (click)="undo()">Undo</button>    
    </div>
    <button class="btn btn-confirm export-button" (click)="exportToCsv()">Export to CSV</button>
  </div>
</div>
