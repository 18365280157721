import { Component, Input, OnInit } from '@angular/core';
import { UserSettings } from '../../../models/settings/user-settings.model';

@Component({
  selector: 'app-settings-formatting',
  templateUrl: './settings-formatting.component.html',
  styleUrls: ['./settings-formatting.component.scss']
})
export class SettingsFormattingComponent implements OnInit {
  
  @Input() userSettings: UserSettings;
  //need these backing fields to not modify the UserSettings object if user clicks cancel
  defaultVerificationDocumentNumber : string;
  daysOnMarket : string;
  defaultActualAgeCalculation : string;
  textCasing : string;

  //other fields
  defaultVerificationDocumentNumberOptions : string[] = ['Document Number', 'Alternate Number'];
  defaultDaysOnMarketOptions : string[] = ['Days On Market (DOM)', 'Cumulative Days On Market (CDOM)'];
  defaultActualAgeOptions : string[] = [ 'Sold Date','Effective Date'];

  textCasingEnabled: boolean;

  constructor() {
    //
  }

  ngOnInit(): void {
    this.defaultVerificationDocumentNumber = this.userSettings.defaultVerificationDocumentNumber; 
    this.daysOnMarket = this.userSettings.daysOnMarket;
    this.defaultActualAgeCalculation = this.userSettings.defaultActualAgeCalculation;

    if(!this.userSettings.textCasing){
      this.textCasingEnabled = true;
      this.textCasing = this.userSettings.textCasing;
    }
  }

}
