// Angular
import {Injectable} from '@angular/core';
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router} from '@angular/router';
// Model
// Service
import {OidcSecurityService} from 'angular-auth-oidc-client';
// Store
import {Select, Store} from '@ngxs/store';
import {Observable, of} from 'rxjs';
import { RefreshUserData, UserState } from '../services/user/state';
import { UserStateModel } from '../models/user/user-state.model';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
	user$: Observable<UserStateModel> = this.store.select(UserState.getUserData);
	private currentUser: UserStateModel;

	constructor(
		private oidcSecurityService: OidcSecurityService,
		private store: Store,
		private router: Router
	) {
		this.user$.subscribe((user: UserStateModel) => this.currentUser = user);
	}

	/**
	 * Can activate validation.
	 * @param route: ActivatedRouteSnapshot
	 * @param state: RouterStateSnapshot
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async resolve => {
            if (this.oidcSecurityService.isAuthenticated()) {
                if (this.currentUser.userId === null) {
                    await this.store.dispatch(new RefreshUserData()).subscribe(() => {
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }

            } else {
                this.oidcSecurityService.authorize();
                resolve(false);
            }
        });
	}
}
