import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../../dependency-issues';
// Models
import { DataSource } from '../../models/data-source/data-source';
import { requestOptions } from '../../models/http/constant.model';
import { DataSourceCredentials } from '../../models/settings/data-source-credentials.model';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  private readonly dmApiUrl: string = this.configService.api.dataMasterApi;
  constructor(private httpClient: HttpClient, private configService: ConfigService) { }

  getUserDataSources(planCodes: string[]): Observable<DataSource[]> {
    return this.httpClient.get<DataSource[]>(
      `${this.dmApiUrl}datasources/GetUserDataSourcesFromPlanCodes?planCodes=${planCodes.join('&planCodes=')}`,
      requestOptions
    );
  }

  getDataSourcesByIds(ids: number[]): Observable<DataSource[]> {
    return this.httpClient.get<DataSource[]>(
      `${this.dmApiUrl}datasources/GetDataSourcesByIds?ids=${ids?.join('&ids=')}`,
      requestOptions
    );
  }

  getDataSources(): Observable<DataSource[]> {
    return this.httpClient.get<DataSource[]>(
      `${this.dmApiUrl}datasources/GetDataSources`,
      requestOptions
    );
  }

  getDataSourceCredentials(dataSourceIds: number[]): Observable<DataSourceCredentials[]> {
    return this.httpClient.get<DataSourceCredentials[]>(
      `${this.dmApiUrl}usersettings/GetDataSourceCredentials?datasourceids=${dataSourceIds.join(
        '&datasourceids='
      )}`,
      requestOptions
    );
  }
}
