import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
// Model
import { Comparable, PropertyUsageType } from '@datamaster/core';
import { SummaryComparableExportComponent } from './summary-comparable-export/summary-comparable-export.component';

@Component({
  selector: 'app-summary-custom-export',
  templateUrl: './summary-custom-export.component.html',
  styleUrls: ['./summary-custom-export.component.scss']
})
export class SummaryCustomExportComponent implements OnInit, OnDestroy {

  @Input() hasAddenda = false;
  @Input() comparables: Comparable[];

  hasComparables = false;
  hasReoListings = false;
  hasRentals = false;
  hasListings = false;
  canExport = true;

  mainComparables: Comparable[];
  reoListings: Comparable[];
  rentals: Comparable[];
  listings: Comparable[];

  @ViewChildren(SummaryComparableExportComponent) summaryComparableExports: QueryList<SummaryComparableExportComponent>;
  private _summaryComparableExportsSubscription: any;
  private _changeDetector: ChangeDetectorRef;

  mainComparableExports: SummaryComparableExportComponent[];
  reoListingsExports: SummaryComparableExportComponent[];
  rentalsExports: SummaryComparableExportComponent[];
  listingExports: SummaryComparableExportComponent[];

  ngOnInit(): void {
    if (this.comparables && this.comparables.length > 0) {

      this.mainComparables = this.comparables.filter(x => x.propertyUseType == PropertyUsageType.Comparable);
      this.hasComparables = this.mainComparables.length > 0;

      this.reoListings = this.comparables.filter(x => x.propertyUseType == PropertyUsageType.ReoListing);
      this.hasReoListings = this.reoListings.length > 0;

      this.rentals = this.comparables.filter(x => x.propertyUseType == PropertyUsageType.ComparableRental);
      this.rentals = this.comparables.filter(x => x.propertyUseType == PropertyUsageType.Rental);
      this.hasRentals = this.rentals.length > 0;

      this.listings = this.comparables.filter(x => x.propertyUseType == PropertyUsageType.Listing); // ERC form use listing comparables like rentals
      this.hasListings = this.listings.length > 0;
    }

  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    this._summaryComparableExportsSubscription = this,this.summaryComparableExports.changes.subscribe((summaryComparableExports) => {
      
      this._changeDetector.detectChanges();
    });
    
    this.mainComparableExports = this.summaryComparableExports.filter(x => x.parcel.propertyUseType == PropertyUsageType.Comparable);
    this.rentalsExports = this.summaryComparableExports.filter(x => x.parcel.propertyUseType == PropertyUsageType.ComparableRental || x.parcel.propertyUseType == PropertyUsageType.Rental);
    this.listingExports = this.summaryComparableExports.filter(x => x.parcel.propertyUseType == PropertyUsageType.Listing);
    this.reoListingsExports = this.summaryComparableExports.filter(x => x.parcel.propertyUseType == PropertyUsageType.ReoListing);
    this._changeDetector.detectChanges();
  }

  /**
   * Doc must exist
   *
   * @param event
   */
  onChange(event: any): void {

    const checked = event.target.checked;

    switch (event.target.value) {
      case 'comparable':
        this.toggleComparables(this.mainComparables, checked);
        break;
      case 'reoListing':
        this.toggleComparables(this.reoListings, checked);
        break;
      case 'listing':
        this.toggleComparables(this.listings, checked);
        break;
      case 'rentals':
        this.toggleComparables(this.rentals, checked);
        break;
    }
  }

  changeExportNumber(currentComp: any): void {
    const summaryComparableExport = this.summaryComparableExports.find(x => x.parcel.propertyGuid == currentComp.propertyGuid);
    
    this.checkExportNumbers(summaryComparableExport);
  }

  /**
   * Doc must exist
   *
   * @param comparables
   * @param checked
   */
  toggleComparables(comparables: Comparable[], checked: boolean): void {
    comparables.forEach(x => {
      x.exportToReport = checked;
    });
  }

  checkExportNumbers(changedComparable: SummaryComparableExportComponent): void {
    let customExportComps: SummaryComparableExportComponent[];
    const propertyUseType = changedComparable.parcel.propertyUseType;

    switch (propertyUseType) {
      case PropertyUsageType.Comparable:
        customExportComps = this.mainComparableExports;
        break;
      case PropertyUsageType.Rental:
      case PropertyUsageType.ComparableRental:
        customExportComps = this.rentalsExports;
        break;
      case PropertyUsageType.Listing:
        customExportComps = this.listingExports;
        break;
      case PropertyUsageType.ReoListing:
        customExportComps = this.reoListingsExports;
        break;
    }

    customExportComps.forEach(x => x.exportPositionExists = false);

    customExportComps.filter(x => x.parcel.exportToReport).forEach(a => {      
      if (a.parcel.propertyGuid !== changedComparable.parcel.propertyGuid && 
        a.parcel.exportPropertyPlacementNumber == changedComparable.parcel.exportPropertyPlacementNumber) {
        a.exportPositionExists = true;
        changedComparable.exportPositionExists = true;
      }
    });
    this.canExport = customExportComps.filter(x => x.exportPositionExists).length <= 0;        
  }

  ngOnDestroy(): void {
    this._summaryComparableExportsSubscription.unsubscribe();
  }

}
