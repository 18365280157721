// Angular
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
// Modules
import { SafePipeModule } from 'safe-pipe';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
// Custom Modules
import { MaterialSharedModule } from './utils/material.module';
import { DependencyIssuesModule } from './dependency-issues/dependency-issues.module';
import { NewWorkFileComponent } from './components/new-work-file/new-work-file.component';
// Components
import { FooterComponent } from './layout/footer/footer.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { AboutComponent } from './components/about/about.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PinchZoomComponent } from './layout/ivypinch_pro_ng8+/pinch-zoom.component';
import { AuthCallbackComponent } from './components/auth/auth-callback/auth-callback.component';
import { UnauthorizedComponent } from './components/auth/unauthorized/unauthorized.component';
// Sub-components
import { LogInAsComponent } from './layout/header/log-in-as/log-in-as.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsGeneralComponent } from './components/settings/settings-general/settings-general.component';
import { SettingsFormattingComponent } from './components/settings/settings-formatting/settings-formatting.component';
import { SettingsDataSourceSelectionComponent } from './components/settings/settings-data-source-selection/settings-data-source-selection.component';
import { SettingsDataSourceCredentialsComponent } from './components/settings/settings-data-source-credentials/settings-data-source-credentials.component';
import { SettingsComptrackerComponent } from './components/settings/settings-comptracker/settings-comptracker.component';
import { SettingsAppraiserComponent } from './components/settings/settings-appraiser/settings-appraiser.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SettingsMarketAnalysisComponent } from './components/settings/settings-market-analysis/settings-market-analysis.component';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { ApnSearchComponent } from './components/apn-search/apn-search.component';
import { MlsSearchComponent } from './components/mls-search/mls-search.component';
import { SearchErrorComponent } from './components/search-error/search-error.component';
import { DialogAddressSearchComponent } from './components/dialog-address-search/dialog-address-search.component';
import { DialogApnSearchComponent } from './components/dialog-apn-search/dialog-apn-search.component';
import { DialogMlsSearchComponent } from './components/dialog-mls-search/dialog-mls-search.component';
import { MlsDataSourceMediaComponent } from './components/mls-data-source-media/mls-data-source-media.component';
import { MultipleResultsTableComponent } from './components/multiple-results-table/multiple-results-table.component';
// Services
import { SettingsService } from './components/settings/services/settings.service';
import { NgbDateCustomParserFormatter } from './utils/ngb-date-parser-formatter';
import { PropertyImportComponent } from './components/property-import/property-import.component';
import { MapsComponent } from './components/new-file/property-detail/maps/maps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { NewFileComponent } from './components/new-file/new-file.component';
import { PropertyDetailComponent } from './components/new-file/property-detail/property-detail.component';
import { FileUploadComponent } from './components/new-file/file-upload/file-upload.component';
// State
import { NgxsModule } from '@ngxs/store';
import { SearchState } from './services/property-search/state/search.state';
import { AddressSearchState } from './services/property-search/state/address-search.state';
import { MlsSearchState } from './services/property-search/state/mls-search.state';
import { ApnSearchState } from './services/property-search/state/apn-search.state';

import { TextInputDialogComponent } from './components/dialog-text-input/text-input-dialog.component';
import { SettingsMarketAnalysisGraphGroupComponent } from './components/settings/settings-market-analysis/settings-market-analysis-graph-group/settings-market-analysis-graph-group.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        HttpClientModule,
        // 3rd Party
        SafePipeModule,
        GoogleMapsModule,
        NgbModule,
        NgxsModule.forFeature([SearchState, AddressSearchState, ApnSearchState, MlsSearchState]),
        ColorPickerModule,
        // Custom
        DependencyIssuesModule,
        MaterialSharedModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        SpinnerComponent,
        AboutComponent,
        PinchZoomComponent,
        ConfirmDialogComponent,
        AuthCallbackComponent,
        UnauthorizedComponent,
        AddressSearchComponent,
        ApnSearchComponent,
        MlsSearchComponent,
        SearchErrorComponent,
        DialogHeaderComponent,
        DialogAddressSearchComponent,
        DialogApnSearchComponent,
        DialogMlsSearchComponent,
        MultipleResultsTableComponent,
        MlsDataSourceMediaComponent,
        PropertyImportComponent,
        MapsComponent,
        NewFileComponent,
        PropertyDetailComponent,
        FileUploadComponent,
        // cUSTOM
        DependencyIssuesModule,
        MaterialSharedModule,
        
    ],
    declarations: [
        AboutComponent,
        AuthCallbackComponent,
        DialogHeaderComponent,
        ConfirmDialogComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        LogInAsComponent,
        PinchZoomComponent,
        SpinnerComponent,
        SettingsComponent,
        SettingsFormattingComponent,
        SettingsMarketAnalysisComponent,
        SettingsGeneralComponent,
        SettingsDataSourceSelectionComponent,
        SettingsDataSourceCredentialsComponent,
        SettingsComptrackerComponent,
        SettingsAppraiserComponent,
        UnauthorizedComponent,
        AddressSearchComponent,
        ApnSearchComponent,
        MlsSearchComponent,
        SearchErrorComponent,
        DialogAddressSearchComponent,
        DialogApnSearchComponent,
        DialogMlsSearchComponent,
        MultipleResultsTableComponent,
        MlsDataSourceMediaComponent,
        NewWorkFileComponent,
        PropertyImportComponent,
        MapsComponent,
        NewFileComponent,
        PropertyDetailComponent,
        FileUploadComponent,
        TextInputDialogComponent,
        SettingsMarketAnalysisGraphGroupComponent,
        ErrorDialogComponent
    ],
    providers: [
        SettingsService,
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class DMSharedModule { }
