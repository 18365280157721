<div class="list-container">
  <div class="list-container-child">
    <mat-selection-list #defaultMatSelectionList [multiple]="false" [disableRipple]="true">
      <mat-list-option
        *ngFor="let listItem of defaultListItems"
        [value]="listItem"
        (click)="listOptionClicked(listItem)"
      >
        {{listItem.name}}
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div class="button-container">
    <button class="btn" type="button" (click)="add()" [disabled]="!addEnabled">
      <i class="fa fa-chevron-double-right"></i>
    </button>
    <button
      class="btn"
      type="button"
      (click)="remove()"
      [disabled]="!removeEnabled"
    >
      <i class="fa fa-chevron-double-left"></i>
    </button>
  </div>
  <div class="list-container-child">
    <mat-selection-list #userMatSelectionList [multiple]="false" [disableRipple]="true">
      <mat-list-option
        *ngFor="let userListItem of userListItems"
        [value]="userListItem"
        (click)="userlistOptionClicked(userListItem)"
      >
        {{userListItem.name}}
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div class="button-container">
    <button class="btn" type="button" (click)="up()" [disabled]="!upEnabled">
      <i class="fa fa-chevron-up"></i>
    </button>
    <button
      class="btn"
      type="button"
      (click)="down()"
      [disabled]="!downEnabled"
    >
      <i class="fa fa-chevron-down"></i>
    </button>
  </div>
</div>
