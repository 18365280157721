import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, DataSource, DataSourceService, Parcel, SpinnerService, UserSettings, UserSettingsService } from '@datamaster/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs';

@Component({
  selector: 'app-comp-deeds',
  templateUrl: './comp-deeds.component.html',
  styleUrls: ['./comp-deeds.component.scss']
})
export class CompDeedsComponent {
  parcel!: Parcel;
  mlsDataSources!: DataSource[];
  prDataSources!: DataSource[];
  userSettings!: UserSettings;
  private username!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    parcel: Parcel,
  },
  private dataSourceService: DataSourceService,
  private userSettingsService: UserSettingsService,
  private authService: AuthService,
  private spinnerService: SpinnerService) { 
    this.parcel = data.parcel;
    
    //TODO: retrieve user data sources from local cache instead. Get rid of waitFor function call
    const p = this.dataSourceService.getUserDataSources().then(((userDataSources) => {
      const mlsDataSources = this.dataSourceService.filterMlsDataSources(userDataSources);
      const prDataSources = this.dataSourceService.filterPrDataSources(userDataSources);

      this.mlsDataSources = mlsDataSources;
      this.prDataSources = prDataSources;
    })
  );
  this.dataSourceService.waitFor(p);
  }
  ngOnInit(){

    this.username = this.authService.getClaims()?.name;

    this.userSettingsService
      .getUserSettings(this.username)
      .pipe(tap((userSettings) => (this.userSettings = userSettings)))
      .subscribe();
  }
  saveUserSettings(event) {
    const spinnerRef = this.spinnerService.open();
    this.userSettingsService.saveUserSettings(this.userSettings, this.username).subscribe(() => {
      spinnerRef.close();
    });
  }

  loading(obsv: Observable<any>) {
    const spinnerRef = this.spinnerService.open();

    obsv.subscribe(() => {
      spinnerRef.close();
    });
  }
}
