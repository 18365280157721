import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject, DataMasterFileService, FormattingHelper } from '@datamaster/core';

@Component({
  selector: 'app-subject-detail',
  templateUrl: './subject-detail.component.html',
  styleUrls: ['./subject-detail.component.scss']
})
export class SubjectDetailComponent implements OnInit {

  subj!: Subject;

  sectionType = 'Subject';

  propertyAddress!: string;
  city!: string;
  state!: string;
  zip!: string;

  borrower!: string;
  ownerOfPublicRecord!: string;
  county!: string;

  legalDescription!: string;

  neighborhoodName!: string;
  mapReference!: string;
  censusTract!: string;

  apn!: string;
  taxYear!: string;
  reTaxes!: string;

  occupant_Owner!: string;
  occupant_Tenant!: string;
  occupant_Vacant!: string;
  specialAssessments!: string;
  pud_Y!: string;
  hoaAmount!: string;
  hoaPeriod_PerYear!: string;
  hoaPeriod_PerMonth!: string;

  propertyRightsAppraised_FeeSimple!: string;
  propertyRightsAppraised_Leasehold!: string;
  propertyRightsAppraised_Other!: string;
  propertyRightsOtherDescription!: string;

  assignment_Purchase!: string;
  assignment_Refinance!: string;
  assignment_Other!: string;
  assignmentOtherDescription!: string;

  lenderClient!: string;
  lenderClientAddress!: string;

  subjectOfferForSale_Y!: string;
  subjectOfferForSale_N!: string;

  reportedDataSources!: string;

  analyzedContract_Y!: string;
  analyzedContract_N!: string;
  analyzedContractExplanation!: string;
  contractAnalysisDescriptionBackfield!: string;
  contractPrice!: string;
  contractDate!: string;
  closeDate!: string;
  sellerOwnerOfPublicRecord_Y!: string;
  sellerOwnerOfPublicRecord_N!: string;
  sellerOwnerOfPublicRecordDataSource!: string;
  financialAssistance_Y!: string;
  financialAssistance_N!: string;
  financialAssistanceDescription!: string;
  concessionsAmount!: string;
  financialAssistanceUnknownAmount!: string;
  neighborhoodLocation_Urban!: string;
  neighborhoodLocation_Suburban!: string;
  neighborhoodLocation_Rural!: string;
  neighborhoodBuiltUp_PercentOver75!: string;
  neighborhoodBuiltUp_Percent25To75!: string;
  neighborhoodBuiltUp_PercentUnder25!: string;
  neighborhoodGrowth_Rapid!: string;
  neighborhoodGrowth_Stable!: string;
  neighborhoodGrowth_Slow!: string;
  propertyValues_Increasing!: string;
  propertyValues_Stable!: string;
  propertyValues_Declining!: string;
  demandSupply_Shortage!: string;
  demandSupply_InBalance!: string;
  demandSupply_OverSupply!: string;
  marketingTime_MonthUnder3!: string;
  marketingTime_Month3To6!: string;
  marketingTime_MonthOver6!: string;
  singleFamilyLowPrice!: string;
  singleFamilyHighPrice!: string;
  singleFamilyPredPrice!: string;
  singleFamilyLowAge!: string;
  singleFamilyHighAge!: string;
  singleFamilyPredAge!: string;
  oneUnitLowPrice!: string;
  oneUnitHighPrice!: string;
  oneUnitPredPrice!: string;
  oneUnitLowAge!: string;
  oneUnitHighAge!: string;
  oneUnitPredAge!: string;
  oneUnitPercentage!: string;
  twoToFourUnitPercentage!: string;
  multiFamilyPercentage!: string;
  commercialPercentage!: string;
  otherVacantPercentage!: string;
  neighborhoodBoundaries!: string;
  neighborhoodDescription!: string;
  marketConditions!: string;

  lotDimensions!: string;
  siteArea!: string;
  lotShape!: string;
  view!: string;

  zoningClassification!: string;
  zoningDescription!: string;
  zoningCompliance_Legal!: string;
  zoningCompliance_LegalNoncomforming!: string;
  zoningCompliance_Illegal!: string;
  zoningCompliance_NoZoning!: string;
  zoningComplianceIllegalDescription!: string;
  highestBestUseImproved_Y!: string;
  highestBestUseImproved_N!: string;
  highestBestUseImprovedDescription!: string;
  electricityPublic!: string;
  electricityOther!: string;
  electricityDescription!: string;
  gasPublic!: string;
  gasOther!: string;
  gasDescription!: string;
  waterPublic!: string;
  waterOther!: string;
  waterDescription!: string;
  sewerPublic!: string;
  sewerOther!: string;
  sewerDescription!: string;
  streetPublic!: string;
  streetPrivate!: string;
  streetOffSiteImprovements!: string;
  alleyPublic!: string;
  alleyPrivate!: string;
  alleyOffSiteImprovements!: string;
  femaFloodHazardArea_Y!: string;
  femaFloodHazardArea_N!: string;
  femaFloodZone!: string;
  femaMap!: string;
  femaMapDate!: string;
  utilitiesImprovementsTypical_Y!: string;
  utilitiesImprovementsTypical_N!: string;
  utilitiesImprovementsTypicalDescription!: string;
  adverseConditions_Y!: string;
  adverseConditions_N!: string;
  adverseConditionsDescription!: string;

  generalUnits_One!: string;
  generalUnits_OneWithAccessoryUnit!: string;
  numberOfStories!: string;
  attachment_Detached!: string;
  attachment_Attached!: string;
  attachment_SemiDetachedEndUnit!: string;
  construction_Exisiting!: string;
  construction_Proposed!: string;
  construction_UnderConstruction!: string;
  style!: string;
  yearBuilt!: string;
  effectiveAge!: string;
  concreteSlab!: string;
  crawlSpace!: string;
  fullBasement!: string;
  partialBasement!: string;
  basementTotalSqFt!: string;
  basementFinishedPercent!: string;
  outsideEntryExit!: string;
  sumpPump!: string;
  evidenceOfInfestation!: string;
  evidenceOfDampness!: string;
  evidenceOfSettlement!: string;
  evidenceOfDescription!: string;
  foundationWalls!: string;
  exteriorWalls!: string;
  roofSurface!: string;
  guttersAndDownspouts!: string;
  windowType!: string;
  stormSashAndInsulated!: string;
  screens!: string;
  floors!: string;
  walls!: string;
  trimAndFinish!: string;
  bathFloor!: string;
  bathWainscot!: string;
  fuelType!: string;
  fWA!: string;
  hWBB!: string;
  radiant!: string;
  heatOther!: string;
  heatOtherDescription!: string;
  centralAirConditioning!: string;
  individualCooling!: string;
  individualCoolingDescription!: string;
  coolingOther!: string;
  coolingOtherDescription!: string;
  carStorageNone!: string;
  driveway!: string;
  drivewaySpacesCount!: string;
  drivewaySurface!: string;
  garage!: string;
  garageCount!: string;
  carport!: string;
  carportCount!: string;
  carStorageAttached!: string;
  carStorageDetached!: string;
  carStorageBuiltIn!: string;
  woodStoves!: string;
  numberOfWoodStoves!: string;
  fireplaces!: string;
  numberOfFireplaces!: string;
  fence!: string;
  fenceDescription!: string;
  patioDeck!: string;
  patioDeckDescription!: string;
  porch!: string;
  porchDescription!: string;
  pool!: string;
  poolDescription!: string;
  amenitiesOther!: string;
  amenitiesOtherDescription!: string;
  atticNone!: string;
  atticStairs!: string;
  atticDropStair!: string;
  atticScuttle!: string;
  atticFloor!: string;
  atticHeated!: string;
  atticFinished!: string;
  refrigerator!: string;
  rangeOven!: string;
  dishwasher!: string;
  disposal!: string;
  microwave!: string;
  washerDryer!: string;
  appliancesOther!: string;
  appliancesOtherDescription!: string;
  totalAboveGradeRooms!: string;
  totalAboveGradeBedrooms!: string;
  totalAboveGradeBathrooms!: string;
  aboveGradeGla!: string;
  additionalFeaturesEnergyEfficientItems!: string;
  conditionDescription!: string;
  physicalDeficienciesOrAdverseConditions_Y!: string;
  physicalDeficienciesOrAdverseConditions_N!: string;
  physicalDeficienciesOrAdverseConditionsDescription!: string;
  conformToNeighborhood_Y!: string;
  conformToNeighborhood_N!: string;
  conformToNeighborhoodDescription!: string;

  constructor(private dataMasterFileService: DataMasterFileService,
    private router: Router) { }

  ngOnInit() {

    this.initialize();

    //not sure if this is best approach
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      this.initialize();
    });
  }

  initialize(){
    this.subj = this.dataMasterFileService.dataMasterFile.appraisal.subject;

    if (this.subj) {
      this.propertyAddress = this.getFormattedFieldResultFormattedValue('AddressLine1');
      this.city = this.getFormattedFieldResultFormattedValue('City');
      this.state = this.getFormattedFieldResultFormattedValue('State');
      this.zip = this.getFormattedFieldResultFormattedValue('Zip');
      this.borrower = this.getFormattedFieldResultFormattedValue('Borrower');
      this.ownerOfPublicRecord = this.getFormattedFieldResultFormattedValue('OwnerOfPublicRecord');
      this.county = this.getFormattedFieldResultFormattedValue('County');
      this.legalDescription = this.getFormattedFieldResultFormattedValue('LegalDescription');
      this.neighborhoodName = this.getFormattedFieldResultFormattedValue('NeighborhoodName');
      this.mapReference = this.getFormattedFieldResultFormattedValue('MapReference');
      this.censusTract = this.getFormattedFieldResultFormattedValue('CensusTract');
      this.apn = this.getFormattedFieldResultFormattedValue('Apn');
      this.taxYear = this.getFormattedFieldResultFormattedValue('TaxYear');
      this.reTaxes = this.getFormattedFieldResultFormattedValue('RETaxes');
      this.reTaxes = this.getFormattedFieldResultFormattedValue('Occupant_Owner');
      this.occupant_Tenant = this.getFormattedFieldResultFormattedValue('Occupant_Vacant');
      this.occupant_Vacant = this.getFormattedFieldResultFormattedValue('Occupant_Tenant');
      this.specialAssessments = this.getFormattedFieldResultFormattedValue('SpecialAssessments');
      this.pud_Y = this.getFormattedFieldResultFormattedValue('Pud_Y');
      this.hoaAmount = this.getFormattedFieldResultFormattedValue('HoaAmount');
      this.hoaPeriod_PerYear = this.getFormattedFieldResultFormattedValue('HoaPeriod_PerYear');
      this.hoaPeriod_PerMonth = this.getFormattedFieldResultFormattedValue('HoaPeriod_PerMonth');
      this.propertyRightsAppraised_FeeSimple = this.getFormattedFieldResultFormattedValue('PropertyRightsAppraised_FeeSimple');
      this.propertyRightsAppraised_Leasehold = this.getFormattedFieldResultFormattedValue('PropertyRightsAppraised_Leasehold');
      this.propertyRightsAppraised_Other = this.getFormattedFieldResultFormattedValue('PropertyRightsAppraised_Other');
      this.propertyRightsOtherDescription = this.getFormattedFieldResultFormattedValue('PropertyRightsOtherDescription');
      this.assignment_Purchase = this.getFormattedFieldResultFormattedValue('Assignment_Purchase');
      this.assignment_Refinance = this.getFormattedFieldResultFormattedValue('Assignment_Refinance');
      this.assignment_Other = this.getFormattedFieldResultFormattedValue('Assignment_Other');
      this.assignmentOtherDescription = this.getFormattedFieldResultFormattedValue('AssignmentOtherDescription');
      this.lenderClient = this.getFormattedFieldResultFormattedValue('LenderClient');
      this.lenderClientAddress = this.getFormattedFieldResultFormattedValue('LenderClientAddress');
      this.subjectOfferForSale_Y = this.getFormattedFieldResultFormattedValue('SubjectOfferForSale_Y');
      this.subjectOfferForSale_N = this.getFormattedFieldResultFormattedValue('SubjectOfferForSale_N');
      this.reportedDataSources = this.getFormattedFieldResultFormattedValue('ReportedDataSources');
      this.analyzedContract_Y = this.getFormattedFieldResultFormattedValue('AnalyzedContract_Y');
      this.analyzedContract_N = this.getFormattedFieldResultFormattedValue('AnalyzedContract_N');
      this.analyzedContractExplanation = this.getFormattedFieldResultFormattedValue('AnalyzedContractExplanation');
      this.contractAnalysisDescriptionBackfield = this.getFormattedFieldResultFormattedValue('ContractAnalysisDescriptionBackfield');
      this.contractPrice = this.getFormattedFieldResultFormattedValue('ContractPrice');
      this.contractDate = this.getFormattedFieldResultFormattedValue('ContractDate');   //Might need to change this to DateOfContract??? No.
      this.closeDate = this.getFormattedFieldResultFormattedValue('CloseDate');
      this.sellerOwnerOfPublicRecord_Y = this.getFormattedFieldResultFormattedValue('SellerOwnerOfPublicRecord_Y');
      this.sellerOwnerOfPublicRecord_N = this.getFormattedFieldResultFormattedValue('SellerOwnerOfPublicRecord_N');
      this.sellerOwnerOfPublicRecordDataSource = this.getFormattedFieldResultFormattedValue('SellerOwnerOfPublicRecordDataSource');
      this.financialAssistance_Y = this.getFormattedFieldResultFormattedValue('FinancialAssistance_Y');
      this.financialAssistance_N = this.getFormattedFieldResultFormattedValue('FinancialAssistance_N');
      this.financialAssistanceDescription = this.getFormattedFieldResultFormattedValue('FinancialAssistanceDescription');
      this.concessionsAmount = this.getFormattedFieldResultFormattedValue('ConcessionsAmount');
      this.financialAssistanceUnknownAmount = this.getFormattedFieldResultFormattedValue('FinancialAssistanceUnknownAmount');
      this.neighborhoodLocation_Urban = this.getFormattedFieldResultFormattedValue('NeighborhoodLocation_Urban');
      this.neighborhoodLocation_Suburban = this.getFormattedFieldResultFormattedValue('NeighborhoodLocation_Suburban');
      this.neighborhoodLocation_Rural = this.getFormattedFieldResultFormattedValue('NeighborhoodLocation_Rural');
      this.neighborhoodBuiltUp_PercentOver75 = this.getFormattedFieldResultFormattedValue('NeighborhoodBuiltUp_PercentOver75');
      this.neighborhoodBuiltUp_Percent25To75 = this.getFormattedFieldResultFormattedValue('NeighborhoodBuiltUp_Percent25To75');
      this.neighborhoodBuiltUp_PercentUnder25 = this.getFormattedFieldResultFormattedValue('NeighborhoodBuiltUp_PercentUnder25');
      this.neighborhoodGrowth_Rapid = this.getFormattedFieldResultFormattedValue('NeighborhoodGrowth_Rapid');
      this.neighborhoodGrowth_Stable = this.getFormattedFieldResultFormattedValue('NeighborhoodGrowth_Stable');
      this.neighborhoodGrowth_Slow = this.getFormattedFieldResultFormattedValue('NeighborhoodGrowth_Slow');
      this.propertyValues_Increasing = this.getFormattedFieldResultFormattedValue('PropertyValues_Increasing');
      this.propertyValues_Stable = this.getFormattedFieldResultFormattedValue('PropertyValues_Stable');
      this.propertyValues_Declining = this.getFormattedFieldResultFormattedValue('PropertyValues_Declining');
      this.demandSupply_Shortage = this.getFormattedFieldResultFormattedValue('DemandSupply_Shortage');
      this.demandSupply_InBalance = this.getFormattedFieldResultFormattedValue('DemandSupply_InBalance');
      this.demandSupply_OverSupply = this.getFormattedFieldResultFormattedValue('DemandSupply_OverSupply');
      this.marketingTime_MonthUnder3 = this.getFormattedFieldResultFormattedValue('MarketingTime_MonthUnder3');
      this.marketingTime_Month3To6 = this.getFormattedFieldResultFormattedValue('MarketingTime_Month3To6');
      this.marketingTime_MonthOver6 = this.getFormattedFieldResultFormattedValue('MarketingTime_MonthOver6');
      this.singleFamilyLowPrice = this.getFormattedFieldResultFormattedValue('SingleFamilyLowPrice');
      this.singleFamilyHighPrice = this.getFormattedFieldResultFormattedValue('SingleFamilyHighPrice');
      this.singleFamilyPredPrice = this.getFormattedFieldResultFormattedValue('SingleFamilyPredPrice');
      this.singleFamilyLowAge = this.getFormattedFieldResultFormattedValue('SingleFamilyLowAge');
      this.singleFamilyHighAge = this.getFormattedFieldResultFormattedValue('SingleFamilyHighAge');
      this.singleFamilyPredAge = this.getFormattedFieldResultFormattedValue('SingleFamilyPredAge');
      this.oneUnitLowPrice = this.getFormattedFieldResultFormattedValue('OneUnitLowPrice');
      this.oneUnitHighPrice = this.getFormattedFieldResultFormattedValue('OneUnitHighPrice');
      this.oneUnitPredPrice = this.getFormattedFieldResultFormattedValue('OneUnitPredPrice');
      this.oneUnitLowAge = this.getFormattedFieldResultFormattedValue('OneUnitLowAge');
      this.oneUnitHighAge = this.getFormattedFieldResultFormattedValue('OneUnitHighAge');
      this.oneUnitPredAge = this.getFormattedFieldResultFormattedValue('OneUnitPredAge');
      this.oneUnitPercentage = this.getFormattedFieldResultFormattedValue('OneUnitPercentage');
      this.twoToFourUnitPercentage = this.getFormattedFieldResultFormattedValue('TwoToFourUnitPercentage');
      this.multiFamilyPercentage = this.getFormattedFieldResultFormattedValue('MultiFamilyPercentage');
      this.commercialPercentage = this.getFormattedFieldResultFormattedValue('CommercialPercentage');
      this.otherVacantPercentage = this.getFormattedFieldResultFormattedValue('OtherVacantPercentage');
      this.neighborhoodBoundaries = this.getFormattedFieldResultFormattedValue('NeighborhoodBoundaries');
      this.neighborhoodDescription = this.getFormattedFieldResultFormattedValue('NeighborhoodDescription');
      this.marketConditions = this.getFormattedFieldResultFormattedValue('MarketConditions');

      this.lotDimensions = this.getFormattedFieldResultFormattedValue('LotDimensions');
      this.siteArea = this.getFormattedFieldResultFormattedValue('SiteArea');
      this.lotShape = this.getFormattedFieldResultFormattedValue('LotShape');
      this.view = this.getFormattedFieldResultFormattedValue('View');

      this.zoningClassification = this.getFormattedFieldResultFormattedValue('ZoningClassification');
      this.zoningDescription = this.getFormattedFieldResultFormattedValue('ZoningDescription');
      this.zoningCompliance_Legal = this.getFormattedFieldResultFormattedValue('ZoningCompliance_Legal');
      this.zoningCompliance_LegalNoncomforming = this.getFormattedFieldResultFormattedValue('ZoningCompliance_LegalNoncomforming');
      this.zoningCompliance_Illegal = this.getFormattedFieldResultFormattedValue('ZoningCompliance_Illegal');
      this.zoningCompliance_NoZoning = this.getFormattedFieldResultFormattedValue('ZoningCompliance_NoZoning');
      this.zoningComplianceIllegalDescription = this.getFormattedFieldResultFormattedValue('ZoningComplianceIllegalDescription');
      this.highestBestUseImproved_Y = this.getFormattedFieldResultFormattedValue('HighestBestUseImproved_Y');
      this.highestBestUseImproved_N = this.getFormattedFieldResultFormattedValue('HighestBestUseImproved_N');
      this.highestBestUseImprovedDescription = this.getFormattedFieldResultFormattedValue('HighestBestUseImprovedDescription');
      this.electricityPublic = this.getFormattedFieldResultFormattedValue('ElectricityPublic');
      this.electricityOther = this.getFormattedFieldResultFormattedValue('ElectricityOther');
      this.electricityDescription = this.getFormattedFieldResultFormattedValue('ElectricityDescription');
      this.gasPublic = this.getFormattedFieldResultFormattedValue('GasPublic');
      this.gasOther = this.getFormattedFieldResultFormattedValue('GasOther');
      this.gasDescription = this.getFormattedFieldResultFormattedValue('GasDescription');
      this.waterPublic = this.getFormattedFieldResultFormattedValue('WaterPublic');
      this.waterOther = this.getFormattedFieldResultFormattedValue('WaterOther');
      this.waterDescription = this.getFormattedFieldResultFormattedValue('WaterDescription');
      this.sewerPublic = this.getFormattedFieldResultFormattedValue('SewerPublic');
      this.sewerOther = this.getFormattedFieldResultFormattedValue('SewerOther');
      this.sewerDescription = this.getFormattedFieldResultFormattedValue('SewerDescription');
      this.streetPublic = this.getFormattedFieldResultFormattedValue('StreetPublic');
      this.streetPrivate = this.getFormattedFieldResultFormattedValue('StreetPrivate');
      this.streetOffSiteImprovements = this.getFormattedFieldResultFormattedValue('StreetOffSiteImprovements');
      this.alleyPublic = this.getFormattedFieldResultFormattedValue('AlleyPublic');
      this.alleyPrivate = this.getFormattedFieldResultFormattedValue('AlleyPrivate');
      this.alleyOffSiteImprovements = this.getFormattedFieldResultFormattedValue('AlleyOffSiteImprovements');
      this.femaFloodHazardArea_Y = this.getFormattedFieldResultFormattedValue('FemaFloodHazardArea_Y');
      this.femaFloodHazardArea_N = this.getFormattedFieldResultFormattedValue('FemaFloodHazardArea_N');
      this.femaFloodZone = this.getFormattedFieldResultFormattedValue('FemaFloodZone');
      this.femaMap = this.getFormattedFieldResultFormattedValue('FemaMap');
      this.femaMapDate = this.getFormattedFieldResultFormattedValue('FemaMapDate');
      this.utilitiesImprovementsTypical_Y = this.getFormattedFieldResultFormattedValue('UtilitiesImprovementsTypical_Y');
      this.utilitiesImprovementsTypical_N = this.getFormattedFieldResultFormattedValue('UtilitiesImprovementsTypical_N');
      this.utilitiesImprovementsTypicalDescription = this.getFormattedFieldResultFormattedValue('UtilitiesImprovementsTypicalDescription');
      this.adverseConditions_Y = this.getFormattedFieldResultFormattedValue('AdverseConditions_Y');
      this.adverseConditions_N = this.getFormattedFieldResultFormattedValue('AdverseConditions_N');
      this.adverseConditionsDescription = this.getFormattedFieldResultFormattedValue('AdverseConditionsDescription');
      this.generalUnits_One = this.getFormattedFieldResultFormattedValue('GeneralUnits_One');
      this.generalUnits_OneWithAccessoryUnit = this.getFormattedFieldResultFormattedValue('GeneralUnits_OneWithAccessoryUnit');
      this.numberOfStories = this.getFormattedFieldResultFormattedValue('NumberOfStories');
      this.attachment_Detached = this.getFormattedFieldResultFormattedValue('Attachment_Detached');
      this.attachment_Attached = this.getFormattedFieldResultFormattedValue('Attachment_Attached');
      this.attachment_SemiDetachedEndUnit = this.getFormattedFieldResultFormattedValue('Attachment_SemiDetachedEndUnit');
      this.construction_Exisiting = this.getFormattedFieldResultFormattedValue('Construction_Exisiting');
      this.construction_Proposed = this.getFormattedFieldResultFormattedValue('Construction_Proposed');
      this.construction_UnderConstruction = this.getFormattedFieldResultFormattedValue('Construction_UnderConstruction');
      this.style = this.getFormattedFieldResultFormattedValue('Style');
      this.yearBuilt = this.getFormattedFieldResultFormattedValue('YearBuilt');
      this.effectiveAge = this.getFormattedFieldResultFormattedValue('EffectiveAge');
      this.concreteSlab = this.getFormattedFieldResultFormattedValue('ConcreteSlab');
      this.crawlSpace = this.getFormattedFieldResultFormattedValue('CrawlSpace');
      this.fullBasement = this.getFormattedFieldResultFormattedValue('FullBasement');
      this.partialBasement = this.getFormattedFieldResultFormattedValue('PartialBasement');
      this.basementTotalSqFt = this.getFormattedFieldResultFormattedValue('BasementTotalSqFt');
      this.basementFinishedPercent = this.getFormattedFieldResultFormattedValue('BasementFinishedPercent');
      this.outsideEntryExit = this.getFormattedFieldResultFormattedValue('OutsideEntryExit');
      this.sumpPump = this.getFormattedFieldResultFormattedValue('SumpPump');
      this.evidenceOfInfestation = this.getFormattedFieldResultFormattedValue('EvidenceOfInfestation');
      this.evidenceOfDampness = this.getFormattedFieldResultFormattedValue('EvidenceOfDampness');
      this.evidenceOfSettlement = this.getFormattedFieldResultFormattedValue('EvidenceOfSettlement');
      this.evidenceOfDescription = this.getFormattedFieldResultFormattedValue('EvidenceOfDescription');
      this.foundationWalls = this.getFormattedFieldResultFormattedValue('FoundationWalls');
      this.exteriorWalls = this.getFormattedFieldResultFormattedValue('ExteriorWalls');
      this.roofSurface = this.getFormattedFieldResultFormattedValue('RoofSurface');
      this.guttersAndDownspouts = this.getFormattedFieldResultFormattedValue('GuttersAndDownspouts');
      this.windowType = this.getFormattedFieldResultFormattedValue('WindowType');
      this.stormSashAndInsulated = this.getFormattedFieldResultFormattedValue('StormSashAndInsulated');
      this.screens = this.getFormattedFieldResultFormattedValue('Screens');
      this.floors = this.getFormattedFieldResultFormattedValue('Floors');
      this.walls = this.getFormattedFieldResultFormattedValue('Walls');
      this.trimAndFinish = this.getFormattedFieldResultFormattedValue('TrimAndFinish');
      this.bathFloor = this.getFormattedFieldResultFormattedValue('BathFloor');
      this.bathWainscot = this.getFormattedFieldResultFormattedValue('BathWainscot');
      this.fuelType = this.getFormattedFieldResultFormattedValue('FuelType');
      this.fWA = this.getFormattedFieldResultFormattedValue('FWA');
      this.hWBB = this.getFormattedFieldResultFormattedValue('HWBB');
      this.radiant = this.getFormattedFieldResultFormattedValue('Radiant');
      this.heatOther = this.getFormattedFieldResultFormattedValue('HeatOther');
      this.heatOtherDescription = this.getFormattedFieldResultFormattedValue('HeatOtherDescription');
      this.centralAirConditioning = this.getFormattedFieldResultFormattedValue('CentralAirConditioning');
      this.individualCooling = this.getFormattedFieldResultFormattedValue('IndividualCooling');
      this.individualCoolingDescription = this.getFormattedFieldResultFormattedValue('IndividualCoolingDescription');
      this.coolingOther = this.getFormattedFieldResultFormattedValue('CoolingOther');
      this.coolingOtherDescription = this.getFormattedFieldResultFormattedValue('CoolingOtherDescription');
      this.carStorageNone = this.getFormattedFieldResultFormattedValue('CarStorageNone');
      this.driveway = this.getFormattedFieldResultFormattedValue('Driveway');
      this.drivewaySpacesCount = this.getFormattedFieldResultFormattedValue('DrivewaySpacesCount');
      this.drivewaySurface = this.getFormattedFieldResultFormattedValue('DrivewaySurface');
      this.garage = this.getFormattedFieldResultFormattedValue('Garage');
      this.garageCount = this.getFormattedFieldResultFormattedValue('GarageCount');
      this.carport = this.getFormattedFieldResultFormattedValue('Carport');
      this.carportCount = this.getFormattedFieldResultFormattedValue('CarportCount');
      this.carStorageAttached = this.getFormattedFieldResultFormattedValue('CarStorageAttached');
      this.carStorageDetached = this.getFormattedFieldResultFormattedValue('CarStorageDetached');
      this.carStorageBuiltIn = this.getFormattedFieldResultFormattedValue('CarStorageBuiltIn');
      this.woodStoves = this.getFormattedFieldResultFormattedValue('WoodStoves');
      this.numberOfWoodStoves = this.getFormattedFieldResultFormattedValue('NumberOfWoodStoves');
      this.fireplaces = this.getFormattedFieldResultFormattedValue('Fireplaces');
      this.numberOfFireplaces = this.getFormattedFieldResultFormattedValue('NumberOfFireplaces');
      this.fence = this.getFormattedFieldResultFormattedValue('Fence');
      this.fenceDescription = this.getFormattedFieldResultFormattedValue('FenceDescription');
      this.patioDeck = this.getFormattedFieldResultFormattedValue('PatioDeck');
      this.patioDeckDescription = this.getFormattedFieldResultFormattedValue('PatioDeckDescription');
      this.porch = this.getFormattedFieldResultFormattedValue('Porch');
      this.porchDescription = this.getFormattedFieldResultFormattedValue('PorchDescription');
      this.pool = this.getFormattedFieldResultFormattedValue('Pool');
      this.poolDescription = this.getFormattedFieldResultFormattedValue('PoolDescription');
      this.amenitiesOther = this.getFormattedFieldResultFormattedValue('AmenitiesOther');
      this.amenitiesOtherDescription = this.getFormattedFieldResultFormattedValue('AmenitiesOtherDescription');
      this.atticNone = this.getFormattedFieldResultFormattedValue('AtticNone');
      this.atticStairs = this.getFormattedFieldResultFormattedValue('AtticStairs');
      this.atticDropStair = this.getFormattedFieldResultFormattedValue('AtticDropStair');
      this.atticScuttle = this.getFormattedFieldResultFormattedValue('AtticScuttle');
      this.atticFloor = this.getFormattedFieldResultFormattedValue('AtticFloor');
      this.atticHeated = this.getFormattedFieldResultFormattedValue('AtticHeated');
      this.atticFinished = this.getFormattedFieldResultFormattedValue('AtticFinished');
      this.refrigerator = this.getFormattedFieldResultFormattedValue('Refrigerator');
      this.rangeOven = this.getFormattedFieldResultFormattedValue('RangeOven');
      this.dishwasher = this.getFormattedFieldResultFormattedValue('Dishwasher');
      this.disposal = this.getFormattedFieldResultFormattedValue('Disposal');
      this.microwave = this.getFormattedFieldResultFormattedValue('Microwave');
      this.washerDryer = this.getFormattedFieldResultFormattedValue('WasherDryer');
      this.appliancesOther = this.getFormattedFieldResultFormattedValue('AppliancesOther');
      this.appliancesOtherDescription = this.getFormattedFieldResultFormattedValue('AppliancesOtherDescription');
      this.totalAboveGradeRooms = this.getFormattedFieldResultFormattedValue('TotalAboveGradeRooms');
      this.totalAboveGradeBedrooms = this.getFormattedFieldResultFormattedValue('TotalAboveGradeBedrooms');
      this.totalAboveGradeBathrooms = this.getFormattedFieldResultFormattedValue('TotalAboveGradeBathrooms');
      this.aboveGradeGla = this.getFormattedFieldResultFormattedValue('AboveGradeGla');
      this.additionalFeaturesEnergyEfficientItems = this.getFormattedFieldResultFormattedValue('AdditionalFeaturesEnergyEfficientItems');
      this.conditionDescription = this.getFormattedFieldResultFormattedValue('ConditionDescription');
      this.physicalDeficienciesOrAdverseConditions_Y = this.getFormattedFieldResultFormattedValue('PhysicalDeficienciesOrAdverseConditions_Y');
      this.physicalDeficienciesOrAdverseConditions_N = this.getFormattedFieldResultFormattedValue('PhysicalDeficienciesOrAdverseConditions_N');
      this.physicalDeficienciesOrAdverseConditionsDescription = this.getFormattedFieldResultFormattedValue('PhysicalDeficienciesOrAdverseConditionsDescription');
      this.conformToNeighborhood_Y = this.getFormattedFieldResultFormattedValue('ConformToNeighborhood_Y');
      this.conformToNeighborhood_N = this.getFormattedFieldResultFormattedValue('ConformToNeighborhood_N');
      this.conformToNeighborhoodDescription = this.getFormattedFieldResultFormattedValue('ConformToNeighborhoodDescription');
    }
  }

  getFormattedFieldResultFormattedValue(field: string): string {

    return FormattingHelper.getFormattedFieldResultFormattedValue(field, this.sectionType, this.subj.formattedFieldResults, this.dataMasterFileService.formatFieldMaps);

  }

}
