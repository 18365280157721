<div class="property-photo">
    <span *ngIf="!primaryPhotoUrl" class="fal fa-image fa-5x photo-icon" style="width: 129;height: 100;"></span>
    <span class="primary-photo" *ngIf="primaryPhotoUrl"
    [ngStyle]="{'background-image': 'url(' + primaryPhotoUrl + ')'}"></span>
</div>
<div>
    <div>
        <span class="property-address-line-one">{{addressLine1}}</span>
    </div>
    <div>
        <span class="property-address-line-two">{{addressLine2}}</span>
    </div>
    <div>
        <label class="property-apn-label">APN:</label><span class="property-apn">{{apn}}</span>
    </div>
</div>