<app-dialog-header title="{{data.title}}"></app-dialog-header>
<div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <p class="dialog-message">{{ data.message }}</p>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="dialog-footer" align="end">
    <button class="btn btn-confirm" (click)="close()">Close</button>
</div>