import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { DataMasterFileService, Graph, GraphsService, MarketAnalysisReportService, MarketConditionsForm, MarketConditionsGraph, MarketConditionsGraphCalculationResult, PropertyUsageType, SpinnerService, UserSettings, UserSettingsService, UserStateModel, UserStateService } from '@datamaster/core';
import { lastValueFrom, Subscription} from 'rxjs';
import { ListItem } from '../../../interfaces/list-item.interface';
import { DualListSelectComponent } from '../../common/dual-list-select/dual-list-select.component';
import { McGraphComponent } from './mc-graph/mc-graph.component';

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss']
})
export class GraphsComponent implements OnInit {

  hasMarketConditionsData = false;
  marketConditionsGraphs: MarketConditionsGraph[];
  marketConditionsForm: MarketConditionsForm;
  userSettings: UserSettings;

  graphListExpanded : boolean;
  saveEnabled : boolean;
  propertyUsageType : PropertyUsageType;

  graphs : ListItem[];
  userGraphs : ListItem[];

  get userState(): UserStateModel {
    return this._userStateService.user;
  }

  private _subscription = new Subscription();
  @ViewChildren(McGraphComponent) mcGraphComponents : QueryList<McGraphComponent>;
  @ViewChild('duallistselect') dualListSelectComponent : DualListSelectComponent;

  constructor(private _marketAnalysisReportService: MarketAnalysisReportService,
    private _userSettingsService: UserSettingsService,
    private _userStateService: UserStateService,
    public dataMasterFileService: DataMasterFileService,
    private _graphsService: GraphsService,
    private _spinnerService : SpinnerService,
    private router: Router) { }

  ngOnInit() {
    this.propertyUsageType = this.router.url.includes('true') ? PropertyUsageType.NeighborhoodAnalysis : PropertyUsageType.MarketConditions;
    this.userSettings = this._userSettingsService.userSettings;
    this.marketConditionsGraphs = this._marketAnalysisReportService.marketConditionsGraphs.filter((x) => x.graphDetails.reportUsageType == this.propertyUsageType);
    this.marketConditionsForm = this.propertyUsageType == PropertyUsageType.MarketConditions ? this._marketAnalysisReportService.marketConditionsForm : this._marketAnalysisReportService.neighborhoodAnalysisForm;
    this.hasMarketConditionsData = this.marketConditionsGraphs && this.marketConditionsGraphs.length > 0;

    const getGraphs$ = this._graphsService.getGraphs(this.userState.user.userName);

    this._subscription.add(getGraphs$.subscribe(results =>{
      this.graphs = results.graphs.map(graph=> this.graphToListItem(graph));
      this.userGraphs = results.userGraphs.map(graph=> this.graphToListItem(graph));
    }));
  }
  graphToListItem(graph : Graph) : ListItem{
    return { id : graph.id, name : graph.displayName}
  }

  removeGraph(marketConditionsGraph: MarketConditionsGraph) {
    const graphIndex = this.marketConditionsGraphs.filter((a) => a.graphDetails.reportUsageType == marketConditionsGraph.graphDetails.reportUsageType).indexOf(marketConditionsGraph);
    this._marketAnalysisReportService.marketConditionsGraphs.splice(graphIndex, 1);
  }

  reInitGraphs() {
    this.marketConditionsGraphs = this._marketAnalysisReportService.marketConditionsGraphs.filter((x) => x.graphDetails.reportUsageType == this.propertyUsageType);
  }

  setGraphs(mcGraphs: MarketConditionsGraph[]) {    
    if (mcGraphs) {
     const graphsExists = mcGraphs.filter((a) => a.graphDetails.reportUsageType == this.propertyUsageType).length > 0;
     if (!graphsExists) {
      this._marketAnalysisReportService.marketConditionsGraphs = this._marketAnalysisReportService.marketConditionsGraphs.concat(mcGraphs);
     }            
    } else {
      this._marketAnalysisReportService.marketConditionsGraphs.filter((a) => a.graphDetails.reportUsageType == this.propertyUsageType)
      .forEach((marketConditionsGraph) => {
        this._marketAnalysisReportService.marketConditionsGraphs.splice(
          this._marketAnalysisReportService.marketConditionsGraphs.indexOf(marketConditionsGraph), 1
        );
      });
      this._marketAnalysisReportService.marketConditionsGraphs = mcGraphs;
    }   
}

  async save(){
    const spinnerRef = this._spinnerService.open();

    await this.getSaveUserSelectedGraphs().catch((err)=>{
      spinnerRef.close();
      console.log(err);
      throw new Error();
    });

    let marketConditionsGraphCalculationResult;
    await this.getCalculateGraphs().then((data)=>{
      marketConditionsGraphCalculationResult = data;
    }).catch((err)=>{
      spinnerRef.close();
      console.log(err);
      throw new Error();
    });
    this.setGraphs(marketConditionsGraphCalculationResult.marketConditionsGraphs);
    spinnerRef.close();
    this.reInitGraphs();
    this.graphListExpanded = false;
  }
  getSaveUserSelectedGraphs() : Promise<any>{
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const result = await lastValueFrom(this._graphsService.saveUserSelectedGraphs(this.dualListSelectComponent.userListItems.map(a=> a.id), this.userState.user.userName));
      if(result){
        resolve(result);
      }else{
        reject();
        throw new Error('');
      }
    });
  }
  getCalculateGraphs() : Promise<any>{
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject)=>{
      const marketConditionsGraphCalculationResult = await lastValueFrom(this._graphsService.calculateGraphs(this.marketConditionsForm,this._userStateService.userDataForSearch, PropertyUsageType.MarketConditions));
      if(marketConditionsGraphCalculationResult){
        resolve(marketConditionsGraphCalculationResult);
      }else{
        reject();
        throw new Error('');
      }
    });
  }

  onEditedList(edited : boolean){
    this.saveEnabled = edited;
  }

  cancel(){
    this.dualListSelectComponent.resetLists();
    this.dualListSelectComponent.resetSelection();
    this.graphListExpanded = false;
  }

  toggleGraphList(){
    if(this.graphListExpanded){
      this.dualListSelectComponent.resetLists();
      this.dualListSelectComponent.resetSelection();
    }
    this.graphListExpanded =  !this.graphListExpanded;
  }

  async copyGraphsToClipboard(){
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const columnCount = 3;

    const canvases = this.mcGraphComponents.map(a=> a.generateCanvasForClipboard());
    const canvasForSizing = canvases[0];
    const canvasWidth = canvasForSizing.width;
    const canvasHeigth = canvasForSizing.height;

    canvas.width = canvasWidth * columnCount;
    canvas.height = Math.ceil(canvases.length / columnCount) * canvasHeigth;

    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);

    let xPosition = 0;
    let yPosition = 0;

    canvases.forEach(graphCanvas=> {
      const xCurrentPosition = xPosition * canvasWidth;
      const yCurrentPosition = yPosition * canvasHeigth;
      context.drawImage(graphCanvas, xCurrentPosition, yCurrentPosition);

      xPosition++;
      if(xPosition % columnCount === 0){
        xPosition = 0;
        yPosition++;
      }
    });

    canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({'image/png': blob})]));
  }
}

